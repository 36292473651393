import React from 'react';
import {SectionList, Text, View, StyleSheet} from 'react-native';
import moment from "moment";
import CardTile from "./CardTile";
import theme from "../styles/theme";
import {useMemo} from "react";
import {Dimensions} from "react-native";


const createRows = (data, columns) => {
  const rows = [];
  data.forEach((item, idx) => {
    if (idx % columns === 0) {
      // start a new row
      rows.push([item]);
    } else {
      // add to the existing row
      rows[rows.length - 1].push(item);
    }
  });
  return rows;
};


// Function to group cards by time intervals
const groupCardsByTimeInterval = (cards, numColumns) => {
    let pastDay = [];
    let pastWeek = [];
    let older = [];

    const now = moment();
    cards.forEach(card => {
        const creationTime = moment(card.creation_time);
        if (now.diff(creationTime, 'days') < 1) {
            pastDay.push(card);
        } else if (now.diff(creationTime, 'days') < 7) {
            pastWeek.push(card);
        } else {
            older.push(card);
        }
    });

  // Adjust each group for multi-column layout
  return [
    { title: 'PAST DAY', data: createRows(pastDay, numColumns) },
    { title: 'PAST WEEK', data: createRows(pastWeek, numColumns) },
    { title: 'OLDER', data: createRows(older, numColumns) }
  ].filter(section => section.data.length > 0);
};


const itemRenderer = (rowItems, rowIndex) => {
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'flex-start', width: '100%' }}>
      {rowItems.map((item, index) => (
        <CardTile key={`card-${item.uuid}-${rowIndex}-${index}`} item={item} />
      ))}
    </View>
  );
};



const renderItem = ({ item, index, section }) => {
  // No need to check for index % 3 here, as each item is already a row
  return itemRenderer(item, index);
};



const renderSectionHeader = ({ section: { title } }) => (
  <View style={styles.sectionHeaderContainer}>
    <Text style={styles.sectionHeaderText}>{title}</Text>
  </View>
);


const keyExtractor = (item) => {
  // Use the UUID of the first card in the row for the key
  return item[0].uuid;
};


function TimeGallery({
   nextPageURL,
   cards,
   loadMore,
   loading,
   onRefresh,
   headerComponent,
   footerComponent,
   emptyComponent,
}) {
  const windowWidth = Dimensions.get('window').width;
  const MAX_CARD_TILE_WIDTH = 180; // A

  const numColumns = useMemo(() => {
    const calculatedColumns = Math.floor(windowWidth / MAX_CARD_TILE_WIDTH) - 1;
    return Math.max(calculatedColumns, 3); // Ensure a minimum of 3 columns
  }, [windowWidth]);

  const sections = groupCardsByTimeInterval(cards, numColumns);

  return (
    <SectionList
      ListHeaderComponent={headerComponent}
      sections={sections}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      keyExtractor={(item) => keyExtractor(item)}
      onEndReached={() => {
        if (!loading && nextPageURL) {
          loadMore(nextPageURL);
        }
      }}
      onEndReachedThreshold={0.5}
      refreshing={loading}
      onRefresh={onRefresh}
      ListFooterComponent={footerComponent}
      ListEmptyComponent={emptyComponent}
      keyboardShouldPersistTaps="handled"
      removeClippedSubviews={false}
    />
  );
}


const styles = StyleSheet.create({
  sectionHeaderContainer: {
    width: '100%',
    alignItems: 'flex-start',
    backgroundColor: theme.colors.beige, // or any other color from your theme
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopWidth: 0,
    borderColor: theme.colors.lightgray,
    padding: theme.spacings.xsmall,
    marginBottom: theme.spacings.xsmall,
  },
  sectionHeaderText: {
    color: theme.colors.gray, // Choose a color that contrasts well with the background
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.medium,
  },
});

export default TimeGallery;

