import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import theme from '../styles/theme'; // Adjust import path as necessary
import CardTile from "./CardTile";
import SvgRiffLine from "../assets/images/RiffLine";

const SimplifiedWorldComponent = ({ world }) => {
  return (
    <View style={styles.container}>
      {world.cards.map((card, index) => (
        <View key={card.uuid} style={styles.cardContainer}>
          {index > 0 &&
            <SvgRiffLine style={styles.connectorLine} height={theme.spacings.medium}/>
          }
          <CardTile item={card} margin={0} />
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: theme.spacings.medium,
    marginBottom: 0,
    marginHorizontal: theme.spacings.medium,
  },
  cardContainer: {
    alignItems: 'center',
    marginVertical: 0,
  },
  connectorLine: {
  },
});

export default React.memo(SimplifiedWorldComponent);
