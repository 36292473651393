import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions } from 'react-native';
import { Image } from 'react-native'; // Adjusted for correct Image import
import UserCircle from './UserCircle';
import theme from '../styles/theme';
import { useNavigation } from '@react-navigation/native';

const windowWidth = Dimensions.get('window').width;
export const WORLD_WIDTH = Math.min(windowWidth - 20, 400);
export const WORLD_HEIGHT = WORLD_WIDTH /  1.618;
export const CARD_SIZE = (WORLD_WIDTH / 3) - 20; // Adjusted for dynamic card size calculation

const WorldComponent = ({ world, disableTouch = false }) => {
  const navigation = useNavigation();

  const navigateToWorldDetail = () => {
    navigation.navigate('CardDetail', { card: { uuid: world.root_card.uuid, world_uuid: world.uuid } });
  };

  const calculateOverlap = (totalCards) => {
    if (totalCards <= 1) return CARD_SIZE; // No overlap if only one card
    const maxOverlap = (WORLD_HEIGHT - CARD_SIZE) / (totalCards - 1);
    return Math.min(CARD_SIZE, maxOverlap);
  };

  const renderCardStack = () => {
    // reverse order of world.cards
    const reversedCards = [...world.cards].reverse();
    const overlap = calculateOverlap(reversedCards.length);

    return (
      <View style={styles.cardStackContainer}>
        {reversedCards.map((card, index) => (
          <Image
            key={card.uuid}
            source={{ uri: card.thumbnail }}
            style={[
              styles.cardImage,
              {
                top: index * overlap,
                // top: 0,
                zIndex: reversedCards.length + index, // Ensure stack order
              },
            ]}
          />
        ))}
        <View style={styles.totalCardsIndicator}>
          <Text style={styles.totalCardsText}>{world.total_cards}</Text>
        </View>
      </View>
    );
  };

  const renderContributors = () => {
    const contributorElements = world.contributors.map((contributor, index) => {
      const marginLeft = index === 0 ? 0 : -10;
      return (
        <View key={contributor.uuid} style={{ marginLeft }}>
          <UserCircle profile_uuid={contributor.uuid} size={40} />
        </View>
      );
    });

    if (world.additional_contributors_count > 0) {
      contributorElements.push(
        <View key="additional-contributors" style={styles.additionalContributorsCircle}>
          <Text style={styles.additionalContributorsText}>+{world.additional_contributors_count}</Text>
        </View>
      );
    }

    return contributorElements;
  };

  return (
    <TouchableOpacity onPress={navigateToWorldDetail} style={styles.pressableContainer} disabled={disableTouch}>
      <View style={styles.container}>
        <View style={styles.content}>
          {renderCardStack()}
          <View style={styles.detailSection}>
            <View style={styles.textArea}>
              <Text style={styles.worldName} adjustsFontSizeToFit={true} numberOfLines={2} minimumFontScale={0.5}>
                {world.name.toUpperCase()}
              </Text>
              <Text style={styles.summary} adjustsFontSizeToFit={true} numberOfLines={5} minimumFontScale={0.7}>
                {world.summary}
              </Text>
            </View>
            <View style={styles.contributorsContainer}>
              {renderContributors()}
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  pressableContainer: {
    width: WORLD_WIDTH,
    marginHorizontal: theme.spacings.small,
    alignSelf: 'flex-start',
  },
  container: {
    flexDirection: 'row',
    backgroundColor: theme.colors.gray,
    borderRadius: theme.borderRadius.medium,
    padding: theme.spacings.small,
    alignItems: 'flex-start',
    minHeight: WORLD_HEIGHT,
    width: '100%', // Ensure container fills the width
  },
  content: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flex: 1,
  },
  cardStackContainer: {
    position: 'relative',
    width: CARD_SIZE,
    height: WORLD_HEIGHT,
    justifyContent: 'flex-start', // Align items to the top
    marginRight: theme.spacings.small,
  },
  cardImage: {
    position: 'absolute',
    width: '100%',
    height: CARD_SIZE,
    borderRadius: theme.borderRadius.small,
  },
  detailSection: {
    flex: 2,
    justifyContent: 'space-between', // Adjust the spacing inside the detail section
    height: WORLD_HEIGHT,
  },
  textArea: {
    justifyContent: 'flex-start', // Align text to the top
  },
  worldName: {
    color: theme.colors.yellowPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
    marginBottom: theme.spacings.xsmall,
  },
  summary: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.medium,
  },
  contributorsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end', // This ensures contributors are aligned to the right
    // marginTop: theme.spacings.small,
  },
  additionalContributorsCircle: {
    width: 40, // Match the size of your UserCircle
    height: 40, // Match the size of your UserCircle
    borderRadius: 20, // Ensure it's perfectly circular
    backgroundColor: theme.colors.yellowPrimary,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -10, // Overlap with previous circles for a grouped effect
  },
  additionalContributorsText: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.small,
    fontWeight: 'bold',
  },
  totalCardsIndicator: {
    // position: 'absolute',
    // left: 0,
    // bottom: 0, // Adjust based on your layout needs
    backgroundColor: theme.colors.redPrimary,
    paddingHorizontal: theme.spacings.xsmall,
    paddingVertical: theme.spacings.xxsmall,
    borderRadius: theme.borderRadius.xsmall,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    zIndex: 100,
  },
  totalCardsText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.small,
    fontFamily: theme.fonts.family.bold,
  },
});

export default React.memo(WorldComponent);

