import React from 'react';
import { View, Text, StyleSheet, Dimensions } from 'react-native';
import theme from "../styles/theme";
import {Image} from "expo-image";

const { width } = Dimensions.get('window');
const NoWorldsComponent = ({title, content, actionComponent=null}) => (
  <View>
    <View style={styles.container}>
      <Text style={styles.headerText}>{title}</Text>
      <Text style={styles.subText}>
        {content}
      </Text>
      {actionComponent && actionComponent}
    </View>
  </View>
);

const styles = StyleSheet.create({
  container: {
    width: width - 40, // Subtracting margins
    maxWidth: 400,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacings.medium,
    marginTop: theme.spacings.xlarge,
    borderRadius: theme.borderRadius.large,
    backgroundColor: theme.colors.loadingBackground, // Light grey background
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    minHeight: 120,
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  headerText: {
    fontSize: theme.fonts.sizes.xlarge,
    fontWeight: 'bold',
    marginBottom: 10,
    color: theme.colors.gray, // Dark text color for better readability
  },
  subText: {
    fontSize: theme.fonts.sizes.medium,
    justifyContent: 'flex-start',
    color: '#666', // Slightly lighter text color
  },
  frankenpug: {
    alignSelf: 'center',
    width: 150,
    height: 375,
  },
});

export default NoWorldsComponent;
