import React, {useContext} from 'react';
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Button,
  TouchableOpacity
} from 'react-native';
import SvgRiffLine from "../../assets/images/RiffLine";
import SvgRiffLineRight from "../../assets/images/RiffLineRight";
import DemoUserCircle from "../../components/DemoUserCircle";
import StaticMiniCardSmall, {BlankCard} from "../../components/StaticMiniCardSmall";
import theme from "../../styles/theme";
import CloseButton from "./CloseButton";
import {useNavigation} from "@react-navigation/native";
import SvgRiffLineLeft from "../../assets/images/RiffLineLeft";
import styles from "./slideStyles";
import cardData from './cardData';
import {useProfile} from "../../contexts/ProfileContext";

const TOP_USER_CIRCLE_SIZE = 47;

const Slide3 = ({startSlideAnimation, navigationTarget, getStartedNavigationTarget}) => {
  const navigation = useNavigation();
  const { markTutorialComplete } = useProfile();

  const handleGetStarted = async () => {
    if (getStartedNavigationTarget) {
      navigation.replace(getStartedNavigationTarget);
    } else {
      await markTutorialComplete(() => {
        navigation.replace('Authenticated', {
          screen: 'Tabs',
          params: {
            screen: 'Create',
            params: {
              screen: 'CreateScreen',
            },
          },
        });
      });
    }
  }

  return (
    <View style={styles.slide}>
      <CloseButton navigationTarget={navigationTarget}/>
      <View style={styles.topContainer}>
        <View style={styles.topIconContainer}>
          <View style={localStyles.buildTogetherHeader}>
            <DemoUserCircle imagePath={cardData.leftRiffCard.userImageSource}
                            size={TOP_USER_CIRCLE_SIZE}/>
            <DemoUserCircle imagePath={cardData.rootCard.userImageSource}
                            size={TOP_USER_CIRCLE_SIZE}/>
            <DemoUserCircle imagePath={cardData.rightRiffCard.userImageSource}
                            size={TOP_USER_CIRCLE_SIZE}/>
          </View>
        </View>
        <Text style={styles.text}>3. INVITE YOUR FRIENDS</Text>
        <Text style={styles.subtitle}>Invite your friends {'\n'}and make stories together.</Text>
      </View>
      <ScrollView>
      <View
        style={localStyles.treeContainer}
        showsVerticalScrollIndicator={false}
      >
        <View style={localStyles.cardRow}>
          <BlankCard />
          <StaticMiniCardSmall
            imageSource={cardData.rootCard.imageSource}
            title={cardData.rootCard.title}
            description={cardData.rootCard.description}
            userImageSource={cardData.rootCard.userImageSource}
          />
          <BlankCard />
        </View>
        <View style={localStyles.riffLinesRowContainer}>
          <SvgRiffLineLeft style={styles.riffLine}/>
          <SvgRiffLine style={styles.riffLine}/>
          <SvgRiffLineRight style={[styles.riffLine, {opacity: 0}]}/>
        </View>
        <View style={localStyles.cardRow}>
          <StaticMiniCardSmall
            imageSource={cardData.leftRiffCard.imageSource}
            title={cardData.leftRiffCard.title}
            description={cardData.leftRiffCard.description}
            userImageSource={cardData.leftRiffCard.userImageSource}
          />
          <StaticMiniCardSmall
            imageSource={cardData.firstRiffCard.imageSource}
            title={cardData.firstRiffCard.title}
            description={cardData.firstRiffCard.description}
            userImageSource={cardData.firstRiffCard.userImageSource}
          />
          <BlankCard/>
        </View>
        <View style={localStyles.riffLinesRowContainer}>
          <SvgRiffLineLeft style={[styles.riffLine, {opacity: 0}]}/>
          <SvgRiffLine style={styles.riffLine}/>
          <SvgRiffLineRight style={styles.riffLine}/>
        </View>
        <View style={localStyles.cardRow}>
          <BlankCard />
          <StaticMiniCardSmall
            imageSource={cardData.secondRiffCard.imageSource}
            title={cardData.secondRiffCard.title}
            description={cardData.secondRiffCard.description}
            userImageSource={cardData.secondRiffCard.userImageSource}
          />
          <StaticMiniCardSmall
            imageSource={cardData.rightRiffCard.imageSource}
            title={cardData.rightRiffCard.title}
            description={cardData.rightRiffCard.description}
            userImageSource={cardData.rightRiffCard.userImageSource}
          />
        </View>
      </View>
      <TouchableOpacity
        style={localStyles.getStartedButton}
        onPress={handleGetStarted}
      >
        <Text style={localStyles.getStartedText}>START FABYLING</Text>
      </TouchableOpacity>
      </ScrollView>
    </View>
  );
};


const localStyles = StyleSheet.create({
  treeContainer: {
    alignSelf: 'center',
    marginBottom: theme.spacings.xlarge,
    marginHorizontal: theme.spacings.xxlarge,
  },
  getStartedButton: {
    backgroundColor: theme.colors.bluePrimary, // Background color
    paddingVertical: 10, // Vertical padding
    paddingHorizontal: 20, // Horizontal padding
    marginTop: theme.spacings.xxlarge,
    borderRadius: 5, // Rounded corners
    alignItems: 'center', // Center text horizontally
    justifyContent: 'flex-end', // Center text vertically
    width: '70%',
    alignSelf: 'center',
  },
  getStartedText: {
    color: theme.colors.white, // Text color
    fontSize: theme.fonts.sizes.xlarge, // Font size
    fontFamily: theme.fonts.family.bold,
    textTransform: 'uppercase', // Make text uppercase
  },
  cardRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  buildTogetherHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  riffLinesRowContainer: {
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
  },
});


export default Slide3;
