import React, {useCallback, useState, useLayoutEffect} from 'react';
import { useFocusEffect } from '@react-navigation/native';
import {ActivityIndicator, FlatList, SectionList, StyleSheet, Text, View, TouchableOpacity} from 'react-native';
import {Ionicons, MaterialIcons} from "@expo/vector-icons";
import {useInfiniteQuery, useQueryClient} from "react-query";
import theme from "../styles/theme";
import {deleteNotification, markNotificationAsRead, markNotificationAsUnread, markAllNotificationsAsRead, fetchNotifications } from "../utils/api/userService";
import SwipeableNotificationItem, {NotificationSkeleton} from "../components/SwipeableNotificationItem";
import {useNavigation} from "@react-navigation/native";
import alert from "../utils/alert";
import {useQuery} from "react-query";
import {fetchFriendRequestsCount} from "../utils/api/userService";
import AsyncStorage from "@react-native-async-storage/async-storage";


const NotificationsScreen = () => {
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  const [refreshing, setRefreshing] = useState(false);
  // const [sections, setSections] = useState([]);
  const { data: friendRequestsCount } = useQuery('friendRequestsCount', fetchFriendRequestsCount);


  const markAllAsReadHandler = async () => {
    alert(
      "Confirm", // Title of the alert
      "Mark all notifications as read?", // Message shown to the user
      [
        {
          text: "Cancel",
          style: "cancel",
        },
        {
          text: "OK",
          onPress: async () => {
            await markAllNotificationsAsRead();
            queryClient.invalidateQueries('notifications');
            queryClient.invalidateQueries('unreadNotificationsCount');
            AsyncStorage.setItem('@unreadCount', "0");
            //  ^ so the notfication button badge updates right away
          }
        }
      ]
    );
  };

  // Set up header button
  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={styles.headerRightContainer}>
          <MaterialIcons
            onPress={markAllAsReadHandler}
            name="mark-email-read"
            size={32}
            color={theme.colors.blueSecondary}
          />
          <Ionicons
            name="options"
            onPress={() => navigation.navigate('NotificationSettings')}
            size={32}
            color={theme.colors.gray}
          />
        </View>
      ),
    });
  }, [navigation]);

  const {
    data,
    isLoading,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery('notifications', ({ pageParam = 1 }) => fetchNotifications(pageParam), {
    getNextPageParam: (lastPage) => lastPage.next != null ? parseInt(lastPage.next.split('page=')[1], 10) : undefined,
    onSuccess: () => {
      // Invalidate unreadNotificationsCount query to ensure the count is updated
      queryClient.invalidateQueries('unreadNotificationsCount');
  },
  });

  useFocusEffect(
    React.useCallback(() => {
      // Invalidate notifications query to trigger a refetch
      queryClient.invalidateQueries('notifications');
    }, [queryClient])
  );

  // Process the fetched data to categorize into unread and read notifications
  const processedData = React.useMemo(() => {
    if (!data) {
      return { unreadNotifications: [], readNotifications: [] };
    }

    const allNotifications = data.pages.flatMap(page => page.results);
    const unreadNotifications = allNotifications.filter(notification => notification.unread);
    const readNotifications = allNotifications.filter(notification => !notification.unread);

    return { unreadNotifications, readNotifications };
  }, [data]);

  // Use processedData for your sections
  const sections = [
    {
      title: 'Unread',
      data: processedData.unreadNotifications.length > 0 ? processedData.unreadNotifications : ['empty-unread'],
    },
    {
      title: 'Read',
      data: processedData.readNotifications.length > 0 ? processedData.readNotifications : ['empty-read'],
    },
  ];

  const onRefresh = useCallback(() => {
    setRefreshing(true);

    queryClient.refetchQueries('notifications').then(() => {
      setRefreshing(false);
    });
    queryClient.invalidateQueries('unreadNotificationsCount');
  }, [queryClient]);


  const deleteNotificationHandler = async (notificationId) => {
    queryClient.setQueryData('notifications', old => {
      return {
        ...old,
        pages: old.pages.map(page => ({
          ...page,
          results: page.results.filter(notification => notification.id !== notificationId),
        })),
      };
    });

    try {
      await deleteNotification(notificationId);
      queryClient.invalidateQueries('notifications');
      queryClient.invalidateQueries('unreadNotificationsCount');
    } catch (error) {
      console.error('Error deleting notification:', error);
      queryClient.invalidateQueries('notifications');
    }
  };

  const markNotificationAsReadHandler = async (notificationId) => {
    queryClient.setQueryData('notifications', old => {
      return {
        ...old,
        pages: old.pages.map(page => ({
          ...page,
          results: page.results.map(notification => {
            if (notification.id === notificationId) {
              return { ...notification, unread: false };
            }
            return notification;
          }),
        })),
      };
    });

    try {
      await markNotificationAsRead(notificationId);
      queryClient.invalidateQueries('notifications');
      queryClient.invalidateQueries('unreadNotificationsCount');
    } catch (error) {
      console.error('Error marking notification as read:', error);
      queryClient.invalidateQueries('notifications');
    }
  }

  const markNotificationAsUnreadHandler = async (notificationId) => {
    queryClient.setQueryData('notifications', old => {
      return {
        ...old,
        pages: old.pages.map(page => ({
          ...page,
          results: page.results.map(notification => {
            if (notification.id === notificationId) {
              return { ...notification, unread: true };
            }
            return notification;
          }),
        })),
      };
    });

    try {
      await markNotificationAsUnread(notificationId);
      queryClient.invalidateQueries('notifications');
    } catch (error) {
      console.error('Error marking notification as unread:', error);
      queryClient.invalidateQueries('notifications');
    }
  }


  const renderItem = ({ item }) => {
    // Handle rendering of empty state marker
    if (item === 'empty-unread') {
      return <Text style={styles.emptySectionText}>No unread notifications</Text>;
    } else if (item === 'empty-read') {
      return <Text style={styles.emptySectionText}>No read notifications</Text>;
    }

    return (
      <SwipeableNotificationItem
        item={item}
        onDelete={() => deleteNotificationHandler(item.id)}
        onMarkAsRead={() => markNotificationAsReadHandler(item.id)}
        onMarkAsUnread={() => markNotificationAsUnreadHandler(item.id)}
      />
    );
  };

  const renderFooter = () => {
    if (hasNextPage && isFetchingNextPage) {
      return (
        <View style={styles.loadingIndicator}>
          <ActivityIndicator size="large" color={theme.colors.primary}/>
        </View>
      );
    }
    return null;
  };

  if (isLoading) {
    return (
      <View style={styles.container}>
        {Array.from({ length: 9 }).map((_, index) => (
          <NotificationSkeleton key={index} />
        ))}
      </View>
    );
  }

  if (isError) {
    return <Text>Error loading notifications</Text>;
  }
  const renderHeader = () => {
    return (
      <TouchableOpacity onPress={() => navigation.navigate('FriendRequests')}>
        <View style={styles.friendHeaderContainer}>
          <View style={styles.addCircle}>
            <MaterialIcons name="person-add" size={20} color={theme.colors.gray} />
          </View>
            <Text style={styles.friendRequestCountText}>View Friend Requests</Text>
        </View>
      </TouchableOpacity>
    )
  }

  return (
      <View style={styles.container}>
      <SectionList
        ListHeaderComponent={renderHeader}
        sections={sections}
        // keyExtractor={(item, index) => item.id + index}
        keyExtractor={(item, index) => `${item.id}-${item.unread}-${index}`}
        renderItem={renderItem}
        renderSectionHeader={({ section: { title } }) => (
          <View style={styles.sectionHeaderContainer}>
            <Text style={styles.sectionHeaderText}>{title}</Text>
          </View>

        )}
        refreshing={isLoading}
        renderSectionFooter={renderFooter}
        onRefresh={onRefresh}
        onEndReached={fetchNextPage}
        onEndReachedThreshold={0.5}
        ListEmptyComponent={<Text style={{alignSelf: "center", marginTop: theme.spacings.xlarge}}>No notifications</Text>}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.beige,
    flex: 1,
    alignSelf: 'center',
    width: '100%',
  },
  headerRightContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 90,
    marginHorizontal: theme.spacings.small,
  },
  loadingIndicator: {
    paddingVertical: theme.spacings.medium,
    alignItems: 'center',
  },
  sectionHeaderContainer: {
    width: '100%',
    alignItems: 'flex-start',
    backgroundColor: theme.colors.loadingBackground, // or any other color from your theme
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopWidth: 0,
    borderColor: theme.colors.lightgray,
    padding: theme.spacings.xsmall,
    marginBottom: theme.spacings.xsmall,
  },
  sectionHeaderText: {
    color: theme.colors.gray, // Choose a color that contrasts well with the background
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.large,
  },
  emptySectionText: {
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.large,
    textAlign: 'flex-start',
    marginLeft: 65,
    color: theme.colors.gray,
    padding: theme.spacings.medium,
  },
  friendRequestCountText: {
    color: theme.colors.bluePrimary,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.medium,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  friendHeaderContainer: {
    padding: theme.spacings.small,
    backgroundColor: theme.colors.beige,
    borderBottomWidth: StyleSheet.hairlineWidth,
    minHeight: 80,
    borderColor: theme.colors.lightgray,
    alignItems: 'center',
    // justifyContent: 'center',
    flexDirection: 'row',
    marginHorizontal: theme.spacings.small,
  },
  addCircle: {
    width: 50,
    height: 50,
    backgroundColor: theme.colors.verylightgray,
    padding: theme.spacings.small,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    marginRight: theme.spacings.medium,
  }
});

export default NotificationsScreen;
