import React, {useLayoutEffect} from 'react';
import {View} from 'react-native';
import API_ENDPOINTS from '../apiEndpoints';
import globalStyles from "../styles/globalStyles";
import SearchButton from "../components/SearchButton";
import NotificationsButton from "../components/NotificationsButton";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import TabComponent from "../components/TabComponent";

function AllCardsScreen({ navigation, route }) {
  const isLargeScreen = useIsLargeScreen();
  const initialTab = route.params?.initialTab || 'cards';

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={globalStyles.headerRightContainer}>
          <NotificationsButton navigation={navigation} />
          <SearchButton
            navigation={navigation}
            targetScreen='Search'
            facetKey='allCards'
          />
        </View>
      ),
      headerShown: !isLargeScreen,
    });
  }, [navigation]);

  return (
    <TabComponent
      cardsEndpoint={API_ENDPOINTS.ALL_CARDS}
      bigWorldsEndpoint={API_ENDPOINTS.ALL_BIG_WORLDS}
      cardsTitle={'ALL FABYLS'}
      bigWorldsTitle={'ALL WORLDS'}
      initialTab={initialTab}
    />
  );
}

export default AllCardsScreen;
