import { useQuery } from 'react-query';
import { fetchPublicProfileData } from "../utils/api/userService";

export const usePublicProfileData = (profile_uuid) => {
  return useQuery(
    ['publicProfile', profile_uuid],
    () => fetchPublicProfileData(profile_uuid),
    { enabled: !!profile_uuid }
  );
};
