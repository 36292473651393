import React from 'react';
import { Platform } from 'react-native';
import TutorialScreenWeb from './TutorialScreenWeb';
import TutorialScreenApp from './TutorialScreenApp';

const TutorialScreen = ({ route }) => {
  const { navigationTarget = null, getStartedNavigationTarget = null } = route.params || {};

  if (Platform.OS === 'web') {
    return <TutorialScreenWeb navigationTarget={navigationTarget} getStartedNavigationTarget={getStartedNavigationTarget} />;
  } else {
    return <TutorialScreenApp navigationTarget={navigationTarget} getStartedNavigationTarget={getStartedNavigationTarget} />;
  }
};

export default TutorialScreen;
