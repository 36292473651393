// AutoplayContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {AuthContext} from "./AuthContext";

const AutoplayContext = createContext();

export const AutoplayProvider = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext); // Use the AuthContext
  const [autoplay, setAutoplay] = useState(true);

  useEffect(() => {
    const loadSetting = async () => {
      const value = await AsyncStorage.getItem('autoplaySetting');
      if (value !== null && isAuthenticated) {
        setAutoplay(JSON.parse(value));
      } else {
        // If not authenticated, you can decide to disable autoplay
        setAutoplay(false);
      }
    };

    loadSetting();
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      AsyncStorage.setItem('autoplaySetting', JSON.stringify(autoplay));
    }
  }, [autoplay, isAuthenticated]);

  return (
    <AutoplayContext.Provider value={{ autoplay, setAutoplay }}>
      {children}
    </AutoplayContext.Provider>
  );
};

export const useAutoplay = () => useContext(AutoplayContext);
