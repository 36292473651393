import React, { useContext, useEffect } from 'react';
import { View, Text, Button } from 'react-native';
import { AuthContext } from '../../contexts/AuthContext';
import theme from "../../styles/theme";


const CardDetailResolver = ({ route, navigation }) => {
  const { card_uuid } = route.params; // Assuming you pass the card's UUID as a parameter
  const { isAuthenticated } = useContext(AuthContext);

  const handlePrivateCard = () => {
    navigation.navigate('Authenticated', {
      screen: 'Tabs',
      params: {
        screen: 'Home',
        params: {
          screen: 'CardDetail',
          params: { card_uuid: card_uuid },
        },
      },
    });
  }

  const handlePublicCard = () => {
    navigation.navigate('PublicCardDetail', { card_uuid: card_uuid });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isAuthenticated) {
        console.log(`Navigating to authenticated CardDetail for card ${card_uuid}`)
        handlePrivateCard();
        // handlePublicCard();
      } else {
        console.log(`Navigating to public CardDetail for card ${card_uuid}`)
        handlePublicCard();
      }
    }, 100);
  }, [isAuthenticated, navigation, card_uuid]);

  return (
    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: theme.colors.beige}}>
    </View>
  );
};

export default CardDetailResolver;
