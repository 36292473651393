let scrollPositions = {};

export const setScrollPosition = (screenName, position) => {
  scrollPositions[screenName] = position;
};

export const getScrollPosition = (screenName) => {
  const val = scrollPositions[screenName];
  console.log(`getScrollPosition(${screenName || 'null'}) = ${val}`);
  return scrollPositions[screenName] || 0;
};

export const clearAllScrollPositions = () => {
  scrollPositions = {};
};
