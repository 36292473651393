// SettingsScreen.js
import React, {useContext, useState} from 'react';
import {Linking, ScrollView, StyleSheet, View, Platform} from 'react-native';
import {AuthContext} from "../contexts/AuthContext";
import MenuModal from "../components/modals/MenuModal";
import RobotImage from "../assets/images/characters/Robot_medium.png";
import {useProfile} from "../contexts/ProfileContext";
import theme from "../styles/theme";
import {Image} from "expo-image";
import SettingLinkButton from "../components/SettingLinkButton";
import ManageSubscriptionButton from '../components/buttons/ManageSubscriptionButton';
import RestorePurchasesButton from "../components/buttons/RestorePurchasesButton";
import PrimaryButton from "../components/PrimaryButton";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import {createCustomerPortalSession} from "../utils/api/stripeService";
import alert from "../utils/alert";
import {unregisterPushToken} from "../utils/api/userService";
import {usePushToken} from "../contexts/PushTokenContext"; // Import the context


const TERMS_OF_USE = process.env.EXPO_PUBLIC_TERMS_OF_USE_URL;
const PRIVACY_POLICY = process.env.EXPO_PUBLIC_PRIVACY_POLICY_URL;


const SettingsScreen = ({ navigation }) => {
  const isLargeScreen = useIsLargeScreen(theme.breakpoints.large);
  const { signOut } = useContext(AuthContext);
  const { profileData } = useProfile();
  const { pushToken } = usePushToken(); // Get the pushToken from the context
  const [modalVisible, setModalVisible] = useState(false);

  const handleLogout = async () => {
    try {
      if (pushToken) {
        await unregisterPushToken(pushToken);
      }
      await signOut();
      setModalVisible(false);
    } catch (error) {
      console.error('Error during logout', error);
    }
  };

  const logoutOptions = [
    {
      label: 'Logout',
      action: handleLogout,
    },
    {
      label: 'Cancel',
      action: () => setModalVisible(false),
    }
  ];

  const showLogoutConfirmationModal = () => {
    setModalVisible(true);
  };

  const openURL = (url) => {
    Linking.canOpenURL(url)
    .then((supported) => {
      if (!supported) {
        console.log("Can't handle URL: " + url);
      } else {
        return Linking.openURL(url);
      }
    })
    .catch((err) => console.error('An error occurred', err));
  };

  const handleManageBilling = async () => {
    try {
      const response = await createCustomerPortalSession();
      const {url} = response.data;
      await Linking.openURL(url); // Open the Stripe Customer Portal
    } catch (error) {
      console.error('Error opening Stripe Customer Portal:', error);
    }
  };

  const handleManageSubscription = () => {
  //   if platform os is web, use handleManageBilling
    if (Platform.OS === 'web') {
      handleManageBilling();
    }
    if (Platform.OS === 'ios') {
      alert(
        'Manage Subscription',
        'To manage your subscription, please follow these steps after this dialog: \n1. This will open the app settings. \n2. Tap your Apple ID. \n3. Tap Subscriptions.',
        [
          {text: 'Cancel', style: 'cancel'},
          {text: 'Open Settings', onPress: () => Linking.openURL('App-prefs:root=STORE&path=SUBSCRIPTIONS')}
        ]
      );
    }
    else {
      console.error('Platform not supported');
    }
  }

  return (
    <View style={[styles.totalContainer, isLargeScreen && styles.largeScreenTotalContainer]}>
      <ScrollView style={[styles.container, isLargeScreen && styles.largeScreenContainer]}>

        <ManageSubscriptionButton />
        <RestorePurchasesButton />
        <SettingLinkButton title="Notification Settings" onPress={() => navigation.navigate('NotificationSettings')} />
        <SettingLinkButton title="Update Email" onPress={() => navigation.navigate('UpdateEmail')} />
        <SettingLinkButton title="Change Password" onPress={() => navigation.navigate('ChangePassword')} />
        <SettingLinkButton title="Deactivate/Delete Account" onPress={() => navigation.navigate('DeactivateAccount')} />
        <SettingLinkButton title="Blocked Users" onPress={() => navigation.navigate('BlockedUsersSearch')} />
        <SettingLinkButton title="Meet The Team" onPress={() => navigation.navigate('MeetTheTeam')} />
        <SettingLinkButton title="Tutorial" onPress={() => navigation.navigate("Tutorial")} />
        <SettingLinkButton title="Support" onPress={() => navigation.navigate('Support')} />
        <SettingLinkButton title="Terms of Use" onPress={() => openURL(TERMS_OF_USE)} />
        <SettingLinkButton title="Privacy Policy" onPress={() => openURL(PRIVACY_POLICY)} />

        <MenuModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          options={logoutOptions}
          header={"Are you sure you want to logout?"}
        />

        <View style={styles.logoutContainer}>
          <PrimaryButton title="LOG OUT" onPress={showLogoutConfirmationModal} />
        </View>
        {!isLargeScreen && (
          <Image source={RobotImage} style={styles.character} />
        )}
      </ScrollView>
      {isLargeScreen && (
        <View style={styles.largeCharacterContainer}>
          <Image source={RobotImage} style={styles.largeCharacter} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  totalContainer: {
    flex: 1,
    backgroundColor: theme.colors.beige,
  },
  largeScreenTotalContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 100,
  },
  container: {
    padding: theme.spacings.large,
    backgroundColor: theme.colors.beige,
  },
  largeScreenContainer: {
    flex: 1,
    paddingHorizontal: theme.spacings.xlarge,
    // alignSelf: 'center',
  },
  title: {
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.gray,
    marginBottom: theme.spacings.medium,
    fontFamily: theme.fonts.family.bold,
  },
  button: {
    // backgroundColor: theme.colors.bluePrimary,
    padding: theme.spacings.small,
    borderRadius: theme.borderRadius.medium,
    alignItems: 'flex-start',
    marginBottom: theme.spacings.medium,
  },
  buttonText: {
    color: theme.colors.bluePrimary,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.large,
  },
  logoutContainer: {
    marginTop: theme.spacings.large,
    // paddingBottom: theme.spacings.large,
  },
  logoutButton: {
    backgroundColor: theme.colors.bluePrimary,
    padding: theme.spacings.small,
    borderRadius: theme.borderRadius.medium,
    alignItems: 'center',
    marginBottom: theme.spacings.medium,
  },
  logoutButtonText: {
    color: theme.colors.white,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.medium,
  },
  character: {
    width: 200,
    height: 300,
    alignSelf: 'center',
    marginBottom: theme.spacings.xxlarge,
    marginTop: theme.spacings.large,
  },
  largeCharacterContainer: {
    flex: 1,
    alignItems: 'center',
  },
  largeCharacter: {
    width: 300,
    height: 450,
    // alignSelf: 'center',
  },
  // Add more styles as needed...
});


export default SettingsScreen;
