import * as React from "react";
import Svg, { Path } from "react-native-svg";


const SvgRiffLine = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={51}
    fill="none"
    {...props}
  >
    <Path
      fill="#DB4C2A"
      d="M5.75 1V.25h-1.5V1zM5 50.33 9.33 46 5 41.67.67 46zM4.25 2.688v.75h1.5v-.75zm1.5 4.5v-.75h-1.5v.75zm-1.5 10.125v.75h1.5v-.75zm1.5 4.5v-.75h-1.5v.75zm-1.5 3.375v.75h1.5v-.75zm1.5 4.5v-.75h-1.5v.75zm-1.5 10.125v.75h1.5v-.75zm1.5 4.5v-.75h-1.5v.75zM4.25 1v1.688h1.5V1zm0 6.188v10.125h1.5V7.188zm0 14.625v3.375h1.5v-3.375zm0 7.875v10.125h1.5V29.687zm0 14.625V46h1.5v-1.687z"
    />
  </Svg>
);
export default SvgRiffLine;

