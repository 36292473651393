import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SvgRiffLineLeft = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={47}
    height={30}
    fill="none"
    {...props}
  >
    <Path
      fill="#DB4C2A"
      d="m45.194 1.35.65-.375.75 1.3-.65.375zM.25 28.165l1.585-5.915 5.915 1.585-1.585 5.915zM44.385 3.55l-.65.375-.75-1.3.65-.375zm-4.907 1.1.65-.375.75 1.3-.65.375zm-8.603 6.7-.65.375-.75-1.3.65-.375zm-4.907 1.1.65-.374.75 1.298-.65.375zm-2.367 3.1-.65.374-.75-1.298.65-.375zm-4.907 1.1.65-.374.75 1.299-.65.375zm-8.603 6.7-.65.374-.75-1.299.65-.375zm-4.907 1.1.65-.374.75 1.299-.65.375zm40.76-21.8-1.559.9-.75-1.3 1.56-.9zm-5.716 3.3-9.353 5.4-.75-1.3 9.353-5.4zm-13.51 7.8-3.117 1.8-.75-1.3 3.117-1.8zm-7.274 4.2-9.353 5.4-.75-1.3 9.353-5.4zm-13.51 7.8-1.559.9-.75-1.3 1.559-.9z"
    />
  </Svg>
);
export default SvgRiffLineLeft;

