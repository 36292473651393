import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  Dimensions,
  View, Linking,
} from 'react-native';
import theme from "../styles/theme"; // assuming this has your color, spacing, and fo
import { useQueryClient } from 'react-query';
import {
  createCustomerPortalSession,
  stripeCheckoutWeb,
  previewSubscriptionChange,
  confirmSubscriptionChange,
  getProductPrices,
} from "../utils/api/stripeService";
import {useStripe} from '@stripe/react-stripe-js';
import UpgradeInfoComponent from "../components/UpgradeInfoComponent";
import UpgradeConfirmationModal from "../components/modals/UpgradeConfirmationModal";
import PlanBox from "../components/PlanBox";
import planConfig from "../planConfig";
import {useSubscription} from "../hooks/useSubscription";
import alert from "../utils/alert";

const PRIVACY_POLICY = process.env.EXPO_PUBLIC_PRIVACY_POLICY_URL;
const TERMS_OF_USE = process.env.EXPO_PUBLIC_TERMS_OF_USE_URL;


const openURL = (url) => {
  Linking.canOpenURL(url)
    .then((supported) => {
      if (supported) {
        return Linking.openURL(url);
      } else {
        console.log("Can't handle URL: " + url);
      }
    })
    .catch((err) => console.error('An error occurred', err));
};


function useIsWideScreen() {
  const { width } = useWindowDimensions();
  return width >= theme.breakpoints.medium; // Define your breakpoint for wide screens
}


const WebUpgradeScreen = () => {
  const stripe = useStripe();
  const queryClient = useQueryClient();  // Get the queryClient instance

  const [isWideScreen, setIsWideScreen] = useState(useIsWideScreen());
  const [showModal, setShowModal] = useState(false);
  const [upgradeInfo, setUpgradeInfo] = useState(null);
  const [prorationPreview, setProrationPreview] = useState(null);
  // const [plans, setPlans] = useState(planConfig); // Maintain plans in the state
  const { data: subscriptionData, isLoading, isError } = useSubscription(true);
  const plans = planConfig.map(plan => ({
    ...plan,
    isActive: plan.id === subscriptionData?.tier
  }));

  const isDeveloperTier = subscriptionData?.tier === 'developer';

  useEffect(() => {
    // Invalidate the 'subscription' query when the component mounts
    queryClient.invalidateQueries('subscription');
  }, [queryClient]);

  useEffect(() => {
    const updateLayout = () => {
      setIsWideScreen(useIsWideScreen());
    };

    // Add event listener for screen dimension changes
    Dimensions.addEventListener('change', updateLayout);

    // Remove event listener when the component unmounts
    return () => {
      Dimensions.removeEventListener('change', updateLayout);
    };
  }, []);

  const handleCheckout = async (tier) => {
    const currentSubscriptionTier = plans.find((plan) => plan.isActive)?.id;

    // Show modal with loading state right away
    setShowModal(true);
    setUpgradeInfo({ tier, loading: true });

    if (currentSubscriptionTier) {
      const preview = await previewSubscriptionChange(tier);
      setProrationPreview(preview);
      setUpgradeInfo({ tier, ...preview, loading: false });
      // setShowModal(true);
    } else {
      const session = await stripeCheckoutWeb(tier);
      if (stripe && session.sessionId) {
        stripe.redirectToCheckout({ sessionId: session.sessionId });
      }
    }
  };

  const handleConfirmUpgrade = async () => {
    // Confirm the upgrade by calling the confirmSubscriptionChange function
    await confirmSubscriptionChange(upgradeInfo.tier);
    setShowModal(false);
    setUpgradeInfo(null);
    // Refresh subscription data here or navigate the user as needed
  };

  const handleCancelUpgrade = () => {
    setShowModal(false);
    setUpgradeInfo(null);
  };

  const handleManageBilling = async () => {
    try {
      const response = await createCustomerPortalSession();
      const { url } = response.data;
      await Linking.openURL(url); // Open the Stripe Customer Portal
    } catch (error) {
      console.error('Error opening Stripe Customer Portal:', error);
    }
  };

  const handlePress = (plan) => {
    if (subscriptionData?.platform === 'internal') {
      alert('You have an internal subscription. Changes can only be made by the administrator. Please contact support@fabyl.ai if you need to make changes.');
      return;
    } else if (subscriptionData?.platform === 'iap') {
      alert("You have an in-app purchase subscription. Please manage your subscription through the app store.");
      return;
    }
    if (plan.isActive) {
      handleManageBilling();
    } else {
      handleCheckout(plan.id);
    }
  };

  const Container = isWideScreen ? View : ScrollView;

  return (
    <View style={styles.container}>
      {isDeveloperTier && (
        <View style={styles.developerMessage}>
          <Text style={styles.developerMessageText}>YOU HAVE A DEVELOPER ACCOUNT.</Text>
        </View>
      )}
      <Container style={[
        styles.container,
        isWideScreen && styles.widescreenContainer,
      ]}>
        {plans.map(plan => (
          <PlanBox
            key={plan.id}
            plan={plan}
            onPress={() => handlePress(plan)}
            isActive={plan.isActive}
            isWideScreen={isWideScreen}
          />
        ))}
      </Container>
      <UpgradeInfoComponent/>
      <UpgradeConfirmationModal
        visible={showModal}
        onConfirm={handleConfirmUpgrade}
        onCancel={handleCancelUpgrade}
        // updateInfo={prorationPreview}
        updateInfo={upgradeInfo}
      />
      <View style={styles.footerSection}>
        <View style={styles.linksRow}>
          <TouchableOpacity onPress={() => openURL(PRIVACY_POLICY)}>
            <Text style={styles.footerLink}>Privacy Policy</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => openURL(TERMS_OF_USE)}>
            <Text style={styles.footerLink}>Terms of Use</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: theme.spacings.medium,
    backgroundColor: theme.colors.beige,
    width: '100%',
  },
  developerMessage: {
    backgroundColor: theme.colors.yellowPrimary,
    padding: theme.spacings.medium,
    // marginBottom: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
    maxWidth: 600,
    alignSelf: 'center',
  },
  developerMessageText: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    textAlign: 'center',
  },
  widescreenContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  footerSection: {
    padding: theme.spacings.large,
    alignItems: 'center',
    width: '100%',
  },
  linksRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  footerLink: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.medium,
    textDecorationLine: 'underline',
    marginHorizontal: theme.spacings.medium,
    marginVertical: theme.spacings.large,
  },

});

export default WebUpgradeScreen;
