import React, { memo } from 'react';
import {
  View,
  StyleSheet,
  useWindowDimensions,
  Text,
  Image,
  TouchableOpacity
} from 'react-native';
import WorldComponent from "./WorldComponent";
import { FlashList } from "@shopify/flash-list";
import { WORLD_WIDTH } from "./WorldComponent";
import BigWorldsSkeletonLoader from "./skeletons/BigWorldsSkeletonLoader";
import theme from "../styles/theme";
import NoWorldsComponent from "./NoWorldsComponent";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";


const ListEmpty = memo(({ loading }) => {
  const navigation = useNavigation();

  if (loading) return <View style={styles.skeletonContainer}><BigWorldsSkeletonLoader /></View>;
  return (
    <View style={styles.emptyList}>
      <NoWorldsComponent
        title={"Develop Your Worlds"}
        content={"As your stories grow larger, you will see them here."}
        actionComponent={
          <View>
            <TouchableOpacity
              style={styles.addFriendButton}
              onPress={() => {
                navigation.navigate('UserSearch');
              }}
            >
              <MaterialIcons name="person-add" size={24} color={theme.colors.bluePrimary} />
              <Text style={styles.addFriendButtonText}>Find Friends</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.browseAllButton}
              onPress={() => {
                navigation.navigate('AllCards', { initialTab: 'bigWorlds' });
              }}
            >
              <Ionicons name="earth" size={24} color={theme.colors.redPrimary} />
              <Text style={styles.browseAllButtonText}>Browse All Worlds</Text>
            </TouchableOpacity>
          </View>
        }
      />
      <Image
        source={require("../assets/images/characters/SpaceChef_medium.png")}
        style={styles.frankenpug}
      />
    </View>
  );
});


function BigWorldsGallery({ nextPageURL, worlds, loadMore, loading, onRefresh, headerComponent, noWorldsComponent=null }) {
    const windowWidth = useWindowDimensions().width;
    const numColumns = Math.max(Math.floor(windowWidth / WORLD_WIDTH) - 1, 1);

    const renderItem = ({item}) => (
      <View style={[styles.worldContainer, { width: WORLD_WIDTH}]}>
        <WorldComponent world={item}/>
      </View>
    );

    return (
        <FlashList
            data={worlds}
            renderItem={renderItem}
            ListHeaderComponent={headerComponent}
            keyExtractor={item => item.uuid}
            numColumns={numColumns}
            onEndReached={() => {
                if (!loading && nextPageURL) {
                    loadMore(nextPageURL);
                }
            }}
            onEndReachedThreshold={0.5}
            refreshing={loading}
            onRefresh={onRefresh}
            estimatedItemSize={200}
            ListEmptyComponent={<ListEmpty loading={loading}/>}
        />
    );
}

const styles = StyleSheet.create({
  skeletonContainer: {
    marginHorizontal: theme.spacings.small,
    alignSelf: 'flex-start',
  },
  worldContainer: {
    marginVertical: 10,
    marginHorizontal: 5,
  },
  emptyList: {
    alignItems: 'center',
    // marginTop: 20,
  },
  frankenpug: {
    alignSelf: 'center',
    width: 250,
    height: 400,
  },
  addFriendButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10, // Adjust the margin as needed
  },
  addFriendButtonText: {
    color: theme.colors.bluePrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
    marginLeft: 5, // Space between icon and text
  },
  browseAllButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  browseAllButtonText: {
    color: theme.colors.redPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
    marginLeft: 5, // Space between icon and text
  },
});

export default BigWorldsGallery;
