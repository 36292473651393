import React, {useState} from 'react';
import {Alert, StyleSheet, TextInput, View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {signUpWithEmail} from '../utils/cognitoService';
import SignUpForm from "../components/SignUpForm";
import alert from "../utils/alert";
import theme from "../styles/theme";

const EmailSignUpScreen = () => {
  const navigation = useNavigation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignUpPress = async () => {
    // Your sign-up logic here...
    try {
      const response = await signUpWithEmail({ email, password });
      console.log(response);
      navigation.navigate('OtpScreen', {
        username: email,
        verificationType: 'signUp',
      });
    } catch (error) {
      console.error('Error during sign-up:', error);
    }
  };

  const emailInputField = (
    <TextInput
      style={styles.input}
      placeholder="Email"
      value={email}
      onChangeText={setEmail}
      keyboardType="email-address"
      autoCapitalize="none"
    />
  );

  return (
    <View style={styles.container}>
      <SignUpForm
        inputField={emailInputField}
        onSignUpPress={handleSignUpPress}
        password={password}
        setPassword={setPassword}
      />
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.colors.beige,
  },
  input: {
    height: 50,
    backgroundColor: '#fff', // White input fields
    borderWidth: 1,
    borderColor: '#ddd', // Light grey border
    borderRadius: 5,
    paddingHorizontal: 10, // Horizontal padding within the input field
    marginBottom: 15, // Space between the input fields
    fontSize: 16,
  },
});

export default EmailSignUpScreen;
