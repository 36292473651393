import API_ENDPOINTS from "../../apiEndpoints";
import { authenticatedAxiosCall } from "./tokenService";
import alert from "../alert";
import { Platform } from 'react-native';



async function stripeCheckout(billingCycle) {
  const response = await authenticatedAxiosCall('post', API_ENDPOINTS.CHECKOUT_SESSION, {
    billing_cycle: billingCycle, // Include billing_cycle in the request body
  });
  return response.data;
}


async function stripeCheckoutWeb(tier) {
  console.log("Trying to checkout")
  try {
    const response = await authenticatedAxiosCall("post", API_ENDPOINTS.CHECKOUT_SESSION, {
      subscription_tier: tier,
    });
    return response.data
  } catch (error) {
    console.error('Error creating checkout session:', error);
    alert('Error', 'Unable to create checkout session');
  }
}

async function previewSubscriptionChange(tier) {
  try {
    const response = await authenticatedAxiosCall('post', API_ENDPOINTS.PREVIEW_SUBSCRIPTION_CHANGE, {
      subscription_tier: tier,
    });
    return response.data;  // Contains upcoming_invoice with proration details
  } catch (error) {
    console.error('Error previewing subscription change:', error);
    alert('Error', 'Unable to preview subscription change');
  }
}


async function confirmSubscriptionChange(tier) {
  // Assume user has confirmed the change after previewing
  try {
    const response = await authenticatedAxiosCall('post', API_ENDPOINTS.CONFIRM_SUBSCRIPTION_CHANGE, {
      subscription_tier: tier,
    });
    return response.data;  // Success message
  } catch (error) {
    console.error('Error confirming subscription change:', error);
    alert('Error', 'Unable to confirm subscription change');
  }
}

async function cancelSubscription() {
  try {
    const response = await authenticatedAxiosCall('post', API_ENDPOINTS.CANCEL_SUBSCRIPTION);
    return response.data;
  } catch (error) {
    console.error('Error cancelling subscription:', error);
    alert('Error', 'Unable to cancel subscription');
  }
}

async function reactivateSubscription() {
  try {
    const response = await authenticatedAxiosCall('post', API_ENDPOINTS.REACTIVATE_SUBSCRIPTION);
    return response.data;
  } catch (error) {
    console.error('Error reactivating subscription:', error);
    alert('Error', 'Unable to reactivate subscription');
  }
}


// const createCustomerPortalSession = async () => {
//   return await authenticatedAxiosCall('post', API_ENDPOINTS.CREATE_CUSTOMER_PORTAL);
// };
const createCustomerPortalSession = async () => {
  // Determine the platform
  const platformQuery = Platform.OS === 'web' ? '?platform=web' : '?platform=mobile';
  // Append the platform information as a query parameter to the URL
  // This allows us to use different returnUrls for web and mobile
  const urlWithPlatform = `${API_ENDPOINTS.CREATE_CUSTOMER_PORTAL}${platformQuery}`;

  return await authenticatedAxiosCall('post', urlWithPlatform);
};

async function getProductPrices() {
  try {
    const response = await authenticatedAxiosCall('get', API_ENDPOINTS.STRIPE_PRODUCT_PRICES);
    return response.data;
  } catch (error) {
    console.error('Error fetching product prices:', error);
    alert('Error', 'Unable to fetch product prices');
  }
}


export { stripeCheckout, cancelSubscription, reactivateSubscription, createCustomerPortalSession, stripeCheckoutWeb, previewSubscriptionChange, confirmSubscriptionChange, getProductPrices };

