import React from 'react';
import { Text, StyleSheet, View, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import GenericList from './GenericList'; // Adjust the import path as necessary
import theme from "../../styles/theme";
import UserCircle from "../UserCircle";
import {useQueryClient} from "react-query";

const UsersList = ({ query, fetchResults, triggerLoad, onPressItem }) => {
  const navigation = useNavigation();
  const queryClient = useQueryClient();

  const defaultPressItem = (profile) => {
    navigation.navigate('UserProfile', { profile_uuid: profile.uuid });
  };

  const renderItem = ({ item }) => {
    // Store the minimal profile data in the React Query cache
    queryClient.setQueryData(['minimalProfile', item.uuid], item);

    return (
      <TouchableOpacity onPress={() => (onPressItem ? onPressItem(item) : defaultPressItem(item))}>
        <View style={styles.userContainer}>
          <UserCircle profile_uuid={item.uuid} />
          <Text style={styles.resultItem}>{item.preferred_username}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <GenericList
      fetchDataFunction={(page) => fetchResults(query, page)}
      renderItem={renderItem}
      itemKeyExtractor={(item) => String(item.uuid)}
      triggerLoad={triggerLoad}
    />
  );
};

const styles = StyleSheet.create({
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  resultItem: {
    padding: 10,
    color: theme.colors.bluePrimary,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.medium,
  },
});

export default UsersList;
