import React, { forwardRef } from 'react';
import { View, TextInput, StyleSheet } from 'react-native';
import CountryPicker from 'react-native-country-picker-modal';
import theme from '../styles/theme';


const PhoneInputComponent = forwardRef(({ countryCode, setCountryCode, setCountryCallingCode, phone, setPhone }, ref) => {
  return (
    <View style={phoneStyles.phoneInputGroup}>
      <CountryPicker
        withFilter
        withFlag
        withCountryNameButton
        withCallingCode
        withCallingCodeButton
        onSelect={(country) => {
          setCountryCode(country.cca2);
          setCountryCallingCode(country.callingCode[0]);
        }}
        countryCode={countryCode}
        translation='eng'
        containerButtonStyle={phoneStyles.countryPicker}
      />
      <TextInput
        ref={ref}
        style={phoneStyles.phoneInput}
        placeholder="Phone Number"
        value={phone}
        onChangeText={setPhone}
        keyboardType="phone-pad"
        autoCapitalize="none"
        placeholderTextColor={theme.colors.gray}
      />
    </View>
  );
});

const phoneStyles = StyleSheet.create({
  phoneInputGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacings.medium,
  },
  countryPicker: {
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    borderColor: theme.colors.gray,
    borderTopLeftRadius: theme.borderRadius.medium,
    borderBottomLeftRadius: theme.borderRadius.medium,
    paddingHorizontal: theme.spacings.small,
    marginRight: theme.spacings.small,
    height: 50,
  },
  phoneInput: {
    flex: 1,
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    borderTopRightRadius: theme.borderRadius.medium,
    borderBottomRightRadius: theme.borderRadius.medium,
    borderColor: theme.colors.gray,
    paddingHorizontal: theme.spacings.small,
    fontSize: theme.fonts.sizes.medium,
    height: 50,
  },
});

export default PhoneInputComponent;
