// slideStyles.js
import { StyleSheet } from 'react-native';
import theme from "../../styles/theme";

export default StyleSheet.create({
  wrapper: {
    backgroundColor: theme.colors.beige,
  },
  lineContainer: {
    flex: 1,
    alignItems: 'center',
  },
  emptySpace: {
    flex: 1,
    alignItems: 'center',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: theme.spacings.medium,
    paddingTop: theme.spacings.small,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
  },
  fabylHeader: {
    transform: [{ scale: 0.46 }],
    marginLeft: theme.spacings.small,
  },
  closeButton: {
    marginRight: theme.spacings.small,
  },
  demoInputContainer: {
    // margin: theme.spacings.large,
    width: '100%',
    alignSelf: 'center',
  },
  closeButtonText: {
    fontSize: 24,
    color: theme.colors.gray,
  },
  cardRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    zIndex: 50,
  },
  topContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacings.medium,
    backgroundColor: theme.colors.beige,
    zIndex: 90,
  },
  topIconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacings.small,
    height: 50,
    maxHeight: 50,
  },
  buildTogetherHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    margin: theme.spacings.medium,
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  text: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    textTransform: 'uppercase',
  },
  subtitle: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.large,
    marginTop: theme.spacings.large,
    marginHorizontal: theme.spacings.medium,
    textAlign: 'center',
  },
  riffLine: {},
  cardContainer: {
    position: 'relative',
    height: 400, // Adjust height as needed
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  treeContainer: {
    alignSelf: 'flex-end',
    margin: theme.spacings.large,
    marginRight: theme.spacings.xxlarge,
  },
  slide: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: theme.colors.beige,
    width: '100%',
    height: '100%',
    maxWidth: 600,
    alignSelf: 'center',
  },
});
