import React, { useState } from 'react';
import { Platform, View, ActivityIndicator, Modal } from 'react-native';
import * as RNIap from 'react-native-iap';
import alert from "../../utils/alert";
import SettingLinkButton from '../SettingLinkButton';
import theme from "../../styles/theme";
import { useSubscription } from "../../hooks/useSubscription";
import { useQueryClient } from 'react-query';

const RestorePurchasesButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { refetch, data: subscriptionData } = useSubscription(); // Use data here directly
  const queryClient = useQueryClient();

  const handleRestorePurchases = async () => {
    setIsLoading(true);
    try {
      // Call the sync() method to synchronize the state with the App Store
      await RNIap.IapIosSk2.sync();

      // Refetch the subscription data from your backend API
      await refetch();

      if (subscriptionData && subscriptionData.tier) {
        alert('Active subscription restored successfully');
      } else {
        alert('No active subscription found');
      }
    } catch (error) {
      console.warn('Error restoring purchases:', error);
      alert('Failed to restore purchases');
    } finally {
      setIsLoading(false);
    }
  };

  if (Platform.OS !== 'ios') {
    return null;
  }

  return (
    <>
      <SettingLinkButton title="Restore Purchases" onPress={handleRestorePurchases} />
      <Modal
        transparent={true}
        visible={isLoading}
        onRequestClose={() => setIsLoading(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.activityIndicatorContainer}>
            <ActivityIndicator size="large" color={theme.colors.white} />
          </View>
        </View>
      </Modal>
    </>
  );
};

const styles = {
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  activityIndicatorContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    borderRadius: theme.borderRadius.medium,
    padding: theme.spacings.large,
  },
};

export default RestorePurchasesButton;
