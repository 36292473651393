import React from 'react';
import { TouchableOpacity, StyleSheet, Text } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import theme from '../styles/theme';
import {HEADER_BUTTON_SIZE} from "../styles/globalStyles";

const SearchButton = ({ navigation, facetKey,withText=null, color=theme.colors.gray }) => {
  const handlePress = () => {
    navigation.navigate("Search", { initialFacetKey: facetKey });
  };

  return (
    <TouchableOpacity
      onPress={handlePress}
      style={styles.iconButton}
      activeOpacity={0.7}
      hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
    >
      {withText &&
        <Text style={styles.text}>{withText}</Text>
      }
      <Ionicons
        name="search-outline"
        size={HEADER_BUTTON_SIZE}
        color={color}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.large,
    paddingRight: theme.spacings.small,
    color: theme.colors.bluePrimary,
  },
  iconButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default SearchButton;
