import React from 'react';
import {SafeAreaView, StyleSheet, View} from 'react-native';
import SearchComponent from '../components/SearchComponent';
import UsersList from '../components/lists/UsersList';
import theme from "../styles/theme";

import {fetchBlockedUsersSearchResults} from '../utils/api/api';

const BlockedUsersSearchScreen = () => {
  const facets = [
    {
      key: 'blocked',
      label: 'Blocked Users',
      component: UsersList,
      fetchFunction: fetchBlockedUsersSearchResults,
    },
  ];

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <SearchComponent
          facets={facets}
          initialFacetKey={'blocked'}
          placeholder={"Search Blocked Users..."}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: theme.colors.beige,
  },
  container: {
    flex: 1,
    padding: 10,
  },
});

export default BlockedUsersSearchScreen;
