import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SvgRiffLineRight = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={30}
    fill="none"
    {...props}
  >
    <Path
      fill="#DB4C2A"
      d="m2.375 1.35-.65-.375-.75 1.3.65.375zm44.944 26.815-1.585-5.915-5.915 1.585 1.585 5.915zM3.184 3.55l.65.375.75-1.3-.65-.375zm4.907 1.1-.65-.375-.75 1.3.65.375zm8.603 6.7.65.375.75-1.3-.65-.375zm4.907 1.1-.65-.374-.75 1.298.65.375zm2.367 3.1.65.374.75-1.298-.65-.375zm4.907 1.1-.65-.374-.75 1.299.65.375zm8.603 6.7.65.374.75-1.299-.65-.375zm4.907 1.1-.65-.374-.75 1.299.65.375zM1.625 2.65l1.559.9.75-1.3-1.559-.9zm5.716 3.3 9.353 5.4.75-1.3-9.353-5.4zm13.51 7.8 3.117 1.8.75-1.3-3.117-1.8zm7.274 4.2 9.353 5.4.75-1.3-9.353-5.4zm13.51 7.8 1.56.9.75-1.3-1.56-.9z"
    />
  </Svg>
);
export default SvgRiffLineRight;

