import {useState, useCallback, useEffect} from 'react';
import {useMutation} from 'react-query';
import {Alert} from "react-native";
import {
  toggleFavorite,
  toggleBookmark,
  toggleFlag,
  setAsProfileCard,
  deleteCard,
} from '../utils/api/cardsService';
import {shareContent} from "../utils/share";
import alert from "../utils/alert";
import {useNavigation} from "@react-navigation/native";
import {useQueryClient} from "react-query";


import theme from "../styles/theme";

const useCardActions = (card) => {
  const navigation = useNavigation();
  const queryClient = useQueryClient();  // Get the query client


  // Define the default colors
  const noBookmarkColor = theme.colors.white;
  const noHeartColor = theme.colors.white;
  const yesBookmarkColor = theme.colors.activeBookmark;
  const yesHeartColor = theme.colors.activeHeart;

  // State for heart and bookmark colors
  const [heartColor, setHeartColor] = useState(noHeartColor);
  const [bookmarkColor, setBookmarkColor] = useState(noBookmarkColor);

  // Update colors when card changes
  useEffect(() => {
    const currentHeartColor = card?.is_favorited ? yesHeartColor : noHeartColor
    const currentBookmarkColor = card?.is_bookmarked ? yesBookmarkColor : noBookmarkColor
    setHeartColor(currentHeartColor);
    setBookmarkColor(currentBookmarkColor);
  }, [card]);

  const toggleHeartColor = (currentColor) => (
    currentColor === noHeartColor ? yesHeartColor : noHeartColor
  );

  const toggleBookmarkColor = (currentColor) => (
    currentColor === noBookmarkColor ? yesBookmarkColor : noBookmarkColor
  );

  const {mutate: likeCard} = useMutation(toggleFavorite, {
    onMutate: async (uuid) => {
      setHeartColor(toggleHeartColor(heartColor));
    },
    onSuccess: (data, variables) => {
      if (data.favorited) {
        setHeartColor(yesHeartColor);
      }
      else if (!data.favorited) {
        setHeartColor(noHeartColor);
      }
    },
    onError: (error, variables) => {
      setHeartColor(card?.is_favorited ? yesHeartColor : noHeartColor);
    },
  });

  const {mutate: bookmarkCard} = useMutation(toggleBookmark, {
    onMutate: async (uuid) => {
      setBookmarkColor(toggleBookmarkColor(bookmarkColor));
    },
    onSuccess: (data, variables) => {
      if (!data || data.bookmarked !== (bookmarkColor === theme.colors.redPrimary)) {
        setBookmarkColor(toggleBookmarkColor(bookmarkColor));
      }
    },
    onError: (error, variables) => {
      setBookmarkColor(toggleBookmarkColor(bookmarkColor));
    },
  });

  const {mutate: flagCard} = useMutation(toggleFlag, {
    onSuccess: (data, variables) => {
      if (data && data.flagged) {
        alert("Flagged", "The card has been flagged. Thanks for pointing it out.");
      } else {
        alert("Already Flagged", "You have already flagged this card.");
      }
    },
    onError: (error, variables) => {
      alert("Error", "There was an issue flagging the card.");
    },
  });

  const performLike = useCallback(() => {
    if (!card) return;
    likeCard(card.uuid);
  }, [likeCard, card?.uuid]);

  const performBookmark = useCallback(() => {
    if (!card) return;
    bookmarkCard(card.uuid);
  }, [bookmarkCard, card?.uuid]);

  const performFlag = useCallback(() => {
    if (!card) return;
    alert(
      "Confirm Flagging",
      `Are you sure you want to flag the card: ${card.name}?`,
      [
        {text: "Cancel", style: "cancel"},
        {text: "OK", onPress: () => flagCard(card.uuid)},
      ]
    );
  }, [flagCard, card?.uuid, card?.name]);

  // Add mutation for setting a card as profile card
  const {mutate: setProfileCard} = useMutation(setAsProfileCard, {
    onSuccess: (data, variables) => {
      if (data && data.status === "success") {
        alert("Success", "Card set as profile card successfully.");
      } else {
        alert("Error", "Unable to set card as profile card.");
      }
    },
    onError: (error, variables) => {
      alert("Error", "Unable to set card as profile card.");
    },
  });

  const performSetAsProfileCard = useCallback(() => {
    setProfileCard(card.uuid);
  }, [setProfileCard, card?.uuid]);

  // Add mutation for deleting a card
  const {mutate: deleteCardMutation} = useMutation(deleteCard, {
    onSuccess: (data, variables) => {
      // Since we're now returning { success: true } for 204 responses, check for this condition.
      if (data.success) {
        alert("Success", "Card deleted successfully.");
      } else {
        // This else block might not be necessary anymore unless you handle 2xx responses differently
        alert("Error", "Unable to delete card.");
      }
    },
    onError: (error, variables) => {
      // The error handling remains the same.
      alert("Error", "Unable to delete card.");
    },
  });

  const performDeleteCard = useCallback(() => {
    alert(
      "Confirm Deletion",
      `Are you sure you want to delete the card: ${card.name}?`,
      [
        {
          text: "Cancel",
          style: "cancel"
        },
        {
          text: "OK",
          onPress: () => {
            deleteCardMutation(card.uuid, {
              onSuccess: () => {
                // Assuming you have access to the navigation object here
                queryClient.invalidateQueries(['cardDetail', card.uuid]);  // Invalidate the cache
                navigation.navigate('Authenticated', {
                  screen: 'Tabs',
                  params: {
                    screen: 'Home',
                    params: {
                      screen: "HomeScreen",
                    }
                  },
                });
              }
            });
          }
        },
      ]
    );
  }, [deleteCardMutation, card?.name, card?.uuid]);

  const shareCard = async () => {
    if (!card) return;
    await shareContent('card', card.uuid);
  };

  return {
    performLike,
    performBookmark,
    performFlag,
    performSetAsProfileCard,
    performDeleteCard,
    heartColor,
    bookmarkColor,
    shareCard,
  };
};

export default useCardActions;
