import React from 'react';
import {Text, View} from 'react-native';
import API_ENDPOINTS from '../apiEndpoints';
import useWorlds from "../hooks/useWorlds";
import BigWorldsGallery from "../components/BigWorldsGallery";
import globalStyles from "../styles/globalStyles";
import {usePublicProfileData} from "../hooks/usePublicProfileData";
import theme from "../styles/theme";


function FriendSharedWorldsScreen({ route }) {
  const { profileUuid } = route.params;
  const { data: user, isLoading, isError } = usePublicProfileData(profileUuid);


  const {
    worlds: bigWorlds,
    loadingWorlds: loadingBigWorlds,
    fetchMoreWorlds: fetchMoreBigWorlds,
    nextPageURL: bigWorldsNextPageURL,
    handleRefresh: refreshBigWorlds,
  } = useWorlds(API_ENDPOINTS.generate.FRIEND_SHARED_BIG_WORLDS(profileUuid));


  return (
    <BigWorldsGallery
      headerComponent={
      <View style={{alignSelf: 'center'}}>
        <Text style={globalStyles.bigGalleryTitle}>COLLABORATIONS WITH
          {'\n'}
          <Text style={{color: theme.colors.bluePrimary}}>
            {user?.preferred_username}
          </Text>
        </Text>
      </View>
      }
      worlds={bigWorlds}
      loading={loadingBigWorlds}
      loadMore={fetchMoreBigWorlds}
      nextPageURL={bigWorldsNextPageURL}
      onRefresh={refreshBigWorlds}
    />
  );
}


export default FriendSharedWorldsScreen;
