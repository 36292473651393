import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import {Image} from 'expo-image';
import theme from "../styles/theme";
import SpaceChef from "../assets/images/characters/SpaceChef_medium.png";
import SteampunkHillbilly from "../assets/images/characters/SteampunkHillbilly_medium.png";

const UpgradeInfoComponent = () => (
  <View>
    <View style={styles.characterRow}>
      <Image source={SteampunkHillbilly} style={styles.character}></Image>
      <Image source={SpaceChef} style={styles.character}/>
    </View>
  </View>
);

const styles = StyleSheet.create({
  characterRow: {
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-around',
    marginBottom: theme.spacings.xxlarge,
    marginTop: theme.spacings.large,
  },
  character: {
    width: 170,
    height: 300,
    resizeMode: 'contain',
  },
});

export default UpgradeInfoComponent;
