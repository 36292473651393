import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import theme from "../styles/theme";
import {useSubscription} from "../hooks/useSubscription";
import {useProfile} from "../contexts/ProfileContext";
import {useConfig} from "../hooks/useConfig"; // Ensure the theme is correctly imported


const renderFeature = (feature) => (
  <View style={styles.featureItem}>
    <Text style={styles.checkMark}>✓</Text>
    <Text style={styles.featureText}>{feature}</Text>
  </View>
);


const PlanBox = ({ plan, onPress, isWideScreen }) => {
  const { isLoading: isConfigLoading } = useConfig();
  const { isLoading: isProfileLoading } = useProfile();
  const { isLoading: isSubscriptionLoading } = useSubscription();

  const isLoading = isConfigLoading || isProfileLoading || isSubscriptionLoading;

  return (
      <View key={plan.id} style={[
      styles.planCard,
      isWideScreen && styles.widescreenPlanCard,
    ]}>
      <Text style={styles.planTitle}>{plan.title}</Text>
      <View style={styles.planPriceRow}>
        <Text style={styles.planPrice}>{plan.price}</Text>
        <Text style={styles.planSlash}>/</Text>
        <Text style={styles.planCadence}>{plan.cadence}</Text>
      </View>
      <TouchableOpacity
        style={[styles.button, plan.isActive ? styles.activeButton : styles.upgradeButton]}
        onPress={onPress}
        disabled={isLoading}
      >
        <Text
          style={
          [styles.buttonText, plan.isActive ? styles.activeButtonText : styles.buttonText]
        }
        >
          {plan.isActive ? 'ACTIVE' : 'UPGRADE PLAN'}</Text>
      </TouchableOpacity>
      <View style={styles.featureList}>
        {plan.features.map(renderFeature)}
      </View>
    </View>
  );
};


const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  planCard: {
    // flex: 1,
    borderWidth: 1,
    borderColor: theme.colors.gray,
    backgroundColor: theme.colors.loadingBackground,
    borderRadius: theme.borderRadius.medium,
    padding: theme.spacings.medium,
    width: '100%', // Adjust as needed for your layout
    alignSelf: 'center',
    marginVertical: theme.spacings.medium,
    minHeight: 280,
    alignItems: 'flex-start',
  },
  widescreenPlanCard: {
    width: '30%', // Adjust as needed for your layout
  },
  planTitle: {
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
  },
  planPriceRow: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  planPrice: {
    fontSize: theme.fonts.sizes.xlarge,
    color: theme.colors.black,
    marginVertical: theme.spacings.small,
  },
  planSlash: {
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.lightgray,
    marginHorizontal: theme.spacings.xsmall,
  },
  planCadence: {
    fontSize: theme.fonts.sizes.small,
    color: theme.colors.lightgray,
    marginBottom: theme.spacings.small,
  },
  button: {
    borderRadius: theme.borderRadius.small,
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    alignItems: 'center',
    marginVertical: theme.spacings.small,
    backgroundColor: theme.colors.bluePrimary,
    width: '100%',
  },
  activeButton: {
    backgroundColor: theme.colors.yellowPrimary,
  },
  upgradeButton: {
    backgroundColor: theme.colors.bluePrimary,
  },
  buttonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
  },
  activeButtonText: {
    color: theme.colors.gray,
  },
  featureList: {
    alignSelf: 'stretch',
    marginVertical: theme.spacings.medium,
  },
  featureItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: theme.spacings.xsmall,
  },
  checkMark: {
    color: theme.colors.redPrimary,
    marginRight: theme.spacings.xsmall,
  },
  featureText: {
    fontSize: theme.fonts.sizes.large,
  },
});

export default PlanBox;
