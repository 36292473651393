import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Modal, ActivityIndicator } from 'react-native';
import theme from "../../styles/theme";
import moment from 'moment';


const UpgradeConfirmationModal = ({ visible, onConfirm, onCancel, updateInfo }) => {
  if (!visible || !updateInfo) return null;

  let content;
  if (updateInfo && updateInfo.loading) {
    // Show an activity indicator if the data is still loading
    content = <ActivityIndicator size="large" color={theme.colors.bluePrimary} />;
  } else if (updateInfo.subscription_change === 'upgrade') {
    // Handle upgrade

    const { amount_due, currency, lines, period_end } = updateInfo.upcoming_invoice;
    const nextInvoiceAmount = amount_due / 100; // Convert cents to dollars
    const nextInvoiceDate = moment.unix(period_end).format('MMMM D, YYYY'); // Format for readability

    // Filter for prorated lines and calculate total prorated amount
    const proratedAmount = lines.data
      .filter(line => line.proration)
      .reduce((acc, line) => acc + (line.amount / 100), 0); // Sum and convert to dollars

    // Sum only regular charges for the next billing period
    const regularCharges = lines.data
      .filter(line => !line.proration)
      .reduce((acc, line) => acc + line.amount, 0) / 100;

    // Extract line item descriptions for clarity on what each charge/refund is for
    const lineItems = lines.data.map(line => ({
      description: line.description,
      amount: (line.amount / 100).toFixed(2), // Convert to dollars
      isProration: line.proration
    }));

    content = (
      <>
        <Text style={styles.modalText}>
          Upgrading will result in an immediate prorated charge of ${nextInvoiceAmount.toFixed(2)}.
        </Text>
        <Text style={styles.modalText}>
          Your next invoice on {nextInvoiceDate} will be ${regularCharges.toFixed(2)}.
        </Text>
        <View style={styles.lineItems}>
          {lineItems.map((item, index) => (
            <Text key={index} style={styles.itemText}>
              {item.description}: ${item.amount}
              {item.isProration ? ' (Prorated)' : ''}
            </Text>
          ))}
        </View>
      </>
    );
  } else if (updateInfo.subscription_change === 'downgrade') {
    // Handle downgrade
    const { current_tier, downgraded_tier, effect_from, note } = updateInfo.downgrade_preview;
    const effectFromDate = moment.unix(effect_from).format('MMMM D, YYYY');

    content = (
      <>
        <Text style={styles.modalText}>You are downgrading from
          <Text style={{fontWeight: "bold"}}> {current_tier} </Text>
          to
          <Text style={{fontWeight: "bold"}}> {downgraded_tier} </Text>.
        </Text>
        <Text style={styles.modalText}>Changes will take effect starting on {effectFromDate}.</Text>
        <Text style={styles.modalText}>{note}</Text>
      </>
    );
  } else if (updateInfo.subscription_change === 'keep_current') {
    const message = updateInfo.message || 'No changes will be made to your subscription.';
    content = <Text style={styles.modalText}>{message}</Text>;
  }
  else {
    // Handle other cases
    content = <Text style={styles.modalText}>Are you sure you want to proceed?</Text>;
  }

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onCancel}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalContainer}>
          <Text style={styles.modalTitle}>Confirm Subscription Change</Text>
          {content}
          <TouchableOpacity
            style={styles.confirmButton}
            onPress={onConfirm}
            disabled={updateInfo.loading}
          >
            <Text style={styles.confirmButtonText}>Confirm</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.cancelButton} onPress={onCancel}>
            <Text style={styles.cancelButtonText}>Cancel</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: theme.colors.white,
    padding: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
    width: '80%',
    maxWidth: 500,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
  },
  modalTitle: {
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    marginBottom: theme.spacings.medium,
  },
  modalText: {
    fontSize: theme.fonts.sizes.large,
    marginBottom: theme.spacings.small,
  },
  confirmButton: {
    backgroundColor: theme.colors.bluePrimary,
    borderRadius: theme.borderRadius.small,
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    alignItems: 'center',
    marginTop: theme.spacings.medium,
  },
  lineItems: {
    marginTop: theme.spacings.medium,
    flexDirection: 'column',
  },
  confirmButtonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
  },
  cancelButton: {
    backgroundColor: theme.colors.gray,
    borderRadius: theme.borderRadius.small,
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    alignItems: 'center',
    marginTop: theme.spacings.small,
  },
  cancelButtonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
  },
});

export default UpgradeConfirmationModal;
