import React from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import {Image} from "expo-image";
import theme from "../../styles/theme";
import FabylPremiumButton from "../../assets/images/FabylPremiumButton.png"
import {useNavigation} from "@react-navigation/native";
import {useProfile} from "../../contexts/ProfileContext";
import {MaterialIcons} from "@expo/vector-icons";


const UpgradeModal = ({ isVisible, onConfirm, onCancel }) => {
  const navigation = useNavigation();
  const { profileData, refetchProfileData } = useProfile();

  const handlePremiumPress = () => {
    navigation.navigate('UpgradeScreen');
    onCancel();
  }

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={onCancel}>
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <TouchableOpacity
            style={styles.closeButton}
            onPress={onCancel} // Use the onCancel function to close the modal
          >
            <MaterialIcons name="close" size={24} color={theme.colors.white} />
          </TouchableOpacity>
          <Text style={styles.modalText}>ah jeebz...</Text>
          <Text style={styles.descriptionText}>No creations remaining
          </Text>
          <Text style={styles.descriptionText}>Upgrade to Fabyl Premium for more cards and more control.</Text>
          <TouchableOpacity onPress={handlePremiumPress}>
            <Image source={FabylPremiumButton} style={styles.fabylPremiumButton} />
          </TouchableOpacity>
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button} onPress={onCancel}>
              <Text style={styles.buttonText}>CANCEL</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};


const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacings.small,
    right: theme.spacings.small,
  },
  fabylPremiumButton: {
    width: 1316 / 4.2,
    height: 750 / 4.2,
    backgroundColor: theme.colors.yellowPrimary,
    marginTop: theme.spacings.medium,
    marginBottom: theme.spacings.xxlarge,
    borderRadius: 21,
  },
  time: {
    fontFamily: theme.fonts.family.bold,
  },
  mage: {
    fontSize: 30,
  },
  modalView: {
    margin: theme.spacings.large,
    backgroundColor: theme.colors.gray,
    borderRadius: theme.borderRadius.large,
    padding: theme.spacings.large,
    alignItems: 'center',
    shadowColor: theme.colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    minWidth: '80%',
    minHeight: '50%',
  },
  modalText: {
    marginBottom: theme.spacings.medium,
    textAlign: 'center',
    fontWeight: theme.fonts.weight.bold,
    fontSize: theme.fonts.sizes.xlarge,
    color: theme.colors.redPrimary,
    fontFamily: theme.fonts.family.bold,
  },
  descriptionText: {
    marginBottom: theme.spacings.medium,
    textAlign: 'center',
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.white,
    fontFamily: theme.fonts.family.regular,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  button: {
    borderRadius: theme.borderRadius.medium,
    paddingVertical: theme.spacings.small,
    elevation: 2,
    marginBottom: theme.spacings.small,
    width: '40%',
  },
  buttonText: {
    color: theme.colors.white,
    textAlign: 'center',
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.medium,
    textDecorationLine: 'underline',
  },
});

export default UpgradeModal;
