import { useQuery } from 'react-query';
import {getConfig} from "../utils/api/userService";

export const useConfig = () => {
  return useQuery('config', getConfig, {
    // Additional options to ensure the data is up-to-date
    staleTime: 0,  // Always refetch on window focus
    refetchOnWindowFocus: true,
  });
};
