// web/react-native-stripe/index.js
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';


export const StripeProvider = ({ children, publishableKey }) => {
  const stripePromise = loadStripe(publishableKey)

  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};


// Add more functions or components as needed, mirroring the API you use from @stripe/stripe-react-native
