import React from 'react';
import { ScrollView, Text, View, StyleSheet } from 'react-native';
import { Image } from 'expo-image';
import theme from "../styles/theme";

const TeamMember = ({ name, title, description, image }) => (
  <View style={styles.memberContainer}>
    <Image source={image} style={styles.image} contentFit="contain" />
    <Text style={styles.name}>{name}</Text>
    <Text style={styles.title}>{title}</Text>
    <Text style={styles.description}>{description}</Text>
  </View>
);

const MeetTheTeamScreen = () => {
const teamMembers = [
  {
    name: 'Vorkov',
    title: 'The Mushroom King, CEO',
    description: "Vorkov, our CEO, navigates the market's wilderness with a gruff demeanor. His unparalleled vision compels loyalty, driving innovation from the shadows of his discontent.",
    image: require('../assets/images/characters/Vorkov_medium.png'),
  },
  {
    name: 'Frankenpug',
    title: 'Chief Happiness Officer',
    description: "Frankenpug, with a somber soul masked by a wagging tail, cultivates joy in our ranks, silently bearing the weight of his own quest for happiness.",
    image: require('../assets/images/characters/Frankenpug_medium.png'),
  },
  {
    name: 'Space Chef',
    title: 'Culinary Innovator',
    description: "SpaceChef, our kitchen's laid-back maestro, stirs innovation with a dash of nonchalance. His dishes fuel us, securing his spot despite a relaxed approach to work.",
    image: require('../assets/images/characters/SpaceChef_medium.png'),
  },
  {
    name: 'Robot',
    title: 'Lead Engineer',
    description: "Robot, the overworked backbone of our tech, automates success while juggling the stress of innovation, especially when SpaceChef's culinary distractions arise.",
    image: require('../assets/images/characters/Robot_medium.png'),
  },
  {
    name: 'Steampunk Hillbilly',
    title: 'Creative Director',
    description: "SteampunkHillbilly, aloof yet artistic, crafts our brand's future with a quirky touch, blissfully detached from the office's bustling dynamics.",
    image: require('../assets/images/characters/SteampunkHillbilly_medium.png'),
  },
];



  return (
    <ScrollView contentContainerStyle={styles.container}>
      {teamMembers.map((member, index) => (
        <TeamMember key={index} {...member} />
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingVertical: theme.spacings.medium,
  },
  memberContainer: {
    width: '100%',
    alignItems: 'center',
    marginVertical: theme.spacings.xlarge,
    borderBottomWidth: StyleSheet.hairlineWidth,
    maxWidth: 500,
  },
  image: {
    width: 250,
    height: 400,
    marginBottom: theme.spacings.medium,
  },
  name: {
    fontSize: theme.fonts.sizes.xlarge,
    color: theme.colors.blueSecondary,
    fontWeight: 'bold',
  },
  title: {
    fontSize: theme.fonts.sizes.large,
    fontStyle: 'italic',
  },
  description: {
    fontSize: theme.fonts.sizes.medium,
    justifyContent: 'flex-start',
    marginTop: 5,
    paddingHorizontal: 10,
    paddingBottom: theme.spacings.xlarge,
  },
});

export default MeetTheTeamScreen;
