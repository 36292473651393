import React, { useEffect, useRef, useState } from 'react';
import {View, Text, StyleSheet, ScrollView, Animated, Platform} from 'react-native';
import SvgRiff from "../../assets/images/Riff";
import SvgRiffLine from "../../assets/images/RiffLine";
import StaticMiniCard from "../../components/StaticMiniCard";
import theme from "../../styles/theme";
import CloseButton from "./CloseButton";
import DemoInputBox from "../DemoInputBox";
import styles from "./slideStyles";
import cardData from './cardData';


const Slide2 = ({ startSlideAnimation, navigationTarget }) => {
  const scrollViewRef = useRef(null);
  const [animationStep, setAnimationStep] = useState(0);
  const opacityRiffLine = useRef(new Animated.Value(0)).current;
  const opacitySecondCard = useRef(new Animated.Value(0)).current;
  const opacityDemoInput = useRef(new Animated.Value(0)).current;

  const [startFirstCardPressAnimation, setStartFirstCardPressAnimation] = useState(false);
  const [startDemoInputAnimation, setStartDemoInputAnimation] = useState(false);
  const [startSecondCardAnimation, setStartSecondCardAnimation] = useState(false);

  const handleAnimationComplete = () => {
    setTimeout(() => {
      setStartSecondCardAnimation(true);
    }, 200); // Delay the card animation by 200ms
  };

  useEffect(() => {
    if (startSlideAnimation) {
      setAnimationStep(1);
    } else {
      // Reset animations and state variables when startSlideAnimation becomes false
      setAnimationStep(0);
      opacityRiffLine.setValue(0);
      opacitySecondCard.setValue(0);
      opacityDemoInput.setValue(0);
      setStartFirstCardPressAnimation(false);
      setStartDemoInputAnimation(false);
      setStartSecondCardAnimation(false);
      if (Platform.OS === 'web') {
        scrollViewRef.current.style.transform = 'translateY(0)';
      }
      else {
        scrollViewRef.current?.scrollTo({ y: 0, animated: false });
      }
    }
  }, [startSlideAnimation]);

  useEffect(() => {
    let timeoutId;

    switch (animationStep) {
      case 1:
        timeoutId = setTimeout(() => {
          setStartFirstCardPressAnimation(true);
          setAnimationStep(2);
        }, 3000);
        break;
      case 2:
        timeoutId = setTimeout(() => {
          Animated.timing(opacityRiffLine, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
          }).start(() => {
            setAnimationStep(3);
          });
        }, 100);
        break;
      case 3:
        timeoutId = setTimeout(() => {
          Animated.timing(opacityDemoInput, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true,
          }).start(() => {
            setAnimationStep(4);
          });
        }, 200);
        break;
      case 4:
        timeoutId = setTimeout(() => {
          setStartDemoInputAnimation(true);
          setAnimationStep(5);
          if (Platform.OS === 'web') {
            scrollViewRef.current.style.transform = 'translateY(-300px)';
            scrollViewRef.current.style.transition = 'transform 0.5s ease-in-out';
          }
          else {
            scrollViewRef.current?.scrollTo({ y: 300, animated: true });
          }
        }, 2000);
        break;
      case 5:
        timeoutId = setTimeout(() => {
          Animated.timing(opacityDemoInput, {
            toValue: 0,
            duration: 500,
            useNativeDriver: true,
          }).start(() => {
            setAnimationStep(0);
          });
        }, 7000);
        // ^ This has the match with the duration of the demo input box animation
        // Clearly this could be better writtten to have the animation sequence centralized
        // and easier to edit...but this is where we are at.
        break;
      default:
        break;
    }

    // Cleanup function to clear timeouts and reset animations if startSlideAnimation becomes false
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (!startSlideAnimation) {
        opacityRiffLine.setValue(0);
        opacitySecondCard.setValue(0);
        opacityDemoInput.setValue(0);
        setStartFirstCardPressAnimation(false);
        setStartDemoInputAnimation(false);
        setStartSecondCardAnimation(false);
      }
    };
  }, [animationStep, startSlideAnimation]);


  return (
    <View style={styles.slide}>
      <CloseButton navigationTarget={navigationTarget} />
      <View style={styles.topContainer}>
        <View style={styles.topIconContainer}>
          <SvgRiff style={{transform: [{scale: 1.5}]}} wandColor={theme.colors.gray}/>
        </View>
        <Text style={styles.text}>2. CONTINUE THE STORY</Text>
        <Text style={styles.subtitle}>
          Press riff
          <View style={localStyles.inlineSvg}>
            <SvgRiff style={{transform: [{scale: 0.8}]}} wandColor={theme.colors.gray}/>
          </View>
          {'\n'}and enter the next title.
        </Text>
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        ref={scrollViewRef}
        style={{ flex: 1, width: '100%'}}
      >
        <View style={styles.cardRow}>
          <StaticMiniCard
            imageSource={cardData.rootCard.imageSource}
            title={cardData.rootCard.title}
            description={cardData.rootCard.description}
            username={cardData.rootCard.username}
            userImageSource={cardData.rootCard.userImageSource}
            initialOpacity={1}
            startAnimation={startFirstCardPressAnimation}
          />
        </View>
        <Animated.View style={[styles.rowContainer, { opacity: opacityRiffLine }]}>
          <SvgRiffLine style={styles.riffLine} />
        </Animated.View>
         <View style={styles.cardContainer}>
          <Animated.View style={[
            styles.demoInputContainer,
            styles.overlay,
            { opacity: opacityDemoInput }
          ]}>
            <DemoInputBox
              text={cardData.firstRiffCard.title}
              animationStep={animationStep}
              startAnimation={startDemoInputAnimation}
              onAnimationComplete={handleAnimationComplete}
              placeHolderText={"NAME THE NEXT FABYL"}
            />
          </Animated.View>
          <View style={[styles.cardRow, styles.overlay]}>
            <StaticMiniCard
              imageSource={cardData.firstRiffCard.imageSource}
              title={cardData.firstRiffCard.title}
              description={cardData.firstRiffCard.description}
              username={cardData.firstRiffCard.username}
              userImageSource={cardData.firstRiffCard.userImageSource}
              initialOpacity={0}
              endingRiffButtonY={0}
              startAnimation={startSecondCardAnimation}
            />
          </View>
         </View>
      </ScrollView>
    </View>
  );
};

const localStyles = StyleSheet.create({
  inlineSvg: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: -3, // Adjust this value as needed
  }
});

export default Slide2;
