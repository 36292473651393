import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FlatList, ScrollView, StyleSheet, Platform} from 'react-native';
import Animated from 'react-native-reanimated';
import MiniCardWithInfo, {TOTAL_CARD_HEIGHT} from './MiniCardWithInfo';
import { debounce } from 'lodash';

const ROWS = 3;
const SCROLL_INCREMENT = 0.23;
const SCROLL_INTERVAL = 10;
const MANUAL_SCROLL_DELAY = 1500;

const HorizontalMiniCardGallery = ({
  cards,
  nextPageURL,
  loadMore,
  loading,
  renderItem,
  initialScrollPosition = 0,
  onScrollPositionChange,
}) => {
  const flatListRefs = useRef([]);
  const [widths, setWidths] = useState([]);
  const scrollViewRef = useRef(null);
  const scrollPosition = useRef(initialScrollPosition);
  const animationRef = useRef(null);
  const autoScrollTimeoutRef = useRef(null);
  const isTouchingRef = useRef(false);
  const isInitialRenderRef = useRef(true);

  const defaultRenderItem = ({item}) => <MiniCardWithInfo item={item}/>;
  renderItem = renderItem || defaultRenderItem;

  const handleEndReached = () => {
    if (nextPageURL && !loading) {
      loadMore(nextPageURL);
    }
  };

  const smoothScroll = useCallback(() => {
    if (scrollViewRef.current && !isTouchingRef.current) {
      scrollPosition.current += SCROLL_INCREMENT;
      scrollViewRef.current.scrollTo({x: scrollPosition.current, animated: false});
      animationRef.current = requestAnimationFrame(smoothScroll);
    }
  }, [onScrollPositionChange]);

  const startAutoScroll = useCallback((withDelay = false) => {
    if (autoScrollTimeoutRef.current) {
      clearTimeout(autoScrollTimeoutRef.current);
    }

    const startScrolling = () => {
      if (!animationRef.current) {
        animationRef.current = requestAnimationFrame(smoothScroll);
      }
    };

    if (withDelay) {
      autoScrollTimeoutRef.current = setTimeout(startScrolling, MANUAL_SCROLL_DELAY);
    } else {
      startScrolling();
    }
  }, [smoothScroll]);

  const stopAutoScroll = useCallback(() => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
      animationRef.current = null;
    }
    if (autoScrollTimeoutRef.current) {
      clearTimeout(autoScrollTimeoutRef.current);
      autoScrollTimeoutRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (isInitialRenderRef.current) {
      scrollViewRef.current?.scrollTo({x: initialScrollPosition, animated: false});
    }
  }, []);  // Empty dependency array, runs only once on mount

  useEffect(() => {
    if (isInitialRenderRef.current) {
      startAutoScroll(false);
      isInitialRenderRef.current = false;
    }
    return () => {
      stopAutoScroll();
      onScrollPositionChange(scrollPosition.current);
    };
  }, [startAutoScroll, stopAutoScroll]);

  const splitDataIntoRows = (data, numRows) => {
    const rows = Array.from({length: numRows}, () => []);
    data.forEach((item, index) => {
      rows[index % numRows].push(item);
    });
    return rows;
  };

  const rowsData = useMemo(() => splitDataIntoRows(cards, ROWS), [cards]);

  const checkEndReached = useCallback(
    debounce(({nativeEvent}) => {
      const contentWidth = Math.min(...widths);
      const layoutWidth = nativeEvent.layoutMeasurement.width;
      const offsetX = nativeEvent.contentOffset.x;
      const triggerThreshold = 0.2;

      scrollPosition.current = offsetX;

      const isNearEnd = contentWidth - offsetX - layoutWidth < layoutWidth * triggerThreshold;

      if (isNearEnd && nextPageURL && !loading) {
        handleEndReached();
      }
    }, 0), // 200ms debounce time, adjust as needed
    [widths, nextPageURL, loading, handleEndReached]
  );

  const onContentSizeChange = (contentWidth, index) => {
    setWidths((prevWidths) => {
      const newWidths = [...prevWidths];
      newWidths[index] = contentWidth;
      return newWidths;
    });
  };

  return (
    <ScrollView
      ref={scrollViewRef}
      horizontal
      showsHorizontalScrollIndicator={false}
      scrollEventThrottle={16}
      scrollEnabled={Platform.OS !== 'web'}
      // onScroll={checkEndReached}
      onScroll={({nativeEvent}) => {
        checkEndReached({nativeEvent});
        onScrollPositionChange(nativeEvent.contentOffset.x);  // Add this line
      }}
      onScrollBeginDrag={stopAutoScroll}
      onScrollEndDrag={() => startAutoScroll(true)}
      onMomentumScrollEnd={() => startAutoScroll(true)}
      onTouchStart={() => {
        isTouchingRef.current = true;
        stopAutoScroll();
      }}
      onTouchEnd={() => {
        isTouchingRef.current = false;
        startAutoScroll(true);
      }}
    >
      <Animated.View style={[{ height: TOTAL_CARD_HEIGHT * ROWS }]}>
        {rowsData.map((rowData, rowIndex) => (
          <FlatList
            key={rowIndex}
            ref={(ref) => (flatListRefs.current[rowIndex] = ref)}
            data={rowData}
            renderItem={renderItem}
            keyExtractor={(item, index) => `${item?.uuid}-${index}`}
            onContentSizeChange={(width, height) => onContentSizeChange(width, rowIndex)}
            horizontal
            scrollEnabled={false}
            contentContainerStyle={[styles.rowContainer]}
          />
        ))}
      </Animated.View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    marginVertical: 0,
  },
  scrollView: {
    flex: 1,
  },
});

export default React.memo(HorizontalMiniCardGallery);
