import React, { useState, useEffect } from 'react';
import { View, Switch, Text, ScrollView, StyleSheet } from 'react-native';
import { fetchNotificationSettings, updateNotificationSettings } from '../utils/api/userService';
import theme from '../styles/theme';

// A mapping to convert settings keys to user-friendly text
// The keys must match the serializer for notification settings
const settingLabels = {
    receive_riffs_notifications: 'Riffs',
    receive_sent_to_notifications: 'Sent Cards',
    receive_friend_requests_notifications: 'Friend Requests',
    receive_friend_acceptance_notifications: 'Friend Acceptances',
    receive_likes_notifications: 'Likes',
};

const NotificationSettingsScreen = () => {
    const [settings, setSettings] = useState({
        receive_riffs_notifications: true,
        receive_sent_to_notifications: true,
        receive_friend_requests_notifications: true,
        receive_friend_acceptance_notifications: true,
        receive_likes_notifications: false,
    });

    useEffect(() => {
        const loadSettings = async () => {
            try {
                const fetchedSettings = await fetchNotificationSettings();
                setSettings(fetchedSettings);
            } catch (error) {
                console.error('Failed to fetch notification settings:', error);
            }
        };

        loadSettings();
    }, []);

    const handleToggle = async (settingKey) => {
        const newSettings = { ...settings, [settingKey]: !settings[settingKey] };
        try {
            await updateNotificationSettings(newSettings);
            setSettings(newSettings);
        } catch (error) {
            console.error('Failed to update notification settings:', error);
        }
    };

    return (
        <ScrollView style={styles.container}>
            <Text style={styles.title}>Notification Preferences</Text>
            <Text style={styles.description}>What kind of notifications would you like to receive as push notifications on your device?</Text>
            {Object.keys(settings).map((key) => (
                <View key={key} style={styles.settingContainer}>
                    <Text style={styles.settingText}>
                        {settingLabels[key]}
                    </Text>
                    <Switch
                        ios_backgroundColor={theme.colors.verylightgray}
                        onValueChange={() => handleToggle(key)}
                        value={settings[key]}
                    />
                </View>
            ))}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: theme.spacings.large,
        backgroundColor: theme.colors.beige,
        maxWidth: 600,
        alignSelf: 'center',
    },
    title: {
        fontSize: theme.fonts.sizes.xlarge,
        fontWeight: 'bold',
        marginBottom: theme.spacings.small,
        color: theme.colors.gray,
    },
    description: {
        fontSize: theme.fonts.sizes.medium,
        marginBottom: theme.spacings.large,
        color: theme.colors.lightgray,
    },
    settingContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacings.small,
        marginBottom: theme.spacings.medium,
        // backgroundColor: theme.colors.white,
        borderRadius: theme.borderRadius.medium,
        shadowColor: theme.colors.gray,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 3.84,
        elevation: 2,
    },
    settingText: {
        fontSize: theme.fonts.sizes.large,
        fontFamily: theme.fonts.family.bold,
        color: theme.colors.gray,
    },
});

export default NotificationSettingsScreen;
