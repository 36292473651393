import { useState, useRef, useEffect } from 'react';
import { Audio } from 'expo-av';
import debounce from "lodash/debounce";
import Animated, { useSharedValue, useAnimatedStyle, withTiming, Easing } from 'react-native-reanimated';


const AUDIO_FILE_GAP = 500; // 500 milliseconds gap between audio files

const useAudioPlayer = (card) => {
  const audioFiles = card ? [card.title_audio, card.flavor_audio] : [];
  const audioDurations = card ? [card.title_audio_duration, card.flavor_audio_duration] : [];
  const soundRef = useRef(null);
  // const [progress, setProgress] = useState(0); // New state for progress
  const progress = useSharedValue(0);
  const stopPlayback = useRef(false); // New ref to track whether playback should stop

  const shouldContinuePlayback = useRef(true);
  const isAudioPlayingRef = useRef(false);

  // Convert audio durations from seconds to milliseconds and add gap time
  const durations = audioDurations.map((duration, index) =>
    (duration * 1000) + (index < audioDurations.length - 1 ? AUDIO_FILE_GAP : 0)
  );

  // Calculate total duration
  const totalDuration = durations.reduce((total, duration) => total + duration, 0);

  const playAudioList = async () => {
    if (isAudioPlayingRef.current) {
      return;
    }
    if (audioFiles.length === 0) {
      return;
    }
    isAudioPlayingRef.current = true; // Update ref synchronously

    shouldContinuePlayback.current = true;
    stopPlayback.current = false;

    await Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
      allowsRecordingIOS: false,
      staysActiveInBackground: false,
      shouldDuckAndroid: false,
    });


    let cumulativePosition = 0;
    for (const [index, file] of audioFiles.entries()) {
      if (!shouldContinuePlayback.current || stopPlayback.current) {
        break;
      }
      try {
        if (soundRef.current) {
          await soundRef.current.unloadAsync();
        }

        const { sound: newSound } = await Audio.Sound.createAsync({ uri: file });
        soundRef.current = newSound;

        let isFinishedPlaying = false;
        let progressInterval;

        newSound.setOnPlaybackStatusUpdate((status) => {
          if (status.isLoaded) {
            let previousFilesDuration = durations.slice(0, index).reduce((total, duration) => total + duration, 0);
            let cumulativeProgress = (previousFilesDuration + status.positionMillis) / totalDuration;

            // setProgress(cumulativeProgress);
            progress.value = withTiming(cumulativeProgress, {
              duration: 500, // Adjust this duration for a smoother transition
              easing: Easing.linear, // You can experiment with different easing functions
            });
          }
          if (status.didJustFinish) {
            isFinishedPlaying = true;
            clearInterval(progressInterval);
          }
        });

        await newSound.playAsync();


        // Wait for the audio to finish playing
        await new Promise((resolve) => {
          const checkIfFinished = setInterval(() => {
            if (isFinishedPlaying) {
              clearInterval(checkIfFinished);
              resolve();
            }
          }, 100);
        });

        await new Promise(resolve => setTimeout(resolve, AUDIO_FILE_GAP));
      } catch (error) {
        console.error("Error playing audio file", file, error);
      }
    }
    isAudioPlayingRef.current = true;
  };


  const playAudio = async () => {
    progress.value = 0 // Reset progress
    await stopAndPreventFurtherPlayback();
    await playAudioList(audioFiles);
  };

  const debouncedPlayAudio = debounce(async() => {
    progress.value = 0 // Reset progress
    await stopAndPreventFurtherPlayback();
    await playAudioList(audioFiles);
  }, 500);


  const pauseAudio = async () => {
    if (soundRef.current) {
      await soundRef.current.pauseAsync();
      isAudioPlayingRef.current = false; // Update ref synchronously
    }
    stopPlayback.current = true; // Set the flag to stop subsequent playback
  };

  const resumeAudio = async () => {
    if (soundRef.current) {
      await soundRef.current.playAsync();
      isAudioPlayingRef.current = true; // Update ref synchronously
    }
    stopPlayback.current = false; // Set the flag to stop subsequent playback
  };

  const togglePlayPause = async () => {
        if (isAudioPlayingRef.current) {
            await pauseAudio(); // If playing, pause
        } else {
          // await resumeAudio(); // If paused, play
          await playAudio(); // If paused, play
        }
    };

  const stopAndPreventFurtherPlayback = async () => {
    shouldContinuePlayback.current = false;
    if (soundRef.current) {
      const status = await soundRef.current.getStatusAsync();
      if (status.isLoaded) {
        await soundRef.current.pauseAsync();
        isAudioPlayingRef.current = false; // Update ref synchronously
      }
    }
  };

  useEffect(() => {
    return () => {
      if (soundRef.current) {
        soundRef.current.unloadAsync();
      }
    };
  }, []);

  return { progress, playAudioList, pauseAudio, stopAndPreventFurtherPlayback, playAudio, debouncedPlayAudio, togglePlayPause };
};

export default useAudioPlayer;
