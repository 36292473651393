import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Modal } from 'react-native';
import {MaterialIcons, Ionicons, FontAwesome} from '@expo/vector-icons';
import theme from '../../styles/theme';
import {useNavigation} from "@react-navigation/native";
import PublicProfileDetailsModal from "../modals/PublicProfileDetailsModal";

const FriendInteractionButton = ({
  profileData,
  isPublic,
  isOwnProfile,
  isFriend,
  hasRequestReceived,
  hasRequestSent,
  onAcceptFriendRequest,
  onSendFriendRequest,
  onRemoveFriend,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const navigation = useNavigation();

  const handlePress = () => {
    if (!isPublic) {
      navigation.navigate('FriendsSearch')
    } else if (isOwnProfile) {
      // navigation.navigate('PrivateProfileScreen');
      navigation.navigate('Authenticated', {
      screen: 'Tabs',
      params: {
        screen: 'Profile',
        params: {
          screen: 'PrivateProfileScreen',
        },
      },
    });
    }
    else if (isFriend) {
      setModalVisible(!modalVisible);
    } else if (hasRequestReceived) {
      onAcceptFriendRequest();
    } else if (!hasRequestSent && !isFriend) {
      onSendFriendRequest();
    }
  };

  return (
    <View>
      <TouchableOpacity
        onPress={handlePress}
        style={[
          styles.button,
          (!isPublic || isFriend || isOwnProfile) && styles.buttonSpecial,
        ]}>
        {!isPublic ? (
          <View style={styles.friendStatus}>
            <Text style={styles.buttonTextWhite}>
              FRIENDS
            </Text>
            <MaterialIcons name="keyboard-arrow-right" size={24} color={theme.colors.white} />
          </View>
        ) : isOwnProfile ? (
          <View style={styles.friendStatus}>
            <Text style={styles.buttonTextWhite}>
              This is your public profile.
            </Text>
          </View>
        ) : isFriend ? (
          <View style={styles.friendStatus}>
            <Text style={styles.buttonTextWhite}>You are friends.</Text>
            <Ionicons
              name="ellipsis-horizontal-circle"
              size={24}
              color={theme.colors.white}
            />
          </View>
        ) : hasRequestReceived ? (
          <Text style={styles.buttonText}>Accept Friend Request</Text>
        ) : hasRequestSent ? (
          <View style={styles.friendStatus}>
            <Text style={styles.buttonText}>Request Sent</Text>
             <FontAwesome name="paper-plane-o" size={20} color={theme.colors.black} />
          </View>
        ) : (
          <View style={styles.friendStatus}>
            <Text style={styles.buttonText}>Add Friend</Text>
            <MaterialIcons
              name="add"
              size={24}
              color={theme.colors.black}
            />
          </View>

        )}
      </TouchableOpacity>
      <PublicProfileDetailsModal
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        profileData={profileData}
        onRemoveFriend={onRemoveFriend}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  // Existing styles remain unchanged
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)', // Optional: for backdrop effect
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  modalOptionButton: {
    // Style for your modal buttons
    backgroundColor: theme.colors.yellowPrimary,
    paddingHorizontal: theme.spacings.medium,
    paddingVertical: theme.spacings.small,
    borderRadius: theme.borderRadius.small,
    marginVertical: theme.spacings.small,
  },
  modalOptionText: {
    color: theme.colors.black,
    fontSize: theme.fonts.sizes.medium,
    textAlign: 'center',
  },
  button: {
    backgroundColor: theme.colors.yellowPrimary,
    padding: theme.spacings.small,
    borderRadius: theme.borderRadius.medium,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%', // Take up the whole width
    marginBottom: theme.spacings.small,
  },
  friendStatus: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    color: theme.colors.black,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
  },
  buttonTextWhite: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
  },
  buttonSpecial: {
    backgroundColor: theme.colors.bluePrimary, // Special background color for !isPublic and isFriend
  },
  buttonTextSpecial: {
    color: theme.colors.white, // Special text color for !isPublic and isFriend
  },
  options: {
    backgroundColor: theme.colors.lightgray,
    padding: theme.spacings.small,
    marginTop: theme.spacings.xsmall,
    borderRadius: theme.borderRadius.small,
    position: 'absolute', // Use absolute positioning
    top: '100%', // Position the dropdown right below the button
    left: 0, // Align the dropdown to the left edge of the button
    right: 0, // Align the dropdown to the right edge of the button
    zIndex: 1000, // Make sure the dropdown overlays other content
  },
  optionButton: {
    padding: theme.spacings.small,
  },
  optionText: {
    color: theme.colors.black,
    fontSize: theme.fonts.sizes.medium,
  },
  buttonAndDropdown: {
  },

  // Other styles unchanged
});

export default FriendInteractionButton;
