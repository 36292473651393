import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import theme from '../styles/theme';
import {HEADER_BUTTON_SIZE} from "../styles/globalStyles";
import {useNavigation} from "@react-navigation/native";

const SettingsButton = () => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity onPress={() => navigation.navigate('Settings')} style={styles.button}>
      <Ionicons
        name="settings-outline"
        size={HEADER_BUTTON_SIZE}
        color={theme.colors.gray}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    // padding: 10,
  },
});

export default SettingsButton;
