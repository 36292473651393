import React, {useLayoutEffect} from 'react';
import {View} from 'react-native';
import API_ENDPOINTS from '../apiEndpoints';
import globalStyles from "../styles/globalStyles";
import SearchButton from "../components/SearchButton";
import NotificationsButton from "../components/NotificationsButton";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import TabComponent from "../components/TabComponent";
import TimeGallery from "../components/TimeGallery";
import useCards from "../hooks/useCards";
import {useFocusEffect} from "@react-navigation/native";
import {Ionicons} from "@expo/vector-icons";
import theme from "../styles/theme";
import {HEADER_BUTTON_SIZE} from "../styles/globalStyles";

function HomeScreen({ navigation }) {
  const isLargeScreen = useIsLargeScreen();

  // Use useLayoutEffect to set the navigation options
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Ionicons
          name="earth"
          size={HEADER_BUTTON_SIZE}
          color={theme.colors.gray}
          style={{marginLeft: theme.spacings.medium}}
          onPress={() => navigation.navigate('AllCards')}
        />
      ),
      headerRight: () => (
        <View style={globalStyles.headerRightContainer}>
          <NotificationsButton navigation={navigation} />
          <SearchButton
            navigation={navigation}
            targetScreen='Search'
            facetKey='friendsCards'
          />
        </View>
      ),
      headerShown: !isLargeScreen,
    });
  }, [navigation]);


  return (
    <TabComponent
      cardsEndpoint={API_ENDPOINTS.FRIENDS_CARDS}
      bigWorldsEndpoint={API_ENDPOINTS.BIG_FRIENDS_WORLDS}
      cardsTitle={'FRIENDS\' FABYLS'}
      bigWorldsTitle={'FRIENDS\' WORLDS'}
    />
  );
}


export default HomeScreen;
