import React, { useState } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import theme from "../../styles/theme";
import {useAutoplay} from "../../contexts/AutoplayContext";


const AutoPlayToggle = () => {
  const { autoplay, setAutoplay } = useAutoplay();

  const toggleAutoplay = () => {
    setAutoplay(!autoplay);
  };

  return (
    <TouchableOpacity
      onPress={toggleAutoplay} style={styles.container}
      hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }} // Generous hitSlop for easier interaction
    >
      {autoplay ? (
        <View style={styles.iconContainer}>
          <FontAwesome name="toggle-on" size={32} color={theme.colors.blueSecondary} />
          <MaterialIcons name="play-arrow" size={24} color={"green"} style={styles.playIcon} />
        </View>
      ) : (
        // Toggle 'Off' State: Just the toggle-off icon
        <FontAwesome name="toggle-off" size={32} color={theme.colors.redSecondary} />
      )}
    </TouchableOpacity>
  );
};

// Styles for positioning the play icon over the circle
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainer: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playIcon: {
    position: 'absolute', // Position play icon over the circle
    right: 0,
  },
});

export default AutoPlayToggle;
