import React, {useEffect, useState, memo, useContext} from 'react';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import theme from "../styles/theme";
import {useNavigation} from "@react-navigation/native";
import {sendFriendRequest, removeFriend, checkIsFriend, acceptFriendRequest, rejectFriendRequest } from "../utils/api/userService";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";
import UserCircle from "./UserCircle";
import alert from "../utils/alert";
import FriendInteractionButton from "./buttons/FriendInteractionButton";
import ChooseProfileCardModal from "./modals/ChooseProfileCardModal";
import ContentLoader, { Rect } from 'react-content-loader/native';
import {useProfile} from "../contexts/ProfileContext";
import {useFriendStatus} from "../hooks/useFriendStatus";
import {useFriendInteraction} from "../hooks/useFriendInteraction";
import {AuthContext} from "../contexts/AuthContext";
import {useQueryClient} from "react-query";


export const UserInfoSkeleton = () => (
  <View style={[styles.userInfoBoxSkeleton, {borderWidth: 0, height: 620}]}>
    <ContentLoader
      width={500}
      height={220} // Adjusted for the additional rectangle and spacing
      viewBox="0 0 500 220" // Adjusted viewBox to the new height
      backgroundColor={theme.colors.loadingBackground}
      foregroundColor={theme.colors.loadingForeground}
    >
      <Rect x="0" y="0" rx={theme.borderRadius.small} ry={theme.borderRadius.small} width="100%" height="120" />
      <Rect x="0" y="130" rx={theme.borderRadius.medium} ry={theme.borderRadius.medium} width="100%" height="60" />
    </ContentLoader>
  </View>
);


const UserInfo = memo(({
  profileData,
  isPublic,
  isOwnProfile = false,
  shareProfile,
  onSendFriendRequest,
  onRemoveFriend,
  handleBlockPress,
}) => {
  const navigation = useNavigation();
  const { isAuthenticated } = useContext(AuthContext);
  const { data = {}, isLoading, isError } = useFriendStatus(profileData?.uuid);
  const { sendFriendRequest, acceptFriendRequest, removeFriend } = useFriendInteraction(profileData?.uuid);

  const { isFriend = false, hasRequestSent = false, hasRequestReceived = false } = data;

  const [isChooseCardModalVisible, setChooseCardModalVisible] = useState(false);

  const handleRemoveFriend = async () => {
    if (!profileData) {
      console.error('No profile data available for removing friend.');
      return;
    }

    alert(
      "Remove Friend",
      `Are you sure you want to remove ${profileData.preferred_username} as a friend?`,
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel"
        },
        {
          text: "OK",
          onPress: async () => {
            await removeFriend();
          }
        }
      ]
    );
  };

  const handleUserCirclePress = () => {
    if (!isPublic) {
      setChooseCardModalVisible(true);
    }
    else if (profileData.featured_card) {
      navigation.navigate('CardDetail', { card: profileData.featured_card });
    }
    else {
      console.error('No featured card available for this user.');
    }
  }

  if (!isAuthenticated || !profileData) {
    return <UserInfoSkeleton/>;
  }

  return (
    <View>
      <View style={styles.userInfoBox}>
        {!isOwnProfile && isPublic && (
          <TouchableOpacity
            onPress={handleBlockPress} style={styles.ellipsisContainer}
            hitSlop={{top: 10, right: 10, bottom: 10, left: 10}}
          >
            <Ionicons name="ellipsis-horizontal" size={24} color={theme.colors.gray}/>
          </TouchableOpacity>
        )}
        <View style={styles.userInfoInnerBox}>
          <View style={styles.userInfoRow}>
            <TouchableOpacity onPress={handleUserCirclePress}>
              <UserCircle profile_uuid={profileData.uuid} size={100} />
              {!isPublic && (
                <View style={styles.plusIconContainer}>
                  <MaterialIcons
                    name="add-circle"
                    size={24}
                    color={theme.colors.bluePrimary}
                  />
                </View>
              )}
            </TouchableOpacity>
            <View style={styles.detailsContainer}>

              <View style={styles.usernameRow}>
                <Text style={styles.username}>{profileData?.preferred_username}</Text>
                <TouchableOpacity onPress={shareProfile} style={styles.shareButton}>
                  <MaterialIcons
                    name="ios-share"
                    size={30}
                    color={theme.colors.bluePrimary}
                  />
                </TouchableOpacity>
              </View>
              <Text style={styles.numCreations}>{profileData.num_creations} fabyls</Text>

              {!isPublic && (
                <View style={styles.privateInfoContainer}>
                  <View>
                    <View style={styles.infoRow}>
                      <Text style={styles.infoValue}>{`${profileData?.remaining_generations}/${profileData?.daily_generations}`}</Text>
                      <Text style={styles.infoText}> remaining</Text>
                    </View>
                    {profileData?.human_time_to_next_generation_refill ? (
                      <View style={styles.infoRow}>
                        <Text style={styles.infoText}>Next refill</Text>
                        <Text style={styles.infoValue}> {profileData?.human_time_to_next_generation_refill}</Text>
                      </View>
                    ) : (
                      <View style={styles.infoRow}>
                        <Text style={styles.infoText}>
                          Get premium for more.
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              )}

              {isPublic && (
                <View style={styles.publicInfoContainer}>
                </View>
              )}
            </View>
          </View>

        </View>
      </View>
        <View style={styles.singleButtonContainer}>
          <FriendInteractionButton
            profileData={profileData}
            isPublic={isPublic}
            isOwnProfile={isOwnProfile}
            isFriend={isFriend}
            hasRequestReceived={hasRequestReceived}
            hasRequestSent={hasRequestSent}
            // onAcceptFriendRequest={handleAcceptFriendRequest}
            // onSendFriendRequest={handleSendFriendRequest}
            onAcceptFriendRequest={acceptFriendRequest}
            onSendFriendRequest={sendFriendRequest}
            onRemoveFriend={handleRemoveFriend}
          />
        </View>
        <ChooseProfileCardModal
          visible={isChooseCardModalVisible}
          onClose={() => setChooseCardModalVisible(false)}
        />
    </View>
  );
});

const styles = StyleSheet.create({
  disabledButton: {
    opacity: 0.6,
  },
  userInfoBox: {
    alignSelf: 'center',
    backgroundColor: theme.colors.beige,
    borderRadius: theme.borderRadius.small,
    margin: theme.spacings.medium,
    borderColor: theme.colors.bluePrimary,
    borderWidth: 1,
    width: '95%',
    maxWidth: 500,
    zIndex: 10,
  },
  userInfoBoxSkeleton: {
    alignSelf: 'center',
    backgroundColor: theme.colors.beige,
    // borderRadius: theme.borderRadius.small,
    margin: theme.spacings.medium,
    // borderColor: theme.colors.bluePrimary,
    borderWidth: 0,
    width: '95%',
    maxWidth: 500,
    // zIndex: 10,
    height: 620,
  },
  userInfoInnerBox: {
    margin: theme.spacings.small,
    // paddingHorizontal: theme.spacings.medium,
  },
  friendButton: {
    // Base style for friend buttons
    paddingHorizontal: theme.spacings.medium,
    paddingVertical: theme.spacings.small,
    borderRadius: theme.borderRadius.small,
    flexDirection: 'row', // Adjust for icon and text
    alignItems: 'center',
    justifyContent: 'center',
    // width: 100, // Adjust based on your UI
  },
  userInfoRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageContainer: {
    // Container for the image
  },
  cardThumbnail: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  detailsContainer: {
    flex: 1,
    marginLeft: theme.spacings.medium,
  },
  usernameRow: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
  },
  username: {
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.bluePrimary,
    marginRight: theme.spacings.small,
  },
  privateInfoContainer: {
    marginTop: theme.spacings.small,
  },
  publicInfoContainer: {
    // Add styles for public info container
  },
  infoText: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.gray,
  },
  infoRow: {
    flexDirection: 'row',
    marginBottom: theme.spacings.small,
    flexWrap: 'wrap',
  },
  infoLabel: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.lightgray,
  },
  infoValue: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.redPrimary,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacings.large,
    marginHorizontal: theme.spacings.medium,
  },
  singleButtonContainer: {
    // flexDirection: 'row',
    // justifyContent: 'center',
    alignSelf: 'center',
    // marginTop: theme.spacings.xsmall,
    width: "95%", // Take up the whole width
    maxWidth: 500,
    // flexGrow: 1,
    // marginHorizontal: theme.spacings.medium,
  },
  followerFollowingButton: {
    // backgroundColor: theme.colors.redPrimary,
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
    borderColor: theme.colors.bluePrimary,
    borderWidth: 1,
    marginHorizontal: theme.spacings.small,
    alignItems: 'center',
  },
  buttonText: {
    // color: theme.colors.white,
    color: theme.colors.bluePrimary,
    fontSize: theme.fonts.sizes.small,
    fontFamily: theme.fonts.family.bold,
  },
  upgradeButton: {
    backgroundColor: theme.colors.yellowPrimary,
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
    borderColor: theme.colors.black,
    borderWidth: 1,
    alignSelf: 'flex-start',
    // width: "75%",
    // marginHorizontal: theme.spacings.small,
    alignItems: 'center',
  },
  upgradeButtonText: {
    color: theme.colors.black,
    fontSize: theme.fonts.sizes.small,
    fontFamily: theme.fonts.family.bold,
  },
  skeletonImageContainer: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: theme.colors.lightgray,
    justifyContent: 'center',
    alignItems: 'center',
  },
  acceptButton: {
    backgroundColor: theme.colors.yellowPrimary,
  },
  skeletonImageText: {
    fontSize: theme.fonts.sizes.xlarge,
    color: theme.colors.darkgray,
  },
  shareButton: {
    // Additional styles if needed
  },
  actionButtonText: {
    // color: theme.colors.white,
    color: theme.colors.gray,
    fontWeight: 'bold',
    fontSize: theme.fonts.sizes.small,
  },
  numCreations: {
    // marginVertical: 10,
    color: theme.colors.lightgray,
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.medium,
  },
  plusIconContainer: {
    position: 'absolute', // Absolute position to place it over the UserCircle
    right: 5, // Align to the right
    bottom: 5, // Align to the bottom
    borderRadius: 12,
    // Make it half of width/height including padding to create a circle
    padding: 0, // Adjust padding to reduce the size of the actual icon
    backgroundColor: theme.colors.beige,
  },
  ellipsisContainer: {
    position: 'absolute',
    top: theme.spacings.small,
    right: theme.spacings.small,
    zIndex: 1, // Ensure it appears above other content
  },
});

export default UserInfo;
