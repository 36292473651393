import React, {useState, useEffect} from 'react';
import {
  Modal,
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  Dimensions, SafeAreaView
} from 'react-native';
import theme from '../../styles/theme';
import SearchComponent from "../SearchComponent";
import {
  fetchFriendsSearchResults,
} from "../../utils/api/api";
import UsersList from "../lists/UsersList";
import withCustomPress from "../utility/withCustomPress";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";


const screenHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width; // Get the window's width
let leftMargin = 0
if (windowWidth > theme.breakpoints.medium) {
  leftMargin = theme.layout.drawerWidth
}

const ChooseFriendModal = ({ visible, onClose, onSelectFriend, headerTitle="Who do you want to write to?" }) => {
  const isLargeScreen = useIsLargeScreen();
  const customUsersList = withCustomPress(UsersList, onSelectFriend);

  let leftMargin = 0;
  if (isLargeScreen) {
    leftMargin = theme.layout.drawerWidth;
  } else {
    leftMargin = 0;
  }

  const facets = [
    {
      key: 'friends',
      label: 'Friend',
      fetchFunction: fetchFriendsSearchResults,
      component: customUsersList,
    },
  ];
  const initialFacetKey = 'friends';

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <TouchableOpacity
        style={[styles.centeredView, { marginLeft: leftMargin }]}
        activeOpacity={1}
        onPress={onClose}
      >
        <TouchableOpacity
          style={styles.modalView}
          activeOpacity={1}
          onPress={(e) => e.stopPropagation()}
        >
          <SafeAreaView style={styles.safeArea}>
            <View style={styles.header}>
              <Text style={styles.headerTitle}>{headerTitle}</Text>
            </View>
            <View style={styles.container}>
              <SearchComponent
                facets={facets}
                initialFacetKey={initialFacetKey}
                placeholder={"Search Your Friends..."}
              />
            </View>
          </SafeAreaView>
          <TouchableOpacity
            style={styles.buttonClose}
            onPress={onClose}
          >
            <Text style={styles.textStyle}>CANCEL</Text>
          </TouchableOpacity>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
};


const styles = StyleSheet.create({
  header: {
    padding: theme.scaledSpacings.medium,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.colors.verylightgray,
    backgroundColor: theme.colors.loadingBackground,
    width: '100%',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: theme.fonts.scaledSizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.bluePrimary,
    textAlign: 'center',
  },
  buttonClose: {
    backgroundColor: theme.colors.bluePrimary,
    borderRadius: theme.scaledBorderRadius.large,
    padding: theme.scaledSpacings.small,
    elevation: 2,
    marginBottom: theme.scaledSpacings.large,
    width: '80%',
    alignSelf: 'center', // Center the button within the view
  },
  textStyle: {
    color: theme.colors.white,
    fontSize: theme.fonts.scaledSizes.medium,
    fontFamily: theme.fonts.family.bold,
    textAlign: "center",
  },
  centeredView: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  modalView: {
    backgroundColor: theme.colors.loadingBackground,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 0, // Adjust padding as necessary
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '100%',
    height: screenHeight * 0.85, // Adjust the height as necessary
    position: 'absolute',
    // bottom: theme.spacings.xlarge,
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  },
  safeArea: {
    flex: 1,
    backgroundColor: theme.colors.loadingBackground,
    width: '95%',
  },
  container: {
    flex: 1,
    padding: 0,
  },
});

export default ChooseFriendModal;
