import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import theme from '../styles/theme';
import {useSuggestedTitles} from "../hooks/useSuggestedTitles";


const SuggestedTitles = ({ onSelectTitle }) => {
  const { data: suggestedTitles, isLoading, isError } = useSuggestedTitles();

  if (!suggestedTitles || suggestedTitles.length === 0) {
    return (
      <View style={styles.emptyContainer}>
        <Text style={styles.emptyText}>No suggestions available.</Text>
      </View>
    );
  }

  return (
    <ScrollView
      style={styles.suggestionsContainer}
      keyboardShouldPersistTaps="always"
      scrollEventThrottle={16}
    >
      {suggestedTitles.map((title, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => onSelectTitle(title)}
          activeOpacity={0.7}
        >
          <View style={styles.suggestionItem}>
            <Text style={styles.suggestionText}>{title}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  suggestionsContainer: {
    flex: 1,
    paddingHorizontal: theme.spacings.medium,
    width: '100%',
    // marginVertical: theme.spacings.small,
    backgroundColor: theme.colors.beige,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  suggestionItem: {
    paddingVertical: theme.spacings.medium,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.colors.lightgray,
  },
  suggestionText: {
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.redPrimaryPlaceholder,
  },
});

export default SuggestedTitles;
