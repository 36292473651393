import React, { useEffect, useRef } from 'react';
import { View, StyleSheet, Dimensions, Animated } from 'react-native';
import { Image } from 'expo-image';
import theme from '../styles/theme';
import DemoUserCircle from "./DemoUserCircle";

const windowWidth = Dimensions.get('window').width;

export const CARD_WIDTH = 80;
export const CARD_HEIGHT = CARD_WIDTH * 1;
export const CARD_MARGIN = theme.spacings.xsmall;
export const CARD_PADDING = theme.spacings.small;
export const CARD_TOTAL_HEIGHT = CARD_HEIGHT + (CARD_MARGIN * 2);

const BlankCard = () => {
  return (
    <View style={styles.cardContainer} />
  );
};

const StaticMiniCardSmall = ({ imageSource, userImageSource, startAnimation }) => {
  const slideAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (startAnimation) {
      Animated.timing(slideAnim, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start();
    }
  }, [startAnimation, slideAnim]);

  return (
    <Animated.View
      style={[
        styles.cardContainer,
        {
          transform: [{ translateX: slideAnim }],
        },
      ]}
    >
      <Image
        source={imageSource}
        style={styles.imageStyle}
        resizeMode="cover"
      />
      <View style={styles.userCircleContainer}>
        <DemoUserCircle imagePath={userImageSource} size={30} />
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: CARD_WIDTH,
    height: CARD_HEIGHT,
    margin: CARD_MARGIN,
    borderRadius: theme.borderRadius.small,
    overflow: 'hidden',
  },
  imageStyle: {
    width: '100%',
    height: '100%',
  },
  userCircleContainer: {
    position: 'absolute',
    bottom: theme.spacings.xxsmall,
    left: theme.spacings.xxsmall,
  },
});

export default StaticMiniCardSmall;
export { BlankCard }
