import React, {useLayoutEffect} from 'react';
import {SafeAreaView, StyleSheet, View, Text, TouchableOpacity} from 'react-native';
import SearchComponent from '../components/SearchComponent';
import UsersList from '../components/lists/UsersList';
import theme from "../styles/theme";
import {useNavigation} from "@react-navigation/native";
import {MaterialIcons} from "@expo/vector-icons";


import {fetchFriendsSearchResults} from '../utils/api/api';


const FriendsSearchScreen = () => {
  const navigation = useNavigation();

  const facets = [
    {
      key: 'friends',
      label: 'Friends',
      component: UsersList, // Reusing UsersList for followers
      fetchFunction: fetchFriendsSearchResults,
    },
  ];

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          style={styles.addFriendButton}
          onPress={() => {
            navigation.navigate('UserSearch');
          }}
        >
          <MaterialIcons name="person-add" size={18} color={theme.colors.gray} />
          <Text style={styles.addFriendButtonText}>Find Friends</Text>
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <SearchComponent
          facets={facets}
          initialFacetKey={'friends'}
          placeholder={"Search Your Friends..."}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: theme.colors.beige,
  },
  container: {
    flex: 1,
    padding: 10,
  },
  addFriendButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10, // Adjust the margin as needed
  },
  addFriendButtonText: {
    color: theme.colors.gray,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.small,
    marginLeft: theme.spacings.xsmall,
    marginRight: theme.spacings.small,
    // Space between icon and text
  },
});

export default FriendsSearchScreen;
