import { Platform, Share } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import alert from './alert';


const djangoUrl = process.env.EXPO_PUBLIC_API_URL;
const djangoCardUrl = `${djangoUrl}/cards/card_link`;
const djangoProfileUrl = `${djangoUrl}/cards/profile_link`;


export const shareContent = async (type, uuid) => {
  const fullUrl = type === 'profile' ? `${djangoProfileUrl}/${uuid}` : `${djangoCardUrl}/${uuid}`;

  if (Platform.OS === 'web') {
    try {
      await Clipboard.setStringAsync(fullUrl);
      alert('Link Copied', 'The link has been copied to your clipboard.');
    } catch (error) {
      console.error('Error copying link to clipboard:', error);
      alert('Error', 'Failed to copy the link to your clipboard.');
    }
  } else {
    try {
      const result = await Share.share({
        message: fullUrl,
      });

      if (result.action === Share.dismissedAction) {
        console.log('Share dismissed');
      }
    } catch (error) {
      console.error('Error sharing:', error);
      alert('Error', error.message);
    }
  }
};
