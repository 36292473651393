import React, { useEffect, useReducer } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import theme from "../styles/theme";

const initialState = {
  displayText: "NAME YOUR FABYL",
  isAnimating: false,
  isButtonPressed: false,
  currentIndex: 0,
  statusMessage: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'START_ANIMATION':
      return { ...state, isAnimating: true, currentIndex: 0 };
    case 'UPDATE_TEXT':
      return { ...state, displayText: action.payload, currentIndex: state.currentIndex + 1 };
    case 'UPDATE_STATUS':
      return { ...state, statusMessage: action.payload };
    case 'COMPLETE_ANIMATION':
      return { ...state, isAnimating: false, isButtonPressed: true };
    case 'RESET_BUTTON':
      return { ...state, isButtonPressed: false };
    case 'RESET_ANIMATION':
      return initialState;
    default:
      return state;
  }
};

const DemoInputBox = ({ text, onAnimationComplete, startAnimation, placeHolderText = "NAME YOUR FABYL" }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, displayText: placeHolderText });

  useEffect(() => {
    if (startAnimation) {
      dispatch({ type: 'START_ANIMATION' });
    } else {
      dispatch({ type: 'RESET_ANIMATION' });
    }
  }, [startAnimation]);

useEffect(() => {
  if (state.isAnimating) {
    const intervalId = setInterval(() => {
      if (state.currentIndex <= text.length) {
        dispatch({ type: 'UPDATE_TEXT', payload: text.slice(0, state.currentIndex) });
      } else {
        clearInterval(intervalId);

        // Press the create button
        dispatch({ type: 'COMPLETE_ANIMATION' });

        // Unpress the create button after a delay
        setTimeout(() => {
          dispatch({ type: 'RESET_BUTTON' });

          // Display status messages immediately after the button reset using a recursive function
          const statusMessages = ["crafting story...", "narrating text...", "creating story art..."];
          let statusIndex = 0;

          const displayNextStatus = () => {
            if (statusIndex < statusMessages.length) {
              dispatch({ type: 'UPDATE_STATUS', payload: statusMessages[statusIndex] });
              statusIndex++;
              setTimeout(displayNextStatus, 1000);
            } else {
              dispatch({ type: 'UPDATE_STATUS', payload: "" });

              // Animation is complete after all status messages have been shown
              if (onAnimationComplete) {
                onAnimationComplete();
              }
            }
          };

          displayNextStatus();
        }, 200);
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }
}, [state.isAnimating, state.currentIndex, text, onAnimationComplete]);
  return (
    <View style={styles.container}>
      <View style={styles.rowContainer}>
        <View style={styles.inputBox}>
          <Text style={styles.text}>{state.displayText}</Text>
        </View>
        <TouchableOpacity
          style={[styles.button, state.isButtonPressed && styles.pressedButton]}
          disabled={true}
        >
          <Text style={styles.buttonText}>Create</Text>
        </TouchableOpacity>
      </View>
      <Text style={styles.statusText}>{state.statusMessage}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacings.small,
    width: '100%',
  },
  statusText: {
    marginTop: theme.spacings.medium,
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.lightgray,
    textAlign: 'center',
    minHeight: 24,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.borderRadius.large,
    marginHorizontal: theme.spacings.xlarge,
    width: '85%',
  },
  inputBox: {
    flex: 3,
    borderTopLeftRadius: theme.borderRadius.large,
    borderBottomLeftRadius: theme.borderRadius.large,
    backgroundColor: theme.colors.white,
    paddingHorizontal: theme.spacings.medium,
    height: 50,
    borderWidth: 1.5,
    borderColor: theme.colors.black,
    justifyContent: 'center',
    marginLeft: theme.spacings.medium,
    width: '100%',
  },
  text: {
    color: theme.colors.redPrimary,
    fontSize: theme.fonts.scaledSizes.medium,
  },
  button: {
    flex: 1,
    backgroundColor: theme.colors.bluePrimary,
    borderTopRightRadius: theme.borderRadius.large,
    borderBottomRightRadius: theme.borderRadius.large,
    borderRightWidth: 1.5,
    borderTopWidth: 1.5,
    borderBottomWidth: 1.5,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: theme.colors.black,
    shadowOpacity: 0.2,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
    elevation: 4,
    marginRight: theme.spacings.medium,
  },
  pressedButton: {
    transform: [{ translateY: 2 }],
    shadowOpacity: 0,
    elevation: 0,
  },
  buttonText: {
    color: theme.colors.white,
    fontWeight: 'bold',
    fontSize: theme.fonts.sizes.medium,
  },
});

export default DemoInputBox;
