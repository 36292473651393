export const brandColors = {
  beige: '#F7EBDF',
  gray: '#333333',
  bluePrimary: '#3366CC',
  blueSecondary: '#22406B',
  redPrimary: '#DB4C2A',
  redSecondary: '#661212',
  yellowPrimary: '#FFC523',
  yellowSecondary: '#896500',
  white: '#FFFFFF',
  black: '#000000',
};

export const layout = {
  topBarHeight: '10%',
  topBarTopPadding: '10%',
  contentHeight: '80%',  // approximate calculation based on the CSS provided
  bottomNavHeight: '7%',
  bottomBarBottomPadding: '10%',
  desktopBreak: 768,     // This would be different in React Native and might be used differently
  sidebarSize: 235,      // Adjust as per React Native requirements
  titleHeight: '5%',
  imageHeight: '60%',
  typeHeight: '3%',
  descriptionHeight: '14%',
  maxWidth: 400,
  aspectRatio: 1.618,
};
