const IS_DEV = process.env.APP_VARIANT === 'development';
// ^ dev here indicate the fabyl (dev) app in App Store Connect
// not really a one-to-one mapping onto the concept of dev in the general sense.
// see how this APP_VARIANT is used in the app.config.js file.
// We do this because app store connect requires unique product ids even if they are
// in different apps. So we add a suffix to the product ids in dev mode to avoid conflicts.
const PRODUCT_ID_SUFFIX = IS_DEV ? '.dev' : '';
// Use products for fabyl (dev) instead of fabyl when in dev mode.
// There should be products in dev which are same as prod but ids have .dev suffix.
// This is configured in App Store Connect.

export default {
  items: [
    {
      productId: `pro01${PRODUCT_ID_SUFFIX}`,
      type: 'renewable',
      iosType: 'RENEWABLE_SUBSCRIPTION',
    },
    {
      productId: `standard01${PRODUCT_ID_SUFFIX}`,
      type: 'renewable',
      iosType: 'RENEWABLE_SUBSCRIPTION',
    },
    {
      productId: `basic01${PRODUCT_ID_SUFFIX}`,
      type: 'renewable',
      iosType: 'RENEWABLE_SUBSCRIPTION',
    },
  ],
};
