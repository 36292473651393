import React, { useState, useContext, useRef, useEffect } from 'react';
import { View, TextInput, StyleSheet, Text, Alert } from 'react-native';
import { Auth } from 'aws-amplify';
import { AuthContext } from "../contexts/AuthContext";
import PrimaryButton from "../components/PrimaryButton";
import theme from "../styles/theme";
import PhoneInput from "../components/PhoneInput";
import styles from "../styles/Settings.styles";
import alert from "../utils/alert";

const UpdatePhoneScreen = ({ navigation }) => {
  const [newPhone, setNewPhone] = useState('');
  const { user } = useContext(AuthContext);
  const phoneInputRef = useRef(null);

  const [countryCode, setCountryCode] = useState('');
  const [countryCallingCode, setCountryCallingCode] = useState('');

  useEffect(() => {
    // Automatically focus the TextInput when the screen is loaded
    phoneInputRef.current.focus();
  }, []);

  const handleSubmitNewPhone = async () => {
    // Concatenate country calling code and local phone number
    const fullPhoneNumber = `+${countryCallingCode}${newPhone.trim()}`;

    // Ensure the phone number starts with '+' followed by the country code and number
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    if (!phoneRegex.test(fullPhoneNumber)) {
      alert("Invalid Phone Number", "Please enter a valid international phone number starting with '+'.");
      return;
    }

    if (fullPhoneNumber === user.attributes.phone_number) {
      alert("Same Phone Number", "The entered phone number is the same as your current phone number.");
      return;
    }

    try {
      const response = await Auth.updateUserAttributes(user, {
        'phone_number': fullPhoneNumber,
      });
      console.log('Update phone response:', response);

      // Proceed to OTP Screen and pass a callback to refresh user after successful update
      navigation.navigate('OtpScreen', {
        username: fullPhoneNumber,
        verificationType: 'phoneUpdate',
      });
    } catch (e) {
      console.error('Error updating phone number', e);
      // Handle the error, possibly showing an alert to the user
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>UPDATE PHONE</Text>
      <View style={styles.currentContactRow}>
        <Text style={styles.currentContactText}>Current Phone: </Text>
        <Text style={styles.currentContact}>{user?.attributes?.phone_number || 'No phone number set'}</Text>
      </View>
      <PhoneInput
        ref={phoneInputRef}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        setCountryCallingCode={setCountryCallingCode}
        phone={newPhone}
        setPhone={setNewPhone}
      />
      <PrimaryButton title="SUBMIT NEW PHONE" onPress={handleSubmitNewPhone}/>
    </View>
  );
};


// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     padding: theme.spacings.large,
//     paddingTop: theme.spacings.medium, // Adjusted top padding
//     backgroundColor: theme.colors.beige,
//     alignItems: 'stretch', // Ensures that children width is the same as the container
//     alignSelf: 'center',
//     maxWidth: 800, // Added to limit the width of the container
//   },
//   title: {
//     fontSize: theme.fonts.scaledSizes.xlarge,
//     color: theme.colors.gray,
//     marginBottom: theme.spacings.medium,
//     fontFamily: theme.fonts.family.medium,
//     alignSelf: 'center',
//   },
//   currentPhoneRow: {
//     marginBottom: theme.spacings.large,
//     flexDirection: 'row',
//   },
//   currentPhoneText: {
//     fontSize: theme.fonts.scaledSizes.big,
//     color: theme.colors.gray,
//     fontFamily: theme.fonts.family.bold,
//     marginBottom: theme.spacings.small,
//   },
//   phone: {
//     fontSize: theme.fonts.scaledSizes.big,
//     color: theme.colors.dark,
//     fontFamily: theme.fonts.family.regular,
//     marginBottom: theme.spacings.medium,
//   },
//   input: {
//     borderWidth: 1,
//     borderColor: theme.colors.gray,
//     padding: theme.spacings.small,
//     marginBottom: theme.spacings.medium,
//     backgroundColor: theme.colors.white,
//     color: theme.colors.dark,
//   },
//   // ... other styles
// });

export default UpdatePhoneScreen;
