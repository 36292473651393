const validateUsername = (username) => {
  const errors = [];

  if (!username) {
    errors.push("Username is required.");
    return errors;
  }

  if (username.length < 3) {
    errors.push("Username must be at least 3 characters long.");
  }

  if (username.length > 16) {
    errors.push("Username must be no more than 16 characters long.");
  }

  if (username.includes(' ')) {
    errors.push("Username cannot contain spaces.");
  }

  if (!username.match(/^[a-zA-Z0-9]+$/)) {
    errors.push("Username can only contain letters and numbers.");
  }

  return errors;
};

export default validateUsername;
