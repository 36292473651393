import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import {FlatList, Text, TouchableOpacity, View, Button, Linking} from 'react-native';
import Card, {cardHeightOuter, CardSkeleton, cardWidth} from '../components/Card';
import {useQuery} from 'react-query';
import {
  fetchCardByUUID,
  fetchWorld
} from "../utils/api/cardsService";
import {useFocusEffect, useIsFocused, useNavigation} from '@react-navigation/native';
import useCardDetails from "../hooks/useCardDetails";
import SvgRiff from "../assets/images/Riff";
import {ScaledSheet} from 'react-native-size-matters';
import theme, {cardScaleSize} from "../styles/theme";
import AutoPlayToggle from "../components/buttons/AutoPlayToggle";
import {useAudioPlayer} from "../contexts/AudioPlayerContext";
import {useAutoplay} from "../contexts/AutoplayContext";
import WorldComponent, {WORLD_HEIGHT} from "../components/WorldComponent";
import SvgFabylHeader from "../assets/images/FabylHeader";
import {AuthContext} from "../contexts/AuthContext";
import LoginPromptModal from "../components/modals/LoginPromptModal";
import AddFriendToRiffModal from "../components/modals/AddFriendToRiffModal";
import {Ionicons} from "@expo/vector-icons";
import alert from "../utils/alert";
import AppStoreBadge from "../assets/App_Store_Badge";


const APP_STORE = process.env.EXPO_PUBLIC_IOS_APP_URL;


const openURL = (url) => {
  Linking.canOpenURL(url)
  .then((supported) => {
    if (!supported) {
      console.log("Can't handle URL: " + url);
    } else {
      return Linking.openURL(url);
    }
  })
  .catch((err) => console.error('An error occurred', err));
};


const handleAppStorePress = () => {
  if (APP_STORE) {
    openURL(APP_STORE);
  } else {
    alert('Coming Soon', 'The app is coming soon to the App Store.');
  }
};


const CardItem = ({ uuid, isViewable, onImageTap, outerScrollRef, openModal }) => {

  const { data, isLoading } = useQuery(
    ['cardDetail', uuid],
    () => fetchCardByUUID(uuid),
    { staleTime: Infinity }
  );

  if (isLoading || !data) {
    return <CardSkeleton />;
  }

  return <Card
    card={data}
    onImageTap={onImageTap}
    openModal={() => openModal(data)}
    isViewable={isViewable}
  />;
};


const HEADER_HEIGHT = WORLD_HEIGHT + theme.spacings.xxlarge;

const TopHeader = ({ card }) => {
  const [world, setWorld] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (card && card.world_uuid) {
      // Assuming 'url' is your API's base URL for fetching worlds
      fetchWorld(card.world_uuid)
        .then(response => {
          if (response.error) {
            setError(response.error);
            console.log(response.error);
          } else {
            setWorld(response.world);
          }
        })
        .catch(err => {
          console.error("Failed to fetch world:", err);
          setError(err.toString());
        });
    }
  }, [card]);

  // Check if the world exists and both 'name' and 'summary' are neither null nor empty
  const isValidWorld = world && world.name && world.summary && world.name.trim() !== '' && world.summary.trim() !== '';

  return (
    <View style={{ height: HEADER_HEIGHT, alignItems: "center", alignSelf: "center" }}>
      {isValidWorld ? (
        <WorldComponent world={world} disableTouch={true} />
      ) : (
        <View style={{height: "100%"}}>
          <SvgFabylHeader style={{marginTop: theme.spacings.xxlarge}}/>
        </View>
      )}
    </View>
  );
};


export default function CardDetailScreen({ route }) {
    const navigation = useNavigation();
    const { stopSound } = useAudioPlayer();
    const { isAuthenticated } = useContext(AuthContext);
    const [isLoginModalVisible, setLoginModalVisible] = useState(false);
    const [isNotFriendsModalVisible, setIsNotFriendsModalVisible] = useState(false);
    const [contentSizeReady, setContentSizeReady] = useState(false);

    const { autoplay } = useAutoplay();
    const initialCardThumbnail = route.params.card || (route.params.card_uuid ? { uuid: route.params.card_uuid } : null);

    const flatListRef = useRef(null);

    const {
      cards,
      isLoading,
      isError,
      lastCardUuid,
      handleViewableItemsChanged,
      currentViewableCard,
      currentViewableCardUuid,
      updateMainCardData,
    } = useCardDetails(initialCardThumbnail.uuid);

    useFocusEffect(
      React.useCallback(() => {
        updateMainCardData();
      }, [])
    );

    useEffect(() => {
      if (!isAuthenticated) {
        const timer = setTimeout(() => {
          console.log('Not authenticated, showing login modal');
          setLoginModalVisible(true);
        }, 10000); // 10 seconds delay

        return () => clearTimeout(timer); // Cleanup the timer
      }
    }, [isAuthenticated]);

    useFocusEffect(
      useCallback(() => {
        // No operation when the screen comes into focus

        return () => {
          stopSound(); // Call stopSound to stop any audio playback
        };
      }, [])
    );

    useLayoutEffect(() => {
      navigation.setOptions({
        headerRight: () => isAuthenticated ? (
          <View style={styles.headerRightContainer}>
            <Ionicons
              name="options"
              onPress={() => navigation.navigate('WorldSettings', {worldUUID: initialCardThumbnail?.world_uuid})}
              size={32}
              color={theme.colors.gray}
            />
            <AutoPlayToggle />
          </View>
        ) : (
            <TouchableOpacity style={styles.appStoreButton} onPress={handleAppStorePress}>
              <AppStoreBadge style={{transform: [{scale: 1}]}}/>
            </TouchableOpacity>
        ), // Don't render the header right content if not authenticated
        headerShown: true, // Show header only if authenticated
      });
    }, [navigation, isAuthenticated]);

    const viewabilityConfig = {
      itemVisiblePercentThreshold: 50, // Consider an item viewable when 50% is visible
      minimumViewTime: 0, // Item needs to be viewable for 50 milliseconds
    };

    const viewabilityConfigCallbackPairs = useRef([
      {
        viewabilityConfig,
        onViewableItemsChanged: handleViewableItemsChanged,
      },
    ]);

    const initialCardIndex = cards.findIndex(card => card.uuid === initialCardThumbnail.uuid);
    const validInitialIndex = initialCardIndex !== -1;
    const scaledCardHeight = cardScaleSize(cardHeightOuter);

    const getItemLayout = useCallback((data, index) => ({
      length: scaledCardHeight, // The actual height of the card
      offset: scaledCardHeight * index, // The cumulated height of all items before
      index,
    }), [scaledCardHeight]); // Add cardHeightOuter to the dependency array

  const isFocused = useIsFocused();

  useEffect(() => {
    const performScroll = (animate = false) => {
      if (flatListRef.current) {
        const headerHeight = HEADER_HEIGHT; // Your predefined header height
        const offset = scaledCardHeight * initialCardIndex + headerHeight;
        flatListRef.current.scrollToOffset({ animated: animate, offset });
      }
    };

    // Condition for initial load: content size is ready, no animation
    if (contentSizeReady && validInitialIndex) {
      performScroll();
    }

    // Additional condition for when returning to the screen, with animation
    if (isFocused && validInitialIndex) {
      // Use a delay only when returning to the screen to allow layout updates
      const timeoutId = setTimeout(() => performScroll(true), 100); // Only animate this scroll
      return () => clearTimeout(timeoutId); // Cleanup the timeout
    }
  }, [contentSizeReady, isFocused, initialCardIndex, validInitialIndex, scaledCardHeight]);



    if (isLoading) return <CardSkeleton />;
    if (isError) return <Text>Error Loading Card</Text>;

    const setCurrentViewableCard = (uuid) => {
      navigation.navigate("FullscreenDetail", { card: { uuid } })
    };

    const gotToRiffScreen = (card) => {
      if (!isAuthenticated) {
        console.log('Not authenticated, showing login modal');
        setLoginModalVisible(true);
        return;
      }
      navigation.navigate('ExpandedInput', {
        // card: card,
        cardRiffedFrom: card,
        returnFromCreation: false,
      });
    };

    const handleGoToRiffScreen = (card) => {
      gotToRiffScreen(card);
    }

    const renderItem = ({ item }) => {
      const isViewable = item.uuid === currentViewableCardUuid;

      return (
        <CardItem
          uuid={item.uuid}
          isViewable={isViewable}
          onImageTap={setCurrentViewableCard}
          openModal={gotToRiffScreen}
          outerScrollRef={flatListRef}
        />
      );
    };

    const renderFooter = () => {
      if (!lastCardUuid) {
        // Optionally return a loading indicator or null if lastCardUuid is not available yet
        return null; // or <LoadingIndicator />;
      }

      const {data, isLoading} = useQuery(
        ['cardDetail', lastCardUuid],
        () => fetchCardByUUID(lastCardUuid),
        {staleTime: Infinity}
      );
      return (
        <TouchableOpacity
          onPress={() => handleGoToRiffScreen(data)}
          style={[
            styles.bottomRiffBox,
          ]}
        >
          <View
            style={[
              styles.bottomRiffRow,
            ]}
          >
            <Text style={styles.bottomRiffText}>CLICK TO RIFF</Text>
            <SvgRiff/>
          </View>
        </TouchableOpacity>
      );
    }

    return (
        <View style={{ flex: 1, backgroundColor: theme.colors.beige }}>
          <FlatList
              ref={flatListRef}
              data={cards}
              onContentSizeChange={() => setContentSizeReady(true)}
              renderItem={renderItem}
              ListHeaderComponent={<TopHeader card={currentViewableCard}/>}
              keyExtractor={(item) => item.uuid}
              viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
              getItemLayout={getItemLayout}
              initialScrollIndex={validInitialIndex ? initialCardIndex : null}
              ListFooterComponent={renderFooter}
              onEndReached={updateMainCardData}
              onEndReachedThreshold={1.0}
              {...(autoplay ? {
                snapToInterval: scaledCardHeight, // Apply only if autoplay is true
                decelerationRate: 'fast', // Apply only if autoplay is true
                snapToAlignment: 'start', // Apply only if autoplay is true
                snapToOffsets: cards.map((_, index) => index * scaledCardHeight + HEADER_HEIGHT), // Apply only if autoplay is true
              } : {})}
          />
          {!isAuthenticated && (<LoginPromptModal
            isVisible={isLoginModalVisible}
            onConfirm={() => console.log('User wants to sign up or log in')}
            onCancel={() => setLoginModalVisible(false)}
          />)}
          <AddFriendToRiffModal
            isVisible={isNotFriendsModalVisible}
            onClose={() => setIsNotFriendsModalVisible(false)}
          />
        </View>
    );
}

const styles = ScaledSheet.create({
  headerRightContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 90,
    marginHorizontal: theme.spacings.small,
  },

  bottomRiffBox: {
    width: cardScaleSize(cardWidth),
    paddingTop: theme.spacings.medium,
    paddingBottom: theme.spacings.medium,
    marginTop: 10,
    alignSelf: 'center',
    backgroundColor: theme.colors.gray,
    borderRadius: 10,
    color: theme.colors.yellowPrimary,
    marginBottom: theme.spacings.large,
  },
  bottomRiffRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomRiffText: {
    color: theme.colors.yellowPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.scaledSizes.medium,
    alignItems: 'center',
    marginRight: theme.spacings.small,
  },
  openInAppButton: {
    backgroundColor: theme.colors.bluePrimary, // Example blue color for the button
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  openInAppButtonText: {
    color: theme.colors.white, // White text color
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
  },
  disabledRiffButton: {
    opacity: 0.5,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: 24, // Adjust based on your icon's size
    height: 24, // Adjust based on your icon's size
    marginRight: 8, // Space between icon and text
  },
  spur: {
    marginLeft: 20,
    zIndex: 1,
    transform: [{ scale: 2 }],
  },
  appStoreButton: {
    marginHorizontal: theme.spacings.xsmall,
  },
});
