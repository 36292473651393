import React, {useCallback, useContext, useEffect, useState, memo} from 'react';
import {Share, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Image} from 'expo-image'
import {useFocusEffect} from '@react-navigation/native';
import {AuthContext} from '../contexts/AuthContext';
import useCards from "../hooks/useCards";
import API_ENDPOINTS from '../apiEndpoints';
import UserInfo from "../components/UserInfo";
import {FontAwesome, Ionicons, MaterialIcons} from '@expo/vector-icons';
import {useProfile} from "../contexts/ProfileContext";
import theme from "../styles/theme";
import globalStyles from "../styles/globalStyles";
import NotificationsButton from "../components/NotificationsButton";
import SettingsButton from "../components/SettingsButton";
import TimeGallery from "../components/TimeGallery";
import Robot from "../assets/images/characters/Robot_medium.png";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import NoWorldsComponent from "../components/NoWorldsComponent";
import useWorlds from "../hooks/useWorlds";
import GallerySkeletonLoader from "../components/skeletons/GallerySkeletonLoader";
import {useNavigation} from "@react-navigation/native";


const TabBar = memo(({ setActiveTab, activeTab }) => {
  return (
    <View style={styles.tabBar}>
      <TouchableOpacity
        style={[styles.tabButton, activeTab === 'profileCards' && styles.activeTab]}
        onPress={() => setActiveTab('profileCards')}
      >
        <FontAwesome
          name={activeTab === 'profileCards' ? 'user' : 'user-o'}
          size={26}
          color={activeTab === 'profileCards' ? theme.colors.bluePrimary : theme.colors.gray}
        />
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.tabButton, activeTab === 'bookmarkedCards' && styles.activeTab]}
        onPress={() => setActiveTab('bookmarkedCards')}
      >
        <FontAwesome
          name={activeTab === 'bookmarkedCards' ? 'bookmark' : 'bookmark-o'}
          size={26}
          color={activeTab === 'bookmarkedCards' ? theme.colors.activeBookmark : theme.colors.gray}
        />
      </TouchableOpacity>
    </View>
  );
});


const CharacterEmptyState = memo(({ verb = "creating", isLoading }) => {
  if (isLoading) {
    return (
      <View style={{ marginTop: theme.spacings.small }}>
        <GallerySkeletonLoader />
      </View>
    );
  }

  return (
    <View style={styles.emptyStateContainer}>
      <NoWorldsComponent
        title={verb}
        content={`Start ${verb} cards to see them here.`}
      />
      <TouchableOpacity activeOpacity={1}>
        <Image source={Robot} style={styles.imageStyle} />
      </TouchableOpacity>
    </View>
  );
});


const ProfileHeader = memo(({ profileData, shareProfile, setActiveTab, activeTab }) => {
  const navigation = useNavigation();

  const handleViewYourWorlds = () => {
    navigation.navigate('YourWorlds')
  }

  return (
    <View>
      <UserInfo
        profileData={profileData}
        isPublic={false}
        shareProfile={shareProfile}
      />
      <View style={styles.topButtonsContainer}>
        <TouchableOpacity
          style={styles.button}
          onPress={handleViewYourWorlds}
        >
          <View style={styles.buttonView}>
            <Text style={styles.buttonTextWhite} >
              WORLDS
            </Text>
            <Ionicons name="book" size={24} color={theme.colors.white} style={{marginLeft: theme.spacings.small}}/>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, styles.yellowPrimary]}
          onPress={() => navigation.navigate('UpgradeScreen')}
        >
          {['active', 'pending_cancel', 'past_due'].includes(profileData?.subscription_status) ? (
            <Text style={[styles.buttonTextWhite, styles.textGray]}>
              PREMIUM
            </Text>
          ) : (
            <Text style={[styles.buttonTextWhite, styles.textGray]}>
              PREMIUM
            </Text>
          )}
          <Ionicons name="star" size={24} color={theme.colors.gray} style={{marginLeft: theme.spacings.xsmall}}/>
        </TouchableOpacity>
      </View>
      <TabBar setActiveTab={setActiveTab} activeTab={activeTab} />
    </View>
  );
});


const ProfileGallery = React.memo(({ activeTab, ...props }) => {
  switch (activeTab) {
    case 'profileCards':
      return <TimeGallery {...props} />;
    case 'bookmarkedCards':
      return <TimeGallery {...props} />;
    default:
      return null; // or some default/fallback component
  }
});


function PrivateProfileScreen({ navigation }) {
  const isLargeScreen = useIsLargeScreen();
  const { isAuthenticated } = useContext(AuthContext);
  const { profileData, loading, error, refetchProfileData, shareProfile } = useProfile();
  const [activeTab, setActiveTab] = useState('profileCards'); // Possible values: 'profileCards', 'bookmarkedCards'

  const {
    cards,
    nextPageURL,
    loadingCards,
    fetchMoreCards,
    handleRefresh,
  } = useCards(API_ENDPOINTS.PROFILE_CARDS);

  const {
    worlds: protoWorlds,
    loadingWorlds: loadingProtoWorlds,
    fetchMoreWorlds: fetchMoreProtoWorlds,
    nextPageURL: protoWorldsNextPageURL,
    handleRefresh: refreshProtoWorlds,
  } = useWorlds(API_ENDPOINTS.USER_WORLDS);

  const {
    worlds: bigWorlds,
    loadingWorlds: loadingBigWorlds,
    fetchMoreWorlds: fetchMoreBigWorlds,
    nextPageURL: bigWorldsNextPageURL,
    handleRefresh: refreshBigWorlds,
  } = useWorlds(API_ENDPOINTS.USER_BIG_WORLDS)

  const {
    cards: bookmarkedCards,
    nextPageURL: bookmarkedNextPageURL,
    loadingCards: loadingBookmarkedCards,
    fetchMoreCards: fetchMoreBookmarkedCards,
    handleRefresh: handleRefreshBookmarked,
  } = useCards(API_ENDPOINTS.USER_BOOKMARKED_CARDS); // Replace with the correct endpoint

  const renderTabBar = () => (
    <View style={styles.tabBar}>
      <TouchableOpacity
        style={[styles.tabButton, activeTab === 'profileCards' && styles.activeTab]}
        onPress={() => setActiveTab('profileCards')}
      >
        <FontAwesome
          name={activeTab === 'profileCards' ? 'user' : 'user-o'}
          size={26}
          color={activeTab === 'profileCards' ? theme.colors.bluePrimary : theme.colors.gray}
        />
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.tabButton, activeTab === 'bookmarkedCards' && styles.activeTab]}
        onPress={() => setActiveTab('bookmarkedCards')}
      >
        {/*<Text style={styles.tabButtonText}>Bookmarked Cards</Text>*/}
        <FontAwesome
          name={activeTab === 'bookmarkedCards' ? 'bookmark' : 'bookmark-o'}
          size={26}
          color={activeTab === 'bookmarkedCards' ? theme.colors.activeBookmark : theme.colors.gray}
        />
      </TouchableOpacity>
    </View>
  );


  useFocusEffect(
    useCallback(() => {
      if (isAuthenticated) {
        refetchProfileData();
      } else {
        navigation.navigate('Login');
      }
    }, [isAuthenticated, refetchProfileData, navigation])
  );

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={globalStyles.headerRightContainer}>
          <NotificationsButton navigation={navigation} />
          <SettingsButton />
        </View>
      ),
      headerShown: !isLargeScreen,
    });
  }, [navigation]);


  return (
    <View style={{flex: 1}}>
      <ProfileGallery
        activeTab={activeTab}
        nextPageURL={activeTab === 'profileCards' ? nextPageURL : bookmarkedNextPageURL}
        headerComponent={
          <ProfileHeader
            profileData={profileData}
            shareProfile={shareProfile}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        }
        cards={activeTab === 'profileCards' ? cards : bookmarkedCards}
        loadMore={activeTab === 'profileCards' ? fetchMoreCards : fetchMoreBookmarkedCards}
        loading={activeTab === 'profileCards' ? loadingCards : loadingBookmarkedCards}
        onRefresh={activeTab === 'profileCards' ? handleRefresh : handleRefreshBookmarked}
          emptyComponent={() => <CharacterEmptyState isLoading={activeTab === 'profileCards' ? loadingCards : loadingBookmarkedCards} verb={activeTab === 'profileCards' ? 'creating' : 'bookmarking'} />}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.beige,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacings.xsmall,
  },
  followerFollowingButton: {
    backgroundColor: theme.colors.bluePrimary,
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
    marginHorizontal: theme.spacings.small,
  },
  buttonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.small,
    fontFamily: theme.fonts.family.regular,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1, // Make sure this is below the modal but above other content
  },
  modalToggle: {
    // Add specific styles for modal toggle if needed
  },
  hamburgerButton: {
    marginRight: theme.spacings.medium,
    padding: theme.spacings.small,
  },
  headerButton: {
    paddingHorizontal: theme.spacings.small,
  },
  emptyStateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: theme.spacings.large,
  },
  emptyStateText: {
    width: '85%',
    textAlign: 'center',
    color: theme.colors.black,
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.large,
  },
  speechBubbleContainer: {
    width: 200, // Set the width of the container
    height: 80, // Set the height of the container
    position: 'relative',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: -35,
  },
  speechBubbleSVG: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  speechBubbleText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [
      { translateX: -92 }, // Adjust these values as needed
      { translateY: -28 }, // Adjust these values as needed
    ],
    width: 180, // Width of the text container; adjust as needed
    textAlign: 'center',
    color: 'black',
    // Define other text styles as needed
  },
  tabBar: {
    flexDirection: 'row',
    backgroundColor: theme.colors.beige,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.lightgray, // Assuming you want a black border
    height: 50, // Set a fixed height for the tab bar
  },
  tabButton: {
    flex: 1, // Make tabs take up equal space
    alignItems: 'center', // Center items horizontally in the tab
    justifyContent: 'center', // Center items vertically in the tab
    borderRightWidth: 0.0, // Add border left and right
    borderLeftWidth: 0.0,
    borderBottomWidth: 0, // Remove border bottom width to make it look like it's part of the tab bar
    // marginHorizontal: 2, // Add some space between the tabs if necessary
    borderColor: theme.colors.lightgray, // Assuming you want a black border
  },
  activeTab: {
    borderBottomWidth: 2, // Highlight the active tab with a bottom border
    borderColor: 'black', // Border color for the active tab
    // backgroundColor: theme.colors.yellowPrimary,
  },
  tabButtonText: {
    color: theme.colors.dark,
    fontWeight: 'bold', // If you want the text to be bold
  },
  imageStyle: {
    marginTop: theme.spacings.medium,
    width: 200,
    height: 300,
  },
  button: {
    backgroundColor: theme.colors.redPrimary,
    padding: theme.spacings.small,
    borderRadius: theme.borderRadius.medium,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%', // Take up the whole width
    marginBottom: theme.spacings.small,
  },
  topButtonsContainer: {
    width: '95%', // this should match the width of UserInfo
    maxWidth: 500,
    alignSelf: 'center',
  },
  buttonView: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTextWhite: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
  },
  textGray: {
    color: theme.colors.gray,
  },
  yellowPrimary: {
    backgroundColor: theme.colors.yellowPrimary,
  },
});


export default PrivateProfileScreen;
