import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SvgSpur = ({ color, filled = false, ...props }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={34}
      fill="none"
      viewBox="-6 0 34 34"  // Adjust this as needed
      {...props}
    >
      <Path
        fill={filled ? color : "none"}
        stroke={filled ? "none" : color}
        strokeMiterlimit={10}
        strokeWidth={filled ? 0 : 1.5} // Remove stroke if filled
        d="m17.71 13.99 2.51-2.51-2.51-2.51a7.397 7.397 0 0 0-4.45-4.45l-2.51-2.51-2.51 2.51a7.397 7.397 0 0 0-4.45 4.45l-2.51 2.51 2.51 2.51a7.438 7.438 0 0 0 4.45 4.46l2.51 2.51 2.51-2.51a7.387 7.387 0 0 0 4.45-4.46Z"
      />
    </Svg>
  );
};

export default SvgSpur;
