import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Image } from "expo-image";
import theme from "../styles/theme";

const DemoUserCircle = ({ imagePath, size = 50 }) => {
  return (
    <View style={styles.container}>
      <Image
        source={imagePath}
        style={[styles.image, { width: size, height: size, borderRadius: size / 2 }]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderWidth: 0.8,
    borderColor: theme.colors.beige,
    borderRadius: 25,
    overflow: 'hidden',
  },
  image: {
    resizeMode: 'cover',
  },
});

export default DemoUserCircle;
