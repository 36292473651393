import React, { useEffect, useState, useRef } from 'react';
import { View, Text, StyleSheet, Animated, Easing, Dimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import theme from '../styles/theme';

const windowWidth = Dimensions.get('window').width;

export const CARD_WIDTH = Math.min(windowWidth / 1, 200);
export const CARD_HEIGHT = CARD_WIDTH * 1.5;
export const CARD_MARGIN = theme.spacings.xsmall;
export const CARD_PADDING = theme.spacings.small;
export const CARD_TOTAL_HEIGHT = CARD_HEIGHT + (CARD_MARGIN * 2);

const CreatingFabylCard = ({ fabylName }) => {
  const [typedText, setTypedText] = useState('');
  const typingInterval = useRef(null);

  useEffect(() => {
    let index = 0;
    typingInterval.current = setInterval(() => {
      setTypedText((prev) => prev + fabylName.charAt(index));
      index++;
      if (index === fabylName.length) clearInterval(typingInterval.current);
    }, 100);

    return () => clearInterval(typingInterval.current);
  }, [fabylName]);

  const shimmerAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(shimmerAnimation, {
          toValue: 1,
          duration: 4000,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(shimmerAnimation, {
          toValue: 0,
          duration: 4000,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }, [shimmerAnimation]);

  const shimmerTranslate = shimmerAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [-CARD_WIDTH * 2, 0],
  });

  return (
    <View style={styles.cardContainer}>
      <View style={styles.imagePlaceholder}>
        <Animated.View style={[styles.shimmer, { transform: [{ translateX: shimmerTranslate }] }]}>
          <LinearGradient
            colors={[theme.colors.redPrimary, theme.colors.bluePrimary, theme.colors.yellowPrimary]}
            style={styles.gradient}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
          />
        </Animated.View>
      </View>
      <Text style={styles.cardName}>
        {typedText}
      </Text>
      <View style={styles.flavorTextContainer}>
        <View style={styles.flavorTextLine}>
          <Animated.View style={[styles.shimmerLine, { transform: [{ translateX: shimmerTranslate }] }]}>
            <LinearGradient
              colors={[theme.colors.white, theme.colors.lightgray, theme.colors.white, theme.colors.lightgray]}
              style={styles.gradient}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
            />
          </Animated.View>
        </View>
        <View style={styles.flavorTextLine}>
          <Animated.View style={[styles.shimmerLine, { transform: [{ translateX: shimmerTranslate }] }]}>
            <LinearGradient
              colors={[theme.colors.white, theme.colors.lightgray, theme.colors.white, theme.colors.lightgray]}
              style={styles.gradient}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
            />
          </Animated.View>
        </View>
        <View style={styles.flavorTextLine}>
          <Animated.View style={[styles.shimmerLine, { transform: [{ translateX: shimmerTranslate }] }]}>
            <LinearGradient
              colors={[theme.colors.white, theme.colors.lightgray, theme.colors.white, theme.colors.lightgray]}
              style={styles.gradient}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
            />
          </Animated.View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: CARD_WIDTH,
    // height: CARD_HEIGHT,
    margin: CARD_MARGIN,
    backgroundColor: theme.colors.gray,
    borderRadius: theme.borderRadius.medium,
    padding: CARD_PADDING,
    shadowColor: theme.colors.black,
    shadowOffset: { width: 0, height: 7 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 2,
  },
  imagePlaceholder: {
    width: '100%',
    height: CARD_WIDTH - 10,
    borderRadius: theme.borderRadius.small,
    backgroundColor: theme.colors.redPrimary,
    overflow: 'hidden',
  },
  shimmer: {
    width: CARD_WIDTH * 3,
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  gradient: {
    width: '100%',
    height: '100%',
  },
  cardName: {
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.redPrimary,
    marginTop: theme.spacings.xsmall,
  },
  flavorTextContainer: {
    marginTop: theme.spacings.small,
  },
  flavorTextLine: {
    height: 10,
    backgroundColor: theme.colors.lightgray,
    marginBottom: 5,
    borderRadius: 5,
    overflow: 'hidden',
    position: 'relative',
  },
  shimmerLine: {
    width: '200%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0.5,
  },
});

export default CreatingFabylCard;
