import React, { useState, useContext } from 'react';
import { AuthContext } from "../contexts/AuthContext";
import {
  View,
  Text,
  StyleSheet,
  Alert,
  TextInput,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  ScrollView
} from 'react-native';
import PrimaryButton from "../components/PrimaryButton";
import theme from "../styles/theme";
import settingsStyles from "../styles/Settings.styles";
import alert from "../utils/alert";


const DeactivateAccountScreen = ({ navigation }) => {
  const [password, setPassword] = useState('');
  const { deactivateAccount } = useContext(AuthContext);

  const handleDeactivateAccount = () => {
    alert(
      "Confirm Deactivation",
      "Are you sure you want to deactivate your account? If you deactivate, your account will be deleted in 30 days.",
      [
        {
          text: "Cancel",
          style: "cancel"
        },
        {text: "Yes, Deactivate", onPress: () => confirmDeactivation()}
      ],
      {cancelable: false}
    );
  };

  const confirmDeactivation = async () => {
    if (!password) {
      alert('Error', 'Please enter your password.');
      return;
    }
    try {
      await deactivateAccount(password);
      // navigation.navigate('Login');
      // alert('Account Deactivation', message);
    } catch (error) {
      console.error('Error deactivating account:', error);
      alert("Error", error.message);
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[settingsStyles.container, styles.container]}
      keyboardVerticalOffset={100}
    >
      <ScrollView>
      <View style={styles.warningContainer}>
        <Text style={styles.title}>WARNING</Text>
        <Text style={styles.warningText}>
          • When you deactivate your account, all of your user data will be marked for
          deletion (aside from your fabyls).
        </Text>
        <Text style={styles.warningText}>
          • Your account will be fully deleted 30 days after deactivation.
        </Text>
        <Text style={styles.warningText}>
          • During these 30 days, you can reactivate your account by logging in.
        </Text>
        <Text style={styles.warningText}>
          • Once your account is fully deleted, it cannot be recovered, and your account will be dissociated from all of your cards and worlds, which will remain on fabyl unless you specifically request their deletion.
        </Text>
        <Text style={styles.warningText}>
          • If you have subscribed through Apple, you will need to cancel your subscription through the App Store.
        </Text>
      </View>
      <TextInput
        style={settingsStyles.input}
        secureTextEntry
        placeholder="Enter your password"
        onChangeText={setPassword}
        value={password}
        placeholderTextColor={theme.colors.gray}
      />
      <TouchableOpacity style={styles.button} onPress={handleDeactivateAccount}>
        <Text style={styles.buttonText}>DEACTIVATE ACCOUNT</Text>
      </TouchableOpacity>
      <View style={{ flex : 1 }} />
      {/* ^ fills up bottom area because we must use flex-end for keyboard avoiding */}
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
    // ^ Must use flex-end, otherwise keyboard avoid does not work
  },
  warningContainer: {
    padding: theme.spacings.medium,
    backgroundColor: theme.colors.redPrimary,
    // borderRadius: theme.spacings.small,
    marginBottom: theme.spacings.medium,
    borderWidth: 1,
  },
  title: {
    fontSize: theme.fonts.sizes.xxlarge,
    color: theme.colors.white,
    marginBottom: theme.spacings.medium,
    fontFamily: theme.fonts.family.medium,
    alignSelf: 'center',
    textDecorationLine: 'underline',
  },
  warningText: {
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.white, // Color to emphasize the warning
    marginBottom: theme.spacings.medium,
    fontFamily: theme.fonts.family.bold,
  },
  input: {
    borderWidth: 1,
    borderColor: theme.colors.gray,
    padding: theme.spacings.small,
    marginBottom: theme.spacings.medium,
    backgroundColor: theme.colors.white,
    color: theme.colors.dark,
    fontSize: theme.fonts.sizes.medium,
  },
  button: {
    backgroundColor: theme.colors.redPrimary,
    padding: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
    alignItems: 'center',
    marginBottom: theme.spacings.large,
  },
  buttonText: {
    color: theme.colors.white,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.large,
  },
  // ... other styles
});

export default DeactivateAccountScreen;
