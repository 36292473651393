import React from 'react';
import {SafeAreaView, StyleSheet, View} from 'react-native';
import SearchComponent from '../components/SearchComponent';
import CardsList from "../components/lists/CardsList";
import UsersList from "../components/lists/UsersList"; // Import your SearchComponent
import {
  fetchCardSearchResults,
  fetchFriendsCardsSearchResults, fetchUserCardsSearchResults,
  fetchUserSearchResults
} from "../utils/api/api";
import theme from "../styles/theme";


const SearchScreen = ({ route }) => {
  const initialFacetKey = route.params?.initialFacetKey || 'allCards'; // Default to 'followers' if not provided

  const facets = [
    { key: 'myCards', label: 'My Cards', component: CardsList, fetchFunction: fetchUserCardsSearchResults },
    { key: "friendsCards", label: "Friends' Cards", component: CardsList, fetchFunction: fetchFriendsCardsSearchResults},
    { key: 'allCards', label: 'All Cards', component: CardsList, fetchFunction: fetchCardSearchResults },
    { key: 'allUsers', label: 'All Users', component: UsersList, fetchFunction: fetchUserSearchResults },
  ];

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <SearchComponent facets={facets} initialFacetKey={initialFacetKey}/>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: theme.colors.beige,
  },
  container: {
    flex: 1,
    padding: 10,
  },
  // You can add more styles if needed
});

export default SearchScreen;
