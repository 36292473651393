import React, {useEffect, useState} from 'react';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import SvgGenerate from "../../assets/images/Generate";
import DemoInputBox from "../../components/DemoInputBox";
import StaticMiniCard from "../../components/StaticMiniCard";
import theme from "../../styles/theme";
import CloseButton from "./CloseButton";
import styles from "./slideStyles";
import SvgGenerateWhite from "../../assets/images/GenerateWhite";
import cardData from './cardData';

const Slide1 = ({ startSlideAnimation, navigationTarget }) => {
  console.log(`navigationTarget: ${navigationTarget}`);
  const [startDemoInputAnimation, setStartDemoInputAnimation] = useState(false);
  const [startCardAnimation, setStartCardAnimation] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (startSlideAnimation) {
      timeoutId = setTimeout(() => {
        setStartDemoInputAnimation(true);
      }, 3000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setStartDemoInputAnimation(false);
      setStartCardAnimation(false);
    };
  }, [startSlideAnimation]);

  const handleAnimationComplete = () => {
    setTimeout(() => {
      setStartCardAnimation(true);
    }, 200);
  };

  return (
    <View style={styles.slide}>
      <CloseButton navigationTarget={navigationTarget} />
      <View style={styles.topContainer}>
        <View style={styles.topIconContainer}>
          <SvgGenerateWhite style={{transform: [{scale: 1.25}]}}/>
        </View>
        <Text style={styles.text}>1. NAME YOUR STORY</Text>
        <Text style={styles.subtitle}>Enter the title {'\n'}and press create.</Text>
      </View>
      <View style={[styles.demoInputContainer, {marginBottom: theme.spacings.large}]}>
        <DemoInputBox
          text={cardData.rootCard.title}
          onAnimationComplete={handleAnimationComplete}
          startAnimation={startDemoInputAnimation}
        />
      </View>
      <StaticMiniCard
        imageSource={cardData.rootCard.imageSource}
        title={cardData.rootCard.title}
        description={cardData.rootCard.description}
        username={cardData.rootCard.username}
        userImageSource={cardData.rootCard.userImageSource}
        startAnimation={startCardAnimation}
        animationStep={2}
        endingRiffButtonY={0}
      />
    </View>
  );
};


export default Slide1;
