import React from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { Image } from 'expo-image';
import theme from '../../styles/theme';
import {FontAwesome, Ionicons, MaterialIcons} from '@expo/vector-icons';
import UserCircle from "../UserCircle";
import {useNavigation} from "@react-navigation/native";

const PublicProfileDetailsModal = ({ isVisible, onClose, profileData, onRemoveFriend }) => {
  const navigation = useNavigation();

  const handleFriendSharedPress = () => {
    navigation.navigate('FriendSharedWorlds', {profileUuid: profileData.uuid});
    onClose();
  }

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}>
      <View style={styles.centeredView}>
        <TouchableOpacity
          style={StyleSheet.absoluteFill}
          activeOpacity={1}
          onPress={onClose}
        />
        <View style={styles.modalView}>
          <TouchableOpacity
            style={styles.closeButton}
            onPress={onClose}
            hitSlop={{top: 10, right: 10, bottom: 10, left: 10}}
          >
            <MaterialIcons name="close" size={24} color={theme.colors.black}/>
          </TouchableOpacity>

          <UserCircle profile_uuid={profileData.uuid} size={100} />
          <Text style={styles.username}>{profileData?.preferred_username}</Text>
          <View style={styles.buttonsContainer}>
            <TouchableOpacity
              onPress={handleFriendSharedPress}
              style={[styles.optionButton]}
            >
              <View style={styles.iconContainer}>
                <Ionicons name="book" size={20} color={theme.colors.gray} />
              </View>
              <Text style={[styles.optionText]}>View Shared Worlds</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={onRemoveFriend}
              style={[styles.optionButton, styles.optionButtonRemove]}
            >
              <View style={styles.iconContainer}>
                <MaterialIcons name="person-remove" size={20} color={theme.colors.reportRed} />
              </View>
              <Text style={[styles.optionText,  styles.optionRemoveText]}>Remove Friend</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  modalView: {
    margin: 20,
    backgroundColor: theme.colors.beige,
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '85%',
    maxWidth: 500,
    minHeight: '30%',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  username: {
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.bluePrimary,
    fontSize: theme.fonts.sizes.xlarge,
  },
  numCreations: {
    marginVertical: 10,
  },
  featuredCardImage: {
    width: 100,
    height: 100,
    borderRadius: 10,
    marginVertical: 10,
  },
  buttonsContainer: {
    marginTop: theme.spacings.large,
    alignItems: 'flex-start',
  },
  optionButton: {
    borderRadius: theme.borderRadius.medium,
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    alignItems: 'center',
    flexDirection: 'row',

  },
  optionButtonRemove: {
    marginTop: theme.spacings.xlarge,
  },
  optionRemoveText: {
    color: theme.colors.reportRed,
    marginLeft: 10,
  },
  optionText: {
    color: theme.colors.black,
    textAlign: 'center',
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.large,
  },
  iconContainer: {
    width: 24,
    alignItems: 'center', // Center the icon horizontally in this container
    marginRight: theme.spacings.small, // Space between icon and text
  },

});

export default PublicProfileDetailsModal;
