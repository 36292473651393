import React, {memo} from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions
} from 'react-native';
import {Image} from 'expo-image';
import {useNavigation} from '@react-navigation/native';
import theme from '../styles/theme';

const windowWidth = Dimensions.get('window').width;

export const CARD_WIDTH = Math.min(windowWidth / 3, 150);
export const CARD_HEIGHT = CARD_WIDTH * 1.4;
export const CARD_MARGIN = theme.spacings.xsmall;
export const CARD_PADDING = theme.spacings.small;
export const CARD_TOTAL_HEIGHT = CARD_HEIGHT + (CARD_MARGIN * 2);


const MiniCard = memo(({ item, pressable=true, newScreen="CardDetail"}) => {
  const navigation = useNavigation();

  const defaultPressHandler = () => {
    navigation.navigate(newScreen, { card: item });
  };

  return (
    <TouchableOpacity
      disabled={!pressable}
      onPress={defaultPressHandler}
    >
      <View style={styles.cardTileContainer}>
        <Image
          source={{ uri: item.art || item.thumbnail }}
          style={styles.imageStyle}
        />
        <Text
          style={styles.cardName}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          {item.name}
        </Text>
        {/*<Text*/}
        {/*  style={styles.creatorName}*/}
        {/*  numberOfLines={1}*/}
        {/*  ellipsizeMode="tail"*/}
        {/*>*/}
        {/*  @{item.creator_preferred_username}*/}
        {/*</Text>*/}
      </View>
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  cardTileContainer: {
    width: CARD_WIDTH,
    // height: CARD_HEIGHT - (theme.spacings.small * 2),
    height: CARD_HEIGHT,
    margin: CARD_MARGIN,
    backgroundColor: theme.colors.gray, // Adjust as needed
    borderRadius: theme.borderRadius.medium,
    padding: theme.spacings.small,
    paddingBottom: theme.spacings.medium,

    // shadowColor: theme.colors.black, // Optional shadow effect
    // shadowOffset: { width: 0, height: 7 },
    // shadowOpacity: 0.2,
    // shadowRadius: 4,
    // elevation: 2,
  },
  imageStyle: {
    width: '100%',
    height: CARD_WIDTH - 10,
    borderRadius: theme.borderRadius.small, // Adjust as needed
  },
  cardName: {
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.redPrimary, // Adjust as needed
    marginTop: theme.spacings.xsmall,
  },
  creatorName: {
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.lighterBlue, // Adjust as needed
  },
  flavor: {
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.small,
    color: theme.colors.white, // Adjust as needed
    marginTop: theme.spacings.xsmall,
  },
  userName: {
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.xsmall,
    color: theme.colors.bluePrimary, // Adjust as needed
    marginTop: theme.spacings.xsmall,
  },
});

export default MiniCard;
