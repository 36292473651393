import { useState, useEffect, useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { fetchCardByUUID } from "../utils/api/cardsService";
import { debounce } from 'lodash';


const useCardDetails = (initialCardUuid) => {
  const queryClient = useQueryClient();
  const [cards, setCards] = useState([]);
  const [currentViewableCardUuid, setCurrentViewableCardUuid] = useState(null);
  const [currentViewableCard, setCurrentViewableCard] = useState(null); // New state for the full data of the current viewable card


  // Fetch main card details
  const {
    data: mainCard,
    isLoading,
    isError,
    refetch: refetchMainCard,
  } = useQuery(
    ['cardDetail', initialCardUuid],
    () => fetchCardByUUID(initialCardUuid),
    {
      staleTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: 'always',
    }
  );

  // Update cards array when main card data is available
  useEffect(() => {
    if (mainCard) {
      const skeletonDescendants = mainCard.descendants_uuids.map(uuid => ({ uuid }));
      const skeletonAncestors = mainCard.ancestors_uuids.map(uuid => ({ uuid }));
      setCards([...skeletonAncestors, mainCard, ...skeletonDescendants]);
    }
  }, [mainCard]);

  // Lazy loading of card details
  const fetchLazyCard = async (uuid) => {
      const card = await fetchCardByUUID(uuid);
      setCards(prevCards => {
          const updatedCards = [...prevCards];
          const indexToUpdate = updatedCards.findIndex(c => c.uuid === card.uuid);
          if (indexToUpdate !== -1) {
              updatedCards[indexToUpdate] = card;
          }
          return updatedCards;
      });
  };

  // Add a method to trigger refetching the main card
  const updateMainCardData = () => {
      refetchMainCard();
  };

  const getCardFromCache = (uuid) => {
    return queryClient.getQueryData(['cardDetail', uuid]);
  };

  // Prefetching logic for card details
  const prefetchCardDetails = useCallback((uuid) => {
    queryClient.prefetchQuery(['cardDetail', uuid], () => fetchCardByUUID(uuid), {
      staleTime: Infinity
    });
  }, [queryClient]);


  // Logic to get first and last card uuids
  const firstCardUuid = cards.length > 0 ? cards[0].uuid : null;
  const lastCardUuid = cards.length > 0 ? cards[cards.length - 1].uuid : null;

  // New handler for viewable items
  const handleViewableItemsChanged = useCallback(({ viewableItems }) => {
    viewableItems.forEach(({ item }) => {
      if (item.uuid && item.uuid !== initialCardUuid) {
        prefetchCardDetails(item.uuid);
      }
    });

    // Update current viewable card UUID
    if (viewableItems.length > 0) {
      const firstViewItem = viewableItems[0]?.item;
      if (firstViewItem && firstViewItem.uuid) {
        setCurrentViewableCardUuid(firstViewItem.uuid);
      }
    }
  }, [prefetchCardDetails, initialCardUuid]);

    // New effect to update currentViewableCard based on currentViewableCardUuid
  useEffect(() => {
    const updateCurrentViewableCard = async () => {
      let cardData = getCardFromCache(currentViewableCardUuid);
      if (!cardData) {
        cardData = await fetchCardByUUID(currentViewableCardUuid);
      }
      setCurrentViewableCard(cardData);
    };

    if (currentViewableCardUuid) {
      updateCurrentViewableCard();
    }
  }, [currentViewableCardUuid, queryClient]);


  return {
    mainCard,
    cards,
    fetchLazyCard,
    prefetchCardDetails,
    isLoading,
    isError,
    getCardFromCache,
    firstCardUuid,
    lastCardUuid,
    handleViewableItemsChanged,
    currentViewableCardUuid,
    currentViewableCard,
    updateMainCardData,
  };
};

export default useCardDetails;
