import React, { useEffect, useState } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  Text,
  View, ActivityIndicator, Modal, Dimensions, useWindowDimensions,
  Linking,
} from 'react-native';
import theme from "../styles/theme";
import UpgradeInfoComponent from "../components/UpgradeInfoComponent";
import {
  initConnection,
  getProducts,
  requestSubscription,
  endConnection,
  purchaseUpdatedListener,
  purchaseErrorListener,
  clearTransactionIOS,
} from 'react-native-iap';
import iapConfig from '../iap.config';
import planConfig from "../planConfig";
import {useProfile} from "../contexts/ProfileContext";
import PlanBox from "../components/PlanBox";
import {fetchSubscriptionData} from "../utils/api/userService";
import {useSubscription} from "../hooks/useSubscription";
import { useQueryClient } from 'react-query';
import alert from "../utils/alert";


const PRIVACY_POLICY = process.env.EXPO_PUBLIC_PRIVACY_POLICY_URL;
const TERMS_OF_USE = process.env.EXPO_PUBLIC_TERMS_OF_USE_URL;


const openURL = (url) => {
  Linking.canOpenURL(url)
    .then((supported) => {
      if (supported) {
        return Linking.openURL(url);
      } else {
        console.log("Can't handle URL: " + url);
      }
    })
    .catch((err) => console.error('An error occurred', err));
};


function useIsWideScreen() {
  const { width } = useWindowDimensions();
  return width >= theme.breakpoints.medium; // Define your breakpoint for wide screens
}


const AppUpgradeScreen = () => {
  const { profileData } = useProfile();
  const queryClient = useQueryClient();  // Get the queryClient instance
  const [products, setProducts] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(useIsWideScreen());

  const { data: subscriptionData } = useSubscription(true);
  const plans = planConfig.map(plan => ({
    ...plan,
    isActive: plan.id === subscriptionData?.tier
  }));

  const isDeveloperTier = subscriptionData?.tier === 'developer';


  useEffect(() => {
    // Call this once during the component's initialization or after a purchase is fully processed
    clearTransactionIOS();
  }, []);

    useEffect(() => {
    // Invalidate the 'subscription' query when the component mounts
    queryClient.invalidateQueries('subscription');
  }, [queryClient]);


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await initConnection();
        console.log('Connection initialized')
        const availableProducts = await getProducts({ skus: iapConfig.items.map(item => item.productId) });
        setProducts(availableProducts);
        // console.log('Products fetched:', availableProducts);
      } catch (err) {
        console.log(`This is error: ${err}`)
        console.warn(err.code, err.message);
      }
    };

    fetchProducts();

    purchaseUpdatedListener(async (purchase) => {
      console.log('Purchase updated:');
      // Don't need receipt verification because handled with backend server to server notification
    });

    purchaseErrorListener((error) => {
      console.warn('Purchase error:', error);
      // Handle purchase error
    });

    return () => {
      endConnection();
    };
  }, []);

  const handlePurchase = async (sku) => {
    if (subscriptionData?.platform === 'internal') {
      alert('You have an internal subscription. Changes can only be made by the administrator. Please contact support@fabyl.ai if you need to make changes.');
      return;
    } else if (subscriptionData?.platform === 'stripe') {
      alert("You have a stripe subscription. Please manage your subscription through the fabyl website (fabyl.ai).");
      return;
    }
    console.log(`Purchasing ${sku}`)
    if (!profileData) {
      // make sure we have profile data before proceeding
      console.warn('Profile data not available');
      return;
    }

    setIsProcessing(true);

    try {
      // await clearTransactionIOS();
      const subscription = await requestSubscription({
        sku,
        andDangerouslyFinishTransactionAutomaticallyIOS: false,
        appAccountToken: profileData.uuid,
      //   ^ critical so we can associate server to server notification
      //   with the user profiles on the backend.
      });
      // console.log('Purchased product details:', subscription);
      console.log('Purchased product:', subscription.productId);
      // console.log('Transaction ID:', subscription.transactionId);
    } catch (err) {
      console.warn(err.code, err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const Container = isWideScreen ? View : ScrollView;

  return (
    <ScrollView style={styles.container}>
      <Modal
        transparent={true}
        visible={isProcessing}
        onRequestClose={() => setIsProcessing(false)}
      >
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <ActivityIndicator size="large" color={theme.colors.white} />
        </View>
      </Modal>
      {isDeveloperTier && (
        <View style={styles.developerMessage}>
          <Text style={styles.developerMessageText}>YOU HAVE A DEVELOPER ACCOUNT.</Text>
        </View>
      )}
      <Container
        style={[
          styles.upgradeButtonsContainer,
          isWideScreen && styles.widescreenContainer,
        ]}
      >
        {plans.map(plan => (
          <PlanBox
            key={plan.id}
            plan={plan}
            onPress={() => handlePurchase(plan.productId)}
            isActive={plan.isActive}
            isWideScreen={isWideScreen}
          />
        ))}
      </Container>
      <UpgradeInfoComponent />
      <View style={styles.footerSection}>
        <View style={styles.linksRow}>
          <TouchableOpacity onPress={() => openURL(PRIVACY_POLICY)}>
            <Text style={styles.footerLink}>Privacy Policy</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => openURL(TERMS_OF_USE)}>
            <Text style={styles.footerLink}>Terms of Use</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  activityBox: {
    flex: 1,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },
  container: {
    flex: 1,
    padding: theme.spacings.medium,
    backgroundColor: theme.colors.beige,
  },
  upgradeButton: {
    backgroundColor: theme.colors.bluePrimary,
    paddingVertical: theme.spacings.medium,
    paddingHorizontal: theme.spacings.large,
    borderRadius: theme.borderRadius.medium,
    alignItems: 'center',
    marginTop: theme.spacings.medium,
  },
  upgradeButtonsContainer: {
    marginBottom: 0,
  },
  upgradeButtonDisabled: {
    backgroundColor: theme.colors.lightgray,
    paddingVertical: theme.spacings.medium,
    paddingHorizontal: theme.spacings.large,
    borderRadius: theme.borderRadius.medium,
    alignItems: 'center',
    marginTop: theme.spacings.medium,
    opacity: 0.5,
  },
  developerMessage: {
    backgroundColor: theme.colors.yellowPrimary,
    padding: theme.spacings.medium,
    // marginBottom: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
    maxWidth: 600,
    alignSelf: 'center',
  },
  developerMessageText: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    textAlign: 'center',
  },
  upgradeButtonText: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.white,
  },
  upgradeButtonTextDisabled: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.gray,
  },
  widescreenContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  footerSection: {
    padding: theme.spacings.large,
    paddingBottom: theme.spacings.xlarge,
    alignItems: 'center',
    width: '100%',
  },
  linksRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  footerLink: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.medium,
    textDecorationLine: 'underline',
    marginHorizontal: theme.spacings.medium,
    marginVertical: theme.spacings.large,
  },
});

export default AppUpgradeScreen;
