import {StyleSheet} from "react-native";
import theme from "./theme";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: theme.spacings.large,
    paddingTop: theme.spacings.medium, // Adjusted top padding
    backgroundColor: theme.colors.beige,
    alignItems: 'stretch', // Ensures that children width is the same as the container
    alignSelf: 'center',
    width: '100%',
    maxWidth: 500, // Added to limit the width of the container
  },
  title: {
    fontSize: theme.fonts.scaledSizes.xlarge,
    color: theme.colors.gray,
    marginBottom: theme.spacings.medium,
    fontFamily: theme.fonts.family.medium,
    alignSelf: 'center',
  },
  currentContactRow: {
    marginBottom: theme.spacings.large,
  },
  currentContactText: {
    fontSize: theme.fonts.scaledSizes.large,
    color: theme.colors.gray,
    fontFamily: theme.fonts.family.bold,
    marginBottom: theme.scaledSpacings.small,
    textDecorationLine: 'underline',
  },
  currentContact: {
    fontSize: theme.fonts.scaledSizes.large,
    color: theme.colors.dark,
    fontFamily: theme.fonts.family.regular,
    marginBottom: theme.scaledSpacings.medium,
  },
  input: {
    borderWidth: 1,
    borderColor: theme.colors.gray,
    padding: theme.scaledSpacings.small,
    marginBottom: theme.scaledSpacings.medium,
    backgroundColor: theme.colors.white,
    color: theme.colors.dark,
    fontSize: theme.fonts.scaledSizes.large,
  },
});

export default styles;
