import React from 'react';
import {
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import SvgFabylHeader from '../assets/images/FabylHeader';
import theme from '../styles/theme';
import globalStyles from '../styles/globalStyles';
import AppStoreBadge from '../assets/App_Store_Badge';
import InstagramBadge from '../assets/Instagram_Glyph_Gradient.png';
import DiscordBlue from "../assets/DiscordBlue";
import alert from "../utils/alert";
import SvgVorkov from "../assets/images/characters/Vorkov";
import SvgFrankenpug from "../assets/images/characters/Frankenpug";
import SvgSpacechef from "../assets/images/characters/Spacechef";
import SvgRobot from "../assets/images/characters/Robot";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import {Image} from "expo-image";

const PRIVACY_POLICY = process.env.EXPO_PUBLIC_PRIVACY_POLICY_URL;
const TERMS_OF_USE = process.env.EXPO_PUBLIC_TERMS_OF_USE_URL;
const APP_STORE = process.env.EXPO_PUBLIC_IOS_APP_URL;


function LandingPage() {
  const navigation = useNavigation();
  const isLargeScreen = useIsLargeScreen(theme.breakpoints.large);

  const openURL = (url) => {
    Linking.canOpenURL(url)
    .then((supported) => {
      if (!supported) {
        console.log("Can't handle URL: " + url);
      } else {
        return Linking.openURL(url);
      }
    })
    .catch((err) => console.error('An error occurred', err));
  };

  const handleLogin = () => {
    navigation.navigate('Login');
  };

  const handleHowDoesItWork = () => {
    navigation.navigate('Tutorial', { navigationTarget: 'LandingPage', getStartedNavigationTarget: 'Login' });
  }

  const handleSignUp = () => {
    navigation.navigate('EmailSignUpScreen');
  };

  const handleEmailPress = () => {
    const email = 'mailto:support@fabyl.ai';
    Linking.openURL(email).catch((err) => console.error('An error occurred', err));
  };

  const handleAppStorePress = () => {
    if (APP_STORE) {
      openURL(APP_STORE);
    } else {
      alert('Coming Soon', 'The app is coming soon to the App Store.');
    }
  };

  const handleInstagramPress = () => {
    openURL(process.env.EXPO_PUBLIC_INSTAGRAM_URL);
  }

  const handleDiscordPress = () => {
    openURL(process.env.EXPO_PUBLIC_DISCORD_INVITE_URL);
  }


  return (
    <ScrollView contentContainerStyle={styles.scrollViewContainer}>
      <View style={[
        styles.container,
        isLargeScreen && styles.largeScreenPadding,
      ]}>
        <View style={[
          styles.topAreaContainer,
          isLargeScreen && styles.largeScreenBorderRadius,
        ]}>

          {isLargeScreen && (
            <View style={styles.charactersContainer}>
              <SvgFrankenpug style={styles.image}/>
              <SvgVorkov style={styles.image}/>
            </View>
          )}

          <View style={styles.subContainer}>
            <View style={styles.headerAndPhrase}>
              <SvgFabylHeader style={[styles.header, { transform: [{ scale: 1.5 }] }]} />
              <Text style={globalStyles.brandPhrase}>weave whimsical worlds</Text>
            </View>

            <View style={styles.buttonsContainer}>
              <TouchableOpacity onPress={handleLogin} style={globalStyles.primaryButton}>
                <Text style={globalStyles.primaryButtonText}>LOG IN</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={handleSignUp} style={styles.createAccountButton}>
                <Text style={styles.createAccountText}>CREATE NEW ACCOUNT</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={handleHowDoesItWork}>
                <Text style={styles.hyperlinkStyle}>How does it work?</Text>
              </TouchableOpacity>

            </View>

            {Platform.OS === 'web' && (
              <View style={styles.appStoreContainer}>
                <TouchableOpacity style={styles.appStoreButton} onPress={handleAppStorePress}>
                  <AppStoreBadge style={{transform: [{scale: 1.3}]}}/>
                </TouchableOpacity>
              </View>
            )}

            <View style={styles.socialContainer}>
              <TouchableOpacity style={styles.socialButton} onPress={handleDiscordPress}>
                <DiscordBlue style={{height: 50, width: 50, transform: [{scale: 1}]}}/>
              </TouchableOpacity>
              <TouchableOpacity style={styles.socialButton} onPress={handleInstagramPress}>
                <Image source={InstagramBadge} style={{width: 50, height: 50}}/>
              </TouchableOpacity>
            </View>

          </View>

          {isLargeScreen && (
            <View style={styles.charactersContainer}>
              <SvgSpacechef style={styles.image}/>
              <SvgRobot style={styles.image}/>
            </View>
          )}

        </View>

        <View style={styles.aboutContainer}>

          <View style={styles.aboutSection}>
            <Text style={styles.aboutTitle}>About</Text>
            <Text style={styles.aboutText}>
              Fabyl is a platform for collaborative storytelling. Our mission is to enable users to brainstorm, create, and share stories with friends. You can see examples of what can be done with fabyl{' '}
              <TouchableOpacity style={styles.emailLink} onPress={handleInstagramPress}>
                on our instagram account.
              </TouchableOpacity>
            </Text>
          </View>

          <View style={styles.aboutSection}>
            <Text style={styles.aboutTitle}>Contact</Text>
            <Text style={styles.aboutText}>
              For more information, please{' '}
              <TouchableOpacity onPress={handleDiscordPress}>
                <Text style={styles.emailLink}>join our discord server</Text>
              </TouchableOpacity>{' '}
              or contact us at{' '}
              <TouchableOpacity onPress={handleEmailPress}>
                <Text style={styles.emailLink}>support@fabyl.ai</Text>
              </TouchableOpacity>.
            </Text>
          </View>

        </View>


        <View style={styles.footerSection}>
          <View style={styles.linksRow}>
            <Text style={styles.footerLink} onPress={() => openURL(PRIVACY_POLICY)}>
              Privacy Policy
            </Text>
            <Text style={styles.footerLink} onPress={() => openURL(TERMS_OF_USE)}>
              Terms of Use
            </Text>
            <Text style={styles.footerLink} onPress={() => navigation.navigate('Support')}>
              Support
            </Text>
          </View>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollViewContainer: {
    flexGrow: 1,
    backgroundColor: theme.colors.gray,
  },
  container: {
    flex: 1,
    height: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.gray,
    // padding: theme.spacings.large,
  },
  largeScreenPadding: {
    padding: theme.spacings.large,
  },
  charactersContainer: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  topAreaContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: theme.colors.beige,
    width: '100%',
    justifyContent: 'space-around',
  },
  largeScreenBorderRadius: {
    borderRadius: theme.borderRadius.medium,
  },
  hyperlinkStyle: {
    alignSelf: 'center', // Center the text
    color: theme.colors.bluePrimary, // Hyperlink-like blue color
    textDecorationLine: 'underline', // Underline to mimic hyperlink appearance
    paddingVertical: theme.spacings.small, // Optional: Adjust the padding for better touch feedback
    fontFamily: theme.fonts.family.bold, // Optional: Use a bold font for better visibility
    fontSize: theme.fonts.sizes.large, // Optional: Adjust the font size as needed
    marginTop: theme.spacings.small,
  },
  headerAndPhrase: {
    backgroundColor: theme.colors.beige,
    marginVertical: 100,
    alignSelf: 'center',
    alignItems: 'center',
  },
  emailLink: {
    color: theme.colors.lighterBlue,
    textDecorationLine: 'underline',
  },
  buttonsContainer: {
    maxWidth: 300,
    marginBottom: theme.spacings.xxlarge,
    zIndex: 2,
  },
  header: {
    marginBottom: theme.spacings.xxlarge,
  },
  createAccountButton: {
    width: "100%",
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.beige,
    paddingVertical: theme.spacings.small,
    borderColor: theme.colors.bluePrimary,
    borderRadius: theme.borderRadius.medium,
    padding: theme.spacings.medium,
    borderWidth: 1,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
    marginTop: theme.spacings.medium,
  },
  createAccountText: {
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.bluePrimary,
  },
  appStoreContainer: {
    marginVertical: theme.spacings.large,
    alignSelf: 'center',
    alignItems: 'center',
  },
  socialContainer: {
    // marginVertical: theme.spacings.large,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  image: {
    width: 200,
    height: 350,
    // margin: theme.spacings.xxlarge,
  },
  appStoreButton: {
    marginVertical: theme.spacings.small,
  },
  socialButton: {
    marginVertical: theme.spacings.small,
    marginHorizontal: theme.spacings.large,
  },
  aboutContainer: {
    alignItems: 'center',
    backgroundColor: theme.colors.gray,
    width: '100%',
    // maxWidth: 800,
  },
  aboutSection: {
    backgroundColor: theme.colors.gray,
    padding: theme.spacings.large,
    borderRadius: theme.borderRadius.medium,
    marginVertical: theme.spacings.large,
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: 800,
  },
  aboutTitle: {
    fontSize: theme.fonts.sizes.xxlarge,
    fontFamily: theme.fonts.family.xbold,
    textAlign: 'flex-start',
    color: theme.colors.redPrimary,
    marginBottom: theme.spacings.small,
  },
  aboutText: {
    fontSize: theme.fonts.sizes.xlarge,
    color: theme.colors.beige,
    textAlign: 'flex-start',
  },
  footerSection: {
    backgroundColor: theme.colors.gray,
    padding: theme.spacings.large,
    borderRadius: theme.borderRadius.medium,
    marginVertical: theme.spacings.large,
    alignItems: 'center',
    width: '100%',
  },
  linksRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    // width: '100%',
  },
  footerLink: {
    color: theme.colors.yellowPrimary,
    fontSize: theme.fonts.sizes.medium,
    textDecorationLine: 'underline',
    marginVertical: theme.spacings.small,
    marginHorizontal: theme.spacings.medium,
  },
});

export default LandingPage;
