import { Auth } from 'aws-amplify';
import axios from 'axios';

const refreshToken = async () => {
  try {
    const session = await Auth.currentSession(); // This will automatically refresh the tokens if needed
    return session.getIdToken().getJwtToken(); // Returns a new ID token (JWT)
  } catch (error) {
    console.error('Error refreshing token with Cognito:', error);
    return null; // Return null if the refresh fails
  }
};

export const signOutUser = async () => {
  try {
    await Auth.signOut(); // This will sign out the user from Cognito

    // After calling signOut, the user’s session will be invalid, and they’ll be signed out from all devices.

    // Handle successful logout, e.g., navigating to login screen, clearing state
  } catch (error) {
    // Handle errors, e.g., showing an alert to the user
    console.error('Error signing out:', error);
  }
};


export const makeAuthenticatedRequest = async (request, requiresTokenRefresh = true) => {
  try {
    // Attempt the original request
    let response = await request();
    return response;
  } catch (error) {
    console.log(`Error status: ${error.response?.status}`)
    // Check if the error is due to a 401 Unauthorized response
    if (error.response?.status === 401 && requiresTokenRefresh) {
      console.log('Attempting to refresh token...');
      // Refresh the token
      const newAccessToken = await refreshToken(); // Your refreshToken function
      if (newAccessToken) {
        // Retry the original request with the new access token
        return request(newAccessToken);
      }
    }
    // If token refresh is not required or it fails, re-throw the error
    throw error;
  }
};


const authenticatedAxiosCall = async (method, url, data = null, config = {}) => {
  try {
    let authConfig = { ...config };

    try {
      const session = await Auth.currentSession();
      const accessToken = session.getIdToken().getJwtToken();

      // Only add the Authorization header if we successfully get an access token
      authConfig = {
        ...authConfig,
        headers: {
          ...authConfig.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
    } catch (userNotLoggedInError) {
      // If there's no session, proceed without adding the Authorization header
      console.log('No user session found, proceeding without authentication.', userNotLoggedInError);
    }

    // Depending on the method, make the call with Axios
    switch (method.toLowerCase()) {
      case 'get':
        return axios.get(url, authConfig);
      case 'post':
        return axios.post(url, data, authConfig);
      case 'put':
        return axios.put(url, data, authConfig);
      case 'patch':
        return axios.patch(url, data, authConfig);
      case 'delete':
        return axios.delete(url, authConfig);
      default:
        throw new Error(`Method ${method} not supported`);
    }
  } catch (error) {
    // Log and re-throw the error for the caller to handle
    console.error(`Error making ${method} request to ${url}:`, error);
    throw error;
  }
};

export {authenticatedAxiosCall};
