import React from 'react';
import {Alert, Linking, Platform} from 'react-native';
import {useSubscription} from '../../hooks/useSubscription';
import {createCustomerPortalSession} from '../../utils/api/stripeService';
import SettingLinkButton from '../SettingLinkButton';
import alert from "../../utils/alert";
import * as RNIap from 'react-native-iap';


const ManageSubscriptionButton = () => {
  const { data: subscription, isSuccess } = useSubscription();

  const handleManageBilling = async () => {
    try {
      const response = await createCustomerPortalSession();
      const { url } = response.data;
      await Linking.openURL(url);
    } catch (error) {
      console.error('Error opening Stripe Customer Portal:', error);
    }
  };

  const handleManageSubscriptionsIAP = async () => {
    try {
      await RNIap.IapIosSk2.showManageSubscriptions();
    } catch (error) {
      console.error('Error opening iOS subscription settings:', error);
      alert(
        'Error',
        'Unable to open subscription management settings. Please try again later.',
        [{ text: 'OK' }]
      );
    }
  };

  const showPlatformAlert = () => {
    alert(
      'Manage Subscription',
      'You have subscribed with Apple. Please manage your subscription through your Apple account or device.',
      [{ text: 'OK' }]
    );
  };

  const showInternalSubscriptionAlert = () => {
    alert(
      'Internal Subscription',
      'You have an internal subscription. Changes can only be made by the administrator. Please contact support@fabyl.ai if you need to make changes.',
      [{text: 'OK'}]
    );
  };

  if (!isSuccess || !subscription) {
    return null; // No subscription info, or query not successful
  }

  const { platform } = subscription;
  console.log(`Platform: ${Platform.OS}, Subscription platform: ${platform}`);

  if (platform === 'internal') {
    return <SettingLinkButton title="Manage Subscription" onPress={showInternalSubscriptionAlert} />;
  }

  if (Platform.OS === 'ios') {
    if (platform === 'stripe') {
      return <SettingLinkButton title="Manage Subscription" onPress={handleManageBilling} />;
    } else if (platform === 'iap') {
      return <SettingLinkButton title="Manage Subscription" onPress={handleManageSubscriptionsIAP} />;
    }
  } else if (Platform.OS === 'web') {
    if (platform === 'iap') {
      return <SettingLinkButton title="Manage Subscription" onPress={showPlatformAlert} />;
    } else if (platform === 'stripe') {
      return <SettingLinkButton title="Manage Subscription" onPress={handleManageBilling} />;
    }
  }

  return null; // In case none of the conditions are met
};

export default ManageSubscriptionButton;
