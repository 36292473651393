import { useContext } from 'react';
import { useQuery } from 'react-query';
import {checkIsFriend} from "../utils/api/userService";
import { AuthContext } from '../contexts/AuthContext';

export const useFriendStatus = (profileUuid) => {
  const { isAuthenticated } = useContext(AuthContext);

  const result = useQuery(
    ['friendStatus', profileUuid],
    () => checkIsFriend(profileUuid),
    {
      enabled: !!profileUuid && isAuthenticated,
      select: (data) => ({
        isFriend: data?.is_friend ?? false,
        hasRequestSent: data?.has_request_sent ?? false,
        hasRequestReceived: data?.has_request_received ?? false,
      }),
      // refetchOnWindowFocus: true,
      // staleTime: 0,
    }
  );

  return result;
};
