import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const SvgProfileWhite = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    viewBox="0 0 34 34"
    fill="none"
    {...props}
  >
    <Circle cx={16.971} cy={18} r={14} fill="#4681F5" />
    <Path fill="#4681F5" d="M16.971 1.03 33.941 18l-16.97 16.97L.001 18z" />
    <Path fill="#FFF" d="M11 21a5 5 0 0 1 5-5h2a5 5 0 0 1 5 5v6H11z" />
    <Circle cx={17} cy={12} r={4.5} fill="#FFF" stroke="#4681F5" />
  </Svg>
);
export default SvgProfileWhite;
