import React from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import SvgFabylHeader from "../assets/images/FabylHeader";
import theme from "../styles/theme";


const SignUpScreen = () => {
  const navigation = useNavigation();

  const handleSignUpWithEmailPress = () => {
    navigation.navigate('EmailSignUpScreen');
  };

  return (
    <View style={styles.container}>
      <SvgFabylHeader style={styles.header} />
      <TouchableOpacity style={styles.button} onPress={handleSignUpWithEmailPress}>
        <Text style={styles.buttonText}>Sign Up with Email</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacings.medium,
    backgroundColor: theme.colors.beige,
  },
  header: {
    marginBottom: 30, // Add space below the header
    width: 200, // Adjust the size as needed
    height: 100, // Adjust the size as needed
    resizeMode: 'contain', // Ensure the image is scaled correctly
  },
  button: {
    marginBottom: 20,
    width: '80%', // Adjust width as needed
    maxWidth: 300,
    paddingVertical: 15, // Increase vertical padding for bigger button
    paddingHorizontal: theme.spacings.small, // Increase horizontal padding for wider button
    backgroundColor: theme.colors.bluePrimary, // Example button color, adjust as needed
    borderRadius: 10, // Adjust for rounded corners
  },
  buttonText: {
    textAlign: 'center',
    color: '#fff', // Adjust text color as needed
    fontWeight: 'bold', // Make text bold
    fontSize: 16, // Adjust font size as needed
  },
});

export default SignUpScreen;
