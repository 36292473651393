import React, {memo} from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Image } from "expo-image";
import theme from "../styles/theme";
import {useMinimalProfileData} from "../hooks/useMinimalProfileData";

const SkeletonImage = ({ username, size=50 }) => (
  <View style={[styles.skeletonThumbnail, { width: size, height: size, borderRadius: size / 2 }]}>
    <Text style={[styles.skeletonText, {fontSize: size / 2}]}>{username ? username.charAt(0).toUpperCase() : ''}</Text>
  </View>
);

const UserCircle = memo(({ profile_uuid, size = 50 }) => {
  const { data: user, isLoading, isError } = useMinimalProfileData(profile_uuid);
  // log the user data as json
  console.log(JSON.stringify(user));

  return (
    <View>
      {user?.featured_card ? (
        <Image source={{ uri: user?.featured_card.thumbnail }} style={[styles.cardThumbnail,  { width: size, height: size, borderRadius: size / 2 }]} />
      ) : (
        <SkeletonImage username={user?.preferred_username} size={size} />
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  cardThumbnail: {
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.gray,
  },
  resultItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    color: theme.colors.bluePrimary,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.medium,
  },
  skeletonThumbnail: {
    backgroundColor: theme.colors.verylightgray,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: StyleSheet.hairlineWidth,
  },
  skeletonText: {
    color: theme.colors.white,
    fontWeight: 'bold',
  },
});

export default UserCircle;
