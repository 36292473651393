import {useInfiniteQuery, useQueryClient} from 'react-query';
import { fetchCards } from "../utils/api/cardsService";
import API_ENDPOINTS from "../apiEndpoints";


export const fetchInitialRandomCards = async () => {
  // This should directly call fetchCards with the initial endpoint
  const result = await fetchCards(API_ENDPOINTS.RANDOM_CARDS);

  // fetchCards already returns the correct structure, so we just return it
  return result;
};


function useCards(endpoint) {
  const queryKey = ['cards', endpoint];
  const queryClient = useQueryClient();

  const fetchFunction = ({ pageParam = endpoint }) => {
    // Only perform the fetch if endpoint is not null
    if (endpoint) {
      return fetchCards(pageParam);
    } else {
      // Return a resolved promise with an empty structure to maintain consistency
      return Promise.resolve({ cards: [], nextPageURL: null });
    }
  };

  const {
    data,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    status,
    refetch,
  } = useInfiniteQuery(
    ['cards', endpoint],
    fetchFunction,
    {
      getNextPageParam: (lastPage) => lastPage.nextPageURL || undefined,
      enabled: !!endpoint, // Only run the query if endpoint is not null
      refetchOnWindowFocus: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      initialData: () => {
        const prefetchedData = queryClient.getQueryData(queryKey);
        return prefetchedData ? prefetchedData : undefined;
      },
    }
  );
  const loadingCards = status === 'loading' || isFetching || isRefetching;

  const cards = data?.pages?.flatMap(page => page.cards) ?? [];

  return {
    cards,
    nextPageURL: data?.pages?.[data.pages.length - 1]?.nextPageURL,
    // loadingCards: status === 'loading',
    loadingCards: loadingCards,
    fetchMoreCards: () => {
      if (hasNextPage) fetchNextPage();
    },
    handleRefresh: refetch,
  };
}

export default useCards;
