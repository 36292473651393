import React, { useEffect, useRef } from 'react';
import { View, Animated, Easing, StyleSheet, Text } from 'react-native';
import theme, {moderateScaleSize} from "../styles/theme";
import SvgSpur from "../assets/images/Spur";
import {ScaledSheet} from "react-native-size-matters";


const BouncingSpurs = ({ isAnimating, progress }) => {
  const spur1 = useRef(new Animated.Value(0)).current;
  const spur2 = useRef(new Animated.Value(0)).current;
  const spur3 = useRef(new Animated.Value(0)).current;

  const startBounceAnimation = (spur) => {
    const bounceUp1 = Animated.timing(spur, {
      toValue: -0.8, // Corresponds to -1.5vh approximately
      duration: 200, // 40% of the animation duration if 1s is the total
      easing: Easing.in(Easing.ease),
      useNativeDriver: true,
    });

    const bounceDown = Animated.timing(spur, {
      toValue: -0.4, // Corresponds to -1vh approximately
      duration: 100, // 20% of the animation duration
      easing: Easing.out(Easing.ease),
      useNativeDriver: true,
    });

    const bounceUp2 = Animated.timing(spur, {
      toValue: 0,
      duration: 300, // Back to the initial position
      easing: Easing.in(Easing.ease),
      useNativeDriver: true,
    });

    Animated.loop(
      Animated.sequence([
        bounceUp1,
        bounceDown,
        bounceUp2,
      ]),
      {
        iterations: -1,
      }
    ).start();
  };

  useEffect(() => {
    if (isAnimating) {
      startBounceAnimation(spur1);
      setTimeout(() => startBounceAnimation(spur2), 200); // Delay by 20% of the animation duration
      setTimeout(() => startBounceAnimation(spur3), 400); // Delay by 40% of the animation duration
    } else {
      // Stop the animation and reset the value if not animating
      [spur1, spur2, spur3].forEach(spur => {
        spur.stopAnimation();
        spur.setValue(0);
      });
    }
  }, [isAnimating, spur1, spur2, spur3]);

const getSpur = (colorIndex) => {
  // console.log('progress', progress);
  const size = { width: moderateScaleSize(35), height: moderateScaleSize(35) }; // Set the size for the SVGs

  const colors = [theme.colors.redPrimary, theme.colors.bluePrimary, theme.colors.yellowPrimary];

  return (
    <SvgSpur
      {...size}
      color={colors[colorIndex - 1]}
      filled={progress >= colorIndex}
    />
  );
};


  const ballStyle = (spur) => ({
    transform: [{
      translateY: spur.interpolate({
        inputRange: [0, 1],
        outputRange: [0, -20],
      })
    }],
  });

  return (
    <View style={styles.container}>
      <Animated.View
        style={[styles.spurContainer, isAnimating ? ballStyle(spur1) : {}]}>
        {getSpur(1)}
      </Animated.View>
      <Animated.View
        style={[styles.spurContainer, isAnimating ? ballStyle(spur2) : {}]}>
        {getSpur(2)}
      </Animated.View>
      <Animated.View
        style={[styles.spurContainer, isAnimating ? ballStyle(spur3) : {}]}>
        {getSpur(3)}
      </Animated.View>
    </View>
  );
};


const styles = ScaledSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center', // Center children horizontally
    // height: 30,
    width: '30%', // Ensure the container takes full width
    maxWidth: 120, // Limit the width of the container
    marginVertical: '5@mvs0.2', // Should probably match moderateScaleSize
  },
  spurContainer: {
    flex: 1, // Give each spur equal space
    alignItems: 'center', // Center each spur vertically in its container
  },
});

export default BouncingSpurs;
