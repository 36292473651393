import React, { useState, useEffect } from 'react';
import { View, TextInput, Text, StyleSheet } from 'react-native';
import theme from "../styles/theme";


const PasswordInputBasic = ({ password, setPassword, placeholder="Password" }) => {
  return (
    <View style={styles.container}>
      <TextInput
        style={styles.input}
        placeholder={placeholder}
        value={password}
        onChangeText={setPassword}
        secureTextEntry
      />
    </View>
  );
}

const PasswordInputWithConditions = ({ password, setPassword, placeholder="Password" }) => {
  const [conditions, setConditions] = useState({
    minLength: false,
    hasNumber: false,
    hasUpper: false,
    hasLower: false,
    hasSpecialChar: false,
  });

  // Descriptive texts for each condition
  const conditionDescriptions = {
    minLength: "Contains at least 8 characters",
    hasNumber: "Contains at least 1 number",
    hasUpper: "Contains at least 1 uppercase letter",
    hasLower: "Contains at least 1 lowercase letter",
    hasSpecialChar: "Contains at least 1 special character",
  };

  useEffect(() => {
    evaluatePassword(password);
  }, [password]);

  const evaluatePassword = (password) => {
    setConditions({
      minLength: password.length >= 8,
      hasNumber: /\d/.test(password),
      hasUpper: /[A-Z]/.test(password),
      hasLower: /[a-z]/.test(password),
      // Updated regex pattern to include all Cognito special characters
      hasSpecialChar: /[\^$*.()\[\]{}?!"@#%&\/\\,><':;|_~`\=\+\-]/.test(password),
    });
  };

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.input}
        placeholder={placeholder}
        value={password}
        onChangeText={setPassword}
        secureTextEntry
      />
      <View style={styles.conditionsList}>
        {Object.keys(conditions).map((key) => (
          <View key={key} style={styles.conditionItem}>
            <Text style={[styles.conditionText, conditions[key] ? styles.conditionMet : styles.conditionUnmet]}>
              {conditionDescriptions[key]}
            </Text>
            <Text style={conditions[key] ? styles.conditionMet : styles.conditionUnmet}>
              {conditions[key] ? '✓' : '✗'}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: theme.spacings.medium,
  },
  input: {
    height: 50,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    paddingHorizontal: 10,
    marginBottom: 15,
    fontSize: 16,
  },
  conditionsList: {
    alignSelf: 'stretch',
    marginHorizontal: theme.spacings.large
  },
  conditionItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  conditionText: {
    flex: 1, // Take up as much space as possible for the text
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
  },
  conditionMet: {
    color: 'green',
    marginLeft: 4, // Ensure some space between the text and symbol
  },
  conditionUnmet: {
    color: theme.colors.redSecondary,
    marginLeft: 4, // Ensure some space between the text and symbol
  },
});

export { PasswordInputBasic, PasswordInputWithConditions};
export default PasswordInputWithConditions;

