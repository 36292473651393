// ProfileContext.js
import React, { createContext, useContext, useCallback } from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import { fetchProfileData, updateTutorialSeen, updateDisclaimerAcknowledged } from "../utils/api/userService";
import {shareContent} from "../utils/share";
import {AuthContext} from "./AuthContext";

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const { user, isAuthenticated } = useContext(AuthContext);
  const userId = user?.attributes?.sub;
  const queryClient = useQueryClient();

  const {
    data: profileData,
    isLoading,
    error,
    refetch,
  } = useQuery(['profileData', userId], fetchProfileData, {
    enabled: isAuthenticated && !!userId,
  });

  const shareProfile = async () => {
    if (profileData && profileData.uuid) {
      await shareContent('profile', profileData.uuid);
    }
  };

  const tutorialMutation = useMutation(
    () => updateTutorialSeen(userId),
    {
      onMutate: async () => {
        // Cancel any outgoing refetches
        await queryClient.cancelQueries(['profileData', userId]);

        // Snapshot the previous value
        const previousProfileData = queryClient.getQueryData(['profileData', userId]);

        // Optimistically update to the new value
        queryClient.setQueryData(['profileData', userId], (oldProfileData) => ({
          ...oldProfileData,
          tutorial_seen: true,
        }));

        return { previousProfileData };
      },
      onError: (err, newProfileData, context) => {
        queryClient.setQueryData(['profileData', userId], context.previousProfileData);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['profileData', userId]);
      },
    }
  );


  const disclaimerMutation = useMutation(
    () => updateDisclaimerAcknowledged(userId), // Add the new API function
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['profileData', userId]);
        const previousProfileData = queryClient.getQueryData(['profileData', userId]);
        queryClient.setQueryData(['profileData', userId], (oldProfileData) => ({
          ...oldProfileData,
          disclaimer_acknowledged: true,
        }));
        return { previousProfileData };
      },
      onError: (err, newProfileData, context) => {
        console.log('Error updating disclaimer_acknowledged:', err)
        queryClient.setQueryData(['profileData', userId], context.previousProfileData);
      },
      onSettled: () => {
        console.log('Disclaimer update settled')
        console.log(`value of disclaimer_acknowledged: ${profileData.disclaimer_acknowledged}`)
        console.log(`json value of profileData: ${JSON.stringify(profileData)}`)
        queryClient.invalidateQueries(['profileData', userId]);
      },
    }
  );

  const markTutorialComplete = async (onSuccessCallback) => {
    await tutorialMutation.mutateAsync();
    onSuccessCallback();
  };

  const markDisclaimerAcknowledged = async () => {
    await disclaimerMutation.mutateAsync();
  };

  const contextValue = {
    profileData,
    isLoading,
    error,
    refetchProfileData: refetch,
    shareProfile,
    markTutorialComplete,
    markDisclaimerAcknowledged,
  };

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => useContext(ProfileContext);
