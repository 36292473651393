// withCustomPress.js
import React from 'react';
import UsersList from '../lists/UsersList'; // Adjust the import path as necessary

const withCustomPress = (Component, customPress) => {
  return (props) => (
    <Component
      {...props}
      onPressItem={customPress}
    />
  );
};

export default withCustomPress;
