import React, { useContext, useEffect } from 'react';
import { View } from 'react-native';
import { AuthContext } from '../../contexts/AuthContext';
import theme from "../../styles/theme";

const ProfileResolver = ({ route, navigation }) => {
  const { profile_uuid } = route.params; // Assuming the profile's UUID is passed as a parameter
  const { isAuthenticated } = useContext(AuthContext);

  const handlePrivateProfile = () => {
    // Navigate to private profile view
    navigation.navigate('Authenticated', {
      screen: 'Tabs',
      params: {
        screen: 'Home',
        params: {
          screen: 'UserProfile',
          params: { profile_uuid },
        },
      },
    });
  };

  const handlePublicProfile = () => {
    // Navigate to public profile view
    navigation.navigate('PublicUserProfile', { profile_uuid });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isAuthenticated) {
        console.log(`Navigating to authenticated UserProfile for profile ${profile_uuid}`);
        handlePrivateProfile();
      } else {
        console.log(`Navigating to public UserProfile for profile ${profile_uuid}`);
        handlePublicProfile();
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [isAuthenticated, navigation, profile_uuid]);

  return (
    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: theme.colors.beige}}>
      {/* Visual feedback or loading indicator can be placed here */}
    </View>
  );
};

export default ProfileResolver;
