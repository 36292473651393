import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import theme from '../styles/theme';

const SettingLinkButton = ({ title, onPress }) => (
  <TouchableOpacity style={styles.button} onPress={onPress}>
    <Text style={styles.buttonText}>{title}</Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  button: {
    padding: theme.spacings.small,
    borderRadius: theme.borderRadius.medium,
    alignItems: 'flex-start',
    marginBottom: theme.spacings.medium,
  },
  buttonText: {
    color: theme.colors.bluePrimary,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.large,
  },
});

export default SettingLinkButton;
