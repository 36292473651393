import { useInfiniteQuery } from 'react-query';
import { fetchWorlds } from "../utils/api/cardsService";


function useWorlds(endpoint) {
  const fetchFunction = ({ pageParam = endpoint }) => {
    // Only perform the fetch if endpoint is not null
    if (endpoint) {
      return fetchWorlds(pageParam); // Use fetchWorlds instead of fetchCards
    } else {
      // Return a resolved promise with an empty structure to maintain consistency
      return Promise.resolve({ worlds: [], nextPageURL: null });
    }
  };

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
    isRefetching,
  } = useInfiniteQuery(
    ['worlds', endpoint], // Change the query key to "worlds"
    fetchFunction,
    {
      getNextPageParam: (lastPage) => lastPage.nextPageURL || undefined,
      enabled: !!endpoint, // Only run the query if endpoint is not null
      // refetchOnWindowFocus: false, // Adjust based on your preference
      refetchOnWindowFocus: true,
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
    }
  );

  const worlds = data?.pages?.flatMap(page => page.worlds) ?? [];
  const loadingWorlds = status === 'loading' || isFetching || isRefetching;

  return {
    worlds,
    nextPageURL: data?.pages?.[data.pages.length - 1]?.nextPageURL,
    loadingWorlds: loadingWorlds,
    fetchMoreWorlds: () => {
      if (hasNextPage) fetchNextPage();
    },
    handleRefresh: refetch,
    isRefetching: isRefetching,
  };
}

export default useWorlds;
