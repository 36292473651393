import React, {useContext} from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import theme from "../../styles/theme";
import {useProfile} from "../../contexts/ProfileContext";

const CloseButton = ({navigationTarget}) => {
  const navigation = useNavigation();
  const { markTutorialComplete } = useProfile();
  console.log(`navigationTarget: ${navigationTarget}`);

  const onPress = async () => {
    if (navigationTarget) {
      navigation.replace(navigationTarget);
    } else {
      await markTutorialComplete(() => {
        navigation.replace('Authenticated', {
          screen: 'Tabs',
          params: {
            screen: 'Create',
            params: {
              screen: 'CreateScreen',
            },
          },
        });
      });
    }
  };

  return (
    <TouchableOpacity
      style={styles.closeButton}
      onPress={onPress}
      hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
    >
      <Text style={styles.closeButtonText}>X</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    top: theme.spacings.medium,
    right: theme.spacings.medium,
    zIndex: 100,
  },
  closeButtonText: {
    fontSize: 24,
    color: theme.colors.gray,
  },
});

export default CloseButton;
