import React from 'react';
import {Linking, ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import theme from '../styles/theme';
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import SvgRobot from "../assets/images/characters/Robot";
import SvgSpacechef from "../assets/images/characters/Spacechef";
import SvgBillybob from "../assets/images/characters/Billybob";

const PRIVACY_POLICY_URL = process.env.EXPO_PUBLIC_PRIVACY_POLICY_URL;
const TERMS_OF_SERVICE_URL = process.env.EXPO_PUBLIC_TERMS_OF_USE_URL;
const FAQ_URL = process.env.EXPO_PUBLIC_FAQ_URL;
const DISCORD_URL = process.env.EXPO_PUBLIC_DISCORD_INVITE_URL;
const SUPPORT_EMAIL = process.env.EXPO_PUBLIC_SUPPORT_EMAIL;

const SupportScreen = () => {
  const isLargeScreen = useIsLargeScreen(theme.breakpoints.large);

  const openURL = (url) => {
    Linking.canOpenURL(url)
    .then((supported) => {
      if (!supported) {
        console.log("Can't handle URL: " + url);
      } else {
        return Linking.openURL(url);
      }
    })
    .catch((err) => console.error('An error occurred', err));
  };

  const handleEmailPress = () => {
    Linking.openURL(`mailto:${SUPPORT_EMAIL}`);
  };

  return (
    <ScrollView contentContainerStyle={styles.scrollViewContainer}>
      <View style={styles.container}>
        <View style={styles.topAreaContainer}>
          {isLargeScreen && (
            <View style={styles.charactersContainer}>
              <SvgBillybob style={styles.billyBobImage}/>
            </View>
          )}

          <View style={styles.subContainer}>
            <View style={styles.headerAndPhrase}>
              <Text style={styles.title}>SUPPORT</Text>
            </View>
          </View>

          {isLargeScreen && (
            <View style={styles.charactersContainer}>
              <SvgRobot style={styles.image} />
            </View>
          )}
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Frequently Asked Questions</Text>
          <Text style={styles.sectionText}>
            Check out our <Text style={styles.link} onPress={() => openURL(FAQ_URL)}>FAQ</Text> for answers to common questions about using the app, features, and troubleshooting. You can also find answers on the fabyl <Text style={styles.link} onPress={() => openURL(DISCORD_URL)}>discord</Text>.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Contact Support</Text>
          <Text style={styles.sectionText}>
            If you need further assistance or have any inquiries, please connect with us on <Text style={styles.link} onPress={() => openURL(DISCORD_URL)}>discord</Text> in the #support channel, or contact our support team at{' '}
            <Text style={styles.link} onPress={handleEmailPress}>{SUPPORT_EMAIL}</Text>.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Community</Text>
          <Text style={styles.sectionText}>
            Join our community on <Text style={styles.link} onPress={() => openURL(DISCORD_URL)}>discord</Text> to connect with other users, share your experiences, and get the latest updates.
          </Text>
        </View>

        <View style={styles.linksContainer}>
          <TouchableOpacity onPress={() => openURL(PRIVACY_POLICY_URL)}>
            <Text style={styles.footerLink}>Privacy Policy</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => openURL(TERMS_OF_SERVICE_URL)}>
            <Text style={styles.footerLink}>Terms of Use</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
};


const styles = StyleSheet.create({
  section: {
    backgroundColor: theme.colors.gray,
    padding: theme.spacings.large,
    borderRadius: theme.borderRadius.medium,
    marginVertical: theme.spacings.large,
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: 800,
  },
  sectionTitle: {
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.xbold,
    color: theme.colors.redPrimary,
    marginBottom: theme.spacings.small,
  },
  sectionText: {
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.beige,
    textAlign: 'left',
  },
  link: {
    color: theme.colors.lighterBlue,
    textDecorationLine: 'underline',
  },
  scrollViewContainer: {
    flexGrow: 1,
    backgroundColor: theme.colors.gray,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: theme.colors.gray,
    padding: theme.spacings.large,
  },
  topAreaContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: theme.colors.beige,
    width: '100%',
    borderRadius: theme.borderRadius.medium,
    justifyContent: 'space-around',
  },
  charactersContainer: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  billyBobImage: {
    width: 175,
    height: 350,
  },
  image: {
    width: 200,
    height: 350,
  },
  subContainer: {
    alignItems: 'center',
    paddingVertical: theme.spacings.xlarge,
  },
  headerAndPhrase: {
    backgroundColor: theme.colors.beige,
    marginVertical: theme.spacings.large,
    alignItems: 'center',
  },
  title: {
    fontSize: theme.fonts.sizes.banner,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.gray,
    marginBottom: theme.spacings.small,
  },
  buttonsContainer: {
    width: '100%',
    marginVertical: theme.spacings.large,
  },
  button: {
    marginBottom: theme.spacings.medium,
  },
  linksContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  footerLink: {
    color: theme.colors.yellowPrimary,
    fontSize: theme.fonts.sizes.medium,
    textDecorationLine: 'underline',
    marginVertical: theme.spacings.small,
    marginHorizontal: theme.spacings.medium,
  },
});

export default SupportScreen;
