import * as SecureStore from 'expo-secure-store';

export const saveToken = async (accessToken, refreshToken) => {
  await SecureStore.setItemAsync('userToken', accessToken);
  await SecureStore.setItemAsync('refreshToken', refreshToken);
};

export const getAccessToken = async () => {
  return await SecureStore.getItemAsync('userToken');
};

export const getRefreshToken = async () => {
  return await SecureStore.getItemAsync('refreshToken');
};

export const deleteTokens = async () => {
  await SecureStore.deleteItemAsync('userToken');
  await SecureStore.deleteItemAsync('refreshToken');
};
