import {DrawerContentScrollView, DrawerItem} from "@react-navigation/drawer";
import { View, StyleSheet } from "react-native";
import SvgFabylHeader from "../../assets/images/FabylHeader";
import theme from "../../styles/theme";
import SvgHomeWhite from "../../assets/images/HomeWhite";
import SvgHome from "../../assets/images/Home";
import SvgGenerateWhite from "../../assets/images/GenerateWhite";
import SvgGenerate from "../../assets/images/Generate";
import SvgProfileWhite from "../../assets/images/ProfileWhite";
import SvgProfile from "../../assets/images/Profile";
import React from "react";
import {MaterialIcons, Ionicons} from "@expo/vector-icons";
import {HEADER_BUTTON_SIZE} from "../../styles/globalStyles";
import NotificationsButton from "../NotificationsButton";

const NotificationIconActive = () => <NotificationsButton isActive={true} iconColor={theme.colors.white} />
const NotificationsIconInactive = () => <NotificationsButton isActive={false} iconColor={theme.colors.white} />

const SearchIconActive = () => <Ionicons name="search" size={HEADER_BUTTON_SIZE} color={theme.colors.white} />
const SearchIconInactive = () => <Ionicons name="search-outline" size={HEADER_BUTTON_SIZE} color={theme.colors.white} />

const SettingsIconInactive = () => <Ionicons name="settings-outline" size={HEADER_BUTTON_SIZE} color={theme.colors.white} />
const SettingsIconActive = () => <Ionicons name="settings" size={HEADER_BUTTON_SIZE} color={theme.colors.white} />
const AllCardsIconInactive = () => <Ionicons name="earth-outline" size={HEADER_BUTTON_SIZE} color={theme.colors.white} />
const AllCardsIconActive = () => <Ionicons name="earth" size={HEADER_BUTTON_SIZE} color={theme.colors.white} />

function CustomDrawerContent(props) {
  const { state, navigation } = props;

  const isActiveRoute = (routeName, nestedRouteName) => {
    const route = state.routes[state.index];

    if (nestedRouteName) {
      if (route.name === routeName) {
        const nestedRoute = route.state?.routes[route.state.index];
        return nestedRoute?.name === nestedRouteName;
      }
      return false;
    }

    return route.name === routeName;
  };


  const navigateToInitialScreen = (stackName, initialRouteName) => {
    navigation.reset({
      index: 0,
      routes: [{ name: stackName, state: { routes: [{ name: initialRouteName }] } }],
    });
  };

  const handleAllCards = () => {
    navigation.navigate('Authenticated', {
      screen: 'Tabs',
      params: {
        screen: 'Home',
        params: {
          screen: 'AllCards',
        },
      },
    });
  }

  return (
    <DrawerContentScrollView {...props}>
      <View style={styles.headerIconContainer}>
        <SvgFabylHeader style={{ transform: [{ scale: 0.7 }] }} fillColor={theme.colors.white}/>
      </View>
      <DrawerItem
        label="Home"
        onPress={() => navigateToInitialScreen('Home', 'HomeScreen')}
        labelStyle={isActiveRoute('Home') && !isActiveRoute('Home', 'AllCards') ? styles.activeLabel : styles.inactiveLabel}
        icon={() => isActiveRoute('Home') && !isActiveRoute('Home', 'AllCards') ? <SvgHomeWhite /> : <SvgHome />}
      />
      <DrawerItem
        label="Create"
        onPress={() => navigateToInitialScreen('Create', 'CreateScreen')}
        labelStyle={isActiveRoute('Create') ? styles.activeLabel : styles.inactiveLabel}
        icon={() => isActiveRoute('Create') ? <SvgGenerateWhite /> : <SvgGenerate />}
      />
      <DrawerItem
        label="Profile"
        onPress={() => navigateToInitialScreen('Profile', 'ProfileScreen')}
        labelStyle={isActiveRoute('Profile') ? styles.activeLabel : styles.inactiveLabel}
        icon={() => isActiveRoute('Profile') ? <SvgProfileWhite /> : <SvgProfile />}
      />
      <View style={{marginVertical: theme.spacings.medium}}></View>
      <DrawerItem
        label="Search"
        onPress={() => navigateToInitialScreen('Search', 'SearchScreen')}
        labelStyle={isActiveRoute('Search') ? styles.activeLabel : styles.inactiveLabel}
        icon={() => isActiveRoute('Search') ? <SearchIconActive /> : <SearchIconInactive />}
      />
      <DrawerItem
        label="Messages"
        onPress={() => navigateToInitialScreen('Notifications', 'NotificationsScreen')}
        labelStyle={isActiveRoute('Notifications') ? styles.activeLabel : styles.inactiveLabel}
        icon={() => isActiveRoute('Notifications') ? <NotificationIconActive /> : <NotificationsIconInactive />}
      />
      <DrawerItem
        label="Settings"
        onPress={() => navigation.navigate('Settings')}
        labelStyle={isActiveRoute('Settings') ? styles.activeLabel : styles.inactiveLabel}
        icon={() => isActiveRoute('Settings') ? <SettingsIconActive /> : <SettingsIconInactive />}
      />
      <DrawerItem
        label="All Cards"
        onPress={handleAllCards}
        labelStyle={isActiveRoute("Home", 'AllCards') ? styles.activeLabel : styles.inactiveLabel}
        icon={() => isActiveRoute("Home", 'AllCards') ? <AllCardsIconActive /> : <AllCardsIconInactive />}
      />
    </DrawerContentScrollView>
  );
}

const styles = StyleSheet.create({
  headerIconContainer: {
    marginVertical: theme.spacings.large,
    marginLeft: theme.spacings.small,
    // alignSelf: 'center',
  },
  activeLabel: {
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.white, // Active label color
    fontSize: theme.fonts.sizes.large,
  },
  inactiveLabel: {
    color: theme.colors.white, // Inactive label color
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.large,
  },
});

export default CustomDrawerContent;

