import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import theme from '../styles/theme';

const PrimaryButton = ({ title, onPress }) => (
  <TouchableOpacity style={styles.button} onPress={onPress}>
    <Text style={styles.buttonText}>{title}</Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  button: {
    backgroundColor: theme.colors.bluePrimary,
    padding: theme.scaledSpacings.small,
    borderRadius: theme.borderRadius.medium,
    alignItems: 'center',
    marginBottom: theme.scaledSpacings.medium,
  },
  buttonText: {
    color: theme.colors.white,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.scaledSizes.medium,
  },
});

export default PrimaryButton;
