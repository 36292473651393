import React, {useState, useContext, useRef, useEffect} from 'react';
import { View, TextInput, StyleSheet, Text, Alert, KeyboardAvoidingView, Platform, ScrollView } from 'react-native';
import { AuthContext } from '../contexts/AuthContext';
import PrimaryButton from "../components/PrimaryButton";
import theme from "../styles/theme";
import styles from "../styles/Settings.styles";
import alert from "../utils/alert";

const ForgotPasswordScreen = ({ navigation }) => {
  const [username, setUsername] = useState('');
  const { forgotPassword } = useContext(AuthContext);

  const inputRef = useRef(null);

  useEffect(() => {
    // Automatically focus the TextInput when the screen is loaded
    inputRef.current.focus();
  }, []);

  const handleForgotPassword = async () => {
    try {
      const data = await forgotPassword(username);
      alert(
        "Verification Code Sent",
        `A verification code has been sent to ${data.CodeDeliveryDetails.Destination}.`,
        [
          { text: 'OK', onPress: () => navigation.navigate('OtpScreen', {
            username, // Pass the username to OtpScreen
            verificationType: 'resetPassword' // Indicate the type of verification
          }) }
        ]
      );
    } catch (error) {
      alert("Password Reset Failed", error.message);
    }
  };

  return (
      <View style={styles.container}>
        <Text style={styles.title}>FORGOT PASSWORD</Text>
        <Text style={styles.currentContact}>
          Enter your email or phone number associated with this account.
        </Text>
        <TextInput
          ref={inputRef}
          style={styles.input}
          value={username}
          onChangeText={setUsername}
          placeholder="email or phone number"
          keyboardType="email-address"
          autoCapitalize="none"
          placeholderTextColor={theme.colors.gray}
        />
        <PrimaryButton title="SEND VERIFICATION CODE" onPress={handleForgotPassword} />
      </View>
  );
};


export default ForgotPasswordScreen;
