import React, {useRef, useState, useEffect} from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from 'react-native';
import theme from '../styles/theme';


const SearchComponent = ({facets, initialFacetKey = 'allCards', placeholder="Search...", immediateDisplay=false}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeQuery, setActiveQuery] = useState('');
  const [selectedFacet, setSelectedFacet] = useState(initialFacetKey);
  const [loadTrigger, setLoadTrigger] = useState(0);
  // New state to trigger load

  const inputRef = useRef(null); // Ref for the TextInput

  const SelectedFacetComponent = facets.find(facet => facet.key === selectedFacet)?.component;

  useEffect(() => {
    if (facets.length === 1) {
      setSelectedFacet(facets[0].key);
    }
  }, [facets]);

  const handleSearch = () => {
        // if (searchQuery.trim().length === 0) {
      // Optionally, you can add an alert or some visual feedback to inform the user
      // console.log("Search query cannot be empty.");
      // return; // Exit the function if the search query is empty
    // }

    console.log(`Searching for ${searchQuery} in ${selectedFacet}`);
    setActiveQuery(searchQuery); // Set active query to the current input value
    setLoadTrigger(Date.now());
  };

  useEffect(() => {
    console.log('Triggering initial search for the facet:', initialFacetKey);
    const initialFetchFunction = facets.find(facet => facet.key === initialFacetKey)?.fetchFunction;
    if (initialFetchFunction) {
      initialFetchFunction('', 1); // Assuming the initial query is empty and page is 1
    }
    handleSearch();
  }, [initialFacetKey, facets, selectedFacet]);

  const clearSearch = () => {
    setSearchQuery('');
    inputRef.current.focus(); // Focus on the text input after clearing
  };

  return (
    <View style={styles.container}>
      <View style={styles.searchBarContainer}>
        <View style={styles.inputContainer}>
          <TextInput
            ref={inputRef}
            style={styles.searchInput}
            onChangeText={setSearchQuery}
            value={searchQuery}
            onSubmitEditing={handleSearch}
            autoCapitalize={'none'}
            returnKeyType="search"
            placeholder={placeholder}
          />
          {searchQuery.length > 0 && (
            <TouchableOpacity
              style={styles.clearButton}
              onPress={clearSearch}
              hitSlop={{
                top: theme.spacings.small,
                bottom: theme.spacings.small,
                left: theme.spacings.small,
                right: theme.spacings.small,
            }}
            >
              <Text style={styles.clearButtonText}>X</Text>
            </TouchableOpacity>
          )}
        </View>
        <TouchableOpacity style={styles.searchButton} onPress={handleSearch}>
          <Text style={styles.searchButtonText}>Search</Text>
        </TouchableOpacity>
      </View>

      {facets.length > 1 && (
        <View>
          <ScrollView horizontal style={styles.facetsContainer} showsHorizontalScrollIndicator={false}>
            {facets.map((facet) => (
              <TouchableOpacity
                key={facet.key}
                style={[styles.facet, selectedFacet === facet.key && styles.selectedFacet]}
                onPress={() => setSelectedFacet(facet.key)}
              >
                <Text style={[
                  styles.facetText,
                  selectedFacet === facet.key && styles.selectedFacetText
                ]}>
                  {facet.label}
                </Text>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>
      )}

      <View style={styles.resultsContainer}>
        {SelectedFacetComponent && (
          <SelectedFacetComponent
            query={searchQuery}
            fetchResults={facets.find(facet => facet.key === selectedFacet)?.fetchFunction}
            triggerLoad={loadTrigger}
          />
        )}
      </View>
    </View>
  );
};

const INPUT_BORDER_WIDTH = 1.5;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: theme.spacings.medium,
  },
  searchBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacings.small,
  },
  searchInput: {
    borderTopLeftRadius: theme.borderRadius.medium,
    borderBottomLeftRadius: theme.borderRadius.medium,
    backgroundColor: theme.colors.white,
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.medium,
    paddingHorizontal: theme.spacings.medium,
    borderColor: theme.colors.black,
    marginRight: theme.spacings.xsmall,
    // outlineWidth: 0,
    width: '100%',
  },
  searchButton: {
    flex: 1,
    backgroundColor: theme.colors.bluePrimary,
    borderTopRightRadius: theme.borderRadius.medium,
    borderBottomRightRadius: theme.borderRadius.medium,
    borderRightWidth: INPUT_BORDER_WIDTH,
    borderTopWidth: INPUT_BORDER_WIDTH,
    borderBottomWidth: INPUT_BORDER_WIDTH,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchButtonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
  },
  inputContainer: {
    flex: 3,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    height: 50,
    borderTopLeftRadius: theme.borderRadius.medium,
    borderBottomLeftRadius: theme.borderRadius.medium,
    borderWidth: INPUT_BORDER_WIDTH,
    borderColor: theme.colors.black,
    paddingHorizontal: theme.spacings.xsmall,
  },
  clearButton: {
    alignSelf: 'center',
    position: 'absolute',
    right: theme.spacings.xsmall,
    padding: theme.spacings.small,
  },
  clearButtonText: {
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.lightgray,
  },
  facetsContainer: {
    flexDirection: 'row',
    marginBottom: theme.spacings.small,
  },
  facet: {
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    borderRadius: theme.borderRadius.small,
    borderWidth: 1,
    marginRight: theme.spacings.small,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedFacet: {
    backgroundColor: theme.colors.bluePrimary,
  },
  facetText: {
    color: theme.colors.black,
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.regular,
  },
  selectedFacetText: {
    color: theme.colors.white,
  },
  resultsContainer: {
    flex: 1,
  },
});

export default SearchComponent;
