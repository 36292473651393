import React, { useState, useEffect } from 'react';
import { View, Alert, TextInput, Button, StyleSheet, Text } from 'react-native';
import CountryPicker from 'react-native-country-picker-modal';
import { useNavigation } from '@react-navigation/native';
import { signUpWithPhone } from '../utils/cognitoService';
import SignUpForm from '../components/SignUpForm';
import alert from "../utils/alert";

const PhoneSignUpScreen = () => {
  const navigation = useNavigation();
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [withCountryCode, setWithCountryCode] = useState('');
  const [countryCallingCode, setCountryCallingCode] = useState('');

  useEffect(() => {
    // Whenever the countryCallingCode or the local phone part changes,
    // update the withCountryCode state
    if (phone && countryCallingCode) {
      setWithCountryCode(`+${countryCallingCode}${phone}`);
    }
  }, [countryCallingCode, phone]);


  const handleSignUpPress = async () => {
    if (!withCountryCode) {
      alert('Error', 'Please make sure to select your country and enter your phone number.');
      return;
    }

    try {
      console.log(withCountryCode)
      const response = await signUpWithPhone({ phone: withCountryCode, password });
      navigation.navigate('OtpScreen', {
        username: withCountryCode,
        verificationType: 'signUp',
      });
    } catch (error) {
      console.log(error)
      alert('Error', 'An error occurred during sign up.');
    }
  };

  const phoneInputField = (
    <View style={styles.phoneInputGroup}>
      <CountryPicker
        withFilter
        withFlag
        withCountryNameButton
        withCallingCode
        withCallingCodeButton
        onSelect={(country) => {
          setCountryCode(country.cca2);
          setCountryCallingCode(country.callingCode[0]);
        }}
        countryCode={countryCode}
        translation='eng'
        containerButtonStyle={styles.countryPicker}
      />
      <TextInput
        style={styles.phoneInput}
        placeholder="Phone Number"
        value={phone}
        onChangeText={setPhone}
        keyboardType="phone-pad"
        autoCapitalize="none"
      />
    </View>
  );

  return (
    <SignUpForm
      inputField={phoneInputField}
      onSignUpPress={handleSignUpPress}
      password={password}
      setPassword={setPassword}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    backgroundColor: '#f9f9f9',
  },
  phoneInputContainer: {
    flexDirection: 'row',
    marginBottom: 15,
  },
  input: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    paddingHorizontal: 10,
    marginBottom: 15,
    fontSize: 16,
    height: 50,
  },
  phoneInputGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  countryPicker: {
    alignSelf: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#ddd',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    paddingHorizontal: 10,
    marginRight: 5, // Added to separate from the country code
    height: 50,
  },
  countryCodeText: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderColor: '#ddd',
    paddingHorizontal: 10,
    height: 50,
    lineHeight: 50, // Center the text vertically
    marginRight: 5, // Added to separate from the phone input
  },
  phoneInput: {
    flex: 1,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderColor: '#ddd',
    paddingHorizontal: 10,
    fontSize: 16,
    height: 50,
  },
});

export default PhoneSignUpScreen;
