import React, { useState, useEffect } from 'react';
import { TouchableOpacity, Text, StyleSheet, Animated, Easing, View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import theme from "../../styles/theme";
import useIsLargeScreen from "../../hooks/useIsLargeScreen";

const RefreshButton = ({ onRefresh, loading: externalLoading }) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const isLargeScreen = useIsLargeScreen();
  const spinValue = new Animated.Value(0);

  useEffect(() => {
    if (isRefreshing || externalLoading) {
      Animated.loop(
        Animated.timing(spinValue, {
          toValue: 1,
          duration: 1000,
          easing: Easing.linear,
          useNativeDriver: true,
        })
      ).start();
    } else {
      spinValue.setValue(0);
    }
  }, [isRefreshing, externalLoading]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      await onRefresh();
    } finally {
      setIsRefreshing(false);
    }
  };

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  const loading = isRefreshing || externalLoading;

  return (
    <TouchableOpacity onPress={handleRefresh} disabled={loading} style={styles.refreshButton}>
      <Animated.View style={{ transform: [{ rotate: spin }] }}>
        <Ionicons name="refresh" size={24} color={theme.colors.bluePrimary} />
      </Animated.View>
      {isLargeScreen && (
        <View style={styles.textContainer}>
          <Text style={styles.refreshText}>{loading ? 'Refreshing...' : 'Refresh'}</Text>
        </View>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  refreshButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  textContainer: {
    width: 80, // Adjust this value based on your needs
    marginLeft: 5,
  },
  refreshText: {
    color: theme.colors.bluePrimary,
    fontFamily: theme.fonts.family.medium,
  },
});

export default RefreshButton;
