import React, {useLayoutEffect} from 'react';
import {SafeAreaView, StyleSheet, View, Text, TouchableOpacity} from 'react-native';
import SearchComponent from '../components/SearchComponent';
import UsersList from '../components/lists/UsersList';
import theme from "../styles/theme";
import {useNavigation} from "@react-navigation/native";
import {MaterialIcons} from "@expo/vector-icons";

import {fetchFriendsSearchResults, fetchUserSearchResults} from '../utils/api/api';


const FriendsSearchScreen = () => {
  // const navigation = useNavigation();

  const facets = [
    // {
    //   key: 'friends',
    //   label: 'Friends',
    //   component: UsersList, // Reusing UsersList for followers
    //   fetchFunction: fetchFriendsSearchResults,
    // },
    {
      key: 'allUsers',
      label: 'All Users',
      component: UsersList,
      fetchFunction: fetchUserSearchResults
    },

  ];

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <SearchComponent facets={facets} placeholder={"Search All Users..."}/>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: theme.colors.beige,
  },
  container: {
    flex: 1,
    padding: 10,
  },
  addFriendButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10, // Adjust the margin as needed
  },
  addFriendButtonText: {
    color: theme.colors.gray,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
    marginLeft: 5, // Space between icon and text
  },
});

export default FriendsSearchScreen;
