import { useQuery } from 'react-query';
import axios from 'axios';
import apiEndpoints from "../apiEndpoints";

export const fetchSuggestedTitles = async () => {
  const { data } = await axios.get(apiEndpoints.SUGGESTED_TITLES);
  return data;
};

export const useSuggestedTitles = () => {
  return useQuery('suggestedTitles', fetchSuggestedTitles, {
    staleTime: 1000 * 60 * 60 * 24, // one day
    cacheTime: 1000 * 60 * 60 * 24, // one day
  });
};
