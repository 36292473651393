import ContentLoader, { Rect } from 'react-content-loader/native'
import React from 'react';
import { useWindowDimensions } from 'react-native';
import {WORLD_WIDTH, WORLD_HEIGHT, worldItemWidth, worldItemHeight} from "../WorldComponent";
import theme from "../../styles/theme";

const BigWorldsSkeletonLoader = () => {
  const { width, height } = useWindowDimensions();
  const numColumns = Math.floor(width / WORLD_WIDTH);
  const numRows = Math.floor(height / WORLD_HEIGHT)

  const skeletons = [];

  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numColumns; col++) {
      skeletons.push(
        <Rect
          key={`skeleton-${row}-${col}`}
          x={col * (WORLD_WIDTH)}
          y={row * WORLD_HEIGHT}
          // Adjust height based on your item's expected height
          rx="8"
          ry="8"
          width={worldItemWidth}
          height={worldItemHeight} // Adjust this as per your content's size
        />
      );
    }
  }

  return (
    <ContentLoader
      viewBox={`0 0 ${width} ${numRows * WORLD_HEIGHT}`}
      width={width}
      height={numRows * WORLD_HEIGHT}
      backgroundColor={theme.colors.loadingBackground}
      foregroundColor={theme.colors.loadingForeground}
    >
      {skeletons}
    </ContentLoader>
  );
};

export default BigWorldsSkeletonLoader;
