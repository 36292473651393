import React from 'react';
import {
  FlatList,
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  TouchableOpacity
} from 'react-native';
import SwipeableNotificationItem, {
  NotificationSkeleton
} from '../components/SwipeableNotificationItem';
import { fetchFriendRequests, deleteFriendRequest } from '../utils/api/userService';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import theme from "../styles/theme";
import {useNavigation} from "@react-navigation/native";
import {MaterialIcons} from "@expo/vector-icons";


const adaptFriendRequestToNotification = (friendRequest) => {
  return {
    id: friendRequest.id,
    verb: 'friend_request',
    actor: {
      uuid: friendRequest.from_profile_uuid,
      preferred_username: friendRequest.from_profile.preferred_username,
    },
    action_object: {
      accepted: friendRequest.accepted,
      from_profile_uuid: friendRequest.from_profile_uuid,
    },
    timestamp: friendRequest.created_at,
    unread: null,
  };
};


const EmptyComponent = () => {
  const navigation = useNavigation();

  return (
    <View style={styles.emptyContainer}>
      <Text style={styles.emptyText}>No friend requests yet!</Text>
      <TouchableOpacity
        style={styles.addFriendButton}
        onPress={() => {
          navigation.navigate('UserSearch');
        }}
      >
        <MaterialIcons name="person-add" size={30} color={theme.colors.bluePrimary}/>
        <Text style={styles.addFriendButtonText}>Find Friends</Text>
      </TouchableOpacity>
    </View>
  );
};


const FriendRequestsScreen = () => {
  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery('friendRequests', ({ pageParam = 1 }) => fetchFriendRequests(pageParam), {
    getNextPageParam: (lastPage) => lastPage.next != null ? parseInt(lastPage.next.split('page=')[1], 10) : undefined,
  });

  const friendRequestsData = data?.pages.flatMap((page) => page.results) || [];

  const { mutate: removeFriendRequest } = useMutation(profileUuid => deleteFriendRequest(profileUuid), {
    onSuccess: () => {
      queryClient.invalidateQueries('friendRequests');
    },
  });

  const renderItem = ({ item }) => {
    const adaptedNotification = adaptFriendRequestToNotification(item);
    return (
      <SwipeableNotificationItem
        item={adaptedNotification}
        onDelete={() => removeFriendRequest(item.from_profile_uuid)}
        onMarkAsRead={() => {
        }}
        onMarkAsUnread={() => {
        }}
        showMarkAsRead={false}
      />
    );
  };

  const renderFooter = () => {
    if (hasNextPage && isFetchingNextPage) {
      return (
        <View style={styles.loadingIndicator}>
          <ActivityIndicator size="large" color={theme.colors.primary} />
        </View>
      );
    }
    return null;
  };

  if (isLoading) {
    return (
      <View style={styles.container}>
        {Array.from({ length: 9 }).map((_, index) => (
          <NotificationSkeleton key={index} />
        ))}
      </View>
    );
  }

  if (isError) {
    return <View style={styles.container}><Text>Error loading friend requests.</Text></View>;
  }

  return (
    <View style={styles.container}>
      <FlatList
        data={friendRequestsData}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        onEndReached={() => {
          if (hasNextPage) {
            fetchNextPage();
          }
        }}
        onEndReachedThreshold={0.5}
        ListFooterComponent={renderFooter}
        ListEmptyComponent={<EmptyComponent />}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.beige,
    alignSelf: 'center',
    width: '100%',
  },
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50, // Adjust as necessary
  },
  emptyText: {
    fontSize: 18,
    color: theme.colors.textPrimary,
    fontFamily: theme.fonts.family.bold,
  },
  subText: {
    fontSize: 14,
    color: theme.colors.textSecondary,
    fontFamily: theme.fonts.family.regular,
    marginTop: 10, // Adjust as necessary
  },
  addFriendButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10, // Adjust the margin as needed
    marginTop: theme.spacings.large,
  },
  addFriendButtonText: {
    color: theme.colors.bluePrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.large,
    marginLeft: 5, // Space between icon and text
  },

});

export default FriendRequestsScreen;
