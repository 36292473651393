import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import {FontAwesome, Ionicons, MaterialIcons} from '@expo/vector-icons';
import theme from '../styles/theme';
import {useProfile} from "../contexts/ProfileContext";
import UpgradeModal from "../components/modals/UpgradeModal";
import ChooseFriendModal from "../components/modals/ChooseFriendModal";
import DisclaimerModal from "../components/modals/DisclaimerModal";
import MiniCard from "./MiniCard";
import alert from "../utils/alert";
import SvgRiffLine from "../assets/images/RiffLine";
import {createCard} from "../utils/api/cardsService";
import SuggestedTitles from "./SuggestedTitles";
import {formatCharacterCount} from "../utils/helpers";
import UserCircle from "./UserCircle";
import { useFocusEffect } from '@react-navigation/native';
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import SvgFabylHeader from "../assets/images/FabylHeader";
import globalStyles from "../styles/globalStyles";
import SvgRiff from "../assets/images/Riff";


const MAX_CHARACTERS = 70;
const { height: SCREEN_HEIGHT } = Dimensions.get('window');

const AutoExpandingTextInput = ({ value, onChangeText, placeholder, maxLength, inputRef, onSubmitEditing }) => {
  const [height, setHeight] = useState(50);
  // const isLargeScreen = useIsLargeScreen();
  // const isWeb = Platform.OS === 'web';
  const isLargeScreen = Platform.OS === 'web';

  return (
    <TextInput
      ref={inputRef}
      multiline
      value={value}
      autoCapitalize={'none'}
      onChangeText={onChangeText}
      placeholder={placeholder}
      placeholderTextColor={theme.colors.redPrimaryPlaceholder}
      maxLength={maxLength}
      blurOnSubmit={true}
      returnKeyType="done"
      onContentSizeChange={(event) => {
        setHeight(Math.min(event.nativeEvent.contentSize.height, 150));
      }}
      style={[
        styles.input,
        { height: Math.max(50, height) },
        isLargeScreen && styles.inputLargeScreen,
      ]}
    />
  );
};

const ExpandedInputScreen = ({ route, navigation }) => {
  const { cardRiffedFrom, returnFromCreation } = route.params || {};
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const { profileData, markDisclaimerAcknowledged } = useProfile();
  const [modalVisible, setModalVisible] = useState(false);
  const [friendModalVisible, setFriendModalVisible] = useState(false);
  const [disclaimerModalVisible, setDisclaimerModalVisible] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const isLargeScreen = useIsLargeScreen();
  // const isLargeScreen = useIsLargeScreen();


  useLayoutEffect(() => {
    navigation.setOptions({
      // headerTitle: cardRiffedFrom ? "RIFF" : "NEW",
      headerTitle: () => (
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text style={{fontSize: 18, color: theme.colors.black}}>
            {cardRiffedFrom ? "RIFF" : "NEW"}
          </Text>
          {cardRiffedFrom ? (
            <SvgRiff style={{marginLeft: theme.spacings.xsmall, transform: [{scale: 0.75}]}} wandColor={theme.colors.gray}/>

          ) : (
            <FontAwesome name="plus" size={18} color={theme.colors.redPrimary}
                         style={{marginLeft: theme.spacings.xsmall}}/>
          )}
        </View>
      ),
      headerShown: true,
      headerStyle: {
        backgroundColor: theme.colors.beige,
      },
      headerLeft: () => (null),
      headerRight: () => (
        <TouchableOpacity
          onPress={handleClose}
          hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}
        >
          <MaterialIcons name="close" size={24} color={theme.colors.black} style={{marginRight: 15}}/>
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  useFocusEffect(
    React.useCallback(() => {
      if (!returnFromCreation) {
        setInputValue('');
      }
      // Clear the returnFromCreation flag after using it
      navigation.setParams({ returnFromCreation: false });
    }, [returnFromCreation, navigation])
  );

  useFocusEffect(
    React.useCallback(() => {
      if (inputValue.trim()) {
        setIsCreating(false);
      }
      return () => {
      }; // Optional cleanup function
    }, [inputValue])
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);


  const handleClose = () => {
    if (cardRiffedFrom) {
      navigation.navigate('CardDetail', { card: cardRiffedFrom });
    } else {
      if (Platform.OS === 'web') {
        navigation.navigate("Create");
      } else {
        navigation.goBack();
      }
    }
  }

  const handleLeftButtonPress = () => {
    console.log('Left button pressed')
  }


  const handleCreatePress = async () => {
    if (!inputValue.trim()) {
      alert("Validation Error", "Name cannot be empty!");
      return;
    }
    if (!profileData.disclaimer_acknowledged) {
      setDisclaimerModalVisible(true);
      return;
    }

    setIsCreating(true);
    try {
      const { task_id, uuid } = await createCard(inputValue, cardRiffedFrom?.uuid, selectedFriend?.uuid);
      navigation.navigate('FabylCreation', {
        fabylName: inputValue,
        selectedFriend: selectedFriend,
        cardRiffedFrom: cardRiffedFrom,
        task_id: task_id,
        uuid: uuid,
      });
    } catch (error) {
      setIsCreating(false);
      if (error.response && error.response.data && error.response.data.code === 'INSUFFICIENT_GENERATIONS') {
        setModalVisible(true);
      } else if (error.response && error.response.data && error.response.data.code === 'CURRENT_USER_BLOCKED_RESOURCE_USER') {
        alert("Blocked User Error", "You have blocked a contributor to this world, so you cannot contribute to this world anymore while that user remains blocked.");
      } else {
        alert("Error", error.message || "An error occurred. Please try again.");
      }
    }
  };

  const handleSendToFriendPress = () => {
    setFriendModalVisible(true);
  };

  const handleDisclaimerConfirm = () => {
    setDisclaimerModalVisible(false);
    markDisclaimerAcknowledged();
    handleCreatePress();
  };

  const handleDisclaimerCancel = () => {
    setDisclaimerModalVisible(false);
  };

  const handleSuggestionPress = (title) => {
    setInputValue(title);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const closeFriendModal = () => {
    setSelectedFriend(null); // Clear the selected friend's data (if any
    setFriendModalVisible(false);
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "padding"}
      style={styles.container}
      keyboardVerticalOffset={Platform.OS === "ios" ? 130 : 130}
    >
      <SafeAreaView style={styles.safeArea}>
        {cardRiffedFrom ? (
          <View style={styles.miniCardContainer}>
            <MiniCard item={cardRiffedFrom} pressable={false} />
            <SvgRiffLine style={styles.riffLine} />
          </View>
        ) : (
          !isLargeScreen ? (
            <View style={styles.fabylHeaderContainer}>
              <SuggestedTitles onSelectTitle={handleSuggestionPress} />
            </View>
          ) : (
            <View style={styles.fabylHeaderContainer}>
              <SvgFabylHeader />
              <View style={styles.brandContainer}>
                <Text style={globalStyles.brandPhrase}>weave whimsical worlds</Text>
              </View>
            </View>
          )
        )}

        <View style={[
          styles.contentContainer,
          isLargeScreen && styles.contentContainerLargeScreen,
        ]}>
          <View style={styles.inputWrapper}>
            <View style={styles.inputContainer}>
              <AutoExpandingTextInput
                value={inputValue}
                onChangeText={setInputValue}
                placeholder={cardRiffedFrom ? "NAME THE NEXT CHAPTER..." : "NAME YOUR FABYL..."}
                maxLength={MAX_CHARACTERS}
                inputRef={inputRef}
                onSubmitEditing={handleCreatePress}
              />
            </View>
          </View>
          <View style={styles.infoContainer}>
            <Text style={[styles.infoText, styles.charactersText]}>
              {`${formatCharacterCount(inputValue.length)}/${MAX_CHARACTERS}`}
            </Text>
            <Text style={[styles.infoText, styles.charactersText]}>
              <FontAwesome name="plus" size={10} color={theme.colors.redPrimaryPlaceholder} style={{marginRight: theme.spacings.small}}/>
              {/*&nbsp;*/}
              {`${formatCharacterCount(profileData?.remaining_generations)}/${profileData?.daily_generations} left`}
            </Text>
            <Text style={styles.infoText}>*fabyls are public</Text>
          </View>

          <View style={styles.optionsContainer}>
            <TouchableOpacity style={styles.sendToFriendButton} onPress={handleSendToFriendPress}>
              {selectedFriend ? (
                <UserCircle profile_uuid={selectedFriend.uuid} size={25}/>
              ) : (
                <FontAwesome name="paper-plane-o" size={20} color={theme.colors.lightgray}/>
              )}
              {/*<FontAwesome name="paper-plane-o" size={20} color={theme.colors.lightgray} />*/}
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.createButton, (!inputValue.trim() || isCreating) && styles.createButtonDisabled,
              ]}
              onPress={handleCreatePress}
              disabled={!inputValue.trim() || isCreating}
              hitSlop={{top: 20, bottom: 20, left: 20, right: 20}}
            >
              <Text
                style={[styles.createButtonText, (!inputValue.trim() || isCreating) && styles.createButtonTextDisabled]}>
                {/*{isCreating ? 'CREATING...' : 'CREATE'}*/}
                {'CREATE'}
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        {isLargeScreen && (
          <View style={styles.suggestedTitlesBelowContainer}>
            <SuggestedTitles onSelectTitle={handleSuggestionPress} />
          </View>
        )}

        <UpgradeModal
          isVisible={modalVisible}
          onConfirm={() => setModalVisible(false)}
          onCancel={() => setModalVisible(false)}
        />
        <ChooseFriendModal
          visible={friendModalVisible}
          onClose={closeFriendModal}
          onSelectFriend={(friend) => {
            setSelectedFriend(friend);
            setFriendModalVisible(false);
          }}
        />
        <DisclaimerModal
          isVisible={disclaimerModalVisible}
          onConfirm={handleDisclaimerConfirm}
          onCancel={handleDisclaimerCancel}
        />
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: theme.colors.beige,
  },
  container: {
    flex: 1,
    backgroundColor: theme.colors.beige,
    height: "100%",
  },
  contentContainer: {
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: 600,
    alignSelf: 'center',
    backgroundColor: theme.colors.beige,
  },
  contentContainerLargeScreen: {
    // flex: 1,
    justifyContent: 'flex-end',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacings.medium,
    right: theme.spacings.medium,
    zIndex: 1,
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  input: {
    flex: 1,
    paddingHorizontal: theme.spacings.medium,
    paddingTop: theme.spacings.small,
    paddingBottom: theme.spacings.small,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.redPrimary,
    backgroundColor: theme.colors.white,
  },
  inputLargeScreen: {
    borderRadius: theme.borderRadius.large,
  },
  infoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: theme.spacings.medium,
    marginVertical: theme.spacings.xsmall,
  },
  optionsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: theme.spacings.medium,
  },
  infoText: {
    fontSize: theme.fonts.sizes.small,
    color: theme.colors.lightgray,
    alignSelf: 'center',
  },
  charactersText: {
    fontFamily: theme.fonts.family.monospace,
  },
  createButton: {
    backgroundColor: theme.colors.bluePrimary,
    paddingVertical: theme.spacings.xsmall,
    paddingHorizontal: theme.spacings.medium,
    borderRadius: 200,
    alignSelf: "center",
  },
  createButtonLargeScreen: {
    borderRadius: theme.borderRadius.large,
  },
  createButtonDisabled: {
    backgroundColor: theme.colors.verylightgray,
  },
  createButtonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
  },
  createButtonTextDisabled: {
    color: theme.colors.white,
  },
  sendToFriendButton: {
    padding: theme.spacings.small,
    marginRight: theme.spacings.small,
    alignSelf: 'flex-start',
  },
  miniCardContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 600,
    // flex: 1,
  },
  fabylHeaderContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 600,
    flex: 1,
  },
  suggestedTitlesBelowContainer: {
    flex: 2,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    maxWidth: 600,
    width: '100%',
  },
  brandContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
});

export default ExpandedInputScreen;
