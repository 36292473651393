import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";
const SvgRiff = ({ wandColor = "none", color = "#F9C430", ...props }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={29}
    fill="none"
    {...props}
  >
    <G clipPath="url(#riff_svg__a)">
      <Path
        fill={color}
        d="M13.973.908C6.26.908.003 6.995.003 14.498s6.257 13.59 13.97 13.59c7.713 0 13.97-6.087 13.97-13.59S21.686.908 13.973.908M9.02 7.456l1.033-2.24 1.033 2.24 2.303 1.005-2.303 1.004-1.033 2.24-1.033-2.24-2.303-1.004zm.38 16.62a.455.455 0 0 1-.634.067L6.14 22.108a.427.427 0 0 1-.068-.617l5.936-7.232 3.31 2.603-5.91 7.207zm8.45-10.303-2.006 2.446-3.31-2.603 1.99-2.43a.455.455 0 0 1 .634-.065l2.625 2.034a.427.427 0 0 1 .067.618m3.633-4.308-1.033 2.24-1.033-2.24-2.303-1.004 2.303-1.005 1.033-2.24 1.033 2.24 2.303 1.005z"
      />
      <Path
        fill={wandColor} // if not specified, wand will just be whatever background color is where svg is placed.
        d="M9.02 7.456l1.033-2.24 1.033 2.24 2.303 1.005-2.303 1.004-1.033 2.24-1.033-2.24-2.303-1.004zm.38 16.62a.455.455 0 0 1-.634.067L6.14 22.108a.427.427 0 0 1-.068-.617l5.936-7.232 3.31 2.603-5.91 7.207zm8.45-10.303-2.006 2.446-3.31-2.603 1.99-2.43a.455.455 0 0 1 .634-.065l2.625 2.034a.427.427 0 0 1 .067.618m3.633-4.308-1.033 2.24-1.033-2.24-2.303-1.004 2.303-1.005 1.033-2.24 1.033 2.24 2.303 1.005z"
      />
    </G>
    <Defs>
      <ClipPath id="riff_svg__a">
        <Path fill="#fff" d="M.003.908h27.93V28.08H.004z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default SvgRiff;
