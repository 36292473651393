import {useNavigation} from "@react-navigation/native";
import {Text, TouchableOpacity, View} from "react-native";
import React from "react";
import {ScaledSheet} from "react-native-size-matters";
import theme from "../styles/theme";


const LoginHeader = () => {
  const navigation = useNavigation();

  const handleLoginPress = () => {
    navigation.navigate("Unauthenticated", {screen: "Login"});
    onCancel();
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={handleLoginPress}
        style={styles.openInAppButton}
      >
        <Text style={styles.openInAppButtonText}>LOG IN</Text>
      </TouchableOpacity>
    </View>
  );
};


const styles = ScaledSheet.create({
  openInAppButton: {
    backgroundColor: theme.colors.bluePrimary, // Example blue color for the button
    borderRadius: 5,
    paddingVertical: 5,
    paddingHorizontal: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  openInAppButtonText: {
    color: theme.colors.white, // White text color
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: 24, // Adjust based on your icon's size
    height: 24, // Adjust based on your icon's size
    marginRight: 8, // Space between icon and text
  },
  spur: {
    marginLeft: 20,
    zIndex: 1,
    transform: [{ scale: 2 }],
  },
});


export default LoginHeader;
