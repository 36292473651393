import React, { useState, useContext, useRef, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  Alert,
  TouchableOpacity
} from 'react-native';
import { Auth } from 'aws-amplify';
import {AuthContext} from "../contexts/AuthContext";
import theme from "../styles/theme";
import globalStyles from "../styles/globalStyles";
import alert from "../utils/alert";

const OtpScreen = ({ route, navigation }) => {
  // const [otp, setOtp] = useState('');
  const [otp, setOtp] = useState(Array(6).fill('')); // Array to store OTP digits
  const inputRefs = useRef(otp.map(() => React.createRef())); // Refs for each input box
  const [loading, setLoading] = useState(false);
  const { user, refreshUser } = useContext(AuthContext); // Use the context

  // Assuming username (email or phone number) is passed via route.params
  const { username, verificationType } = route.params;

  const verificationActions = {
    signUp: async () => {
      const otpCode = otp.join('');
      await Auth.confirmSignUp(username, otpCode);
      alert('Success', 'Sign Up confirmed! You will now be redirected to login.', [
        { text: 'OK', onPress: () => navigation.navigate('Login') },
      ]);
    },
    emailUpdate: async () => {
      const otpCode = otp.join('');
      await Auth.verifyCurrentUserAttributeSubmit('email', otpCode);
      setTimeout(refreshUser, 1000); // 1 second delay
      alert('Success', 'Email updated successfully!', [
        { text: 'OK', onPress: () => navigation.navigate("UpdateEmail") },
      ]);
    },
    phoneUpdate: async () => {
      const otpCode = otp.join('');
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', otpCode);
      setTimeout(refreshUser, 1000); // 1 second delay
      alert('Success', 'Phone number updated successfully!', [
        { text: 'OK', onPress: () => navigation.navigate("UpdatePhone")},
      ]);
    },
    resetPassword: async () => {
      const otpCode = otp.join('');
      try {
        // Confirm the new password using the OTP
        // await Auth.forgotPasswordSubmit(username, otp, newPassword);
        // navigation.navigate('ResetPassword', { username, code: otp });
        alert('Reset Password', 'Continue to set your new password.', [
          { text: 'OK', onPress: () => navigation.navigate('ResetPassword', { username, code: otpCode })},
        ]);
      } catch (error) {
        alert('Error', `Password reset failed: ${error.message}`);
      }
    },
  };

  const handleVerifyOtp = async () => {
    if (loading) return;

    const action = verificationActions[verificationType];
    if (!action) {
      alert('Error', 'Invalid verification type.');
      return;
    }

    setLoading(true);
    try {
      await action();
    } catch (error) {
      alert('Error', `Verification failed: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeText = (text, index) => {
    const newOtp = [...otp];
    newOtp[index] = text;
    setOtp(newOtp);

    // Focus next input if there's next one and the text is not empty
    if (text && index < 5) {
      inputRefs.current[index + 1].focus();
    }
    // If backspace was hit and text is empty, focus the previous input if there is one
    else if (!text && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };


  useEffect(() => {
    // Auto-focus the first input on mount
    inputRefs.current[0].focus();
  }, []);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Enter One-Time-Passcode</Text>
      <Text style={styles.infoText}>Code sent to: </Text>
      <Text style={styles.sentToText}>{username}</Text>

      <View style={styles.otpContainer}>
        {otp.map((digit, index) => (
          <TextInput
            key={index}
            ref={ref => inputRefs.current[index] = ref}
            style={styles.otpInput}
            keyboardType="numeric"
            maxLength={1}
            onChangeText={text => handleChangeText(text, index)}
            value={digit}
          />
        ))}
      </View>

      {/*<Button*/}
      {/*  title={loading ? 'Verifying...' : 'Verify OTP'}*/}
      {/*  onPress={handleVerifyOtp}*/}
      {/*  disabled={loading || otp.join('').length < 6}*/}
      {/*/>*/}
      <TouchableOpacity
        onPress={handleVerifyOtp}
        style={globalStyles.primaryButton}
        disabled={loading || otp.join('').length < 6}>
        <Text style={globalStyles.primaryButtonText}>{loading ? 'Verifying...' : 'Verify Passcode'}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    backgroundColor: theme.colors.beige,
    padding: 20,
    maxWidth: 500,
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
  input: {
    width: '100%',
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
  },
  infoText: {
    marginBottom: theme.spacings.medium,
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
    textDecorationLine: 'underline',
  },
  sentToText: {
    marginBottom: theme.spacings.large,
    fontSize: theme.fonts.sizes.medium,
  },
  otpContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    marginBottom: 20,
  },
  otpInput: {
    width: 40,
    height: 40,
    borderColor: 'gray',
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    borderRadius: 5,
    textAlign: 'center',
  },
});

export default OtpScreen;
