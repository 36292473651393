import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { Audio } from 'expo-av';
import {debounce} from "lodash";

const AudioPlayerContext = createContext();

export const AudioPlayerProvider = ({ children }) => {
    // const [sound, setSound] = useState(null);
    const soundRef = useRef(null);
    const [progress, setProgress] = useState(0); // Add state to track progress

    // Set global audio mode settings when the provider is mounted
    useEffect(() => {
        const setAudioMode = async () => {
            try {
                await Audio.setAudioModeAsync({
                    playsInSilentModeIOS: true,
                    allowsRecordingIOS: false,
                    staysActiveInBackground: false,
                    shouldDuckAndroid: false,
                });
            } catch (error) {
                console.error('Failed to set audio mode:', error);
            }
        };

        setAudioMode();

        return () => {
            // sound?.unloadAsync();
            soundRef.current?.unloadAsync();
        };
    }, []);

    const noDebouncePlaySound = async (uri, duration) => {
      // Ensure any currently playing sound is stopped and unloaded
      try {
        if (soundRef.current) {
          await soundRef.current.unloadAsync();
        }

        const {sound: newSound} = await Audio.Sound.createAsync({uri});
        soundRef.current = newSound;

        const jumpTo = 500; // there is a delay so we go in the future a bit
        setProgress(jumpTo / duration); // there is a delay so we go in the future a bit

        if (duration && duration > 0) {
          newSound.setOnPlaybackStatusUpdate((status) => {
            if (status.isLoaded && status.positionMillis && duration) {
              const currentProgress = Math.min(status.positionMillis + jumpTo, duration) / duration;
              setProgress(currentProgress); // Add 0.3 to the progress to account for the delay
            }
          });
        }

        await newSound.playAsync();
      } catch (error) {
        console.error('Error playing sound:', error);
      }
    };

    const playSound = debounce(noDebouncePlaySound, 1000,  {
      leading: true,
      trailing: false
    });

    const playTitleAndFlavor = async (titleUri, titleDuration, flavorUri, flavorDuration) => {
      try {
        await playSound(titleUri, titleDuration);
        soundRef.current.setOnPlaybackStatusUpdate(async (status) => {
          if (status.didJustFinish) {
            soundRef.current.setOnPlaybackStatusUpdate(null); // Remove the event listener to prevent it from being called again.
            // Don't debounce in case the title audio was super short / corrupted
            await noDebouncePlaySound(flavorUri, flavorDuration);
          }
        });
      } catch (error) {
        console.error('Error playing title and flavor audio:', error);
      }
    };

    const stopSound = async () => {
      try {
        if (soundRef.current) {
          const status = await soundRef.current.getStatusAsync();
          if (status.isLoaded) {
            await soundRef.current.stopAsync();
            await soundRef.current.unloadAsync();
          } else {
            console.log('Sound is not loaded, skipping stop and unload.');
          }
          soundRef.current = null;
          setProgress(0); // Reset progress when stopping the sound
        }
      } catch (error) {
        console.log('Error stopping sound:', error);
        // ^ this is expected to happen a lot while scrolling fast
        //  hence the console.log instead of console.error:w
      }
    };

    return (
        <AudioPlayerContext.Provider value={{ playSound, stopSound, playTitleAndFlavor, progress }}>
            {children}
        </AudioPlayerContext.Provider>
    );
};

export const useAudioPlayer = () => useContext(AudioPlayerContext);
