import React from 'react';
import { View, StyleSheet } from 'react-native';
import theme from "../../styles/theme";
import ContentLoader, { Rect, Circle } from 'react-content-loader/native';

const SkeletonLoader = () => {
  return (
    <View style={styles.skeletonContainer}>
      <ContentLoader
        width={400}
        height={60}
        viewBox="0 0 400 60"
        backgroundColor={theme.colors.loadingBackground}
        foregroundColor={theme.colors.loadingForeground}
      >
        <Circle cx="30" cy="30" r="30" />
        <Rect x="70" y="15" rx="4" ry="4" width="220" height="10" />
        <Rect x="70" y="35" rx="3" ry="3" width="180" height="10" />
      </ContentLoader>
    </View>
  );
};

const styles = StyleSheet.create({
  skeletonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  skeletonCircle: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.colors.verylightgray,
    marginRight: 10,
  },
  skeletonLine: {
    flex: 1,
    height: 20,
    borderRadius: 4,
    backgroundColor: theme.colors.verylightgray,
  },
});

export default SkeletonLoader;
