// hooks/useIsLargeScreen.js
import { useState, useEffect } from 'react';
import { Dimensions } from 'react-native';
import { useWindowDimensions } from 'react-native';
import theme from '../styles/theme';

const useIsLargeScreen = (breakpoint=theme.breakpoints.medium) => {
  const { width } = useWindowDimensions();

  const [isLargeScreen, setIsLargeScreen] = useState(
    width >= breakpoint
  );

  useEffect(() => {
    const updateIsLargeScreen = () => {
      const isLarge = width >= breakpoint;
      setIsLargeScreen(isLarge);
    };

    const subscription = Dimensions.addEventListener('change', updateIsLargeScreen);
    updateIsLargeScreen(); // Call it initially

    return () => subscription?.remove();
  }, [breakpoint]);


  return isLargeScreen;
};

export default useIsLargeScreen;
