import React from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { MaterialIcons } from "@expo/vector-icons";
import theme from "../../styles/theme";

const MenuModal = ({ modalVisible, setModalVisible, options, header }) => {
  const closeModal = () => setModalVisible(false);

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={closeModal}
    >
      {/* Overlay View */}
      <TouchableOpacity
        style={styles.overlay}
        activeOpacity={1}
        onPressOut={closeModal}
      >
        {/* Modal Content */}
        <View style={styles.modalView}>
          <TouchableOpacity onPress={closeModal} style={styles.closeButton}>
            <MaterialIcons name="close" size={24} color={theme.colors.gray} />
          </TouchableOpacity>
          {header && (
            <Text style={styles.modalHeader}>{header}</Text>
          )}
          {options.map((option, index) => (
            <TouchableOpacity
              key={index}
              style={styles.modalButton}
              onPress={() => {
                closeModal();
                option.action();
              }}
              activeOpacity={0.7}
            >
              <Text style={styles.modalText}>{option.label}</Text>
            </TouchableOpacity>
          ))}
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  modalHeader: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    paddingBottom: theme.spacings.large,
    textAlign: 'center',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'stretch',
  },
  modalView: {
    width: '100%',
    backgroundColor: theme.colors.loadingBackground,
    padding: 20,
    borderWidth: 0.5,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    paddingVertical: 50,
  },
  modalButton: {
    padding: 10,
  },
  modalText: {
    marginBottom: theme.spacings.medium,
    textAlign: "center",
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.bluePrimary,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 10,
    padding: 10,
  },
});

export default MenuModal;
