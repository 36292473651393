// Screens/FollowersSearchScreen.js
import React from 'react';
import { View, StyleSheet, SafeAreaView } from 'react-native';
import SearchComponent from '../components/SearchComponent';
import UsersList from '../components/lists/UsersList'; // Reusing UsersList for displaying followers
import theme from '../styles/theme';

// Import your method for fetching followers' search results
import {
  fetchFriendsSearchResults,
  fetchUserSearchResults
} from '../utils/api/api';

const FollowerFollowingSearchScreen = ({ route }) => {
  const initialFacetKey = route.params?.initialFacetKey || 'friends'; // Default to 'followers' if not provided


  const facets = [
    {
      key: 'friends',
      label: 'Friends',
      component: UsersList, // Reusing UsersList for followers
      fetchFunction: fetchFriendsSearchResults,
    },
    {
      key: 'allUsers',
      label: 'All Users',
      component: UsersList,
      fetchFunction: fetchUserSearchResults
    },
    // You can add more facets if necessary
  ];

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <SearchComponent facets={facets} initialFacetKey={initialFacetKey}/>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    // backgroundColor: '#f5f5f5',
    backgroundColor: theme.colors.beige,
  },
  container: {
    flex: 1,
    padding: 10,
  },
});

export default FollowerFollowingSearchScreen;
