import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const SvgGenerate = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    viewBox="0 0 34 34"
    fill="none"
    {...props}
  >
    <Circle cx={17} cy={17} r={14} fill="#D94C2A" />
    <Path fill="#D94C2A" d="M17 .03 33.97 17 17 33.97.03 17z" />
    <Path fill="#333" d="M9 15h16v4H9z" />
    <Path fill="#333" d="M19 9v16h-4V9z" />
  </Svg>
);
export default SvgGenerate;
