import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SvgSpurWithFWhiteOnRed = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={22}
    fill="none"
    {...props}
  >
    <Path
      fill="#DB4C2A"
      d="M22.27 11.003 11.303 0 .336 11.003l10.967 11.002z"
    />
    <Path fill="#DB4C2A" d="M11.27 20.077a9 9 0 1 0 0-18 9 9 0 0 0 0 18" />
    <Path
      fill="#F7EBDF"
      d="M8.333 15.767c.354 0 .655-.198.899-.593.246-.395.428-.953.55-1.674l.804-4.71h-.793l.079-.465h.792l.069-.349C10.982 6.658 11.756 6 13.054 6c.475 0 .786.058.928.175.135.101.237.275.306.524l-.385.29a.825.825 0 0 0-.363-.395c-.158-.086-.365-.129-.623-.129-.27 0-.508.117-.707.349-.199.232-.353.62-.458 1.162l-.068.349h1.584l-.079.465h-1.584l-.804 4.71c-.105.604-.25 1.087-.436 1.447-.185.361-.443.627-.775.797-.333.17-.766.256-1.302.256l.045-.232z"
    />
  </Svg>
);
export default SvgSpurWithFWhiteOnRed;
