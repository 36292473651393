// FullscreenContext.js
import React, { createContext, useState, useContext } from 'react';

const FullscreenContext = createContext({
  isFullscreen: false,
  setFullscreen: () => {},
});

export const useFullscreen = () => useContext(FullscreenContext);

export const FullscreenProvider = ({ children }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const setFullscreen = (fullscreen) => {
    setIsFullscreen(fullscreen);
  };

  return (
    <FullscreenContext.Provider value={{ isFullscreen, setFullscreen }}>
      {children}
    </FullscreenContext.Provider>
  );
};
