import { useMutation, useQueryClient } from 'react-query';
import { sendFriendRequest, acceptFriendRequest, removeFriend } from "../utils/api/userService";
import alert from "../utils/alert";

export const useFriendInteraction = (profileUuid) => {
  const queryClient = useQueryClient();

  const sendFriendRequestMutation = useMutation(
    () => sendFriendRequest(profileUuid),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['friendStatus', profileUuid]);
        const previousStatus = queryClient.getQueryData(['friendStatus', profileUuid]);
        queryClient.setQueryData(['friendStatus', profileUuid], (old) => ({
          ...old,
          hasRequestSent: true,
        }));
        return { previousStatus };
      },
      onError: (err, variables, context) => {
        if (err.response?.data?.message === 'You have blocked this user') {
          alert('You cannot send a friend request to a user you have blocked. Please unblock them first.');
        } else {
          queryClient.setQueryData(['friendStatus', profileUuid], context.previousStatus);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['friendStatus', profileUuid]);
      },
    }
  );

  const acceptFriendRequestMutation = useMutation(
    () => acceptFriendRequest(profileUuid),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['friendStatus', profileUuid]);
        const previousStatus = queryClient.getQueryData(['friendStatus', profileUuid]);
        queryClient.setQueryData(['friendStatus', profileUuid], (old) => ({
          ...old,
          isFriend: true,
          hasRequestReceived: false,
        }));
        return { previousStatus };
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData(['friendStatus', profileUuid], context.previousStatus);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['friendStatus', profileUuid]);
      },
    }
  );

  const removeFriendMutation = useMutation(
    () => removeFriend(profileUuid),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['friendStatus', profileUuid]);
        const previousStatus = queryClient.getQueryData(['friendStatus', profileUuid]);
        queryClient.setQueryData(['friendStatus', profileUuid], (old) => ({
          ...old,
          isFriend: false,
        }));
        return { previousStatus };
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData(['friendStatus', profileUuid], context.previousStatus);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['friendStatus', profileUuid]);
      },
    }
  );

  return {
    sendFriendRequest: profileUuid ? sendFriendRequestMutation.mutate : () => {},
    acceptFriendRequest: profileUuid ? acceptFriendRequestMutation.mutate : () => {},
    removeFriend: profileUuid ? removeFriendMutation.mutate : () => {},
  };
};
