import React from 'react';
import {Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Gesture, GestureDetector} from 'react-native-gesture-handler';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring
} from 'react-native-reanimated';
import {Ionicons, MaterialIcons} from '@expo/vector-icons';
import theme from "../styles/theme";
import {useNavigation} from "@react-navigation/native";
import {acceptFriendRequest} from "../utils/api/userService";
import moment from 'moment';
import {useQueryClient} from "react-query";
import UserCircle from "./UserCircle";
import {markNotificationAsRead} from "../utils/api/userService";
import ContentLoader, { Rect, Circle } from 'react-content-loader/native';


export const NotificationSkeleton = () => (
  <View style={styles.notificationItem}>
    <ContentLoader
      width={400}
      height={60}
      viewBox="0 0 400 60"
      backgroundColor={theme.colors.loadingBackground}
      foregroundColor={theme.colors.loadingForeground}
    >
      <Circle cx="30" cy="30" r="30" />
      <Rect x="70" y="15" rx="4" ry="4" width="220" height="10" />
      <Rect x="70" y="35" rx="3" ry="3" width="180" height="10" />
    </ContentLoader>
  </View>
);


const SwipeableNotificationItem = ({ item, onDelete, onMarkAsRead, onMarkAsUnread, showMarkAsRead = true }) => {
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  const translateX = useSharedValue(0);
  const MAX_TRANSLATE_X = showMarkAsRead ? -160 : -80;

  const panGesture = Gesture.Pan()
    .activeOffsetX([-10, 10]) // Requires a more deliberate horizontal movement to activate
    .failOffsetY([-5, 5]) // Allows for some vertical movement without failing the gesture
    .onUpdate((event) => {
      translateX.value = Math.max(MAX_TRANSLATE_X, Math.min(0, event.translationX));
    })
    .onEnd(() => {
      if (translateX.value < MAX_TRANSLATE_X / 2) {
        translateX.value = withSpring(MAX_TRANSLATE_X);
      } else {
        translateX.value = withSpring(0);
      }
    });

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: translateX.value }],
  }));

  const handleNotificationPress = (notification) => {
    onMarkAsRead();
    if (notification.verb === 'riffed' || notification.verb === 'liked' || notification.verb === 'wrote') {
      if (notification.target && notification.target.uuid) {
        navigation.navigate('CardDetail', {card: {uuid: notification.target.uuid}});
      } else {
        console.warn('Target UUID is missing or card does not exist');
      }
    }
    else if (notification.verb === 'friend_request') {
      if (notification.actor && notification.actor.uuid) {
        navigation.navigate('UserProfile', {profile_uuid: notification.actor.uuid});
      } else {
        console.warn('Actor UUID is missing or profile does not exist');
      }
    }
    else if (notification.verb === 'friend_request_accepted') {
      if (notification.actor && notification.actor.uuid) {
        navigation.navigate('UserProfile', {profile_uuid: notification.actor.uuid});
      } else {
        console.warn('Actor UUID is missing or profile does not exist');
      }
    }
  };

  const renderNotificationText = (item) => {
    switch (item.verb) {
      case 'liked':
        return (
          <Text>
            <Text style={[styles.boldText, styles.blueText]}>{item.actor.preferred_username}</Text>
            <Text> liked your card.</Text>
          </Text>
        );
      case 'riffed':
        return (
          <Text>
            <Text style={[styles.boldText, styles.blueText]}>{item.actor.preferred_username}</Text>
            <Text> riffed on your card.</Text>
          </Text>
        );
      case 'friend_request':
        return (
          <Text>
            <Text style={[styles.boldText, styles.blueText]}>{item.actor.preferred_username}</Text>
            <Text> sent you a friend request.</Text>
          </Text>
        );
      case 'friend_request_accepted':
        return (
          <Text>
            <Text style={[styles.boldText, styles.blueText]}>{item.actor.preferred_username}</Text>
            <Text> accepted your friend request.</Text>
          </Text>
        );
      case 'wrote':
        return (
          <Text>
            <Text style={[styles.boldText, styles.blueText]}>{item.actor.preferred_username}</Text>
            <Text> wrote you a card.</Text>
          </Text>
        );
      default:
        return (
          <Text>
            <Text style={[styles.boldText, styles.blueText]}>{item.actor.preferred_username}</Text>
            <Text> sent you a notification.</Text>
          </Text>
        );
    }
  };

  const handleAcceptFriendRequest = async (profileUuid) => {
    try {
      await acceptFriendRequest(profileUuid);
      queryClient.invalidateQueries('notifications');
    } catch (error) {
      console.error('Error accepting friend request:', error);
    }
  };

  const renderActionButtons = (item) => {
    // Assuming item.action_object contains the friend request status (accepted: true/false)
    if (item.verb === 'friend_request') {
      if (item.action_object && item.action_object.accepted) {
        // Friend request accepted, show greyed-out button
        return (
          <View style={styles.actionButtonsContainer}>
            <TouchableOpacity style={[styles.friendButton, styles.acceptedButton]} disabled={true}>
              <Text style={styles.acceptedButtonText}>Accepted</Text>
              <Ionicons
                name="checkmark-circle"
                size={18}
                color={theme.colors.white}
                style={styles.friendIcon}
              />
            </TouchableOpacity>
          </View>
        );
      } else if (item.action_object) {
        // Friend request pending, show accept button
        return (
          <View style={styles.actionButtonsContainer}>
            <TouchableOpacity
              style={[styles.friendButton, styles.acceptButton]}
              onPress={() => handleAcceptFriendRequest(item.action_object.from_profile_uuid)}
              hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
            >
              <Text style={styles.actionButtonText}>Accept</Text>
            </TouchableOpacity>
          </View>
        );
      } else {
        return (
          <View style={styles.actionButtonsContainer}>
            <TouchableOpacity style={[styles.friendButton, styles.expiredButton]} disabled={true}>
              <Text style={styles.acceptedButtonText}>Expired</Text>
            </TouchableOpacity>
          </View>
        );
      }
    }
    return null;
  };

  return (
    <GestureDetector gesture={panGesture}>
      <Animated.View style={[styles.outerNotificationItem, animatedStyle]}>
        <TouchableOpacity
          style={styles.notificationItem}
          onPress={() => handleNotificationPress(item)}
        >
          <View style={styles.imageContainer}>
            <UserCircle profile_uuid={item.actor.uuid} size={50} />
          </View>
          <View style={styles.notificationTextContainer}>
            {renderNotificationText(item)}
            <Text style={styles.timestamp}>{moment(item.timestamp).fromNow()}</Text>
          </View>
          {renderActionButtons(item)}
          {item.target && item.target.thumbnail && <Image source={{ uri: item.target.thumbnail }} style={styles.cardThumbnail} />}
        </TouchableOpacity>
        {showMarkAsRead && (
          <TouchableOpacity
            style={styles.markAsReadButton}
            onPress={() => item.unread ? onMarkAsRead() : onMarkAsUnread()}
          >
            <MaterialIcons
              name={item.unread ? "mark-email-read" : "mark-email-unread"}
              size={36}
              color="white"
            />
          </TouchableOpacity>
        )}
        <TouchableOpacity style={[styles.deleteButton, {right: showMarkAsRead ? -160 : -80}]} onPress={onDelete}>
          <MaterialIcons name="delete" size={36} color="white" />
        </TouchableOpacity>
      </Animated.View>
    </GestureDetector>
  );
};

const styles = StyleSheet.create({
  deleteButton: {
    position: 'absolute',
    // right: -160, // Match this with MAX_TRANSLATE_X
    // right: showMarkAsRead ? -160 : -80,
    top: 0,
    bottom: 0,
    width: 80, // Match this with MAX_TRANSLATE_X
    backgroundColor: 'red',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacings.small,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.colors.lightgray,
  },
  markAsReadButton: {
    position: 'absolute',
    right: -80, // Match this with MAX_TRANSLATE_X
    top: 0,
    bottom: 0,
    width: 80, // Match this with MAX_TRANSLATE_X
    backgroundColor: theme.colors.blueSecondary,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacings.small,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.colors.lightgray,
  },
  container: {
    backgroundColor: theme.colors.beige,
    flex: 1,
    paddingTop: 20,
  },
  notificationItem: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacings.medium,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: theme.colors.lightgray,
  },
  outerNotificationItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageContainer: {
    marginRight: theme.spacings.medium,
  },
  cardThumbnail: {
    width: 50,
    height: 50,
    borderRadius: 4,
    // marginRight: theme.spacings.medium,
  },
  notificationTextContainer: {
    flex: 1,
  },
  boldText: {
    fontWeight: 'bold',
  },
  redText: {
    color: theme.colors.redPrimary,
  },
  blueText: {
    color: theme.colors.bluePrimary,
  },
  timestamp: {
    color: theme.colors.lightgray,
    fontSize: theme.fonts.sizes.small,
    marginTop: theme.spacings.xsmall,
  },
  actionButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionButton: {
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.small,
    borderRadius: theme.borderRadius.small,
    marginLeft: theme.spacings.small,
  },
  acceptButton: {
    backgroundColor: theme.colors.yellowPrimary,
  },
  rejectButton: {
    backgroundColor: theme.colors.redPrimary,
  },
  actionButtonText: {
    // color: theme.colors.white,
    color: theme.colors.gray,
    fontWeight: 'bold',
    fontSize: theme.fonts.sizes.small,
  },
  loadingIndicator: {
    paddingVertical: theme.spacings.medium,
    alignItems: 'center',
  },
  skeletonThumbnail: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: theme.colors.loadingBackground,
    marginRight: theme.spacings.medium,
  },
  skeletonTextContainer: {
    flex: 1,
  },
  skeletonText: {
    height: 20,
    backgroundColor: theme.colors.loadingBackground,
    borderRadius: 4,
    marginBottom: theme.spacings.xsmall,
  },
  friendButton: {
    // Base style for friend buttons
    paddingHorizontal: theme.spacings.medium,
    paddingVertical: theme.spacings.small,
    borderRadius: theme.borderRadius.small,
    flexDirection: 'row', // Adjust for icon and text
    alignItems: 'center',
    justifyContent: 'center',
    width: 120, // Adjust based on your UI
  },
  acceptedButton: {
    backgroundColor: theme.colors.bluePrimary, // Or any color that indicates a disabled/accepted state
    // opacity: 0.6, // Optional, for visual differentiation
  },
  acceptedButtonText: {
    color: theme.colors.white, // Or any color that indicates a disabled/accepted state
    fontWeight: 'bold',
    fontSize: theme.fonts.sizes.small,
  },
  expiredButton: {
    backgroundColor: theme.colors.lightgray,
    opacity: 0.6, // Optional, for visual differentiation
  },
  iconStyle: {
    marginRight: 0, // Spacing between icon and text
  },
  // Other styles...
});

export default SwipeableNotificationItem;
