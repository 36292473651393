import React, { useEffect, useState, useRef } from 'react';
import { TouchableOpacity, Text, View, StyleSheet, Dimensions } from 'react-native';
import { Image } from 'expo-image';
import theme from "../styles/theme";
import card_styles from "../styles/components/Card.styles";
import globalStyles from "../styles/globalStyles";
import { PanGestureHandler, PinchGestureHandler, TapGestureHandler, State, GestureHandlerRootView } from 'react-native-gesture-handler';
import Animated, { useAnimatedGestureHandler, useAnimatedStyle, useSharedValue, withSpring, withTiming } from 'react-native-reanimated';
import { useQuery } from 'react-query';
import {fetchCardByUUID} from "../utils/api/cardsService";
import { runOnJS } from 'react-native-reanimated';


const screenWidth = Math.min(Dimensions.get('window').width, 600);
const screenHeight = Dimensions.get('window').height
// Assuming you know the aspect ratio of your image, for example 16:9
const aspectRatio = 1 / 1;
const calculatedImageHeight = screenWidth / aspectRatio;
const cardHeight = calculatedImageHeight;

export {screenWidth};

function FullscreenCardSkeleton() {
  return (
    <View style={styles.fullScreenContainer} />
  );
}


const FullScreenOverlay = ({ cardUuid, outerScrollRef}) => {
  const { data: card, isLoading, isError } = useQuery(
    ['cardDetail', cardUuid],
    () => fetchCardByUUID(cardUuid),
    { staleTime: Infinity }
  );

  const [imageUrl, setImageUrl] = useState('');
  // const [hasPinched, setHasPinched] = useState(false);

  useEffect(() => {
    if (card) {
      setImageUrl(card.art);
    }
    else {
    }
  }, [card]);


  // Shared values for pan and zoom
  const scale = useSharedValue(1);
  const accumulatedScale = useSharedValue(1);
  const offsetX = useSharedValue(0);
  const offsetY = useSharedValue(0);
  const isPinching = useSharedValue(false); // Track if currently pinching
  const [isLocked, setIsLocked] = useState(false);

  const overlayOpacity = useSharedValue(1);
  const titlePositionX = useSharedValue(-screenWidth); // Start off-screen

  // 3. Interpolated style for the overlay
  const overlayStyle = useAnimatedStyle(() => {
    // console.log(`overlayOpacity: ${overlayOpacity.value}`)
    return {
      opacity: overlayOpacity.value,
      backgroundColor: 'black',
      // ...StyleSheet.absoluteFillObject, // Makes sure the overlay covers the image
    };
  });

  const resetScaleAndPinching = () => {
    console.log("Resetting scale, position, and pinching");
    accumulatedScale.value = withSpring(1, { damping: 15, stiffness: 100 }); // More damping for a smoother effect
    offsetX.value = withSpring(0, { damping: 15, stiffness: 100 }); // Same adjustment here
    offsetY.value = withSpring(0, { damping: 15, stiffness: 100 }); // And here
    isPinching.value = false;
  };

  // Pan gesture handler
  const panGestureEvent = useAnimatedGestureHandler({
    onStart: (_, context) => {
      context.startX = offsetX.value;
      context.startY = offsetY.value;
    },
    onActive: (event, context) => {
      console.log('panning')
      console.log(`am i pinching? ${isPinching.value}`)
      console.log(`accumulatedScale: ${accumulatedScale.value}`)
      if (isPinching.value | accumulatedScale.value !== 1) { // Only allow panning if pinching
        offsetX.value = context.startX + event.translationX / accumulatedScale.value;
        offsetY.value = context.startY + event.translationY / accumulatedScale.value;
      }
    },
    onEnd: (event) => {
      console.log(`isPinching: ${isPinching.value}`)
      console.log(`accumulatedScale: ${accumulatedScale.value}`)
      if (Math.abs(event.velocityX) > 500 || Math.abs(event.velocityY) > 500 && (isPinching.value || accumulatedScale.value > 1)) {
        console.log("Fast swipe detected");
        runOnJS(resetScaleAndPinching)();
      }
    },
    // No need to reset to initial position unless desired
  });

  // Pinch gesture handler
  const pinchGestureEvent = useAnimatedGestureHandler({
    onStart: (_, context) => {
      context.startScale = accumulatedScale.value;
      isPinching.value = true; // Pinching starts
    },
    onActive: (event, context) => {
      // Calculate the new scale based on the pinch gesture
      let newScale = context.startScale * event.scale;
      // Clamp the new scale to ensure it does not go below 1
      newScale = Math.max(1, newScale);
      accumulatedScale.value = newScale;
    },
    onEnd: () => {
      // Reset the current scale
      scale.value = 1;
      isPinching.value = false; // Pinching ends
    },
  });

  // Double tap gesture handler
  const doubleTapRef = useRef();
  const onDoubleTap = useAnimatedGestureHandler({
    onActive: () => {
      console.log("Double tap detected");
      if (isPinching.value || accumulatedScale.value > 1) {
        runOnJS(resetScaleAndPinching)();
      }
    },
  });

  // Animated style for the image
  const animatedImageStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { translateX: offsetX.value },
        { translateY: offsetY.value },
        { scale: scale.value * accumulatedScale.value },
      ],
    };
  });

  if (isLoading) {
    return <View style={styles.loadingContainer}><Text>Loading...</Text></View>;
  }

  if (isError) {
    return <View style={styles.errorContainer}><Text>Error loading card</Text></View>;
  }

  return (
      <View style={[styles.fullScreenContainer, styles.darkOverlay]}>
        <View style={styles.fullScreenCard}>
          <TouchableOpacity
            style={styles.lockButton}
            onPress={() => setIsLocked(!isLocked)}
          >
          </TouchableOpacity>
          <View style={styles.fullScreenContainer}>
            <View style={styles.imageContainer}>

              <PinchGestureHandler
                onGestureEvent={pinchGestureEvent}
                simultaneousHandlers={outerScrollRef}
              >
                <Animated.View style={animatedImageStyle}>
                  <PanGestureHandler
                    onGestureEvent={panGestureEvent}
                    simultaneousHandlers={outerScrollRef}
                  >
                    <Animated.View>
                      <TapGestureHandler
                        onGestureEvent={onDoubleTap}
                        numberOfTaps={2}
                        ref={doubleTapRef}
                      >
                        <Image source={{uri: imageUrl}} style={[styles.fullScreenImage]}/>
                      </TapGestureHandler>
                    </Animated.View>
                  </PanGestureHandler>
                </Animated.View>
              </PinchGestureHandler>
            </View>
          </View>
        </View>
      </View>
  );
};

const styles = StyleSheet.create({
  fullScreenContainer: {
    alignItems: 'center',
    backgroundColor: theme.colors.gray,
    height: cardHeight,
  },
  fullScreenCard: {
    backgroundColor: theme.colors.gray,
    borderRadius: 14,
    height: cardHeight,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    width: '100%',
    // height: calculatedImageHeight,
    alignItems: 'center',
    backgroundColor: theme.colors.gray,
  },
  fullScreenImage: {
    width: screenWidth,
    height: calculatedImageHeight,
    resizeMode: 'contain',
    // zIndex: 0,
  },
  closeButton: {
    position: 'absolute',
    top: 50,
    right: 20,
    zIndex: 10, // Make sure it's above other elements
  },
  closeButtonText: {
    color: 'white',
    fontSize: 24,
  },
  progressBarContainer: {
    position: 'absolute',
    bottom: -5,
    left: 0,
    right: 0,
    height: 5,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 20,  // Ensure it's above the image
  },
  progressBar: {
    height: '100%',
    backgroundColor: theme.colors.gray,
  },
  audioPlayer: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 25,
    flexDirection: 'row',
    alignItems: 'center',  // Align items vertically center
    justifyContent: 'center',  // Align items horizontally center
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  nextCard: {
    position: 'absolute',
    bottom: 0,
    left: 20,
    right: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 25,
    flexDirection: 'row',
    alignItems: 'center',  // Align items vertically center
    justifyContent: 'center',  // Align items horizontally center
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },

  audioText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
  lockButton: {
    position: 'absolute',
    top: 50,
    left: 20,
    zIndex: 10, // Make sure it's above other elements
  },
  lockText: {
    color: 'white',
    fontSize: 24,
  },
  bottomOptions: {
    position: 'absolute',
    bottom: -10,
    left: 10,
    zIndex: 100, // Make sure it's above other elements
  },
  titleStyle: {
    position: 'absolute',
    bottom: -12,
    ...globalStyles.cardName,
    right: 0,
    textAlign: 'center',
  },
  nameStyle: {
    ...card_styles.name, // Spread the existing styles
    // Add additional styles specific to this page
    // zIndex: 20,  // Ensure it's above the image
    fontSize: 20, // Example: Change the font size
    // Add other styles as needed
  },
});


export default FullScreenOverlay;
export { styles, screenHeight, cardHeight, FullscreenCardSkeleton }
