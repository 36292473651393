import React, { useEffect, useState } from 'react';
import { View, Animated, Text, StyleSheet } from 'react-native';
import theme from "../styles/theme";

const whimsicalMessages = [
  "charging crystal camels",
  "weaving unicorn manes",
  "twisting troll toes",
  "simmering seer's soup",
];


const WhimsicalMessages = ({ progress, isAnimating }) => {
  const [currentMessage, setCurrentMessage] = useState('');
  const fadeAnim = new Animated.Value(0);

  useEffect(() => {
    if (isAnimating) {
      const index = Math.min(Math.floor(progress), whimsicalMessages.length - 1);
      setCurrentMessage(whimsicalMessages[index]);

      // Fade in new message
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start();
    } else {
      // Fade out message
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start(() => {
        // setCurrentMessage(''); // Clear message when not animating
      });
    }
  }, [progress, isAnimating, fadeAnim]);

  // if (!isAnimating) {
  //   return null;
  // }

  return (
    <View style={styles.container}>
      <Animated.Text style={
        [styles.message,
          { opacity: fadeAnim },
        ]}>
        {currentMessage}
      </Animated.Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10, // Adjust the padding as needed
    // height: 20, // Adjust the height as needed
    marginTop: 3, // Adjust the margin as needed
    marginBottom: 3, // Adjust the margin as needed
  },
  message: {
    fontSize: theme.fonts.scaledSizes.medium, // Adjust the font size as needed
    textAlign: 'center',
    color: theme.colors.lightgray,
  },
});

export default WhimsicalMessages;
