import React, { useEffect, useRef } from 'react';
import { View, Text, StyleSheet, Animated } from 'react-native';
import { Image } from 'expo-image';
import theme from '../styles/theme';
import SvgRiff from "../assets/images/Riff";
import DemoUserCircle from "./DemoUserCircle";

const StaticMiniCard = ({
  imageSource,
  title,
  description,
  username,
  userImageSource,
  startAnimation,
  initialOpacity = 0,
  endingRiffButtonY = 7,
}) => {
  const opacityAnim = useRef(new Animated.Value(initialOpacity)).current;
  const translateYAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (startAnimation) {
      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: true,
      }).start();

      // Animation sequence for pressing down the SvgRiff
      Animated.sequence([
        Animated.timing(translateYAnim, {
          toValue: endingRiffButtonY,  // Move down by 10 units
          duration: 400,
          useNativeDriver: true
        }),
        Animated.timing(translateYAnim, {
          toValue: 0,  // Move back to original position
          duration: 100,
          useNativeDriver: true
        }),
      ]).start();
    } else {
      opacityAnim.setValue(initialOpacity);
      translateYAnim.setValue(0);  // Reset position when not animating
    }
  }, [startAnimation, opacityAnim, translateYAnim]);


  const getTransformStyle = () => {
    return { opacity: opacityAnim }
  };

  return (
    <Animated.View
      style={[
        styles.cardContainer,
        getTransformStyle(),
      ]}
    >
      <Image
        source={imageSource}
        style={styles.imageStyle}
        resizeMode="cover"
      />
      <Text style={styles.cardName} numberOfLines={2}>
        {title}
      </Text>
      <Text style={styles.flavor} numberOfLines={4}>
        {description}
      </Text>
      <View style={styles.riffRow}>
        <View style={styles.usernameImageContainer}>
          <DemoUserCircle imagePath={userImageSource} size={30} />
          <Text style={styles.username}>{username}</Text>
        </View>
        <Animated.View style={{ transform: [{ translateY: translateYAnim }] }}>
          <SvgRiff />
        </Animated.View>
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: 200,
    margin: theme.spacings.xsmall,
    backgroundColor: theme.colors.gray,
    borderRadius: theme.borderRadius.medium,
    padding: theme.spacings.small,
  },
  riffRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
  },
  imageStyle: {
    width: '100%',
    height: 200 - 10,
    borderRadius: theme.borderRadius.small,
  },
  cardName: {
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.redPrimary,
    marginTop: theme.spacings.xsmall,
  },
  flavor: {
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.small,
    color: theme.colors.white,
    marginTop: theme.spacings.xsmall,
    marginBottom: theme.spacings.xsmall,
  },
  username: {
    color: theme.colors.lighterBlue,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.scaledSizes.large,
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: theme.spacings.xsmall
  },
  usernameImageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default StaticMiniCard;
