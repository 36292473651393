import React from 'react';
import {Image} from 'expo-image';
import {Gesture, GestureDetector} from 'react-native-gesture-handler';
import Animated, {runOnJS} from 'react-native-reanimated';

const NormalImage = ({ source, onPinch, style, enableGestures = true }) => {

  const zoomGesture = Gesture.Pinch()
  .onBegin(() => {
    runOnJS(onPinch)();
  })

  const tapGesture = Gesture.Tap()
  .numberOfTaps(2)
  .onEnd(() => {
    runOnJS(onPinch)();
  })
  const gesture = Gesture.Simultaneous(zoomGesture, tapGesture);


  const content = (
    <Animated.View>
      <Image source={source} style={style} />
    </Animated.View>
  );

  if (enableGestures) {
    return <GestureDetector gesture={gesture}>{content}</GestureDetector>;
  } else {
    return content;
  }
};

export default NormalImage;
