import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const SvgSiblingButtonRight = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={45}
    fill="none"
    {...props}
  >
    <Circle
      cx={22.5}
      cy={22.5}
      r={21.75}
      fill="#F9C430"
      stroke="#896500"
      strokeWidth={1.5}
    />
    <Path
      fill="#333"
      d="M9 21.25h-.75v1.5H9zM39 22l-7.5-4.33v8.66zm-28.875.75h.75v-1.5h-.75zm3-1.5h-.75v1.5h.75zm6.75 1.5h.75v-1.5h-.75zm3-1.5h-.75v1.5h.75zm2.25 1.5h.75v-1.5h-.75zm3-1.5h-.75v1.5h.75zm6.75 1.5h.75v-1.5h-.75zm3-1.5h-.75v1.5h.75zM9 22.75h1.125v-1.5H9zm4.125 0h6.75v-1.5h-6.75zm9.75 0h2.25v-1.5h-2.25zm5.25 0h6.75v-1.5h-6.75z"
    />
  </Svg>
);
export default SvgSiblingButtonRight;

