import React from 'react';
import {Modal, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {Image} from "expo-image";
import theme from "../../styles/theme";
import {useNavigation} from "@react-navigation/native";
import {MaterialIcons} from "@expo/vector-icons";
import SvgFabylHeader from "../../assets/images/FabylHeader";
import globalStyles from "../../styles/globalStyles";

const LoginPromptModal = ({ isVisible, onConfirm, onCancel }) => {
  const navigation = useNavigation();

  const handleLoginPress = () => {
    navigation.navigate("Unauthenticated", {screen: "Login"});
    onCancel();
  }

  const handleSignupPress = () => {
    navigation.navigate("Unauthenticated", {screen: "SignUp"});
    onCancel();
  }

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={onCancel}>
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <TouchableOpacity
            style={styles.closeButton}
            onPress={onCancel} // Use the onCancel function to close the modal
          >
            <MaterialIcons name="close" size={24} color={theme.colors.white} />
          </TouchableOpacity>
          <View>
            <SvgFabylHeader style={{transform: [{scale: 1}]}} fillColor={theme.colors.white}/>
          </View>
          <Text style={[globalStyles.brandPhrase, {color: theme.colors.white}]}>weave whimsical worlds</Text>
          <View style={styles.authOptionsContainer}>
            <TouchableOpacity onPress={handleLoginPress}>
              <Text style={styles.link}>LOGIN</Text>
            </TouchableOpacity>
            <Text style={styles.orText}>or</Text>
            <TouchableOpacity onPress={handleSignupPress}>
              <Text style={styles.link}>SIGN UP</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacings.small,
    right: theme.spacings.small,
  },
  fabylPremiumButton: {
    width: 1316 / 4.2,
    height: 750 / 4.2,
    backgroundColor: theme.colors.yellowPrimary,
    marginTop: theme.spacings.medium,
    marginBottom: theme.spacings.xxlarge,
    borderRadius: 21,
  },
  time: {
    fontFamily: theme.fonts.family.bold,
  },
  mage: {
    fontSize: 30,
  },
  link: {
    color: theme.colors.bluePrimary,
    textDecorationLine: 'underline',
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.xlarge,
  },
  character: {
    width: 250,
    height: 400,
    tintColor: theme.colors.white,
    marginBottom: theme.spacings.large,
  },
  modalView: {
    margin: theme.spacings.large,
    backgroundColor: theme.colors.gray,
    borderRadius: theme.borderRadius.large,
    padding: theme.spacings.large,
    alignItems: 'center',
    shadowColor: theme.colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '80%',
    maxWidth: 400,
  },
  authOptionsContainer: {
    alignSelf: 'center', // Stretch the container to the width of the parent
    alignItems: 'center', // Center items horizontally in the container
    justifyContent: 'center', // Center items vertically
    flexDirection: 'column', // Arrange items in a column
    marginTop: theme.spacings.large, // Adjust as needed
    marginBottom: theme.spacings.xxlarge, // Adjust as needed
  },
  orText: {
    color: theme.colors.white, // Or any other color from your theme
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.medium,
    marginVertical: theme.spacings.small, // Space above and below the "or" text
    textAlign: 'center', // Center the text if needed
  },
  modalText: {
    marginBottom: theme.spacings.medium,
    textAlign: 'center',
    fontWeight: theme.fonts.weight.bold,
    fontSize: theme.fonts.sizes.xlarge,
    color: theme.colors.redPrimary,
    fontFamily: theme.fonts.family.bold,
  },
  descriptionText: {
    marginBottom: theme.spacings.medium,
    textAlign: 'center',
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.white,
    fontFamily: theme.fonts.family.regular,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  button: {
    borderRadius: theme.borderRadius.medium,
    paddingVertical: theme.spacings.small,
    elevation: 2,
    marginBottom: theme.spacings.small,
    width: '40%',
  },
  buttonText: {
    color: theme.colors.white,
    textAlign: 'center',
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.medium,
    textDecorationLine: 'underline',
  },
});

export default LoginPromptModal;
