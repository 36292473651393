import React, { useRef, useEffect, useCallback } from 'react';
import { View, Button, Text, TouchableOpacity, SafeAreaView, StyleSheet, Platform } from 'react-native';
import { Image } from 'expo-image';
// import styles from '../styles/components/Card.styles';
import CreateCard from "../components/CreateCard";
import theme, {moderateScaleSize} from "../styles/theme";
import globalStyles from "../styles/globalStyles";
import { MaterialIcons } from '@expo/vector-icons';
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import {useQuery} from "react-query";
import {fetchCanRiffStatus} from "../utils/api/cardsService";
import {useFocusEffect} from "@react-navigation/native";


const useCanRiffStatus = (uuid) => {
  return useQuery(['canRiffStatus', uuid], () => fetchCanRiffStatus(uuid), {
    staleTime: 0, // stale right away
    cacheTime: 600000, // 10 minutes
    refetchOnMount: true, // Refetch on mount
    refetchOnWindowFocus: true, // Refetch on window focus
  });
};


const RiffCardScreen = ({ route, navigation }) => {
  const isLargeScreen = useIsLargeScreen();
  const { card } = route.params;
  const inputRef = useRef(null);
  const { data, isLoading, error, refetch } = useCanRiffStatus(card.uuid);

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [card.uuid])
  );

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => inputRef.current.focus(), 100);
    }
  }, []);

  let artStyle;
  if (Platform.OS === 'web') {
    artStyle = localStyles.webArtStyle;
  }
  else {
    artStyle = localStyles.artStyle;
  }

  const handleGoToCard = () => {
    if (Platform.OS === 'web') {
      // goBack not working right on web
      navigation.navigate('CardDetail', { card_uuid: card.uuid });
    }
    else {
      navigation.goBack();
    }
  }

  const canRiff = data?.can_riff;

  const renderRiffStatusMessage = (code) => {
    switch (code) {
      case 'NOT_FRIENDS_WITH_A_CONTRIBUTOR':
        return (
          <Text style={localStyles.messageText}>
            <Text style={localStyles.underlineText}>Only friends</Text> of world contributors can riff on this card and in this world.
          </Text>
        );
      case 'WORLD_IS_PRIVATE':
        return (
          <Text style={localStyles.messageText}>
            <Text style={localStyles.underlineText}>Only contributors</Text> to this
            world can riff on this card and in this world.
          </Text>
        );
      case 'NOT_IN_CUSTOM_ALLOWED_USERS':
        return (
          <Text style={localStyles.messageText}>
            <Text style={localStyles.underlineText}>Only allowed users</Text> can riff
            on this card and in this world.
          </Text>
        );
      case 'GENERIC_NOT_ALLOWED_TO_RIFF':
        return (
          <Text style={localStyles.messageText}>
            You are not allowed to riff on this card.
          </Text>
        );
      case 'CURRENT_USER_BLOCKED_RESOURCE_USER':
        return (
          <Text style={localStyles.messageText}>
            You have blocked this user.
          </Text>
        );
      default:
        return (
          <Text style={localStyles.messageText}>
            You cannot riff on this card.
          </Text>
        );
    }
  };

  return (
    <SafeAreaView style={localStyles.flexContainer}>
      <View style={[localStyles.container]}>

        <View style={localStyles.pullIndicator} />

        <TouchableOpacity
          style={[localStyles.closeIcon]}
          onPress={handleGoToCard}
          hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }} // Adds extra touchable space
        >
          <MaterialIcons name="close" size={24} color="#000" />
        </TouchableOpacity>

        <Text style={localStyles.title}>Riffing on</Text>
        <Text
          style={[globalStyles.cardName, localStyles.cardName]}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          {card.name}
        </Text>

        <View style={localStyles.separator} />

        <CreateCard
          cardRiffedFrom={card.uuid}
          inputRef={inputRef}
          placeholder={canRiff ? "NAME THE NEXT FABYL" : "CANNOT RIFF"}
          canRiff={canRiff}
        />
        <View style={localStyles.cardInfo}>
          <TouchableOpacity onPress={handleGoToCard}>
            <Image
              source={{ uri: card.art }}
              style={artStyle}
            />
          </TouchableOpacity>
        </View>

        {/* Other screen elements as needed */}
        {!canRiff && (
          <View style={localStyles.cannotRiffContainer}>
            {renderRiffStatusMessage(data?.code)}
          </View>
        )}


      </View>
    </SafeAreaView>
  );
};

const localStyles = StyleSheet.create({
  flexContainer: {
    flex: 1,
    backgroundColor: theme.colors.beige,
  },
  container: {
    padding: 10,
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 10,
    paddingLeft: 20,
    paddingBottom: 20,
  },
  title: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.gray,
    textAlign: 'center',
    // marginBottom: 10,
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginVertical: 10,
  },
  cardInfo: {
    alignItems: 'center',
  },
  artStyle: {
    width: moderateScaleSize(110), // Adjust as needed
    height: moderateScaleSize(110), // Adjust as needed
    resizeMode: 'cover',
    borderRadius: 10,
  },
  webArtStyle: {
    width: moderateScaleSize(250), // Adjust as needed
    height: moderateScaleSize(250), // Adjust as needed
    resizeMode: 'cover',
    alignSelf: 'center',
    borderRadius: 10,
  },
  cardName: {
    alignSelf: 'center',
    marginVertical: theme.spacings.small,
    fontSize: theme.fonts.sizes.xlarge,
    lineHeight: theme.fonts.sizes.xlarge,
    minHeight: 2 * theme.fonts.sizes.xlarge,
  },
  pullIndicator: {
    alignSelf: 'center',
    width: 40, // Width of the indicator
    height: 5, // Height of the indicator
    borderRadius: 2.5, // Rounded corners
    backgroundColor: '#ccc', // Color of the indicator
    marginTop: 0, // Top margin
    marginBottom: 15, // Space before the next content
  },
  cannotRiffContainer: {
    marginVertical: theme.spacings.medium,
  },
  errorText: {
    color: theme.colors.redPrimary,
    // marginVertical: theme.spacings.small,
  },
  messageText: {
    color: theme.colors.redPrimary, // Adjust the text color as needed
    textAlign: 'center',
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.medium,
  },
  underlineText: {
    textDecorationLine: 'underline',
  },
});

export default RiffCardScreen;
