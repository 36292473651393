import React, {useState, useEffect, useContext, useRef} from 'react';
import {View, TextInput, StyleSheet, Text, Alert} from 'react-native';
import {Auth} from 'aws-amplify';
import {AuthContext} from "../contexts/AuthContext";
import PrimaryButton from "../components/PrimaryButton";
import theme from "../styles/theme";
import styles from "../styles/Settings.styles";
import alert from "../utils/alert";

const UpdateEmailScreen = ({route, navigation}) => {
  const [newEmail, setNewEmail] = useState('');
  const {user} = useContext(AuthContext);
  const emailInputRef = useRef(null);

  useEffect(() => {
    // Automatically focus the TextInput when the screen is loaded
    emailInputRef.current.focus();
  }, []);

  const handleSubmitNewEmail = async () => {
    // Validate the new email format here before proceeding
    try {
      // Make sure to await the currentAuthenticatedUser promise to get the user object
      if (newEmail.trim() === user.attributes.email) {
        alert("The entered email is the same as your current email.");
        return;
      }
      const response = await Auth.updateUserAttributes(user, {
        'email': newEmail,
      });

      // Proceed to OTP Screen and pass a callback to refresh user after successful update
      navigation.navigate('OtpScreen', {
        username: newEmail,
        verificationType: 'emailUpdate',
      });
    } catch (e) {
      console.error('Error updating email', e);
      // Handle the error, possibly showing an alert to the user
    }
  };
  return (
    <View style={styles.container}>
      <Text style={styles.title}>UPDATE EMAIL</Text>
      <View style={styles.currentContactRow}>
        <Text style={styles.currentContactText}>Current Email: </Text>
        <Text style={styles.currentContact}>{user?.attributes?.email || 'No email set'}</Text>
      </View>
      <TextInput
        ref={emailInputRef}
        style={styles.input}
        value={newEmail}
        onChangeText={setNewEmail}
        placeholder="Enter new email"
        keyboardType="email-address"
        autoCapitalize={'none'}
        autoCorrect={false}
        autoCompleteType={'email'}
        placeholderTextColor={theme.colors.gray}
      />
      <PrimaryButton title="SUBMIT NEW EMAIL" onPress={handleSubmitNewEmail}/>
    </View>
  );
};

export default UpdateEmailScreen;
