import React, {useState, useRef} from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Text,
  ScrollView,
  Dimensions
} from 'react-native';
import theme from "../styles/theme";
import Slide1 from "../components/demo/Slide1";
import Slide2 from "../components/demo/Slide2";
import Slide3 from "../components/demo/Slide3";

const {width} = Dimensions.get('window');
const MAX_WIDTH = 500;
const thisWidth = Math.min(width, MAX_WIDTH);


const TutorialScreenWeb = ({navigationTarget, getStartedNavigationTarget}) => {
  console.log(`navigationTarget: ${navigationTarget}`);
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollViewRef = useRef(null);

  const handleIndexChanged = (index) => {
    scrollViewRef.current.scrollTo({x: thisWidth * index, animated: true});
    setActiveIndex(index);
  };

  return (
    <View style={styles.container}>
      <SafeAreaView style={styles.safeArea}>
        <ScrollView
          horizontal
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          ref={scrollViewRef}
          onMomentumScrollEnd={(e) => {
            const index = Math.round(e.nativeEvent.contentOffset.x / width);
            setActiveIndex(index);
          }}
          style={styles.scrollView}
        >
          <View style={styles.slideWrapper}>
            <Slide1
              startSlideAnimation={activeIndex === 0}
              navigationTarget={navigationTarget}
            />
          </View>
          <View style={styles.slideWrapper}>
            <Slide2
              startSlideAnimation={activeIndex === 1}
              navigationTarget={navigationTarget}
            />
          </View>
          <View style={styles.slideWrapper}>
            <Slide3
              startSlideAnimation={activeIndex === 2}
              navigationTarget={navigationTarget}
              getStartedNavigationTarget={getStartedNavigationTarget}
            />
          </View>
        </ScrollView>
        <View style={styles.pagination}>
          {[0, 1, 2].map((i) => (
            <Text key={i}
                  style={[styles.dot, activeIndex === i && styles.activeDot]}>•</Text>
          ))}
        </View>
        <View style={styles.buttonWrapper}>
          <Text style={styles.buttonText}
                onPress={() => handleIndexChanged(Math.max(activeIndex - 1, 0))}>‹</Text>
          <Text style={styles.buttonText}
                onPress={() => handleIndexChanged(Math.min(activeIndex + 1, 2))}>›</Text>
        </View>
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.gray,
  },
  safeArea: {
    flex: 1,
    backgroundColor: theme.colors.beige,
    maxWidth: 500,
    alignSelf: 'center',
  },
  slideWrapper: {
    width: '100vw',
    maxWidth: 500,
    // height: '100vh',
  },
  scrollView: {
    flex: 1,
    flexDirection: 'row',
  },
  pagination: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 10,
    width: '100%',
  },
  dot: {
    fontSize: 50,
    color: theme.colors.verylightgray,
    marginHorizontal: 5,
  },
  activeDot: {
    color: theme.colors.lightgray,
  },
  buttonWrapper: {
    position: 'absolute',
    top: "50vh",
    left: 0,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    height: 60,
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 46,
    color: theme.colors.bluePrimary,
  },
});

export default TutorialScreenWeb;
