import React from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, Linking } from 'react-native';
import {Image} from "expo-image";
import { useRoute } from '@react-navigation/native';
import { useQuery } from 'react-query';
import { MaterialIcons } from '@expo/vector-icons';
import { fetchCardByUUID } from '../utils/api/cardsService';
import theme from '../styles/theme';

const CardDeveloperDetails = () => {
  const route = useRoute();
  const { cardUuid } = route.params;

  const { data: card, isLoading, isError } = useQuery(['cardDetail', cardUuid], () => fetchCardByUUID(cardUuid));

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (isError) {
    return <Text>Error loading card details</Text>;
  }

  const handleSearchOnGoogle = (text) => {
    const url = `https://www.google.com/search?q=${encodeURIComponent(text)}`;
    Linking.openURL(url).catch((err) => console.error('An error occurred', err));
  };

  return (
    <ScrollView style={styles.container}>
      <Text style={styles.title}>Developer Details</Text>

      {/* Basic Card Info */}
      <View style={styles.cardContainer}>
        <View style={styles.cardTopRow}>
          <Image source={{ uri: card.thumbnail }} style={styles.cardThumbnail} />
          {/*<View style={styles.cardInfo}>*/}
            <Text style={styles.cardName}>{card.name}</Text>
          {/*</View>*/}
        </View>
        <Text style={styles.cardDescription}>{card.flavor}</Text>
      </View>


      {card.diversifiers && (
        <View style={styles.diversifiersContainer}>
          <Text style={styles.diversifiersTitle}>DIVERSIFIERS:</Text>
          {card.diversifiers.map((diversifier, index) => (
            <View
              key={diversifier.id}
              style={[
                styles.diversifierItem,
                index % 2 === 0 ? styles.evenItem : styles.oddItem,
              ]}
            >
              <TouchableOpacity
                style={styles.searchIcon}
                onPress={() => handleSearchOnGoogle(diversifier.name)}
                hitSlop={{
                  top: theme.spacings.small,
                  bottom: theme.spacings.small,
                  left: theme.spacings.small,
                  right: theme.spacings.small,
                }}
              >
                <MaterialIcons name="search" size={34} color={theme.colors.gray} />
              </TouchableOpacity>
              <Text style={styles.diversifierType} selectable>{diversifier.diversifier_type}</Text>
              <Text style={styles.diversifierName} selectable>{diversifier.name}</Text>
              <Text style={styles.diversifierDescription} selectable>{diversifier.description}</Text>
              {index < card.diversifiers.length - 1 && (
                <View style={styles.divider} />
              )}
            </View>
          ))}
        </View>
      )}
      {/* Add other developer-specific information here */}
      <View style={{marginVertical: theme.spacings.xlarge}}></View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: theme.spacings.large,
    backgroundColor: theme.colors.background,
  },
  title: {
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.primary,
    marginBottom: theme.spacings.medium,
  },
  diversifiersContainer: {
    marginTop: theme.spacings.large,
  },
  diversifiersTitle: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.gray,
    marginBottom: theme.spacings.small,
  },
  diversifierItem: {
    padding: theme.spacings.small,
    borderRadius: theme.borderRadius.medium,
    borderWidth: StyleSheet.hairlineWidth,
    position: 'relative',
    marginVertical: theme.spacings.small,
  },
  evenItem: {
    backgroundColor: theme.colors.loadingBackground,
  },
  oddItem: {
    backgroundColor: theme.colors.loadingBackground,
  },
  searchIcon: {
    position: 'absolute',
    top: theme.spacings.small,
    right: theme.spacings.small,
    zIndex: 1,
  },
  diversifierType: {
    fontSize: theme.fonts.sizes.medium,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.redPrimary,
    marginBottom: theme.spacings.xsmall,
  },
  diversifierName: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.bluePrimary,
    marginBottom: theme.spacings.xsmall,
  },
  diversifierDescription: {
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.text,
  },
  divider: {
    height: 1,
    backgroundColor: theme.colors.divider,
    width: '100%',
    marginVertical: theme.spacings.medium,
  },
  cardTopRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacings.medium,
    marginHorizontal: theme.spacings.medium,
  },
  cardContainer: {
    padding: 10,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.divider,
    marginBottom: theme.spacings.medium,
    backgroundColor: theme.colors.gray,
    borderRadius: theme.borderRadius.medium,
  },
  cardThumbnail: {
    width: 80,
    height: 80,
    marginRight: theme.spacings.medium,
    borderRadius: 5,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.beige,
  },
  cardInfo: {
    flex: 1,
  },
  cardName: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.medium,
    color: theme.colors.redPrimary,
  },
  cardDescription: {
    fontSize: theme.fonts.sizes.small,
    color: theme.colors.white,
  },
});

export default CardDeveloperDetails;
