import React from 'react';
import { View, Text, Modal, StyleSheet, TouchableOpacity } from 'react-native';
import theme from "../../styles/theme";
import { MaterialIcons } from "@expo/vector-icons";

const DisclaimerModal = ({ isVisible, onConfirm, onCancel }) => {
  return (
    <Modal visible={isVisible} transparent={true} animationType="fade">
      <View style={styles.modalOverlay}>
        <TouchableOpacity style={styles.overlayTouchable} onPress={onCancel} />
        <View style={styles.modalContent}>
          <TouchableOpacity style={styles.closeButton} onPress={onCancel}>
            <MaterialIcons name="close" size={24} color={theme.colors.black} />
          </TouchableOpacity>
          <Text style={styles.title}>DISCLAIMER</Text>
          <Text style={styles.disclaimerText}>
            Content made with Fabyl is created using AI technology and is entirely fictional.
            {"\n\n"}
            It should not be taken as real or accurate.
            {"\n\n"}
            Do you understand?
          </Text>
          <TouchableOpacity style={styles.disclaimerButton} onPress={onConfirm}>
            <Text style={styles.buttonText}>I UNDERSTAND.</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  overlayTouchable: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  modalContent: {
    width: '80%',
    maxWidth: 500,
    padding: 20,
    backgroundColor: theme.colors.beige,
    borderRadius: theme.borderRadius.large,
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacings.small,
    right: theme.spacings.small,
  },
  title: {
    alignSelf: 'center',
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    marginBottom: theme.spacings.medium,
    color: theme.colors.black,
  },
  disclaimerText: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.regular,
    marginBottom: theme.spacings.medium,
    // textAlign: 'center',
  },
  disclaimerButton: {
    backgroundColor: theme.colors.bluePrimary,
    padding: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: theme.colors.white,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
  },
});

export default DisclaimerModal;
