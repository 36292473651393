import React, { memo } from 'react';
import {
  View,
  Image,
  useWindowDimensions,
  StyleSheet, TouchableOpacity, Text, ActivityIndicator
} from 'react-native';
import SimplifiedWorldComponent from "./SimplifiedWorldComponent";
import { MasonryFlashList } from "@shopify/flash-list";
import GallerySkeletonLoader from "./skeletons/GallerySkeletonLoader";
import NoWorldsComponent from "./NoWorldsComponent";
import theme from "../styles/theme";
import {useFocusEffect} from "@react-navigation/native";
import {MaterialIcons, Ionicons} from "@expo/vector-icons";
import {useNavigation} from "@react-navigation/native";
import {useProfile} from "../contexts/ProfileContext";


const GetFriendsHeader = memo(({ headerComponent, hasFriends, worlds }) => {
  const navigation = useNavigation();

  if (hasFriends) {
    return headerComponent;
  }

  if (worlds.length === 0) {
    return headerComponent;
  }

  return (
    <View style={{alignItems: 'center', marginTop: 20}}>
      <NoWorldsComponent
        title={"Make Stories With Friends"}
        content={"Invite friends and create stories together here."}
        actionComponent={
          <TouchableOpacity
            style={styles.addFriendButton}
            onPress={() => {
              navigation.navigate('UserSearch');
            }}
          >
            <MaterialIcons name="person-add" size={24} color={theme.colors.bluePrimary} />
            <Text style={styles.addFriendButtonText}>Find Friends</Text>
          </TouchableOpacity>
        }
      />
      <View style={styles.characterRow}>
        <Image
          source={require("../assets/images/characters/SpaceChef_small.png")}
          style={styles.spacechef_small}
          contentFit='none'
        />
        <Image
          source={require("../assets/images/characters/Frankenpug_small.png")}
          style={styles.frankenpug_small}
          contentFit='none'
        />
        <Image
          source={require("../assets/images/characters/SteampunkHillbilly_medium.png")}
          style={styles.steampunkhillbilly_small}
          contentFit='none'
        />
      </View>
    </View>
  );
});


const EmptyOrLoadingComponent = memo(({loading, hasFriends}) => {
  const navigation = useNavigation();

  if (loading) {
    return (
      <View>
        <GallerySkeletonLoader/>
      </View>
    )
      ;
  } else {
    // Return an alternative empty state view when not loading
    return (
      <View style={{alignItems: 'center', marginTop: 0}}>
        <NoWorldsComponent
          title={"Make Stories With Friends"}
          content={"Invite friends and create stories together here."}
          actionComponent={
            <View>
              <TouchableOpacity
                style={styles.addFriendButton}
                onPress={() => {
                  navigation.navigate('UserSearch');
                }}
              >
                <MaterialIcons name="person-add" size={24} color={theme.colors.bluePrimary} />
                <Text style={styles.addFriendButtonText}>Find Friends</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.browseAllButton}
                onPress={() => {
                  navigation.navigate('AllCards');
                }}
              >
                <Ionicons name="earth" size={24} color={theme.colors.redPrimary} />
                <Text style={styles.browseAllButtonText}>Browse All Fabyls</Text>
              </TouchableOpacity>
            </View>
          }
        />
        <Image
          source={require("../assets/images/characters/Frankenpug_medium.png")}
          style={styles.frankenpug}
          contentFit='none'
        />
      </View>
    );
  }
});



function ProtoWorldsGallery({ nextPageURL, worlds, loadMore, loading, onRefresh, headerComponent }) {
    const { width } = useWindowDimensions(); // Get the current window width
    const { profileData, loading: profileLoading } = useProfile();
    const allLoading = profileLoading || loading;
    // Define the desired width for each item (you can adjust this value)
    // const itemWidth = windowWidth / 3 + 20;
    const itemWidth =  Math.min(width / 3, 200);

    // Calculate the number of columns by dividing the screen width by the item width
    // Use Math.floor to ensure the number of columns is a whole number
    const numColumns = Math.floor(width / itemWidth);

    useFocusEffect(
      React.useCallback(() => {
        onRefresh();
      }, [])
    );


    const renderItem = ({ item }) => <SimplifiedWorldComponent world={item} />;

    return (
        <MasonryFlashList
            data={worlds}
            renderItem={renderItem}
            ListHeaderComponent={<GetFriendsHeader headerComponent={headerComponent} hasFriends={profileData && profileData.has_friends} worlds={worlds}/>}
            keyExtractor={item => item.uuid}
            numColumns={numColumns} // Use the dynamically calculated number of columns
            onEndReached={() => {
                if (!loading && nextPageURL) {
                    loadMore(nextPageURL);
                }
            }}
            onEndReachedThreshold={0.7}
            ListEmptyComponent={<EmptyOrLoadingComponent
              loading={allLoading}
              hasFriends={profileData && profileData.has_friends}
            />}
            refreshing={loading}
            onRefresh={onRefresh}
            estimatedItemSize={300} // Adjust if necessary based on your content
        />
    );
}

const styles = StyleSheet.create({
  frankenpug: {
    alignSelf: 'center',
    marginTop: theme.spacings.medium,
    width: 150,
    height: 392,
  },
  frankenpug_small: {
    alignSelf: 'center',
    marginTop: theme.spacings.medium,
    width: 75,
    height: 196,
    marginHorizontal: theme.spacings.medium
  },
  steampunkhillbilly_small: {
    alignSelf: 'center',
    marginTop: theme.spacings.medium,
    width: 80,
    height: 150,
    marginHorizontal: theme.spacings.medium
  },
  spacechef_small: {
    alignSelf: 'center',
    marginTop: theme.spacings.medium,
    width: 100,
    height: 150,
    marginLeft: theme.spacings.medium,
    marginRight: theme.spacings.small,
  },
  addFriendButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10, // Adjust the margin as needed
  },
  addFriendButtonText: {
    color: theme.colors.bluePrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
    marginLeft: 5, // Space between icon and text
  },
  browseAllButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  browseAllButtonText: {
    color: theme.colors.redPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
    marginLeft: 5, // Space between icon and text
  },
  createCardsButtonText: {
    color: theme.colors.redPrimary,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.medium,
    marginLeft: 5, // Space between icon and text
  },
  characterRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }
})

export default ProtoWorldsGallery;
export { EmptyOrLoadingComponent, GetFriendsHeader };
