import React, {useEffect, useState, useContext, useRef} from 'react';
import {
  View,
  TextInput,
  Button,
  StyleSheet,
  Text,
  Alert,
  Platform,
  TouchableOpacity,
  KeyboardAvoidingView,
  Keyboard,
  TouchableWithoutFeedback,
  Linking,
  SafeAreaView,
} from 'react-native';
import {useNavigation} from "@react-navigation/native";
import {AuthContext} from '../contexts/AuthContext';
import {useProfile} from '../contexts/ProfileContext';
import {Auth} from 'aws-amplify';
import theme from "../styles/theme";
import globalStyles from "../styles/globalStyles";
import SvgFabylHeader from "../assets/images/FabylHeader";
import VorkovImage from "../assets/images/characters/Vorkov.png";
import alert from "../utils/alert";
import {Image} from "expo-image";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import AppStoreBadge from "../assets/App_Store_Badge";
import Constants from 'expo-constants';


const APP_STORE = process.env.EXPO_PUBLIC_IOS_APP_URL
const PRIVACY_POLICY = process.env.EXPO_PUBLIC_PRIVACY_POLICY_URL;
const TERMS_OF_USE = process.env.EXPO_PUBLIC_TERMS_OF_USE_URL;


function LoginScreen({}) {
  const navigation = useNavigation();
  const isLargeScreen = useIsLargeScreen();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const {signIn} = useContext(AuthContext);
  const {refetchProfileData} = useProfile(); // Use UserContext
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const usernameInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const openURL = (url) => {
    Linking.canOpenURL(url)
    .then((supported) => {
      if (!supported) {
        console.log("Can't handle URL: " + url);
      } else {
        return Linking.openURL(url);
      }
    })
    .catch((err) => console.error('An error occurred', err));
  };

  const handleLogin = async () => {
    if (!username || !password) {
      alert('Please enter both username and password.');
      return;
    }
    setIsLoggingIn(true);
    try {
      await signIn({username, password});
      await refetchProfileData();
    } catch (error) {
      if (error.code === 'UserNotConfirmedException') {
        // The user has not completed the sign-up process and needs to confirm their account
        // Resend the code and navigate to the OTP screen
        try {
          await Auth.resendSignUp(username);
          navigation.navigate('OtpScreen', {
            username: username,
            verificationType: 'signUp',
          });
        } catch (resendError) {
          alert('Error', resendError.message);
          setIsLoggingIn(false);
        }
      } else {
        alert('Error', error.message);
        setIsLoggingIn(false);
      }
      if (error.response) {
        console.error("Server responded with:", error.response.data);
        setIsLoggingIn(false);
      }
    }
  };

  const handleAppStorePress = () => {
    openURL(APP_STORE);
  }

  const isNotWeb = Platform.OS !== 'web';

  const handleSignUp = () => {
    // navigation.navigate('SignUp');
    // ^ Switch to screen above when we have multiple signup options
    navigation.navigate('EmailSignUpScreen');
  }

  const dismissKeyboard = () => {
    if (Platform.OS !== 'web') {
      Keyboard.dismiss();
    }
  };

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: theme.colors.beige}}>
      <View style={styles.container}>
        <View style={styles.overlay}></View>
        {isLargeScreen &&
          <View style={styles.emptyStateContainer}>
            <Image source={VorkovImage} style={styles.image}/>
          </View>
        }
        <TouchableWithoutFeedback
          onPress={dismissKeyboard}
          accessible={false}
        >
          <View style={styles.innerContainer}>
            <KeyboardAvoidingView
              style={styles.keyboardAvoidingView}
              behavior={Platform.OS === "ios" ? "padding" : "height"}
            >
              <SvgFabylHeader style={styles.header}/>
              <View style={styles.brandContainer}>
                <Text style={globalStyles.brandPhrase}>weave whimsical worlds</Text>
              </View>
              <View style={styles.formContainer}>
                <TextInput
                  ref={usernameInputRef} // Attach the reference
                  value={username}
                  onChangeText={setUsername}
                  autoCapitalize={'none'}
                  placeholder="Email"
                  style={styles.input}
                  returnKeyType="next"
                  onSubmitEditing={() => passwordInputRef.current?.focus()} // Switch focus to the password input
                />
                <TextInput
                  ref={passwordInputRef} // Attach the reference
                  value={password}
                  onChangeText={setPassword}
                  placeholder="Password"
                  secureTextEntry={true}
                  style={styles.input}
                  returnKeyType="done"
                />
                <TouchableOpacity
                  onPress={handleLogin}
                  style={globalStyles.primaryButton}
                  disabled={isLoggingIn}
                >
                  <Text style={globalStyles.primaryButtonText}>
                    {isLoggingIn ? 'LOGGING IN...' : 'LOG IN'}</Text>
                </TouchableOpacity>
              </View>
              <TouchableOpacity onPress={() => navigation.navigate('ForgotPassword')}>
                <Text style={styles.hyperlinkStyle}>Forgot Password?</Text>
              </TouchableOpacity>
            </KeyboardAvoidingView>
            <TouchableOpacity
              style={styles.createAccountButton}
              onPress={handleSignUp}
            >
              <Text style={styles.createAccountText}>CREATE NEW ACCOUNT</Text>
            </TouchableOpacity>
            <View style={styles.footerSection}>
              <View style={styles.linksRow}>
                <TouchableOpacity onPress={() => openURL(PRIVACY_POLICY)}>
                  <Text style={styles.footerLink}>Privacy Policy</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => openURL(TERMS_OF_USE)}>
                  <Text style={styles.footerLink}>Terms of Use</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.beige,
    width: "100%",
    flexDirection: 'row',
    justifyContent: 'center'
  },
  innerContainer: {
    zIndex: 2, // Ensure this is higher than the overlay's z-index
    flex: 1,
    justifyContent: 'center',
    marginTop: theme.spacings.xxlarge,
    backgroundColor: theme.colors.beige,
    borderRadius: theme.borderRadius.large,
    alignSelf: 'center',
    width: '100%', // Ensure it fills the screen width-wise
    maxWidth: 400, // Specify your desired max width here
    height: "100%",
    // maxHeight: 700, // Specify your desired max height here
    ...(Platform.OS === 'web' ? { maxHeight: 700 } : {}), // Apply maxHeight only on web
    paddingHorizontal: theme.spacings.large,
    paddingVertical: theme.spacings.large,
    borderWidth: Platform.select({
      web: 1,
      default: 0, // This will apply to all platforms other than web
    }),
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  emptyStateContainer: {
    // marginVertical: theme.spacings.xlarge,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 400,
    height: 600,
    margin: theme.spacings.xxlarge,
  },
  brandContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacings.xlarge,
  },
  header: {
    justifyContent: 'center',
    alignSelf: 'center',
    marginVertical: 20,
  },
  input: {
    marginBottom: 10,
    backgroundColor: theme.colors.white,
    borderRadius: 10,
    padding: 15,
    borderWidth: 1,
    borderColor: 'gray',
  },
  signUpForgotContainer: {
    alignItems: 'center', // Example style to align items, adjust as needed
    marginVertical: theme.spacings.medium, // Example style for spacing, adjust as needed
    width: "100%",
  },
  hyperlinkStyle: {
    alignSelf: 'center', // Center the text
    color: theme.colors.bluePrimary, // Hyperlink-like blue color
    textDecorationLine: 'underline', // Underline to mimic hyperlink appearance
    paddingVertical: theme.spacings.small, // Optional: Adjust the padding for better touch feedback
    fontFamily: theme.fonts.family.bold, // Optional: Use a bold font for better visibility
    fontSize: theme.fonts.sizes.large, // Optional: Adjust the font size as needed
    marginTop: theme.spacings.small,
  },
  createAccountButton: {
    width: "100%",
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.bluePrimary, // Adjust the color as needed
    backgroundColor: theme.colors.beige, // Adjust the background color as needed
    paddingVertical: theme.spacings.small, // Optional: Adjust the padding for better touch feedback
    borderColor: theme.colors.bluePrimary,
    borderRadius: theme.borderRadius.medium,
    padding: theme.spacings.medium,
    borderWidth: 1,
    // marginBottom: 100,
    // Shadow properties for iOS
    shadowColor: "#000", // Black color for the shadow
    shadowOffset: {width: 0, height: 2}, // X, Y axis for the shadow direction
    shadowOpacity: 0.1, // Transparency of the shadow
    shadowRadius: 3, // Blur radius of the shadow
    // Elevation for Android (similar effect as shadow for iOS)
    elevation: 5,
  },
  createAccountText: {
    fontFamily: theme.fonts.family.bold, // Optional: Use a bold font for better visibility
    fontSize: theme.fonts.sizes.large, // Optional: Adjust the font size as needed
    color: theme.colors.bluePrimary
    // Add any other styling to match your design needs
  },
  appStoreContainer: {
    marginVertical: theme.spacings.large,
    alignSelf: 'center',
    alignItems: 'center',
  },
  appStoreButton: {
    marginVertical: theme.spacings.small,
  },
  appStoreText: {
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.gray,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: Platform.select({
      web: 'rgba(0, 0, 0, 0.1)',
      default: 'rgba(0, 0, 0, 0.0)', // This will apply to all platforms other than web
    }),
    zIndex: 1, // Ensure this is lower than the innerContainer's z-index
  },
  footerSection: {
    marginTop: theme.spacings.large,
    padding: theme.spacings.medium,
    alignItems: 'center',
    width: '100%',
  },
  linksRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  footerLink: {
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.medium,
    textDecorationLine: 'underline',
    marginHorizontal: theme.spacings.medium,
  },
});

export default LoginScreen;
