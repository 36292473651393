import React, {useEffect, useLayoutEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {fetchPublicProfileData} from "../utils/api/userService";
import UserCircle from "../components/UserCircle";
import theme from "../styles/theme";
import SvgSpurWithFWhiteOnRed from "../assets/images/SpurWithFWhiteOnRed";
import {useNavigation} from '@react-navigation/native';
import LoginHeader from "../components/LoginHeader";
import LoginPromptModal from "../components/modals/LoginPromptModal";


const UnauthPublicProfile = ({ route }) => {
  const navigation = useNavigation();
  const { profile, profile_uuid } = route.params || {};
  const [profileData, setProfileData] = useState(null);
  const [isLoginModalVisible, setLoginModalVisible] = useState(false);

  useEffect(() => {
      const timer = setTimeout(() => {
        setLoginModalVisible(true);
      }, 5000);

      return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  useEffect(() => {
    const loadProfileData = async () => {
      if (profile_uuid) {
        try {
          const data = await fetchPublicProfileData(profile_uuid);
          console.log(`Here is the json data: ${JSON.stringify(data)}`);
          setProfileData(data);
        } catch (error) {
          console.error('Error fetching public profile data:', error);
        }
      }
    };
    loadProfileData();
  }, [profile_uuid]);

  if (!profileData) {
    return <Text>Loading...</Text>; // You can replace this with a more suitable loading indicator.
  }

  return (
    <View style={styles.container}>
      <UserCircle profile_uuid={profileData.uuid} size={200} />
      <Text style={styles.username}>{profileData.preferred_username}</Text>
      <LoginPromptModal
        isVisible={isLoginModalVisible}
        onConfirm={() => console.log('User wants to sign up or log in')}
        onCancel={() => setLoginModalVisible(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.beige,
  },
  username: {
    marginTop: theme.spacings.small,
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.bluePrimary,
  },
  spur: {
    marginLeft: 20,
    zIndex: 1,
    transform: [{ scale: 2 }],
  },
});

export default UnauthPublicProfile;
