import { Auth } from 'aws-amplify';
import alert from "./alert";

async function signUpWithEmail({ email, password }) {
  const signUpParams = {
    username: email, // Username is the email
    password, // Password from the sign-up form
    attributes: {
      email, // AWS Cognito requires this to be the same as the username for email sign-up
    },
  };

  try {
    const result = await Auth.signUp(signUpParams);
    alert("Success", "You have signed up successfully. Please check your email to confirm your account.");
    return result;
  } catch (error) {
    console.error('Error during sign-up:', error);
    // Customize your error message based on the error code
    let message = 'An error occurred during sign up. Please try again.';
    if (error.code === 'UsernameExistsException') {
      message = 'An account with this email already exists.';
    }
    if (error.code === 'InvalidPasswordException') {
      message = 'Password does not meet the requirements.';
    }

    alert("Sign Up Error", message);
    throw error; // re-throw the error if you want to handle it in another part of your application
  }
}


async function signUpWithPhone({ phone, password }) {
  const signUpParams = {
    username: phone, // Username is the phone number
    password, // Password from the sign-up form
    attributes: {
      phone_number: phone,
      // AWS Cognito requires the phone number in international format
    },
  };
  const result = await Auth.signUp(signUpParams);
  console.log('Sign-up result:', result)
  return result;
}


// Sign in
async function signIn(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    console.log('Sign-in successful:', user);
  } catch (error) {
    alert('Error signing in:', error.message);
  }
}

// Sign out
async function signOut() {
  try {
    await Auth.signOut();
    console.log('Sign-out successful!');
  } catch (error) {
    console.error('Error signing out:', error);
  }
}

export { signUpWithEmail, signUpWithPhone, signIn, signOut };
