import { StyleSheet } from 'react-native';
import { brandColors, layout } from './variables';
import theme from "./theme";

const HEADER_BUTTON_SIZE = 32;

const globalStyles = StyleSheet.create({
  body: {
    backgroundColor: brandColors.beige,
    color: brandColors.black,
    flex: 1,              // to ensure the view takes up the full screen
    fontFamily: 'Barlow-Regular'  // ensure you've added the font to your RN project
  },
  logo: {
    height: 40,
    width: 40,
  },
  logoContainer: {
    // height: '100%',
    // width: 'auto',
    // paddingTop: 30,
    // marginRight: 10,
  },
  brandPhrase: {
    textAlign: 'center', // Center the text
    color: theme.colors.lightgray, // Replace with your desired text color
    fontSize: theme.fonts.scaledSizes.large, // Adjust font size as needed
    fontFamily: theme.fonts.family.italic, // Replace with your desired font family
  },
  primaryBox: {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  topBar: {
    paddingTop: layout.topBarTopPadding,
    height: layout.topBarHeight,
    flexDirection: 'row',     // to place children in a row
    alignItems: 'center',     // vertically center children
    justifyContent: 'space-between',
    // borderBottomWidth: 1,
    borderBottomColor: brandColors.black,
    backgroundColor: brandColors.gray,
    // paddingLeft: '5%',
    // paddingRight: '5%',
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // right: 0,
    zIndex: 2
  },
  cardName: {
    color: theme.colors.redPrimary,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.large,
  },
  contentWrapper: {
    flex: 1,
    flexDirection: 'row'
  },
  content: {
    height: layout.contentHeight,
    // marginTop: layout.topBarHeight,
    width: '100%',
    zIndex: 0
  },

  h1: {
    fontSize: 6,
    margin: 0
  },
  //... other heading styles

  link: {
    color: brandColors.bluePrimary,
    textDecorationLine: 'none',
  },
  linkVisited: {
    color: brandColors.bluePrimary,
    textDecorationLine: 'none',
  },
  linkHover: {
    color: brandColors.redPrimary,
    textDecorationLine: 'underline',
  },
  linkActive: {
    color: brandColors.yellowPrimary,
    textDecorationLine: 'underline',
  },
  navigation: {
    flexDirection: 'row',     // place children in a row
    justifyContent: 'space-between',  // space the children evenly
    alignItems: 'center',     // vertically center children
    height: layout.bottomNavHeight,               // or any height you prefer
    backgroundColor: brandColors.gray,  // or any background color you prefer
    borderTopWidth: 1,
    bottomPadding: layout.bottomBarBottomPadding,
    bottom: 0,
    borderTopColor: brandColors.beige,
  },
  navItem: {
    flex: 1,                 // each navItem takes up an equal amount of space
    color: brandColors.white,
    alignItems: 'center',     // center the icons horizontally
    justifyContent: 'center'  // center the icons vertically
  },
  galleryContainer: {
    flex: 1,
    backgroundColor: theme.colors.beige,
    // The same as the body background
  },
  galleryTitle: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.black,
    marginBottom: theme.spacings.medium,
    marginLeft: theme.spacings.xsmall,
  },
  bigGalleryTitle: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: theme.fonts.sizes.xlarge,
    fontFamily: theme.fonts.family.medium,
    color: theme.colors.gray,
    marginBottom: theme.spacings.small,
    marginLeft: theme.spacings.xsmall,
    marginTop: theme.spacings.medium,
  },
  headerRightContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 90,
    marginHorizontal: theme.spacings.small,
    // paddingHorizontal: theme.spacings.small,
  },
  primaryButton: {
    backgroundColor: theme.colors.bluePrimary,
    borderRadius: theme.borderRadius.medium,
    borderWidth: 1,
    width: '100%',
    alignSelf: 'center',
  },
  primaryButtonText: {
    color: theme.colors.white,
    padding: theme.spacings.medium,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: theme.fonts.sizes.large,
  },
});

export default globalStyles;
export { HEADER_BUTTON_SIZE };
