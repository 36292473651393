import { useQuery } from 'react-query';
import { fetchMinimalProfileData } from "../utils/api/userService";

export const useMinimalProfileData = (profile_uuid) => {
  return useQuery(
    ['minimalProfile', profile_uuid],
    () => fetchMinimalProfileData(profile_uuid),
    {
      enabled: !!profile_uuid,
      staleTime: 5 * 60 * 1000,  // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
    }
  );
};
