import React, { useState, useEffect } from 'react';
import { View, FlatList, StyleSheet, Text, ActivityIndicator } from 'react-native';
import theme from "../../styles/theme";
import SkeletonLoader from "../utility/SkeletonLoader";

const GenericList = ({ fetchDataFunction, renderItem, itemKeyExtractor, triggerLoad, renderEmptyCustom = null }) => {
  const [data, setData] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [shouldLoadMore, setShouldLoadMore] = useState(true);

  const loadData = async () => {
    if (isLoading || !shouldLoadMore) return;

    setIsLoading(true);
    const response = await fetchDataFunction(nextPage);
    const newData = response.data.results;
    if (newData && newData.length > 0) {
      setData(prevData => [...prevData, ...newData]);
      setNextPage(nextPage + 1);
    } else {
      setShouldLoadMore(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsResetting(true);
  }, [triggerLoad]);

  useEffect(() => {
    if (isResetting) {
      setData([]);
      setNextPage(1);
      setIsResetting(false);
      setShouldLoadMore(true);
    }
  }, [isResetting]);

  useEffect(() => {
    if (!isResetting) {
      loadData();
    }
  }, [isResetting]);

  const renderEmpty = () => {
    if (renderEmptyCustom) return renderEmptyCustom(isLoading);
    return (
      isLoading ? (
        <View>
          {Array.from({ length: 7 }).map((_, index) => (
            <SkeletonLoader key={index} />
          ))}
        </View>
      ) : (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>No items found.</Text>
        </View>
      )
    );
  };

  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={itemKeyExtractor}
      ListEmptyComponent={renderEmpty}
      onEndReached={loadData}
      onEndReachedThreshold={0.5}
    />
  );
};

const styles = StyleSheet.create({
  emptyContainer: {
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyText: {
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.gray,
  },
});

export default GenericList;
