import React, {useState} from 'react';
import {SafeAreaView, StyleSheet, View, Text} from 'react-native';
import Swiper from 'react-native-swiper';
import theme from "../styles/theme";
import Slide1 from "../components/demo/Slide1";
import Slide2 from "../components/demo/Slide2";
import Slide3 from "../components/demo/Slide3";

const TutorialScreenApp = () => {
  const [startSlide1Animation, setStartSlide1Animation] = useState(true);
  const [startSlide2Animation, setStartSlide2Animation] = useState(false);
  const [startSlide3Animation, setStartSlide3Animation] = useState(false);

  const handleIndexChanged = (index) => {
    if (index === 0) {
      console.log('Slide 1');
      setStartSlide1Animation(true);
      setStartSlide2Animation(false);
      setStartSlide3Animation(false);
    }
    if (index === 1) {
      console.log('Slide 2');
      setStartSlide1Animation(false);
      setStartSlide2Animation(true);
      setStartSlide3Animation(false);
    }
    if (index === 2) {
      console.log('Slide 3');
      setStartSlide1Animation(false);
      setStartSlide2Animation(false);
      setStartSlide3Animation(true);
    }
  };

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: theme.colors.beige}}>
      <Swiper
        style={styles.wrapper}
        showsButtons={true}
        loop={false}
        dot={<View style={styles.dot}/>}
        activeDot={<View style={styles.activeDot}/>}
        paginationStyle={styles.pagination}
        removeClippedSubviews={false} // resolves bug where first screen is blank
        onIndexChanged={handleIndexChanged}
        nextButton={<Text style={styles.buttonText}>›</Text>}
        prevButton={<Text style={styles.buttonText}>‹</Text>}
        buttonWrapperStyle={styles.buttonWrapper}
      >
        <Slide1 startSlideAnimation={startSlide1Animation} />
        <Slide2 startSlideAnimation={startSlide2Animation} />
        <Slide3 startSlideAnimation={startSlide3Animation} />
      </Swiper>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: theme.colors.beige,
  },
  dot: {
    backgroundColor: theme.colors.verylightgray,
    width: 10,
    height: 10,
    borderRadius: 5,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
  },
  activeDot: {
    backgroundColor: theme.colors.lightgray,
    width: 10,
    height: 10,
    borderRadius: 5,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
  },
  pagination: {
    bottom: 10,
  },
  buttonWrapper: {
    backgroundColor: 'transparent', // Ensures that the button background is transparent
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    left: 0,
    flex: 1,
    paddingHorizontal: 10,
    paddingVertical: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  buttonText: {
    fontSize: 46,
    color: theme.colors.bluePrimary, // Example: yellow primary color for the arrows
  },
});

export default TutorialScreenApp;
