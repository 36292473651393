import React from 'react';
import {Text} from 'react-native';
import API_ENDPOINTS from '../apiEndpoints';
import useWorlds from "../hooks/useWorlds";
import BigWorldsGallery from "../components/BigWorldsGallery";
import globalStyles from "../styles/globalStyles";


function YourWorldsScreen() {

  const {
    worlds: bigWorlds,
    loadingWorlds: loadingBigWorlds,
    fetchMoreWorlds: fetchMoreBigWorlds,
    nextPageURL: bigWorldsNextPageURL,
    handleRefresh: refreshBigWorlds,
  } = useWorlds(API_ENDPOINTS.USER_BIG_WORLDS);


  return (
    <BigWorldsGallery
      headerComponent={
        <Text style={globalStyles.bigGalleryTitle}>YOUR WORLDS</Text>
      }
      worlds={bigWorlds}
      loading={loadingBigWorlds}
      loadMore={fetchMoreBigWorlds}
      nextPageURL={bigWorldsNextPageURL}
      onRefresh={refreshBigWorlds}
    />
  );
}


export default YourWorldsScreen;
