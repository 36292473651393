import React from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Linking,
} from 'react-native';
import theme from '../styles/theme';
import globalStyles from "../styles/globalStyles";
import Constants from "expo-constants";
import {useNavigation} from "@react-navigation/native";
import PasswordInputWithConditions from "./PasswordInputWithConditions";


const TERMS_OF_USE = process.env.EXPO_PUBLIC_TERMS_OF_USE_URL;
const PRIVACY_POLICY = process.env.EXPO_PUBLIC_PRIVACY_POLICY_URL;


const SignUpForm = ({ inputField, onSignUpPress, inputPlaceHolder, password, setPassword }) => {
  const navigation = useNavigation();

  const openURL = (url) => {
    Linking.canOpenURL(url)
    .then((supported) => {
      if (!supported) {
        console.log("Can't handle URL: " + url);
      } else {
        return Linking.openURL(url);
      }
    })
    .catch((err) => console.error('An error occurred', err));
  };

  const handleLoginPress = () => {
    navigation.navigate('Login');
  }

  return (
    <View style={styles.container}>
      <Text style={styles.headerText}>Create Your Account</Text>
      {inputField}

      <PasswordInputWithConditions
        password={password}
        setPassword={setPassword}
      />

      <TouchableOpacity onPress={onSignUpPress} style={globalStyles.primaryButton}>
        <Text style={globalStyles.primaryButtonText}>SIGN UP</Text>
      </TouchableOpacity>

      <Text style={styles.termsText}>
        <Text>By signing up, you agree to our </Text>
        <Text style={styles.linkText} onPress={() => openURL(TERMS_OF_USE)}>
          Terms of Use
        </Text>
        <Text> and </Text>
        <Text style={styles.linkText} onPress={() => openURL(PRIVACY_POLICY)}>
          Privacy Policy
        </Text>.
      </Text>

      <View style={styles.haveAccountContainer}>
        <Text style={styles.haveAccountStyle}>Already have an account? </Text>
        <TouchableOpacity onPress={handleLoginPress}>
          <Text style={styles.hyperlinkStyle}>Login</Text>
        </TouchableOpacity>
      </View>

    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch', // Align children to stretch to container width
    padding: 20,
    maxWidth: 500,
    width: '100%',
    backgroundColor: theme.colors.beige,
  },
  input: {
    height: 50,
    backgroundColor: '#fff', // White input fields
    borderWidth: 1,
    borderColor: '#ddd', // Light grey border
    borderRadius: 5,
    paddingHorizontal: 10, // Horizontal padding within the input field
    marginBottom: 15, // Space between the input fields
    fontSize: 16,
  },
  buttonText: {
    textAlign: 'center',
    color: theme.colors.white, // White text color
    paddingVertical: 15, // Vertical padding within the button
    fontSize: 16,
  },
  headerText: {
    fontSize: 24,
    fontFamily: theme.fonts.family.bold,
    textAlign: 'center',
    marginBottom: theme.spacings.medium,
  },
  descriptionText: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
  },
  termsText: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  linkText: {
    color: theme.colors.primary, // Adjust color to match your theme
    textDecorationLine: 'underline',
  },
  loginLink: {
    marginTop: 20,
    alignItems: 'center',
  },
  loginLinkText: {
    color: theme.colors.primary,
    textDecorationLine: 'underline',
  },
  haveAccountContainer: {
    flexDirection: 'row', // Align children in a row
    justifyContent: 'center', // Center the children
    alignItems: 'center', // Center the children
    marginTop: theme.spacings.xlarge,
  },
  haveAccountStyle:{
    alignSelf: 'center', // Center the text
    color: theme.colors.gray, // Hyperlink-like blue color
    paddingVertical: theme.spacings.small, // Optional: Adjust the padding for better touch feedback
    fontFamily: theme.fonts.family.bold, // Optional: Use a bold font for better visibility
    fontSize: theme.fonts.sizes.large, // Optional: Adjust the font size as needed
  },
  hyperlinkStyle: {
    alignSelf: 'center', // Center the text
    color: theme.colors.bluePrimary, // Hyperlink-like blue color
    textDecorationLine: 'underline', // Underline to mimic hyperlink appearance
    paddingVertical: theme.spacings.small, // Optional: Adjust the padding for better touch feedback
    fontFamily: theme.fonts.family.bold, // Optional: Use a bold font for better visibility
    fontSize: theme.fonts.sizes.large, // Optional: Adjust the font size as needed
  },
});

export default SignUpForm;
