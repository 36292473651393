import React, {memo} from 'react';
import {Dimensions, TouchableOpacity, View} from 'react-native';
import { Image } from 'expo-image'
import {useNavigation} from '@react-navigation/native';

export const windowWidth = Math.min(Dimensions.get('window').width, 500);
export const cardContainerSize = windowWidth / 3;
export const cardTileSize = cardContainerSize - 10;

const CardTile = ({ item, margin=5 }) => {
    const navigation = useNavigation();
    const { uuid } = item;

    return (
        <TouchableOpacity onPress={() => navigation.navigate('CardDetail', { card: item })}>
            <View style={{ width: cardContainerSize, alignItems: 'center' }}>
                <Image
                    source={{ uri: item.thumbnail || item.art }}
                    style={{ width: cardTileSize, height: cardTileSize, margin: margin }}
                />
            </View>
        </TouchableOpacity>
    );
};

export default memo(CardTile);
