import React from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import GenericList from './GenericList'; // Adjust the import path as necessary
import theme from "../../styles/theme";

const CardsList = ({ query, fetchResults, triggerLoad, onPressItem }) => {
  const navigation = useNavigation();

  const defaultPressItem = (item) => {
    navigation.navigate('CardDetail', { card: item });
  };


  const renderItem = ({ item }) => (
    <TouchableOpacity onPress={() => (onPressItem ? onPressItem(item) : defaultPressItem(item))}>
      <View style={styles.cardContainer}>
        <Image source={{ uri: item.thumbnail }} style={styles.cardThumbnail} />
        <Text style={styles.cardName}>{item.name}</Text>
      </View>
    </TouchableOpacity>
  );

  return (
    <GenericList
      fetchDataFunction={(page) => fetchResults(query, page)}
      renderItem={renderItem}
      itemKeyExtractor={(item) => String(item.uuid)}
      triggerLoad={triggerLoad}
    />
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  cardThumbnail: {
    width: 50,
    height: 50,
    marginRight: 10,
    borderRadius: 5,
  },
  cardName: {
    flex: 1,
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.medium,
    color: theme.colors.redPrimary,
  },
});

export default CardsList;
