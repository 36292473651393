import React, {memo, useEffect, useState} from 'react';
import {
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View
} from 'react-native';
import {Image} from "expo-image";
import TimeGallery from "../components/TimeGallery";
import useCards from "../hooks/useCards";
import API_ENDPOINTS from '../apiEndpoints';
import {
  fetchPublicProfileData,
  sendFriendRequest,
  removeFriend,
  toggleBlock,
} from "../utils/api/userService";
import {useProfile} from "../contexts/ProfileContext";
import UserInfo, {UserInfoSkeleton} from "../components/UserInfo";
import {shareContent} from "../utils/share";
import theme from "../styles/theme";
import GallerySkeletonLoader from "../components/skeletons/GallerySkeletonLoader";
import NoWorldsComponent from "../components/NoWorldsComponent";
import alert from "../utils/alert";
import {useQuery, useMutation, useQueryClient} from "react-query";
import MenuModal from "../components/modals/MenuModal";


const Empty = memo(({loadingCards}) => {
  if (loadingCards) {
    return (
      <GallerySkeletonLoader/>
    )
  }
  return (
    <View style={styles.emptyComponent}>
      <NoWorldsComponent
        title={"No Fabyls Yet"}
        content={"This user has not made any fabyls yet."}
      />
      <Image
        source={require("../assets/images/characters/SteampunkHillbilly_medium.png")}
        style={styles.character}
      />
    </View>
  )
});


function PublicProfile({ route }) {
  // const { profile, profile_uuid } = route.params || {};
  const { profile_uuid } = route.params || {};
  const [isBlockModalVisible, setIsBlockModalVisible] = useState(false);
  const queryClient = useQueryClient();


  const {
    profileData,
    refetchProfileData,
  } = useProfile();

  // Replaced useState and useEffect for fetching public profile data with useQuery
  const { data: publicProfileData, isLoading: profileLoading, isError: profileError } = useQuery(
    ['publicProfile', profile_uuid],
    () => fetchPublicProfileData(profile_uuid),
    { enabled: !!profile_uuid }
  );

  // Custom shareProfile function for sharing public profiles
  const handleSharePress = () => {
    if (publicProfileData && publicProfileData.uuid) {
      shareContent('profile', publicProfileData.uuid);
    }
  };

  useEffect(() => {
    // Check if profileData is null and fetch if needed
    if (profileData === null) {
      refetchProfileData();
    }
  }, [profileData, refetchProfileData]);

  const isOwnProfile = profileData && publicProfileData && profileData.uuid === publicProfileData.uuid;

  // Ensure the useCards hook is called with a valid uuid
  const {
    cards,
    nextPageURL,
    loadingCards,
    fetchMoreCards,
    handleRefresh,
  } = useCards(publicProfileData ? API_ENDPOINTS.generate.USER_PROFILE_CARDS(publicProfileData.uuid) : null);

  const friendRequestMutation = useMutation(
    () => sendFriendRequest(publicProfileData.uuid),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['friendStatus', publicProfileData?.uuid]);
        const previousFriendStatus = queryClient.getQueryData(['friendStatus', publicProfileData?.uuid]);
        queryClient.setQueryData(['friendStatus', publicProfileData?.uuid], { ...previousFriendStatus, is_friend: true });
        return { previousFriendStatus };
      },
      onError: (error, variables, context) => {
        queryClient.setQueryData(['friendStatus', publicProfileData?.uuid], context.previousFriendStatus);
        alert('Failed to send friend request', error.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['friendStatus', publicProfileData?.uuid]);
      },
    }
  );

  const removeFriendMutation = useMutation(
    () => removeFriend(publicProfileData.uuid),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['friendStatus', publicProfileData?.uuid]);
        const previousFriendStatus = queryClient.getQueryData(['friendStatus', publicProfileData?.uuid]);
        queryClient.setQueryData(['friendStatus', publicProfileData?.uuid], { ...previousFriendStatus, is_friend: false });
        return { previousFriendStatus };
      },
      onError: (error, variables, context) => {
        queryClient.setQueryData(['friendStatus', publicProfileData?.uuid], context.previousFriendStatus);
        alert('Failed to remove friend', error.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries(['friendStatus', publicProfileData?.uuid]);
      },
    }
  );

  const handleRemoveFriend = () => {
    alert(
      "Remove Friend",
      `Are you sure you want to remove ${publicProfileData?.preferred_username} as a friend?`,
      [
        {
          text: "Cancel",
          style: "cancel"
        },
        {
          text: "Confirm",
          onPress: () => {
            removeFriendMutation.mutate();
            setIsBlockModalVisible(false);
          }
        }
      ]
    );
  };

  const blockMutation = useMutation(toggleBlock, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(['publicProfile', publicProfileData?.uuid]);
      await queryClient.invalidateQueries(['friendStatus', publicProfileData?.uuid]);
    },
    onError: (error) => {
      alert('Failed to toggle block', error.message);
    },
  });

  const handleBlockPress = () => {
    setIsBlockModalVisible(true);
  };

  const handleConfirmBlock = () => {
    console.log(`Block user: ${publicProfileData?.uuid}`)
    alert(
      "Block User",
      `Are you sure you want to ${publicProfileData?.is_blocked ? 'unblock' : 'block'} this user?

      - The user will not be notified about being ${publicProfileData?.is_blocked ? 'unblocked' : 'blocked'}.
      - If you are currently friends with this user, they will be removed from your friends list.`,
      [
        {
          text: "Cancel",
          style: "cancel"
        },
        {
          text: "Confirm",
          onPress: () => {
            if (publicProfileData && publicProfileData.uuid) {
              blockMutation.mutate(publicProfileData.uuid);
            }
            setIsBlockModalVisible(false);
          }
        }
      ]
    );
  };

  const handleCancelBlock = () => {
    setIsBlockModalVisible(false);
  };

  if (profileLoading) {
    return (
      <UserInfoSkeleton />
    );
  }

  if (!publicProfileData) {
    return <Text>Profile not found.</Text>;
  }

  const blockOptions = [
    {
      label: publicProfileData?.is_blocked ? 'Unblock User' : 'Block User',
      action: handleConfirmBlock,
    },
    {
      label: 'Remove Friend',
      action: handleRemoveFriend,
    },
  ];

  return (
    <View style={styles.screenContainer}>
      <TimeGallery
        headerComponent={
          <UserInfo
            profileData={isOwnProfile ? profileData : publicProfileData}
            isPublic={true}
            isOwnProfile={isOwnProfile}
            shareProfile={handleSharePress}
            onSendFriendRequest={friendRequestMutation.mutate}
            onRemoveFriend={removeFriendMutation.mutate}
            handleBlockPress={handleBlockPress}
          />
        }
        nextPageURL={nextPageURL}
        cards={cards}
        loadMore={fetchMoreCards}
        loading={loadingCards}
        onRefresh={handleRefresh}
        emptyComponent={<Empty loadingCards={loadingCards} />}
      />
      <MenuModal
        modalVisible={isBlockModalVisible}
        setModalVisible={setIsBlockModalVisible}
        options={blockOptions}
        header={`options for ${publicProfileData?.preferred_username}`}
      />
    </View>
  );
}


const styles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  emptyComponent: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  emptyText: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.regular,
    textAlign: 'center',
  },
  username: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  character: {
    marginTop: 20,
    width: 200,
    height: 346,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 20,
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
  },
  modalButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  modalButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 8,
  },
  modalCancelButton: {
    backgroundColor: theme.colors.gray,
  },
  modalConfirmButton: {
    backgroundColor: theme.colors.primary,
  },
  modalButtonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default PublicProfile;
