// Utility function to combine and filter card arrays based on uuid
function combineUniqueCards(oldCards, newCards) {
  const combinedCards = [...oldCards, ...newCards];
  console.log(combinedCards)
  console.log(`Num old cards: ${oldCards.length}`)
  console.log(`Num new cards: ${newCards.length}`)
  console.log(`Num combined cards: ${combinedCards.length}`)
  // console.log(`newCards: ${JSON.stringify(newCards.map(card => [card.name, card.uuid]))}`);
  const uniqueUUIDs = Array.from(new Set(combinedCards.map(card => card.uuid)));
  const uniqueCards = uniqueUUIDs.map(uuid => combinedCards.find(card => card.uuid === uuid));
  console.log(`Num unique cards: ${uniqueCards.length}`)
  // console.log(`Unique cards: ${JSON.stringify(uniqueCards.map(card => [card.name, card.uuid]))}`);
  return uniqueCards;
}

export const formatCharacterCount = (count) => {
  return count < 10 ? ` ${count}` : `${count}`;
};


export { combineUniqueCards };
