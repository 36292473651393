import React from 'react';
import {Dimensions, Modal, StyleSheet, TouchableOpacity, View} from 'react-native';
import theme from "../../styles/theme";
import {MaterialIcons} from "@expo/vector-icons";
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue
} from "react-native-reanimated";
import {Gesture, GestureDetector} from "react-native-gesture-handler";
import {Image} from 'expo-image';


const { width, height } = Dimensions.get('window');
const imageSize = Math.min(width, height);


const ZoomableImage = ({ source }) => {
  const offset = useSharedValue({ x: 0, y: 0 });
  const start = useSharedValue({ x: 0, y: 0 });
  const scale = useSharedValue(1);
  const savedScale = useSharedValue(1);

  const pinchActivated = useSharedValue(false);

  // Animated styles
  const animatedStyles = useAnimatedStyle(() => ({
    transform: [
      { translateX: offset.value.x },
      { translateY: offset.value.y },
      { scale: scale.value },
    ],
  }));

  // Pan gesture
  const dragGesture = Gesture.Pan()
    .averageTouches(true)
    .onUpdate((e) => {
      if (pinchActivated.value) { // Check if pinch has been activated
        offset.value = {
          x: e.translationX + start.value.x,
          y: e.translationY + start.value.y,
        };
      }
    })
    .onEnd(() => {
      if (pinchActivated.value) {
        start.value = {
          x: offset.value.x,
          y: offset.value.y,
        };
      }
    });

  const zoomGesture = Gesture.Pinch()
    .onUpdate((event) => {
      scale.value = savedScale.value * event.scale;
      pinchActivated.value = true;
    })
    .onEnd(() => {
      savedScale.value = scale.value;
    });


  // Composing gestures
  const composedGesture = Gesture.Simultaneous(dragGesture, zoomGesture)

  return (
    <GestureDetector gesture={composedGesture}>
      <Animated.View style={[styles.imageContainer, animatedStyles]}>
        <Image source={source} style={{ width: imageSize, height: imageSize }} />
      </Animated.View>
    </GestureDetector>
  );
};


const ZoomableImageModal = ({ source, onClose, isVisible }) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}>
      <View style={styles.centeredView}>
        <TouchableOpacity
          style={StyleSheet.absoluteFill}
          activeOpacity={1}
          onPress={onClose}
        >
          <View style={styles.overlay} />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.closeButton}
          onPress={onClose}
        >
          <MaterialIcons name="close" size={32} color={theme.colors.white} />
        </TouchableOpacity>
        <View style={styles.modalView}>
          <ZoomableImage source={source} />
        </View>
      </View>
    </Modal>
  );
};



const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
  },
  modalView: {
    alignItems: 'center',
    shadowColor: theme.colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '100%',
    maxWidth: 500,
    minHeight: '50%',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacings.xlarge,
    right: theme.spacings.xsmall,
    padding: 20,
    zIndex: 10000, // Ensure the X icon is above everything
  },
  cardImage: {
    width: 200, // adjust as needed
    height: 200, // adjust as needed
    borderRadius: theme.borderRadius.medium, // rounded corners
    resizeMode: 'cover',
    marginBottom: theme.spacings.medium,
  },
  cardName: {
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.redPrimary,
    textAlign: 'center',
    marginBottom: theme.spacings.small,
  },
  cardFlavor: {
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.lightgray,
    textAlign: 'center',
    marginBottom: theme.spacings.large,
  },
  buttonsContainer: {
    marginTop: theme.spacings.large,
    alignItems: 'flex-start',
  },
  optionButton: {
    borderRadius: theme.borderRadius.medium,
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    alignItems: 'center',
    flexDirection: 'row',
  },
  iconContainer: {
    width: 24,
    alignItems: 'center',
    marginRight: theme.spacings.small,
  },
  optionText: {
    paddingLeft: theme.spacings.small,
    fontSize: theme.fonts.scaledSizes.large,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.bluePrimary,
    textAlign: 'center',
  },
  optionReportButton: {
    marginTop: theme.spacings.xlarge,
  },
  optionReportText: {
    color: theme.colors.reportRed,
  },
  optionProfileButton: {
    marginTop: theme.spacings.xlarge,
    backgroundColor: theme.colors.bluePrimary,
    borderRadius: theme.borderRadius.medium,
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    marginVertical: theme.spacings.small,
    minWidth: '70%',
  },
  optionProfileText: {
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.white,
    textAlign: 'center',
  },
  imageContainer: {
    zIndex: 2,
  },
});

export default ZoomableImageModal;
