import React, { useState, useContext } from 'react';
import {View, TextInput, Button, StyleSheet, Alert, Text} from 'react-native';
import { AuthContext } from '../contexts/AuthContext';
import theme from "../styles/theme";
import settingsStyles from "../styles/Settings.styles";
import PrimaryButton from "../components/PrimaryButton";
import PasswordInputWithConditions from "../components/PasswordInputWithConditions";


const ResetPasswordScreen = ({ route, navigation }) => {
  const [newPassword, setNewPassword] = useState('');
  const { username, code } = route.params;
  const { forgotPasswordSubmit, signOut } = useContext(AuthContext);

  const handleSetNewPassword = async () => {
    try {
      await forgotPasswordSubmit(username, code, newPassword);
    Alert.alert(
      'Success',
      'Your password has been reset. Please log in with your new password.',
      [
        {
          text: 'OK',
          onPress: async () => {
            // First, sign the user out
            await signOut();
            navigation.navigate('Unauthenticated', { screen: 'Login' });
          },
        },
      ]
    );
    } catch (error) {
      Alert.alert('Error', error.message);
    }
  };

  return (
    <View style={styles.container}>
      <Text style={settingsStyles.title}>CHANGE PASSWORD</Text>
      <Text style={settingsStyles.currentContactText}>Enter your new password:</Text>
      <PasswordInputWithConditions
        password={newPassword}
        setPassword={setNewPassword}
        placeholder="New password"
      />
      <PrimaryButton title="RESET PASSWORD" onPress={handleSetNewPassword} />
    </View>
  );
};


// Add styles for the new components

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 20,
  },
  input: {
    width: '100%',
    maxWidth: 400,
    borderColor: 'gray',
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    marginBottom: 20,
  },
});

export default ResetPasswordScreen;
