import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  TextInput,
  TouchableOpacity,
  Alert,
  Text,
  StyleSheet,
  Dimensions
} from 'react-native';
import {useIsFocused, useNavigation} from '@react-navigation/native';
import PropTypes from 'prop-types';
import { checkTaskData, createCard } from "../utils/api/cardsService";
import theme from "../styles/theme";
import BouncingSpurs from "./BouncingSpurs";
import WhimsicalMessages from "./WhimsicalMessages";
import { useProfile } from "../contexts/ProfileContext";
import UpgradeModal from "./modals/UpgradeModal";
import {fetchCardByUUID} from "../utils/api/cardsService";
import {FontAwesome} from "@expo/vector-icons";
import ChooseFriendModal from "./modals/ChooseFriendModal";
import UserCircle from "./UserCircle";
import alert from "../utils/alert";
import DisclaimerModal from "./modals/DisclaimerModal";
import {useQueryClient} from "react-query";


const windowWidth = Dimensions.get('window').width; // Get the window's width
const maxWidth = 500; // Set the max width
const dynamicWidth = Math.min(windowWidth * 0.95, maxWidth); // Calculate the width

const INPUT_BORDER_WIDTH = 1.5;

const CreateCard = ({ cardRiffedFrom, inputRef, placeholder = "NAME YOUR FABYL", canRiff=true}) => {
  const navigation = useNavigation();
  const { profileData, refetchProfileData, markDisclaimerAcknowledged } = useProfile();
  const [disclaimerModalVisible, setDisclaimerModalVisible] = useState(false); // State for disclaimer modal
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [progress, setProgress] = useState(0);
  const pollIntervalRef = useRef(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [friendModalVisible, setFriendModalVisible] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const isFocused = useIsFocused();
  const queryClient = useQueryClient();
  // ^ This hook returns true if the screen is focused

  const handleDisclaimerConfirm = () => {
    setDisclaimerModalVisible(false);
    markDisclaimerAcknowledged();
  };

  const handleDisclaimerCancel = () => {
    setDisclaimerModalVisible(false);
    stopAllProcesses();
  };

  useEffect(() => {
    if (isFocused && !friendModalVisible) {
      // Only reset selectedFriend if the screen is focused and the friend modal is not visible
      // This means the effect won't run when the modal is open or being closed
      setSelectedFriend(null);
    }
  }, [isFocused]);

  const handleConfirm = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const stopAllProcesses = () => {
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
      pollIntervalRef.current = null;
    }
    setIsLoading(false);
    setIsAnimating(false);
    setProgress(0);
  };

  const handleCreatePress = async () => {
    if (!inputValue.trim()) {
      alert("Validation Error", "Name cannot be empty!");
      return;
    }
    if (!profileData.disclaimer_acknowledged) {
      setDisclaimerModalVisible(true);
      return;
    }
    console.log("Creating card for:", selectedFriend ? selectedFriend.preferred_username : "self");

    setIsLoading(true);
    setIsAnimating(true);
    try {
      const { task_id, uuid } = await createCard(inputValue, cardRiffedFrom, selectedFriend?.uuid);
      pollIntervalRef.current = setInterval(async () => {
        try {
          const taskData = await checkTaskData(task_id);

          if (taskData.status === 'SUCCESS') {
            stopAllProcesses();
            setInputValue('');
            // navigation.navigate('CardDetail', { card: { uuid } });
            console.log("Prefetching data for card:", uuid)
            const prefetchedData = await fetchCardByUUID(uuid);

            // Invalidate queries here, after successful card creation
            queryClient.invalidateQueries('cards');
            queryClient.invalidateQueries('worlds');

            navigation.navigate("CardDetail", { card: prefetchedData });
            refetchProfileData();
          } else if (taskData.status === 'FAILURE') {
            stopAllProcesses();
            alert("Error", "Generation failed. Please try again.");
          } else if (taskData.status === 'PROGRESS') {
            setProgress(taskData.meta.total);
          }
        } catch (error) {
          stopAllProcesses();
          alert("Error", error.message || "An error occurred. Please try again.");
        }
      }, 2000);
    } catch (error) {
      stopAllProcesses();
      // Alert.alert("Error", error.message || "An error occurred. Please try again.");
      if (error.response && error.response.data && error.response.data.code === 'INSUFFICIENT_GENERATIONS') {
        setModalVisible(true);
      }
      else if (error.response && error.response.data && error.response.data.code === 'CURRENT_USER_BLOCKED_RESOURCE_USER') {
        alert("Blocked User Error", "You have blocked a contributor to this world, so you cannot contribute to this world anymore while that user remains blocked.");
      }
      else {
        // For other errors, display a generic message
        alert("Error", error.message || "An error occurred. Please try again.");
      }
    }
  };

  useEffect(() => {
    return () => stopAllProcesses();
  }, []);

  const handleSendToFriendPress = () => {
    console.log('Send to friend pressed')
    setFriendModalVisible(true); // Open modal
  };
  const closeFriendModal = () => {
    setSelectedFriend(null); // Clear the selected friend's data (if any
    setFriendModalVisible(false);
  };

  // Modify onSelectFriend to update state with selected friend's information
  const onSelectFriend = (friend) => {
    console.log('Selected friend:', friend);
    setSelectedFriend(friend); // Store the selected friend's data
    setFriendModalVisible(false); // Close the modal
  };

  const renderFriendOrIcon = () => {
    if (selectedFriend) {
      return (
        <TouchableOpacity
          style={styles.iconButton}
          onPress={handleSendToFriendPress}
          disabled={isLoading || isAnimating}
        >
          <UserCircle profile_uuid={selectedFriend.uuid} size={25} />
        </TouchableOpacity>
      );
    } else {
      return (
        <TouchableOpacity
          style={styles.iconButton}
          onPress={handleSendToFriendPress}
          disabled={isLoading || isAnimating}
        >
          <FontAwesome name="paper-plane-o" size={20} color={theme.colors.lightgray} />
        </TouchableOpacity>
      );
    }
  };

  const handleInputPress = () => {
    navigation.navigate('ExpandedInput', {
      initialValue: inputValue,
      onSubmit: (newValue) => {
        setInputValue(newValue);
        handleCreatePress();
      },
    });
  };

  return (
    <View style={[styles.inputButtonContainer, !canRiff && styles.disabledContainer]}>
      <View style={styles.rowAndNoticeContainer}>
        <View style={styles.rowContainer}>
          {/*<View style={styles.textInputContainer}>*/}
          <TouchableOpacity
            style={styles.textInputContainer}
            onPress={handleInputPress}
            // disabled={!canRiff || isLoading}
          >
            {/*<TextInput*/}
            {/*  ref={inputRef}*/}
            {/*  value={inputValue}*/}
            {/*  onChangeText={setInputValue}*/}
            {/*  // autoCorrect={false}*/}
            {/*  style={[styles.input, isLoading ? styles.disabledInput : {}]}*/}
            {/*  placeholder={placeholder}*/}
            {/*  placeholderTextColor={theme.colors.redPrimary}*/}
            {/*  editable={canRiff && !isLoading}*/}
            {/*  autoCapitalize={'none'}*/}
            {/*  maxLength={100} // Limit the input to 100 characters because this is how long name can be*/}
            {/*/>*/}
            <Text style={[styles.input, isLoading ? styles.disabledInput : {}]}>
              {inputValue || placeholder}
            </Text>
            {renderFriendOrIcon()}
          {/*</View>*/}
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button}
            onPress={handleCreatePress}
            disabled={!canRiff || isLoading || isAnimating}>
            <Text style={styles.buttonText}>Create</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.publicNoticeContainer}>
          <Text style={styles.publicNotice}>{`${profileData?.remaining_generations}/${profileData?.daily_generations}`} remaining</Text>
          <Text style={styles.publicNotice}>*fabyls are public</Text>
        </View>
      </View>
      <BouncingSpurs isAnimating={isAnimating} progress={progress} />
      <WhimsicalMessages isAnimating={isAnimating} progress={progress} />
      <UpgradeModal
        isVisible={modalVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <ChooseFriendModal
        visible={friendModalVisible}
        onClose={closeFriendModal}
        onSelectFriend={onSelectFriend}
      />
      <DisclaimerModal
        isVisible={disclaimerModalVisible}
        onConfirm={handleDisclaimerConfirm}
        onCancel={handleDisclaimerCancel}
      />
    </View>
  );
};

CreateCard.defaultProps = {
  cardRiffedFrom: null,
};

CreateCard.propTypes = {
  cardRiffedFrom: PropTypes.string,
};


const styles = StyleSheet.create({
  textInputContainer: {
    // flexDirection: 'row',
    // alignItems: 'center',
    // position: 'relative',
    flex: 3, // Takes 3/4th of the space
  },
  iconButton: {
    position: 'absolute',
    right: 3,
    height: '100%',
    justifyContent: 'center',
    padding: 10, // Padding makes the touch area larger
  },
  inputButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacings.small,
    // marginBottom: theme.spacings.small,
  },
  rowAndNoticeContainer: {
    width: dynamicWidth,
    // marginHorizontal: theme.spacings.medium,
  },
  rowContainer: {
    flexDirection: 'row', // Align items in a row
    justifyContent: 'center',
    alignItems: 'center',
    // Other styles remain the same
    borderRadius: theme.borderRadius.large,
  },
  // input: {
  //   // flex: 3, // Takes 3/4th of the space
  //   borderTopLeftRadius: theme.borderRadius.large,
  //   borderBottomLeftRadius: theme.borderRadius.large,
  //   backgroundColor: theme.colors.white,
  //   color: theme.colors.redPrimary,
  //   fontSize: theme.fonts.scaledSizes.medium,
  //   paddingHorizontal: theme.spacings.medium,
  //   height: 50, // Fixed height for the input field
  //   // borderWidth: 1, // Thin border
  //   borderWidth: INPUT_BORDER_WIDTH,
  //   borderColor: theme.colors.black,
  //   paddingRight: 40, // Add padding to prevent text from overlapping the icon
  // },
  button: {
    flex: 1, // Takes 1/4th of the space
    backgroundColor: theme.colors.bluePrimary,
    borderTopRightRadius: theme.borderRadius.large,
    borderBottomRightRadius: theme.borderRadius.large,
    borderRightWidth: INPUT_BORDER_WIDTH,
    borderTopWidth: INPUT_BORDER_WIDTH,
    borderBottomWidth: INPUT_BORDER_WIDTH,
    height: 50, // Match height with input field
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: theme.colors.white,
    fontWeight: theme.fonts.weight.bold,
    fontSize: theme.fonts.sizes.medium,
  },
  disabledInput: {
    backgroundColor: theme.colors.white,
  },
  publicNoticeContainer: {
    flexDirection: 'row', // Align items in a row
    justifyContent: 'space-between',
    alignSelf: "center",
    width: '90%',
    fontSize: theme.fonts.sizes.small, // Small font size for discrete text
    color: theme.colors.lightgray, // A subtle color
    marginTop: theme.spacings.xsmall, // Small space from the button
    // marginHorizontal: theme.spacings.medium, // Some space from the sides
    // alignSelf: 'flex-end', // Center the text under the button
  },
  publicNotice: {
    fontSize: theme.fonts.scaledSizes.small, // Small font size for discrete text
    color: theme.colors.lightgray, // A subtle color
    paddingLeft: theme.spacings.xsmall,
    // marginTop: theme.spacings.xsmall, // Small space from the button
    // marginHorizontal: theme.spacings.medium, // Some space from the sides
    // alignSelf: 'flex-end', // Center the text under the button
  },
  selectedFriendContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    // Additional styling as needed
  },
  selectedFriendText: {
    marginRight: 10,
    // Text styling
  },
  clearFriendButton: {
    // Button styling
  },
  disabledContainer: {
    opacity: 0.75, // Adjust the opacity as needed
  },
  input: {
    flex: 1,
    borderTopLeftRadius: theme.borderRadius.large,
    borderBottomLeftRadius: theme.borderRadius.large,
    backgroundColor: theme.colors.white,
    color: theme.colors.redPrimary,
    fontSize: theme.fonts.scaledSizes.medium,
    paddingHorizontal: theme.spacings.medium,
    height: 50,
    borderWidth: INPUT_BORDER_WIDTH,
    borderColor: theme.colors.black,
    paddingRight: 40,
    textAlignVertical: 'center',
  },
});

export default CreateCard;
