import React, {useContext, useState, useRef, useEffect} from 'react';
import { View, Text, TextInput, Button, Alert, StyleSheet, TouchableOpacity } from 'react-native';
import { Auth } from 'aws-amplify';
import { AuthContext } from "../contexts/AuthContext";
import theme from "../styles/theme";
import globalStyles from "../styles/globalStyles";
import alert from "../utils/alert";

const SetUsernameScreen = ({ route, navigation }) => {
  const [preferred_username, setPreferredUsername ] = useState('');
  const [loading, setLoading] = useState(false);
  const { setUsername, isSettingUsername } = useContext(AuthContext);
  const inputRef = useRef(null);

  useEffect(() => {
    // Auto-focus on the username input on component mount
    inputRef.current.focus();
  }, []);

const handleSetUsername = async () => {
  if (preferred_username.length === 0) {
    alert('Username Error', 'Please enter a username.');
    return;
  }

  // Confirmation Alert
  alert(
    'Confirm Username',
    `Are you sure you want to set your username to "${preferred_username}"? This cannot be changed later.`,
    [
      {
        text: 'Cancel',
        style: 'cancel',
      },
      {
        text: 'OK',
        onPress: async () => {
          // setLoading(true);
          try {
            await setUsername(preferred_username);
            // Handle success (e.g., navigation or state update)
          } catch (error) {
            alert('Error', error.message);
          } finally {
            // setLoading(false);
          }
        },
      },
    ],
    { cancelable: false }
  );
};
  console.log(`Are you setting username? ${isSettingUsername}`)

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
      <Text style={styles.title}>Choose Your Username</Text>
      <Text style={styles.description}>This is publicly viewable and it cannot be changed later! Choose wisely.</Text>
      <TextInput
        ref={inputRef}
        style={styles.input}
        placeholder="Username"
        value={preferred_username}
        onChangeText={setPreferredUsername}
        autoCapitalize="none"
      />
      <TouchableOpacity
        onPress={handleSetUsername}
        style={globalStyles.primaryButton}
        disabled={isSettingUsername || preferred_username.length === 0}>
        <Text style={globalStyles.primaryButtonText}>{isSettingUsername ? 'SETTING UP...' : 'SET USERNAME'}</Text>
      </TouchableOpacity>
      </View>
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
    maxWidth: 500,
    backgroundColor: theme.colors.beige,
  },
  innerContainer: {
    marginTop: 50,
    flex: 1,
    backgroundColor: theme.colors.beige,
    alignItems: 'flex-start',
    width: '100%',
    // justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.primaryText,
    marginBottom: 20,
    alignSelf: 'stretch', // Ensure the title stretches across the container
  },
  description: {
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.primaryText,
  },
  input: {
    padding: theme.spacings.small,
    alignSelf: 'stretch', // Ensure the input stretches across the container
    width: '100%',
    // minWidth: 300,
    marginVertical: theme.spacings.large,
    paddingHorizontal: theme.spacings.medium,
    borderWidth: 1,
    borderColor: theme.colors.lightgray, // Use the light grey color from your theme
    borderRadius: theme.borderRadius.small,
    fontSize: theme.fonts.sizes.large,
    color: theme.colors.primaryText, // Use the primary text color from your theme
    backgroundColor: theme.colors.white, // Use the white color from your theme
  },
  // Add other styles that you might need
});


export default SetUsernameScreen;
