import React, { createContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import {
  deactivateDjangoAccount,
  reactivateDjangoAccount,
  updatePreferredUsernameInDjango,
  checkUsernameModerationTaskStatus,
} from "../utils/api/userService";
import alert from "../utils/alert";
import validateUsername from "../utils/validate";

const AuthContext = createContext({
  isAuthenticated: false,
  isLoading: true,
  isSettingUsername: false, // Add this new state
  user: null, // Add user state to the context
  mustSetUsername: true, // Add this new state
  isPasswordChangeRequired: false, // Add this new state
  signIn: async () => {},
  signInWithGoogleCognito: async () => {},
  signOut: async () => {},
  refreshUser: async () => {},
  forgotPassword: async () => {},
  forgotPasswordSubmit: async () => {},
  changePassword: async () => {},
  completeNewPassword: async () => {},
  setUsername: async () => {},
  deactivateAccount: async (password) => {},
  setIsSettingUsername: () => {},
});

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSettingUsername, setIsSettingUsername] = useState(false);
  const [user, setUser] = useState(null); // State to hold user info
  const [mustSetUsername, setMustSetUsername] = useState(undefined);
  const [isPasswordChangeRequired, setIsPasswordChangeRequired] = useState(false);

  const checkAuthState = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
      setUser(currentUser); // Set the user state

      if (currentUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsPasswordChangeRequired(true);
      } else {
        setIsPasswordChangeRequired(false);
      }

      // Check if the preferred_username attribute is present and not empty
      const hasPreferredUsername = !!currentUser.attributes['preferred_username'];
      setMustSetUsername(!hasPreferredUsername);
    } catch (e) {
      setIsAuthenticated(false);
      setUser(null); // Clear the user state if not authenticated
      setMustSetUsername(undefined);
    }
    setIsLoading(false);
  };

  const signIn = async ({ username, password }) => {
    try {
      const user = await Auth.signIn(username, password);

      console.log(`challenge is ${user.challengeName}`)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log('User requires new password');
        setIsPasswordChangeRequired(true); // Set the new state accordingly
        // Do not set isAuthenticated here, as user is not fully authenticated yet
        setUser(user); // Save the user object for completing the password challenge
        return; // Exit the function early as further processing is not required
      }

      const userInfo = await Auth.currentAuthenticatedUser({ bypassCache: true });

      const hasPreferredUsername = !!userInfo.attributes['preferred_username'];
      console.log(`hasPreferredUsername ${hasPreferredUsername}`)
      setMustSetUsername(!hasPreferredUsername);
      console.log(`Has preferred username ${hasPreferredUsername}`)
      console.log(`Must set username ${mustSetUsername}`)

      setUser(userInfo);
      setIsAuthenticated(true);
      const data = await reactivateDjangoAccount();
      console.log("trying to reactivate")
      console.log(data)
      if (data.message === "Account reactivated") {
        alert("Account Reactivated", "Your account has been reactivated.");
      }
      // Check if the preferred_username is set
      console.log(`must set username ${userInfo.attributes['preferred_username']}`)
      console.log(`Condition ${!userInfo.attributes['preferred_username']}`)
      console.log(`must set username ${mustSetUsername}`)
    } catch (error) {
      console.log(error);
      setIsAuthenticated(false);
      setUser(null);
      setIsPasswordChangeRequired(false);
      throw error; // Re-throw to be handled in the component
    }
  };

  // Add this function inside your AuthContext.js
  const signInWithGoogleCognito = async (googleIdToken) => {
    console.log("Trying to sign in with Google")
    try {
      const user = await Auth.federatedSignIn(
        'google',
        { token: googleIdToken, expires_at: 60 * 60 * 24 * 365},
        { /* User attributes, like name and email */ }
      );
      // Perform additional sign-in logic, like updating context state
    } catch (e) {
      console.error('Error signing in with Google: ', e);
      // Handle errors appropriately
    }
  };

  useEffect(() => {
    console.log(`Now mustSetUsername is: ${mustSetUsername}`)
  }, [mustSetUsername])

  const signOut = async () => {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
      setUser(null); // Clear the user state on sign out
    } catch (e) {
      console.log(e);
      // Handle sign out error
    }
  };

  const deactivateAccount = async (password) => {
    try {
      // First, re-authenticate the user with the provided password
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.signIn(currentUser.username, password);

      // If successful, call Django backend to deactivate the account
      const data = await deactivateDjangoAccount()
      alert('Account Deactivation', data.message);

      // Sign the user out from Cognito
      await Auth.signOut();
      setIsAuthenticated(false);
      setUser(null); // Clear the user state on sign out

      return data.message; // Return the message from backend
    } catch (error) {
      console.error('Error during account deactivation:', error);
      throw error; // Re-throw to be handled in the component

    }
  };

  const refreshUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser( { bypassCache: true });
      setUser(currentUser); // Refresh and update the user state

      // Check if the preferred_username is set and update the state accordingly
      const hasPreferredUsername = !!currentUser.attributes['preferred_username'];
      console.log(`hasPreferredUsername ${hasPreferredUsername}`)
      setMustSetUsername(!hasPreferredUsername);
    } catch (e) {
      setIsAuthenticated(false);
      setUser(null); // Clear the user state if not authenticated
      setMustSetUsername(false); // Also ensure mustSetUsername is set to false
    }
    setIsLoading(false);
  };

    // Method to request a password reset code
  const forgotPassword = async (username) => {
    try {
      const data = await Auth.forgotPassword(username);
      return data; // This contains the delivery method (email / SMS etc.)
    } catch (e) {
      console.error('Error requesting forgot password', e);
      throw e; // Rethrow the error so that components can handle it
    }
  };

  // Method to submit the password reset code and new password
  const forgotPasswordSubmit = async (username, code, new_password) => {
    try {
      const data = await Auth.forgotPasswordSubmit(username, code, new_password);
      return data; // The response will indicate success
    } catch (e) {
      console.error('Error submitting forgot password code', e);
      throw e; // Rethrow the error so that components can handle it
    }
  };

  // Method to change password for logged-in users
  const changePassword = async (oldPassword, newPassword) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const data = await Auth.changePassword(currentUser, oldPassword, newPassword);
      return data; // The response will indicate success
    } catch (e) {
      console.error('Error changing password', e);
      throw e; // Rethrow the error so that components can handle it
    }
  };

  // Method for users who are in a state of "new password required"
  const completeNewPassword = async (user, newPassword, attributes = {}) => {
    try {
      // Complete the new password challenge
      const loggedUser = await Auth.completeNewPassword(
        user,           // The CognitoUser object
        newPassword,    // The new password
        attributes      // Optional, additional attributes
      );
      // Update authentication and user states
      setIsAuthenticated(true);
      setUser(loggedUser);
      setIsPasswordChangeRequired(false);
    } catch (error) {
      console.error('Error completing new password:', error);
      alert('Complete New Password Error', error.message); // Display an alert for the error
      throw error;
    }
  };

const setUsername = async (preferredUsernameNoTrim) => {
  const preferredUsername = preferredUsernameNoTrim.trim();
  const validationErrors = validateUsername(preferredUsername);
  if (validationErrors.length > 0) {
    alert("Validation Error", validationErrors.join("\n"));
    return; // Exit early if validation fails
  }

  setIsSettingUsername(true); // Start loading
  try {
    console.log(`Requesting username update to: ${preferredUsername}`);
    const { task_id } = await updatePreferredUsernameInDjango(preferredUsername);

    // Define pollForResult as a promise
    await new Promise(async (resolve, reject) => {
      const pollForResult = async () => {
        try {
          const pollResponse = await checkUsernameModerationTaskStatus(task_id);
          if (pollResponse.status === 'pending') {
            // Use setTimeout within a Promise to "await" it
            setTimeout(pollForResult, 2000);
          } else if (pollResponse.status === 'success') {
            console.log('Username update approved');
            await Auth.updateUserAttributes(user, { 'preferred_username': preferredUsername, });
            const updatedUser = await Auth.currentAuthenticatedUser();
            console.log('Updated user:', updatedUser);
            setUser(updatedUser); // Update user state
            setMustSetUsername(false); // User has set their preferred_username
            alert("Success", "Your username has been updated successfully.");
            resolve(); // Resolve the promise once done
          } else {
            // Handle rejection
            alert("Error", "Username update rejected due to content policy.");
            reject(new Error("Username update rejected due to content policy.")); // Reject the promise on failure
          }
        } catch (error) {
          console.error('Error during username update polling', error);
          alert("Error", "An error occurred while updating your username.");
          reject(error); // Reject the promise on error
        }
      };
      pollForResult();
    });
  } catch (error) {
    console.error('Error during username update', error);
    // Handle error
    alert("Error", "An error occurred while updating your username.");
    // No need to re-throw the error here unless you want to handle it further up
  } finally {
    setIsSettingUsername(false); // Ensure loading state is reset after polling completes
  }
};



  useEffect(() => {
    checkAuthState();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        isSettingUsername,
        user,
        mustSetUsername,
        isPasswordChangeRequired,
        signIn,
        signInWithGoogleCognito,
        signOut,
        refreshUser,
        forgotPassword,
        forgotPasswordSubmit,
        changePassword,
        completeNewPassword,
        setUsername,
        deactivateAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export {AuthContext, AuthProvider};
