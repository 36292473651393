// hooks/useSubscription.js
import { useQuery } from 'react-query';
import { fetchSubscriptionData } from '../utils/api/userService';
import {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";

export const useSubscription = (refetchOnFocus = false) => {
  const { user, isAuthenticated } = useContext(AuthContext);
  const userId = user?.attributes?.sub;

  return useQuery(['subscription', userId], fetchSubscriptionData, {
    // Options could be added here, for example:
    enabled: isAuthenticated && !!userId,
    refetchOnWindowFocus: refetchOnFocus,
    select: (data) => {
      return {
        ...data,
        platform: data?.platform || null, // Add the platform field
      };
    },
    staleTime: 5 * 60 * 1000,  // 5 minutes
    cacheTime: 15 * 60 * 1000, // 15 minutes
  });
};
