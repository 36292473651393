import React, {useEffect} from 'react';
import {Platform, Text} from 'react-native';
import WebUpgradeScreen from './WebUpgradeScreen';
import AppUpgradeScreen from './AppUpgradeScreen';
import {useConfig} from "../hooks/useConfig";
import alert from "../utils/alert";

const UpgradeScreen = () => {
  const { data: config, isLoading, isError } = useConfig();
  
  console.log(config)

  useEffect(() => {
    // Trigger an alert if the app is not in live mode
    if (!isLoading && !isError && config && !config.live_mode) {
      alert(
        "WARNING: Test Environment",
        "You are in a test environment. No real transactions will occur. Transactions will use stripe testing environment for web, and apple sandbox environment for the iOS app. Do you understand?",
        [{ text: "Yes, I understand.", onPress: () => console.log('Alert closed') }]
      );
    }
  }, [config, isLoading, isError]);

  // if (isLoading) return <Text>Loading...</Text>;
  // if (isError) return <Text>Error fetching configuration</Text>;

  return Platform.OS === 'web' ? <WebUpgradeScreen /> : <AppUpgradeScreen />;
};

export default UpgradeScreen;
