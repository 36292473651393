export default {
  rootCard: {
    imageSource: require('../../assets/images/bernifred_big.webp'),
    title: 'The Spherical Bird',
    description: "Among the flock lived Bernifred, a spherical bird who couldn't understand why she couldn't fly like the rest.",
    username: 'tnyb',
    userImageSource: require('../../assets/images/jesus.webp'),
  },
  firstRiffCard: {
    imageSource: require('../../assets/images/tree.webp'),
    title: "Bernifred’s Favorite Tree",
    description: "Bernifred snuggled against the gnarled trunk of her favorite tree, dreaming of one day taking flight.",
    username: 'tnyb',
    userImageSource: require('../../assets/images/jesus.webp'),
  },
  leftRiffCard: {
    imageSource: require('../../assets/images/many_birds_big.webp'),
    userImageSource: require('../../assets/images/doodle.png'),
  },
  secondRiffCard: {
    imageSource: require('../../assets/images/salty_bones_big.webp'),
    userImageSource: require('../../assets/images/doodle.png'),
  },
  rightRiffCard: {
    imageSource: require('../../assets/images/robot_ball_big.webp'),
    userImageSource: require('../../assets/images/fluffy.webp'),
  },
};
