import React, {useEffect, useRef, useState} from 'react';
import {FlatList, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {useQuery, useQueryClient} from 'react-query';
import {fetchCardByUUID} from "../utils/api/cardsService";
import FullScreenOverlay from "./FullScreenOverlay";
import FullscreenCardSkeleton, {cardHeight, screenHeight} from "./FullScreenOverlay";
import theme from "../styles/theme";
import {useNavigation} from '@react-navigation/native';
import SvgRiff from "../assets/images/Riff";
import SvgFabylHeader from "../assets/images/FabylHeader";
import {FontAwesome, MaterialIcons} from '@expo/vector-icons';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
  interpolateColor,
} from "react-native-reanimated";
import globalStyles from "../styles/globalStyles";
import useAudioPlayer from "../hooks/useAudioPlayer";
import useCardDetails from "../hooks/useCardDetails";
import SiblingNavigationButtons from "../components/SiblingNavigationButtons";
import {toggleFavorite} from "../utils/api/cardsService";
import useCardActions from "../hooks/useCardActions";
import {screenWidth} from "./FullScreenOverlay";


const FullscreenOverlayItem = React.memo(({ uuid, isViewable, outerScrollRef }) => {

  const { data, isLoading } = useQuery(
    ['cardDetail', uuid],
    () => fetchCardByUUID(uuid),
    { staleTime: Infinity }
  );

  if (isLoading || !data) {
    return <FullscreenCardSkeleton />;
  }

  return (
    <FullScreenOverlay
      cardUuid={data.uuid}
      isViewable={isViewable}
      outerScrollRef={outerScrollRef}
    />
  );
});


const AnimatedListItem = React.memo(({
   item,
   currentViewableCardUuid,
   outerScrollRef,
   onTogglePlayPause,
   onDoubleTap,
   onCenterItem,
}) => {
  // Use Animated Style for the dark overlay
  const overlayStyle = useAnimatedStyle(() => {
    return {
      opacity: currentViewableCardUuid === item.uuid ? 0 : 0.90, // Fully transparent for the focused item, and partially opaque otherwise
      backgroundColor: theme.colors.gray, // The color of the overlay
      ...StyleSheet.absoluteFillObject, // Make sure the overlay covers the whole item
    };
  });

    // const [lastTap, setLastTap] = useState(null);
    const lastTapRef = useRef(null);
    const DOUBLE_TAP_DELAY = 300; // Milliseconds, adjust as needed

  const handleTap = () => {
    const now = Date.now();

    if (lastTapRef.current && (now - lastTapRef.current) < DOUBLE_TAP_DELAY) {
      // Double tap detected
      onDoubleTap();
    } else {
      // Single tap
      if (currentViewableCardUuid !== item.uuid) {
        onCenterItem();
      } else {
        onTogglePlayPause();
      }

      // Set this tap as the lastTap and wait to see if a double tap follows
      lastTapRef.current = now;
    }
  };

  return (
    // <View style={{ flex: 1 }}>
    <TouchableOpacity onPress={handleTap} style={{ flex: 1 }}>
      <FullscreenOverlayItem
        uuid={item.uuid}
        outerScrollRef={outerScrollRef}
      />
      {/* Overlay View */}
      <Animated.View style={[overlayStyle]} />
    {/*</View>*/}
    </TouchableOpacity>
  );
});



const FullscreenDetailScreen = ({ route }) => {
    const navigation = useNavigation();
    const initialCardThumbnail = route.params.card;
    const flatListRef = useRef(null);

    const {
      cards,
      isLoading,
      isError,
      getCardFromCache,
      lastCardUuid,
      handleViewableItemsChanged,
      currentViewableCardUuid,
      currentViewableCard,
    } = useCardDetails(initialCardThumbnail.uuid);

    const {
      progress,
      stopAndPreventFurtherPlayback,
      debouncedPlayAudio,
      togglePlayPause,
    } = useAudioPlayer(currentViewableCard);

    const navigateToCardDetail = (uuid) => {
      // Navigate to CardDetail screen with the given uuid
      navigation.navigate('CardDetail', {card: { uuid }});
    };

    const titleOpacity = useSharedValue(0); // Initial opacity is 0
    // const currentViewableCardUuid = useSharedValue(initialCardThumbnail.uuid);
    const [hasFinishedScrolling, setHasFinishedScrolling] = useState(true);
    const queryClient = useQueryClient();
    const [heartColor, setHeartColor] = useState(theme.colors.white);
    // Would be great to use useCardActions instead for performLike,
    // but getting it to work with a changing currentViewableCard is tricky so
    // I'm tabling it for now.

    // Update heart color when currentViewableCard changes
    useEffect(() => {
        if (currentViewableCard?.is_favorited) {
            setHeartColor(theme.colors.activeHeart);
        } else {
            setHeartColor(theme.colors.white);
        }
    }, [currentViewableCard]);

    const handleLike = async () => {
      // Optimistically update the heart color
      const newHeartColor = heartColor === theme.colors.white ? theme.colors.activeHeart : theme.colors.white;
      setHeartColor(newHeartColor);

      try {
        console.log('toggling favorite')
        const result = await toggleFavorite(currentViewableCardUuid);
        console.log(`json result: ${JSON.stringify(result)}`)

        // Check if the toggle result matches the optimistic update
        if (!result || result.favorited !== (newHeartColor === theme.colors.activeHeart)) {
          // Revert to the original color if the result is not as expected
          setHeartColor(heartColor);
        }
      } catch (error) {
        // Revert the color on error
        setHeartColor(heartColor);
        // Handle the error (e.g., show an error message)
      }
    }

    const handleBackPress = () => {
      stopAndPreventFurtherPlayback();
      navigation.goBack();
      // navigation.navigate('CardDetail', { card: { currentViewableCardUuid } });
      // ^ the above is closer to desired but something the uuid is not defined
      // and this causes error. Why would it ever be undefined?
    };

    useEffect(() => {
      if (hasFinishedScrolling) {
        titleOpacity.value = withTiming(1, {duration: 0});
      }
    }, [hasFinishedScrolling]);


    const viewabilityConfig = {
      itemVisiblePercentThreshold: 50, // Consider an item viewable when 50% is visible
      minimumViewTime: 0, // Item needs to be viewable for 50 milliseconds
    };

    const viewabilityConfigCallbackPairs = useRef([
        {
          viewabilityConfig,
          onViewableItemsChanged: handleViewableItemsChanged,
        },
    ]);

    const centerItem = (index) => {
      if (flatListRef.current) {
        const offset = (cardHeight * index);
        flatListRef.current.scrollToOffset({ animated: true, offset });
      }
    };

    const initialCardIndex = cards.findIndex(card => card.uuid === initialCardThumbnail.uuid);
    const validInitialIndex = initialCardIndex !== -1;


    useEffect(() => {
      console.log("The Initial Card Index is: ", initialCardIndex);
      console.log("The Initial Card UUID is: ", initialCardThumbnail.uuid);
      console.log("The Initial Card Name is: ", initialCardThumbnail.name);
      if (validInitialIndex && flatListRef.current) {
        setTimeout(() => {
          flatListRef.current.scrollToIndex({
            animated: true,
            index: initialCardIndex,
            viewPosition: 0.0,
          });
        }, 100); // Adjust delay as needed
      }
    }, [initialCardIndex, validInitialIndex]);

    const getItemLayout = (data, index) => (
        { length: cardHeight, offset: cardHeight * index, index }
    );

    const renderItem = ({ item, index }) => {

      return (
        <AnimatedListItem
          item={item}
          currentViewableCardUuid={currentViewableCardUuid}
          outerScrollRef={flatListRef}
          onTogglePlayPause={() => togglePlayPause()}
          onDoubleTap={() => navigateToCardDetail(currentViewableCardUuid)}
          onCenterItem={() => centerItem(index)}
        />
      );
    };

    const renderHeader = () => {
      return (
        <View style={styles.TopSection}>
          <SvgFabylHeader fillColor={theme.colors.lightgray} />
        </View>
      );
    };


    const titleAnimatedStyle = useAnimatedStyle(() => {
        return {
            opacity: titleOpacity.value,
        };
    });


    const onScrollBeginDrag = () => {
      titleOpacity.value = withTiming(0, {duration: 0});
      stopAndPreventFurtherPlayback();
      setHasFinishedScrolling(false);
    };

    const onScrollEndDrag = () => {
      titleOpacity.value = withTiming(1, { duration: 0 });
      setHasFinishedScrolling(true);
    };

    useEffect(() => {
      const handleAudioPlayback = async () => {
        await debouncedPlayAudio();
      };
      // if (hasFinishedScrolling){
        // console.log(`playing audio for ${currentCardTitle}`)
        handleAudioPlayback();
      // }

      return () => {
        debouncedPlayAudio.cancel();
      };
    }, [currentViewableCard]);

      const gotToLastRiffScreen = async () => {
        console.log('gotToLastRiffScreen')
        await stopAndPreventFurtherPlayback();
        const lastCard = getCardFromCache(lastCardUuid)
        navigation.navigate('RiffCard', { card: lastCard });
      };

      const goToCurrentCardRiffScreen = async () => {
        await stopAndPreventFurtherPlayback();
        if (currentViewableCard) {
          navigation.navigate('RiffCard', {card: currentViewableCard});
        } else {
          console.warn('Current card not found');
        }
      };

    const progressBarAnimatedStyle = useAnimatedStyle(() => {
      return {
        width: `${progress.value * 100}%`,
      };
    });

  if (isLoading) return <FullscreenCardSkeleton/>;
  if (isError) return <Text>Error Loading Card</Text>;

  return (
        <View style={{ flex: 1, backgroundColor: theme.colors.gray}}>
          <TouchableOpacity
            onPress={handleBackPress}
            style={styles.backButton}
          >
            <MaterialIcons name="close" size={24} color={theme.colors.white}/>
          </TouchableOpacity>
          <FlatList
              pagingEnabled
              ref={flatListRef}
              data={cards}
              onScrollBeginDrag={onScrollBeginDrag}
              onScrollEndDrag={onScrollEndDrag}
              renderItem={renderItem}
              keyExtractor={(item) => item.uuid}
              viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
              snapToInterval={cardHeight}
              snapToAlignment={'start'}
              decelerationRate={'fast'}
              getItemLayout={getItemLayout}
              initialScrollIndex={validInitialIndex ? initialCardIndex : null}
              ListHeaderComponent={renderHeader}
              ListFooterComponent={() => (
                <TouchableOpacity onPress={gotToLastRiffScreen}>
                  <View style={styles.bottomRiffBox}>
                    <Text style={styles.bottomRiffText}>CLICK TO RIFF</Text>
                    <SvgRiff/>
                  </View>
                </TouchableOpacity>
              )}
          />
          <Animated.View style={[styles.bottomInfoStyle, titleAnimatedStyle]}>
            <View style={styles.siblingNavContainer}>
              <SiblingNavigationButtons card={currentViewableCard} screenName={"FullscreenDetail"} />
            </View>
            <View style={styles.progressBarContainer}>
              <Animated.View style={[styles.progressBar, progressBarAnimatedStyle]} />
            </View>
            <View style={styles.nameAndOptions}>
              <View style={styles.titleContainer}>
                <Text style={styles.titleStyle}>
                  {currentViewableCard?.name}
                </Text>
              </View>
              <View style={styles.cardButtonsContainer}>
                <FontAwesome
                  name="heart"
                  size={26}
                  color={heartColor}
                  onPress={handleLike}
                  style={styles.cardButton}
                />
                <TouchableOpacity onPress={goToCurrentCardRiffScreen} style={styles.cardButton}>
                  <SvgRiff width={30} height={30}/>
                </TouchableOpacity>
              </View>
            </View>
          </Animated.View>
        </View>
    );
}

const styles = StyleSheet.create({
  // ... other styles
  backButton: {
    position: 'absolute',
    top: 10, // Adjust as needed
    right: 10, // Adjust as needed
    zIndex: 10, // High zIndex to ensure it's above other content
    padding: 10, // Adjust for touch area
  },
  backButtonText: {
    color: 'blue', // Adjust as needed
    fontSize: 16, // Adjust as needed
  },
  siblingNavContainer: {
    opacity: 0.5,
    bottom: cardHeight,
  },
  TopSection: {
    height: 0.5 * cardHeight,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.gray,
  },
  darkOverlay: {
    // backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity as needed
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  bottomRiffBox: {
    height:  0.5 * cardHeight,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: theme.colors.gray,
    width: '38%',
    zIndex: 1000,
  },
  cardButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // paddingHorizontal: theme.spacings.medium,
  },
  bottomRiffText: {
    color: theme.colors.yellowPrimary,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.medium,
  },
  bottomInfoStyle: {
    position: 'absolute',
    top: 0.63 * screenHeight, // Adjust as needed, to position right below the card
    // width: '100%',
    width: screenWidth,
    alignSelf: 'center',
    zIndex: 1000, // Make sure it's above the FlatList
  },
  titleStyle: {
    ...globalStyles.cardName,
    // left: 0,
    textAlign: 'left',
    flexWrap: 'wrap', // Allow text to wrap

  },
  nameAndOptions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: theme.spacings.small,
  },
  titleContainer: {
    flex: 1,
    flexShrink: 1,
    marginRight: theme.spacings.large,
    // marginTop: 0,
  },
  cardButton: {
    opacity: 0.7,
    paddingHorizontal: theme.spacings.xsmall,
  },
  progressBarContainer: {
    // position: 'absolute',
    marginTop: 50,
    left: 0,
    right: 0,
    height: 5,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: 20,  // Ensure it's above the image
  },
  progressBar: {
    height: '100%',
    backgroundColor: theme.colors.lightgray,
  },
});

export default FullscreenDetailScreen;
