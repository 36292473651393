import React from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import theme from "../../styles/theme";
import useCardActions from '../../hooks/useCardActions'; // Adjust the import path as necessary
import GenericList from './GenericList'; // Adjust the import path as necessary

const CardItem = ({ item }) => {
  const navigation = useNavigation();
  const { performSetAsProfileCard } = useCardActions(item);

  const handlePressItem = () => {
    navigation.navigate('CardDetail', { card: item });
  };

  const handleSetProfileCard = async () => {
    await performSetAsProfileCard();
    // You might want to add some feedback to the user here
  };

  return (
    <View style={styles.cardContainer}>
      <TouchableOpacity onPress={handlePressItem} style={styles.cardTouchArea}>
        <Image source={{ uri: item.thumbnail }} style={styles.cardThumbnail} />
        <Text style={styles.cardName} numberOfLines={2}>{item.name}</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={handleSetProfileCard} style={styles.setProfileButton}>
        <Text style={styles.setProfileButtonText}>Set Profile</Text>
      </TouchableOpacity>
    </View>
  );
};

const CardsList = ({ query, fetchResults, triggerLoad }) => {
  const renderItem = ({ item }) => <CardItem item={item} />;

  return (
    <GenericList
      fetchDataFunction={(page) => fetchResults(query, page)}
      renderItem={renderItem}
      itemKeyExtractor={(item) => String(item.uuid)}
      triggerLoad={triggerLoad}
    />
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    minHeight: 70, // Ensures there's enough space for taller button and text
  },
  cardTouchArea: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1, // Allows this part to expand and fill available space
    marginRight: 10, // Space between this area and the Set Profile button
  },
  cardThumbnail: {
    width: 50,
    height: 50,
    borderRadius: 5,
    marginRight: 10, // Space between thumbnail and text
  },
  cardName: {
    flex: 1, // Allows text to expand and fill available space, pushing long text to truncate
    fontSize: theme.fonts.sizes.large,
    fontFamily: theme.fonts.family.medium,
    color: theme.colors.redPrimary,
    marginRight: 5, // Ensures text doesn't touch the Set Profile button
  },
  setProfileButton: {
    backgroundColor: theme.colors.bluePrimary,
    paddingVertical: 8, // Makes the button taller
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  setProfileButtonText: {
    color: 'white',
    fontSize: theme.fonts.sizes.small,
  },
});

export default CardsList;
