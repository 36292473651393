// HeaderIcon.js
import React from 'react';
import Svg, { G, Path, Defs, ClipPath, Rect } from 'react-native-svg';

const CenteredSVG = () => (
  <G translate="20, 15">
    <Path
      d="M0.854302 35.4069C2.25073 35.4069 3.43638 34.7065 4.39807 33.3057C5.36635 31.9049 6.08432 29.9256 6.56516 27.3676L9.73347 10.6616H6.61128L6.92086 9.01107H10.0431L10.3131 7.77472C11.2946 3.09728 14.3443 0.764648 19.4624 0.764648C21.333 0.764648 22.5582 0.971723 23.1181 1.38587C23.6516 1.74521 24.0534 2.36034 24.3235 3.24345L22.8085 4.27273C22.4792 3.63932 22.0049 3.17036 21.3792 2.87193C20.7534 2.56741 19.9366 2.41515 18.9222 2.41515C17.8552 2.41515 16.9198 2.8293 16.136 3.6515C15.3521 4.47371 14.7461 5.85015 14.3312 7.77472L14.0611 9.01107H20.3055L19.9959 10.6616H13.7515L10.5832 27.3676C10.1682 29.5114 9.59515 31.2228 8.864 32.5018C8.13285 33.7808 7.11847 34.7248 5.80767 35.3278C4.49687 35.9307 2.79085 36.2352 0.676453 36.2352L0.854302 35.413V35.4069Z"
      fill="#F7EBDF"
    />
  </G>
);

const HeaderIcon = (props) => {
  return (
    <Svg width={40} height={40} viewBox="0 0 67 67" {...props}>
      <G clipPath="url(#clip0_126_48)">
        <Path
          d="M67.0012 33.5005L33.5012 0.000488281L0.00114838 33.5005L33.5012 67.0005L67.0012 33.5005Z"
          fill="#DB4C2A"
        />
        <Path
          d="M33.5011 59.6988C47.9696 59.6988 59.6987 47.9698 59.6987 33.5013C59.6987 19.0328 47.9696 7.30371 33.5011 7.30371C19.0326 7.30371 7.30359 19.0328 7.30359 33.5013C7.30359 47.9698 19.0326 59.6988 33.5011 59.6988Z"
          fill="#DB4C2A"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_126_48">
          <Rect width={67} height={67} fill="white" />
        </ClipPath>
      </Defs>

      <CenteredSVG />

    </Svg>
  );
};

export default HeaderIcon;

