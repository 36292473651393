import React, {useState, useContext, useRef, useEffect} from 'react';
import { View, TextInput, StyleSheet, Text, Alert } from 'react-native';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigation } from "@react-navigation/native";
import PrimaryButton from "../components/PrimaryButton";
import theme from "../styles/theme";
import styles from "../styles/Settings.styles";
import alert from "../utils/alert";
import PasswordInputWithConditions, {PasswordInputBasic} from "../components/PasswordInputWithConditions";


const ChangePasswordScreen = () => {
  const navigation = useNavigation();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const { changePassword } = useContext(AuthContext);

  const oldPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmNewPasswordRef = useRef(null);

  // useEffect(() => {
  //   // Automatically focus the TextInput when the screen is loaded
  //   oldPasswordRef.current.focus();
  // }, []);

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      alert("Passwords don't match", "Please make sure the new passwords match.");
      return;
    }

    try {
      await changePassword(oldPassword, newPassword);
      alert('Success', 'Your password has been changed.', [
        {text: "OK", onPress: () => navigation.goBack()}
      ]);
      // You can navigate the user away from the change password screen if needed
    } catch (error) {
      alert('Error', error.message);
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>CHANGE PASSWORD</Text>

      {/*<TextInput*/}
      {/*  ref={oldPasswordRef}*/}
      {/*  style={styles.input}*/}
      {/*  value={oldPassword}*/}
      {/*  onChangeText={setOldPassword}*/}
      {/*  placeholder="Old Password"*/}
      {/*  secureTextEntry*/}
      {/*  placeholderTextColor={theme.colors.gray}*/}
      {/*  onSubmitEditing={() => newPasswordRef.current.focus()}*/}
      {/*  returnKeyType="next"*/}
      {/*/>*/}
      <PasswordInputBasic
          password={oldPassword}
          setPassword={setOldPassword}
          placeholder="Old Password"
      />
      {/*<TextInput*/}
      {/*  ref={newPasswordRef}*/}
      {/*  style={styles.input}*/}
      {/*  value={newPassword}*/}
      {/*  onChangeText={setNewPassword}*/}
      {/*  placeholder="New Password"*/}
      {/*  secureTextEntry*/}
      {/*  placeholderTextColor={theme.colors.gray}*/}
      {/*  onSubmitEditing={() => confirmNewPasswordRef.current.focus()}*/}
      {/*  returnKeyType="next"*/}
      {/*/>*/}
      <PasswordInputWithConditions
          password={newPassword}
          setPassword={setNewPassword}
          placeholder="New Password"
      />
      {/*<TextInput*/}
      {/*  ref={confirmNewPasswordRef}*/}
      {/*  style={styles.input}*/}
      {/*  value={confirmNewPassword}*/}
      {/*  onChangeText={setConfirmNewPassword}*/}
      {/*  placeholder="Confirm New Password"*/}
      {/*  secureTextEntry*/}
      {/*  placeholderTextColor={theme.colors.gray}*/}
      {/*/>*/}
      <PasswordInputBasic
          password={confirmNewPassword}
          setPassword={setConfirmNewPassword}
          placeholder="Confirm New Password"
      />
      <PrimaryButton title="CHANGE PASSWORD" onPress={handleChangePassword} />
    </View>
  );
};

export default ChangePasswordScreen;
