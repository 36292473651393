import React, { useState, useContext } from 'react';
import { View, TextInput, Text, StyleSheet } from 'react-native';
import { AuthContext } from '../contexts/AuthContext';
import PrimaryButton from "../components/PrimaryButton";
import styles from "../styles/Settings.styles";
import theme from "../styles/theme";
import PasswordInputWithConditions, {PasswordInputBasic} from "../components/PasswordInputWithConditions";


const CompleteNewPasswordScreen = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const { completeNewPassword, user } = useContext(AuthContext);

  const handleCompleteNewPassword = async () => {
    if (newPassword !== confirmNewPassword) {
      alert("Passwords don't match", "Please make sure the new passwords match.");
      return;
    }

    try {
      await completeNewPassword(user, newPassword);
    } catch (error) {
      alert('Error', error.message);
    }
  };

  return (
    <View style={localStyles.outerContainer}>
      <View style={styles.container}>
        <Text style={styles.title}>SET NEW PASSWORD</Text>
        <Text style={styles.currentContactText}>Fabyl has undergone a significant update and you'll need to set a new password before logging back in.</Text>
        <PasswordInputWithConditions
          password={newPassword}
          setPassword={setNewPassword}
          placeholder="New Password"
        />
        <PasswordInputBasic
          password={confirmNewPassword}
          setPassword={setConfirmNewPassword}
          placeholder="Confirm New Password"
        />
        <PrimaryButton title="SET PASSWORD" onPress={handleCompleteNewPassword} />
      </View>
    </View>
  );
};

// add localStyles
const localStyles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.colors.beige,
  }
});


export default CompleteNewPasswordScreen;
