import React, { memo } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions } from 'react-native';
import { Image } from 'expo-image';
import { useNavigation } from '@react-navigation/native';
import theme from '../styles/theme';

const windowWidth = Dimensions.get('window').width;

export const CARD_WIDTH = Math.min(windowWidth / 2.2, 200);
export const CARD_HEIGHT = 40; // Adjusted to match the height of text elements and margins
export const CARD_MARGIN = theme.spacings.xsmall;
export const CARD_PADDING = theme.spacings.xsmall;
export const TOTAL_CARD_HEIGHT = CARD_HEIGHT + (CARD_MARGIN + CARD_PADDING) * 2; // Total height including padding

const MiniCardWithInfo = memo(({ item, pressable = true, newScreen = 'CardDetail' }) => {
  const navigation = useNavigation();

  const defaultPressHandler = () => {
    console.log('Card pressed'); // Debug log to check if the press is registered
    navigation.navigate(newScreen, { card: item });
  };

  return (
    <TouchableOpacity disabled={!pressable} onPress={defaultPressHandler}>
      <View style={[styles.cardTileContainer, { height: CARD_HEIGHT + CARD_PADDING * 2 }]}>
        <Image
          source={{ uri: item.art || item.thumbnail }}
          style={styles.imageStyle}
        />
        <View style={styles.textContainer}>
          <Text style={styles.cardName} numberOfLines={1} ellipsizeMode="tail">
            {item.name}
          </Text>
          <Text style={styles.creatorName} numberOfLines={1} ellipsizeMode="tail">
            @{item.creator_preferred_username}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
});

const styles = StyleSheet.create({
  cardTileContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: theme.spacings.xsmall,
    backgroundColor: theme.colors.loadingBackground,
    borderRadius: theme.borderRadius.medium,
    borderColor: theme.colors.gray,
    marginVertical: CARD_MARGIN,
    padding: CARD_PADDING,
  },
  imageStyle: {
    height: CARD_HEIGHT, // Set static height
    width: CARD_HEIGHT, // Keep width and height same to make it square
    borderRadius: theme.borderRadius.small, // Adjust as needed
    marginRight: theme.spacings.small, // Space between image and text
  },
  textContainer: {
    height: CARD_HEIGHT,
    flexDirection: 'column',
    justifyContent: 'space-between', // Vertically center the text
  },
  cardName: {
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.medium,
    color: theme.colors.redPrimary,
  },
  creatorName: {
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.small,
    color: theme.colors.bluePrimary,
  },
});

export default MiniCardWithInfo;
