import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const SvgHome = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    viewBox="0 0 34 34"
    fill="none"
    {...props}
  >
    <Circle cx={16.971} cy={17} r={14} fill="#F9C430" />
    <Path fill="#F9C430" d="M16.971.03 33.941 17l-16.97 16.97L.001 17z" />
    <Path fill="#333" d="M10 14h14v10H10z" />
    <Path fill="#333" d="m17 7 8.66 7.5H8.34z" />
    <Path fill="#F9C430" d="M15 19h4v5h-4z" />
  </Svg>
);
export default SvgHome;
