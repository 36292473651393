import React from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import theme from '../../styles/theme';
import { MaterialIcons } from '@expo/vector-icons';
import UserCircle from "../UserCircle";
import { useNavigation } from "@react-navigation/native";
import {useQuery} from "react-query";
import {fetchPublicProfileData} from "../../utils/api/userService";
import {usePublicProfileData} from "../../hooks/usePublicProfileData";

const AddFriendToRiffModal = ({ isVisible, onClose, profile_uuid=null}) => {
  const navigation = useNavigation();
  const { data: profileData, isLoading, isError } = usePublicProfileData(profile_uuid);


  const navigateToUserProfile = () => {
    navigation.navigate('UserProfile', { profile__uuid: profile_uuid });
    onClose();
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <View style={styles.centeredView}>
        <TouchableOpacity
          style={StyleSheet.absoluteFill}
          activeOpacity={1}
          onPress={onClose}
        />
        <View style={styles.modalView}>
          <TouchableOpacity
            style={styles.closeButton}
            onPress={onClose}
            hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}
          >
            <MaterialIcons name="close" size={24} color={theme.colors.black} />
          </TouchableOpacity>

          <Text style={styles.modalTitle}>
            Add user to riff.
          </Text>
          <Text style={styles.modalDescription}>
            You must be friends with at least one contributor to this world in order to riff.
          </Text>
          {profileData && (
            <TouchableOpacity
              style={styles.userContainer}
              onPress={navigateToUserProfile}
            >
              <UserCircle profile_uuid={profile_uuid} size={75} />
              <Text style={styles.username}>{profileData?.preferred_username}</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    borderRadius: theme.borderRadius.medium,
    backgroundColor: theme.colors.loadingBackground,
    shadowColor: theme.colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginBottom: theme.spacings.medium,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  modalView: {
    margin: 20,
    backgroundColor: theme.colors.beige,
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '85%',
    maxWidth: 500,
    minHeight: '30%',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  modalTitle: {
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.xlarge,
    marginBottom: theme.spacings.medium,
  },
  modalDescription: {
    fontFamily: theme.fonts.family.regular,
    color: theme.colors.gray,
    fontSize: theme.fonts.sizes.large,
    marginBottom: theme.spacings.medium,
  },
  username: {
    fontFamily: theme.fonts.family.bold,
    color: theme.colors.bluePrimary,
    fontSize: theme.fonts.sizes.xxlarge,
    marginLeft: theme.spacings.medium,
  },
  buttonsContainer: {
    marginTop: theme.spacings.large,
    alignItems: 'center',
  },
  optionButton: {
    borderRadius: theme.borderRadius.medium,
    paddingVertical: theme.spacings.small,
    paddingHorizontal: theme.spacings.medium,
    alignItems: 'center',
    flexDirection: 'row',
  },
  optionText: {
    color: theme.colors.black,
    textAlign: 'center',
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.large,
  },
  iconContainer: {
    width: 24,
    alignItems: 'center',
    marginRight: theme.spacings.small,
  },
});

export default AddFriendToRiffModal;
