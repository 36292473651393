import { StyleSheet, Dimensions } from 'react-native';
import theme from '../theme';

const screenHeight = Dimensions.get('window').height;
const screenWidth = Dimensions.get('window').width;
const cardMarginVertical = theme.spacings.small;
// Assuming a medium spacing for card vertical margins
const cardMarginHorizontal = theme.spacings.xsmall;
// Assuming a small spacing for card horizontal margins
// const cardWidth = Math.min(screenWidth - (2 * cardMarginHorizontal), 500);
const cardWidth = screenWidth - (2 * cardMarginHorizontal)
// Subtract margins from both sides of the screen width
const cardHeight = 0.8 * (screenHeight - (2 * cardMarginVertical));
// const cardHeight = 1.618 * cardWidth;
// Subtract margins from top and bottom of the screen height
const imageHeight = cardHeight * 0.52; // Image takes half of the card's height
const imageWidth = cardHeight * 0.52; // Image takes the full width of the card

export default StyleSheet.create({
  cardContainer: {
    flex: 1,
    alignSelf: 'center',
    // justifyContent: 'space-between',
    alignItems: 'flex-start', // Align contents to the left
    // backgroundColor: theme.colors.gray,
    // borderRadius: 14,
    // padding: theme.spacings.small,
    // marginVertical: cardMarginVertical,
    // marginHorizontal: cardMarginHorizontal,
    height: cardHeight,
    // width: cardWidth, // Use the calculated width
  },
  spur: {
    alignSelf: 'center',
    position: 'absolute',
    marginTop: 5,
    zIndex: 1,
    // height: 30,
    // width: 30,
  },
  Card: {
    flex: 1,
    // justifyContent: 'space-between',
    alignItems: 'flex-start', // Align contents to the left
    backgroundColor: theme.colors.gray,
    borderRadius: 14,
    padding: theme.spacings.small,
    marginVertical: cardMarginVertical,
    marginHorizontal: cardMarginHorizontal,
    height: cardHeight,
    width: cardWidth, // Use the calculated width
  },

  bookmarkButton: {
    // Button styles (padding, background color, etc.)
    paddingRight: 15,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  heartButton: {
    // Button styles (padding, background color, etc.)
    // paddingLeft: 5,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  riffButton: {
    // Button styles (padding, background color, etc.)
    paddingLeft: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  riffLine: {
    // justifyContent: 'center',
    // flexDirection: 'row',
    // alignItems: 'center',
    alignSelf: 'center',
  },
  fullScreenContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.black,
  },
  imageContainer: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.black,
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  progressBarContainer: {
    position: 'absolute',
    bottom: 0, // Align to the bottom of the imageContainer
    left: 0,
    right: 0,
    height: 10, // Adjust thickness of the progress bar
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Background of the progress bar
  },
  progressBar: {
    height: '100%',
    backgroundColor: 'blue', // Color of the progress bar
  },
  image: {
    alignSelf: 'center',
    width: imageWidth,
    height: imageHeight,
    resizeMode: 'contain',
  },
  audioPlayer: {
    position: 'absolute',
    bottom: 30,
    left: 20,
    right: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 25,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  audioText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },

  iconStyle: {
    color: 'white',
    fontSize: 20,
    paddingHorizontal: 10,
  },
  artTouchableOpacityStyle: {
    // Set dimensions to match the image or the desired size
    marginTop: theme.spacings.small,
    width: '100%', // e.g., 100% or a fixed value
    height: imageHeight, // e.g., a fixed value or proportional to width
    marginBottom: 0,
    // marginBottom: theme.spacings.xsmall,
    // Other styles as needed
  },
  textContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'flex-start', // Align text to the left
    marginLeft: theme.spacings.small,
    marginRight: theme.spacings.small,
    // marginTop: 0,
  },
  name: {
    color: theme.colors.redPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.large,
    marginVertical: theme.spacings.medium,
    paddingTop: 0,
    fontWeight: theme.fonts.weight.bold,
    alignSelf: 'flex-start', // Ensure the title is aligned to the left
    width: '95%', // Give some padding inside the card
  },
  flavor: {
    color: theme.colors.white,
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.medium,
    // marginVertical: theme.spacings.xsmall,
    alignSelf: 'flex-start', // Ensure the text is aligned to the left
    width: '95%', // Give some padding inside the card
  },
  closeIcon: {
    position: 'absolute',
    top: 50,
    right: 10,
    padding: 20, // Padding to make it easier to tap
  },
  dragIndicator: {
    width: 40, // Width of the line
    height: 5, // Height of the line
    borderRadius: 2.5, // Half of the height to make it rounded
    backgroundColor: '#ccc', // Color of the line
    alignSelf: 'center', // Center it horizontally
    marginTop: 10, // Space from the top
    marginBottom: 10, // Space from the rest of the content
  },
  bottomContainer: {
    width: '95%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: "auto",
  },
  userContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
  user: {
    color: theme.colors.bluePrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.large,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between', // Push buttons to the right
    alignItems: 'center',
    padding: 10,
    // marginRight: theme.spacings.small,
  },
  heartIcon: {
    // Placeholder for heart icon styles (if required)
  },
  // Navigation buttons container
  siblingRiffLines: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'absolute', // Position the buttons over the image
    top: -40,
    // left: 5,
    // right: 5,
    paddingHorizontal: 10, // Add horizontal padding
    zIndex: 1, // Ensure it's above other card elements
  },
  navigationContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'absolute', // Position the buttons over the image
    // paddingHorizontal: theme.spacings.small, // Add horizontal padding
    zIndex: 1, // Ensure it's above other card elements
  },
  // Circle button styles
  circleButton: {
    alignSelf: 'center', // Center the button horizontally
    alignItems: 'center', // Center icon horizontally
  },
  // Add styles for the arrows if using Text, or style the Icon component directly in the JSX
  arrowIcon: {
    // Add specific stylings for the icon component, if necessary
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-start',
    // width: '100%',
    marginTop: 22,
  },
  modalView: {
    position: 'absolute',
    top: 0,
    marginTop: 0,
    backgroundColor: theme.colors.beige,
    borderRadius: 20,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: 15,
    paddingTop: 70,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    // marginBottom: 200,
    elevation: 5,
    // width: cardWidth,
    width: "100%",
    zIndex: 10,
  },
  bottomRiffBox: {
    width: cardWidth,
    // marginHorizontal: theme.spacings.small,
    flexDirection: 'row',
    paddingHorizontal: 110,
    paddingTop: theme.spacings.medium,
    paddingBottom: theme.spacings.medium,
    justifyContent: 'space-between',
    // justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    alignSelf: 'center',
    backgroundColor: theme.colors.gray,
    borderRadius: 10,
    color: theme.colors.yellowPrimary,
    marginBottom: theme.spacings.large,
  },
  bottomRiffText: {
    color: theme.colors.yellowPrimary,
    fontFamily: theme.fonts.family.medium,
    fontSize: theme.fonts.sizes.medium,
    // justifyContent: 'flex-start', // Align to the left
    alignItems: 'center',
    marginLeft: theme.spacings.small,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    zIndex: 0,
  },
  modalHeader: {
    backgroundColor: theme.colors.gray,
    borderRadius: 20,
    padding: 15,
    alignSelf: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    // Add other styling as needed
  },
  modalTitle: {
    alignSelf: 'center',
    fontFamily: theme.fonts.family.regular,
    fontSize: theme.fonts.sizes.large,
    // Add other styling as needed
  },
  cardName: {
    color: theme.colors.redPrimary,
    fontFamily: theme.fonts.family.bold,
    fontSize: theme.fonts.sizes.large,
    // Add other styling specific to the card name
  },
  thumbnailStyle: {
    width: 80, // Adjust size as needed
    height: 80, // Adjust size as needed
    borderRadius: 10, // For a rounded thumbnail
    borderWidth: 0.5,
    marginLeft: 10,
    // Add other styling as needed
  },
  // Add styles for other buttons and elements as required...
});

export { cardHeight, imageHeight, screenHeight }
