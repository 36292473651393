import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";
const SvgSiblingButtonLeft = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={45}
    fill="none"
    {...props}
  >
    <Circle
      cx={22.5}
      cy={22.5}
      r={21.75}
      fill="#F9C430"
      stroke="#896500"
      strokeWidth={1.5}
      transform="rotate(-180 22.5 22.5)"
    />
    <Path
      fill="#333"
      d="M36 23.75h.75v-1.5H36zM6 23l7.5 4.33v-8.66zm28.875-.75h-.75v1.5h.75zm-3 1.5h.75v-1.5h-.75zm-6.75-1.5h-.75v1.5h.75zm-3 1.5h.75v-1.5h-.75zm-2.25-1.5h-.75v1.5h.75zm-3 1.5h.75v-1.5h-.75zm-6.75-1.5h-.75v1.5h.75zm-3 1.5h.75v-1.5h-.75zM36 22.25h-1.125v1.5H36zm-4.125 0h-6.75v1.5h6.75zm-9.75 0h-2.25v1.5h2.25zm-5.25 0h-6.75v1.5h6.75z"
    />
  </Svg>
);
export default SvgSiblingButtonLeft;

